import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import defaultWideLogo from './branding_h48px.svg'

const useLogoStyles = makeStyles(theme => ({
  logo: {
    height: 48,
    marginBottom: theme.spacing(1)
  }
}))

function HeadingBrandLogo() {
  const classes = useLogoStyles()
  const {
    branding: { logo: { wide: wideLogo, square: squareLogo } } = {
      logo: { wide: null, square: null }
    }
  } = useTheme()

  return (
    <Hidden xsDown lgUp>
      {/*<Slide direction="up" in>*/}
      <img
        alt="logo"
        src={wideLogo ? `${process.env.PUBLIC_URL}/${wideLogo}` : defaultWideLogo}
        className={classes.logo}
      />
      {/*</Slide>*/}
    </Hidden>
  )
}

const useStyles = makeStyles(theme => ({
  header: {
    // maxWidth: theme.breakpoints.values.md,
    margin: `${theme.spacing(24)}px auto 0`,
    // paddingLeft: theme.spacing(24 + 3),
    // Tablet or smaller
    [theme.breakpoints.down('md')]: {
      marginTop: 72 + 24, // clear the appbar and some breathing room
      marginLeft: 72 + 36, // clear the nav rail and extra half of negative space
      padding: theme.spacing(2),
      paddingRight: theme.spacing(3),
      width: `calc(100% - 72px - 36px)`
    },
    // Mobile
    [theme.breakpoints.down('xs')]: {
      marginTop: 0, // there's no appbar
      marginLeft: 'auto', // reset margin
      padding: theme.spacing(2),
      width: '100%'
    }
  }
}))

const Header = React.forwardRef(function Header({ children, className }, ref) {
  const classes = useStyles()
  return (
    <Container
      maxWidth="md"
      component="header"
      className={clsx(classes.header, className)}
      ref={ref}
    >
      <HeadingBrandLogo />
      {children}
    </Container>
  )
})

export default Header

Header.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

Header.defaultProps = {
  className: null
}
