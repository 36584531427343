const types = {
  // AEAT
  K: 'Menor de 14 años sin DNI',
  L: 'Residente en el extranjero sin DNI',
  M: 'Extranjero sin NIE, de forma transitoria o definitiva',
  // NIE
  X: 'Numero de Identidad de Extranjero',
  Y: 'Numero de Identidad de Extranjero',
  Z: 'Numero de Identidad de Extranjero',
  // CIF
  A: 'Sociedad anónima',
  B: 'Sociedad de responsabilidad limitada',
  C: 'Sociedad colectiva',
  D: 'Sociedad comanditaria',
  E: 'Comunidad de bienes',
  F: 'Sociedad cooperativa',
  G: 'Asociación o Fundación',
  H: 'Comunidad de propietarios en régimen de propiedad horizontal',
  J: 'Sociedad civil, con o sin personalidad jurídica',
  N: 'Entidad extranjera',
  P: 'Corporación Local',
  Q: 'Organismo público',
  R: 'Congregación o institución religiosa',
  S: 'Órgano de la Administración General del Estado o de Comunidad Autónoma',
  U: 'Unión Temporal de Empresas',
  V: '',
  W: 'Establecimiento permanente de entidad no residente en España',
  // DNI
  0: 'Documento Nacional de Identidad',
  1: 'Documento Nacional de Identidad',
  2: 'Documento Nacional de Identidad',
  3: 'Documento Nacional de Identidad',
  4: 'Documento Nacional de Identidad',
  5: 'Documento Nacional de Identidad',
  6: 'Documento Nacional de Identidad',
  7: 'Documento Nacional de Identidad',
  8: 'Documento Nacional de Identidad',
  9: 'Documento Nacional de Identidad'
}

const dniRegex = /^\d{8}[A-Z]{1}$/
const nieRegex = /^[KLMXYZ]{1}\d{7}[A-Z]{1}$/
const cifRegex = /^[A-HJNP-SUVW]{1}\d{7}[A-J0-9]{1}$/

const dniControlChars = [
  'T',
  'R',
  'W',
  'A',
  'G',
  'M',
  'Y',
  'F',
  'P',
  'D',
  'X',
  'B',
  'N',
  'J',
  'Z',
  'S',
  'Q',
  'V',
  'H',
  'L',
  'C',
  'K',
  'E'
]

const getControlDigitForDNI = eid => {
  const i = eid.substring(0, 8) % 23
  return dniControlChars[i]
}

const nieReplacements = { X: 0, Y: 1, Z: 2, K: 0, L: 0, M: 0 }

const getControlDigitForNIE = eid => {
  const letter = eid.charAt(0)
  const replaced = nieReplacements[letter] + eid.substring(1, 8)
  const i = replaced.substring(0, 8) % 23
  return dniControlChars[i]
}

const getControlDigitForCIF = eid => {
  let even = 0
  let odd = 0
  eid
    .substring(1, 8)
    .split('')
    .map(Number)
    .forEach((digit, i) => {
      if (i % 2) {
        even += digit
      } else {
        odd += ((digit * 2 - 1) % 9) + 1
      }
    })
  const accumDigit = (even + odd) % 10
  const controlDigit = (10 - accumDigit) % 10
  const controlLetter = 'JABCDEFGHI'.substr(controlDigit, 1)
  // PQRSW or begins with 00 -> letter
  if (/[PQRSW]/.test(eid.charAt(0))) return controlLetter
  if (/[0]{2}/.test(eid.substring(1, 3))) return controlLetter
  // ABEH -> digit
  if (/[ABEH]/.test(eid.charAt(0))) return controlDigit.toString()
  // Others -> can be letter or digit, check against the current id
  return /\d/.test(eid.charAt(8)) ? controlDigit.toString() : controlLetter
}

const electronicFormat = unformatted => {
  // Leave only numbers and characters
  // Pad with zeros from the left if less than 9 characters
  return unformatted
    ? unformatted
        .toUpperCase()
        .replace(/[^0-9A-Z]/g, '')
        .padStart(9, '0')
    : unformatted
}

// L 7N-C
const printNIE = unformatted => {
  return unformatted.replace(/(.{1})(.{7})(.{1})/g, '$1 $2-$3')
}

const printFormat = unformatted => {
  // DNI -> electronic format
  // Legal entity NIF -> electronic format
  // NIE and special tax authority NIF -> L 7N-C
  const eid = electronicFormat(unformatted)
  return /[KLMXYZ]/.test(eid.charAt(0)) ? printNIE(eid) : eid
}

const isValid = id => {
  // Not null
  if (id === null) return false
  // 9 characters in electronic form
  const eid = electronicFormat(id)
  if (eid.length !== 9) return false
  // Check pattern
  if (dniRegex.test(eid)) return eid.charAt(8) === getControlDigitForDNI(eid) // check CD
  if (nieRegex.test(eid)) return eid.charAt(8) === getControlDigitForNIE(eid) // check CD
  if (cifRegex.test(eid)) return eid.charAt(8) === getControlDigitForCIF(eid) // check CD
  return false
}

export { types, printFormat, electronicFormat, isValid }
