import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  main: {
    flexGrow: 1
  }
}))

const Main = React.forwardRef(function Content({ children, className }, ref) {
  const classes = useStyles()
  return (
    <main className={clsx(classes.main, className)} ref={ref}>
      {children}
    </main>
  )
})

export default Main

Main.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

Main.defaultProps = {
  className: null
}
