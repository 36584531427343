import React from 'react'

import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(2, 0)
  }
}))

export default function DialogDivider() {
  const classes = useStyles()
  return <Divider className={classes.divider} />
}
