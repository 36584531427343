import React, { useState, useCallback, useRef } from 'react'
import clsx from 'clsx'
import { makeStyles, fade, useTheme } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'

import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Fade from '@material-ui/core/Fade'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Fab from '@material-ui/core/Fab'
import Drawer from '@material-ui/core/Drawer'

import ExploreIcon from '@material-ui/icons/Explore'
import PublicIcon from '@material-ui/icons/Public'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'

import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone'

import Chip from '@material-ui/core/Chip'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import AddresSimpleLabel from './AddressSimpleLabel'
import StreetNameNumber from './StreetNameNumber'
import BlockToponym from './BlockToponym'
import Door from './Door'
import CountryRegionCity from './CountryRegionCity'

import Grow from '@material-ui/core/Grow'
import Skeleton from '@material-ui/lab/Skeleton'
import Hidden from '@material-ui/core/Hidden'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import { useLocationContext } from '../../components/Context/LocationContext'

import debounce from 'lodash/debounce'
import useAutocomplete from '@material-ui/lab/useAutocomplete'
import { IconButton } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  heading: {
    marginBottom: theme.spacing(4)
  },
  typography: {
    padding: theme.spacing(2)
  },
  popoverPaperRoot: {
    //padding: theme.spacing(2),
    //minWidth: theme.breakpoints.values.sm,
    //maxWidth: theme.breakpoints.values.sm,
    minWidth: props => props.anchor.current?.clientWidth + 4,
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    borderStyle: 'solid'
  },
  popoverPaperFullScreen: {
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: 'none',
    borderStyle: 'none'
  },
  actions: {
    marginTop: theme.spacing(4),
    display: 'flex',
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.action.hover
  },
  actionsRight: { flexGrow: 1, textAlign: 'right' },
  inputRoot: {
    flex: 1,
    padding: theme.spacing(1, 2, 0, 1.5),
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
      marginTop: theme.mixins.toolbar.minHeight
    }
  },
  inputBase: {
    // Same as OutlinedInput ('18.5px 14px') less the border of the Paper on the top and left borders: '15.5px 14px 18.5px 12px'
    // Adjusted to align icon position with the Field '15.5px 14px 18.5px 4px'
    // Adjusted to position bottom border
    padding: '15.5px 14px 10.5px 4px',
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    borderBottomColor: theme.palette.field.border,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 4
    }
  },
  appBar: {
    padding: theme.spacing(2, 2, 1),
    background: theme.palette.common.white
  },
  subheading: {
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  chipIcon: {
    marginLeft: theme.spacing(1.5)
  },
  chipArrow: {
    marginLeft: theme.spacing(1)
  },
  chipLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  chipTypography: {
    flexShrink: 1
  },
  city: {
    padding: theme.spacing(1, 2, 2, 0),
    marginLeft: theme.spacing(6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(6.5)
    }
  },
  fabSection: {
    width: '100%',
    position: 'fixed',
    bottom: theme.mixins.toolbar.minHeight,
    display: 'flex',
    justifyContent: 'center'
  },
  fab: {},
  door: {
    paddingLeft: theme.spacing(5.5),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      // marginTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  drawer: {
    padding: theme.spacing(2)
  },
  drawerGrid: {
    marginTop: theme.spacing(1)
  },
  menuList: {
    // borderTopStyle: 'solid',
    // borderTopColor: theme.palette.text.primary
  },
  menuItem: {
    // border: '2px solid transparent',
    padding: theme.spacing(0.75, 4.25),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.75, 2, 0.75, 2.75)
    },
    // borderRadius: '4px',
    '&[aria-selected="true"]': {
      // backgroundColor: fade(theme.palette.primary.main, 0.2),
      // borderColor: 'grey'
      '& $listItemIcon': {
        color: theme.palette.primary.main
      },
      '& $listItemTextPrimary': {
        fontWeight: 500,
        color: theme.palette.primary.main
      }
    },
    '&[data-focus="true"]': {
      backgroundColor: fade(theme.palette.primary.light, 0.1),
      // borderColor: theme.palette.common.black
      '& $listItemIcon': {
        color: theme.palette.primary.light
      }
    },
    '&:active': {
      // backgroundColor: fade(theme.palette.primary.main, 0.2),
      // borderColor: 'grey'
      '& $listItemIcon': {
        color: theme.palette.primary.main
      },
      '& $listItemTextPrimary': {
        fontWeight: 500,
        color: theme.palette.primary.main
      }
    },
    '&[aria-disabled="true"]': {
      opacity: theme.palette.action.disabledOpacity
      // pointerEvents: 'none'
    }
  },
  listItemIcon: {
    minWidth: 'unset',
    padding: '9px'
  },
  listItemText: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1.25)
    }
  },
  listItemTextPrimary: {} // pseudo-class
}))

export default function Address({ id, title, description, help, required, defaultValue }) {
  // Field button anchor ref
  const anchor = useRef()

  // JSS classes
  const classes = useStyles({ anchor })

  // Go fullscreen if mobile
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

  // Location context
  const [defaultLocation, locationUtils, addressUtils] = useLocationContext()

  // Controlled Autocomplete
  const [inputValue, setInputValue] = useState('')
  const [value, setValue] = useState('')

  // Controlled Query
  const [query, setQuery] = useState('')
  // const [streets, accesses, results, isLoading, isError] = useTerritoryLookup(query)
  const [streets, accesses, results, isLoading, isError] = locationUtils.access.findAccess(query)

  // Address value and handlers
  const BLANK_ADDRESS = addressUtils.emptyAddress
  const [address, setAddress] = useState(BLANK_ADDRESS)
  const handleClearAddress = () => {
    setAddress(BLANK_ADDRESS)
    setInputValue('')
    setValue('')
  }
  const handleAccessEdit = v => setAddress(prev => addressUtils.updateAccess(prev, v))
  const handleApartmentEdit = (event, label) =>
    setAddress(prev =>
      addressUtils.updateApartment(prev, event.target.name, event.target.value, label)
    )

  // Popover state management
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  // Popover section visibility
  const [listOpen, setListOpen] = useState(false) // Controls showing the list of results
  const [formOpen, setFormOpen] = useState(false) // Controls showing the additional form controls
  const [actionsOpen, setActionsOpen] = useState(false) // Controls showing the additional actions
  const [settingsOpen, setSettingsOpen] = useState(false) // Controls showing the settings panel

  // Debounced query handler
  const debouncedQuery = useCallback(
    debounce(q => {
      setQuery(q)
      setListOpen(true)
      setActionsOpen(true)
    }, 350),
    []
  )

  // Input value change handler
  const handleInputChange = (e, v, r) => {
    if (r === 'input') {
      setInputValue(v)
      debouncedQuery(v)
    }
  }

  // Value change handler
  const handleChange = (event, val, reason) => {
    setValue(val)
    setInputValue(locationUtils.access.getAccessOptionLabel(val))
    console.log(val)
    console.log(`changed bc ${reason}`)
    // reason One of "create-option", "select-option", "remove-option", "blur" or "clear".
    if (reason === 'select-option') {
      setListOpen(false)
      //setFormOpen(true)
      handleAccessEdit(val)
    }
    if (reason === 'clear') {
      handleClearAddress()
    }
  }

  // Autocomplete close handler
  const handleCloseIntent = (event, reason) => {
    console.log(`wants to close bc ${reason}`)
    // blur, toggleInput, select-option, escape
    const allowedExits = ['escape']
    if (allowedExits.includes(reason)) {
      setOpen(false)
      //setAnchorEl(null)
    }
  }

  // Debounced input change handler
  /*const debouncedHandleChange = useCallback(
    debounce((e, v, r) => {
      if (r === 'input') {
        setInputValue(v)
        setListOpen(true)
        setActionsOpen(true)
      }
    }, 350),
    []
  )*/

  // Autocomplete configuration
  const {
    getRootProps,
    // getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    // getClearProps,
    groupedOptions
  } = useAutocomplete({
    id: 'autocomplete-demo',
    // style: { width: 300 },
    value,
    inputValue,
    onClose: handleCloseIntent,
    onChange: handleChange,
    onInputChange: handleInputChange,
    getOptionLabel: locationUtils.access.getAccessOptionLabel,
    getOptionSelected: locationUtils.access.getAccessOptionSelected,
    filterOptions: x => x,
    options: accesses,
    autoComplete: true,
    includeInputInList: true,
    disableCloseOnSelect: true,
    disablePortal: true,
    open: listOpen,
    blurOnSelect: true
  })

  return (
    <>
      <AddresSimpleLabel
        id={`label-for-address-${id}`}
        title={title}
        help={help}
        required={required}
        onClick={handleClick}
        onClear={handleClearAddress}
        ref={anchor}
        value={address.label}
        primary={addressUtils.formatPrimaryLabel(address)}
        secondary={addressUtils.formatSecondaryLabel(address)}
      />

      {/*<pre>{JSON.stringify(address, null, 2)}</pre>*/}

      <Popover
        id={`popover-for-address-${id}`}
        open={open}
        //open
        anchorEl={anchor.current}
        onClose={handleClose}
        onEntering={() => {
          //value ? setFormOpen(true) : setListOpen(true)
          setListOpen(!value)
        }}
        onExited={() => {
          setListOpen(false)
          setActionsOpen(false)
        }}
        PaperProps={{
          classes: {
            root: clsx(classes.popoverPaperRoot, fullScreen && classes.popoverPaperFullScreen)
          }
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        marginThreshold={fullScreen ? 0 : 16}
      >
        {/*<Typography variant="h6" gutterBottom className={classes.heading}>
          Dirección en Barcelona, España
      </Typography>*/}

        {fullScreen ? (
          <>
            {/*<AppBar color="primary" position="fixed">
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant="subtitle1" noWrap>
                  Dirección del establecimiento
                </Typography>
              </Toolbar>
        </AppBar>*/}
            <AppBar color="default" position="fixed" elevation={0} className={classes.appBar}>
              <Typography variant="subtitle1" gutterBottom noWrap>
                Dirección del establecimiento
              </Typography>
              <OutlinedInput
                //classes={{ root: classes.inputRoot, input: classes.inputBase }}
                fullWidth
                autoFocus
                placeholder="Buscar una dirección"
                startAdornment={
                  <InputAdornment position="start">
                    <IconButton edge="start" color="inherit" onClick={handleClose}>
                      <ArrowBackIcon />
                    </IconButton>
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment position="end">
                    {isLoading ? (
                      <Fade in={isLoading} mountOnEnter unmountOnExit>
                        <CircularProgress className={classes.inputProgress} size={24} />
                      </Fade>
                    ) : (
                      <Fade in={!isLoading} mountOnEnter unmountOnExit>
                        {listOpen ? <SearchIcon /> : <LocationOnTwoToneIcon />}
                      </Fade>
                    )}
                  </InputAdornment>
                }
                inputProps={{ ...getInputProps() }}
                {...getRootProps()}
              />
            </AppBar>
            <section className={classes.subheading}>
              <Chip
                icon={<PublicIcon fontSize="small" />}
                clickable
                label={
                  <>
                    <Typography variant="subtitle2" className={classes.chipTypography} noWrap>
                      Barcelona, España
                    </Typography>
                    {settingsOpen ? (
                      <ExpandLessIcon
                        fontSize="default"
                        className={classes.chipArrow}
                        color="primary"
                      />
                    ) : (
                      <ExpandMoreIcon
                        fontSize="default"
                        className={classes.chipArrow}
                        color="primary"
                      />
                    )}
                  </>
                }
                color="default"
                onClick={() => setSettingsOpen(true)}
                classes={{ root: classes.chip, icon: classes.chipIcon, label: classes.chipLabel }}
              />
            </section>
          </>
        ) : (
          <InputBase
            classes={{ root: classes.inputRoot, input: classes.inputBase }}
            fullWidth
            autoFocus
            placeholder="Buscar una dirección"
            startAdornment={
              <InputAdornment position="start">
                {isLoading ? (
                  <Fade in={isLoading} mountOnEnter unmountOnExit>
                    <CircularProgress className={classes.inputProgress} size={24} />
                  </Fade>
                ) : (
                  <Fade in={!isLoading} mountOnEnter unmountOnExit>
                    {listOpen ? <SearchIcon /> : <LocationOnTwoToneIcon />}
                  </Fade>
                )}
              </InputAdornment>
            }
            /*endAdornment={
            <InputAdornment position="end">
              {isLoading && (
                <Fade
                  in={isLoading}
                  mountOnEnter
                  unmountOnExit
                  style={{
                    transitionDelay: '000ms'
                  }}
                >
                  <CircularProgress className={classes.inputProgress} size={24} />
                </Fade>
              )}
            </InputAdornment>
          }*/
            inputProps={{ ...getInputProps() }}
            {...getRootProps()}
          />
        )}

        {/*
        <InputBase
          classes={{ root: classes.inputRoot, input: classes.inputBase }}
          placeholder="Buscar en Colinas del Campo de Martín Moro Toledano"
          autoFocus
          endAdornment={
            <InputAdornment position="end">
              {isLoading ? (
                <Fade
                  in={isLoading}
                  mountOnEnter
                  unmountOnExit
                  style={{
                    transitionDelay: '000ms'
                  }}
                >
                  <CircularProgress className={classes.inputProgress} size={24} />
                </Fade>
              ) : null}
              {!isLoading ? (
                <Fade in={!isLoading} mountOnEnter unmountOnExit>
                  <SearchIcon className={classes.inputProgress} color="disabled" />
                </Fade>
              ) : null}
            </InputAdornment>
          }
          inputProps={{ ...getInputProps() }}
          {...getRootProps()}
        />*/}
        {!listOpen && (
          <>
            <Grid container spacing={2} className={classes.door}>
              <Door onChange={handleApartmentEdit} apartment={address.apartment} />
            </Grid>
          </>
        )}

        <Hidden smUp>
          {!listOpen && (
            <section className={classes.fabSection}>
              <Fab variant="extended" className={classes.fab} color="primary" onClick={handleClose}>
                <CheckIcon /> Seleccionar
              </Fab>
            </section>
          )}
        </Hidden>

        <Hidden xsDown>
          <section className={classes.city}>
            <Typography variant="subtitle1" noWrap>
              Barcelona, España
            </Typography>

            {listOpen ? (
              <Button
                size="medium"
                color="default"
                variant="text"
                startIcon={<PublicIcon />}
                onClick={() => setSettingsOpen(true)}
              >
                En otro municipio
              </Button>
            ) : (
              <Button
                size="medium"
                color="primary"
                variant="text"
                endIcon={<CheckIcon />}
                onClick={handleClose}
              >
                Seleccionar
              </Button>
            )}
          </section>
        </Hidden>

        <Drawer
          anchor={fullScreen ? 'bottom' : 'right'}
          open={settingsOpen}
          onClose={() => setSettingsOpen(false)}
          classes={{ paper: classes.drawer }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Seleccionar un municipio
          </Typography>
          <Grid container spacing={2} className={classes.drawerGrid}>
            <CountryRegionCity />
          </Grid>
        </Drawer>

        {/*<Fade in={listOpen && isLoading} mountOnEnter unmountOnExit>
          <MenuList className={classes.menuList} disablePadding>
            <Grow in timeout={100}>
              <MenuItem className={classes.menuItem}>
                <ListItemIcon className={classes.listItemIcon}>
                  <LocationOnTwoToneIcon />
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemTextPrimary }}
                  primary={<Skeleton width={240} />}
                  secondary={<Skeleton width={120} />}
                />
              </MenuItem>
            </Grow>
          </MenuList>
          </Fade>*/}

        <Fade in={listOpen /* && groupedOptions.length > 0*/} mountOnEnter unmountOnExit>
          <MenuList {...getListboxProps()} className={classes.menuList} disablePadding>
            {isLoading &&
              [0, 1, 2, 3, 4].map((sk, index) => (
                <Fade key={`skeleton-${sk}`} in style={{ opacity: 0.8 - 0.2 * index }}>
                  <MenuItem className={classes.menuItem} divider={fullScreen}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <LocationOnTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ root: classes.listItemText, primary: classes.listItemTextPrimary }}
                      primary={<Skeleton width={240} />}
                      secondary={<Skeleton width={120} />}
                    />
                  </MenuItem>
                </Fade>
              ))}
            {!isLoading &&
              groupedOptions.map((option, index) => (
                <Fade
                  key={`option-${index}`}
                  in
                  // timeout={100 * (index + 1)}
                  // timeout={Math.min(100 * (index + 1), 500)}
                  // timeout={400}
                  // style={{ transformOrigin: 'center left', transitionDelay: 30 * (index + 1) }}
                  style={{ transitionDelay: Math.min(30 * (index + 1), 300) }}
                >
                  <MenuItem
                    {...getOptionProps({ option, index })}
                    className={classes.menuItem}
                    divider={fullScreen}
                  >
                    <ListItemIcon className={classes.listItemIcon}>
                      <LocationOnTwoToneIcon />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        root: classes.listItemText,
                        primary: classes.listItemTextPrimary
                      }}
                      disableTypography
                      primary={
                        <Typography variant="body1" noWrap>
                          {/*option.nomComplet*/}
                          {locationUtils.access.getAccessOptionLabel(option)}
                        </Typography>
                      }
                      secondary={
                        <Typography variant="body2" noWrap>
                          {`080${option.districtePostal}, ${option.barri.nom} (${option.districte.descripcio})`}
                          {/*Secondary*/}
                        </Typography>
                      }
                    />
                  </MenuItem>
                </Fade>
              ))}
          </MenuList>
        </Fade>
        {/*}
        <section className={classes.actions}>
          <Button size="small" color="default" variant="text" startIcon={<ExploreIcon />}>
            Buscar en otro municipio
          </Button>
          <div className={classes.actionsRight}>
            <Button color="default" variant="outlined">
              No
            </Button>
            <Button color="primary" variant="contained">
              Yes
            </Button>
          </div>
            </section>*/}
      </Popover>
    </>
  )
}
