import React from 'react'
import clsx from 'clsx'

import CardActionArea from '@material-ui/core/CardActionArea'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

import Badge from '@material-ui/core/Badge'

import CircularProgress from '@material-ui/core/CircularProgress'

import ClearIcon from '@material-ui/icons/Clear'
import CheckIcon from '@material-ui/icons/Check'
import AddIcon from '@material-ui/icons/Add'
import ListIcon from '@material-ui/icons/List'

import Fade from '@material-ui/core/Fade'

import Skeleton from '@material-ui/lab/Skeleton'

import { makeStyles } from '@material-ui/core/styles'

import DocumentFileMeta from '../Sections/DocumentFileMeta'
import DocumentPreview from '../Sections/DocumentPreview'

const useStyles = makeStyles(({ palette, spacing, shadows, typography }) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  cardActionArea: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 0
  },
  focusHighlight: {},
  paper: {
    position: 'relative',
    width: spacing(1 * 12),
    height: spacing(1 * 18),
    //padding: spacing(4, 1),
    borderWidth: 2,
    borderColor: palette.field.border,
    textAlign: 'center',
    overflow: 'hidden',
    transition: 'box-shadow 0.3s ease, color 0.3s ease, transform 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
    // marginBottom: spacing(1)
  },
  paperEmpty: {
    //background: palette.grey[100],
    // color: palette.grey[100],
    //cursor: 'pointer',
    '&:hover': {
      borderColor: palette.common.black
    }
  },
  paperAccept: {
    //background: palette.success.light,
    //color: palette.success.contrastText,
    //borderColor: palette.success.main,
    color: palette.text.primary,
    borderColor: palette.success.main, //'#00ffa2',
    boxShadow: `0px 2px 4px 0px ${palette.success.light}`, //'0px 4px 0px 1px #bdfee6',
    borderStyle: 'solid',
    transform: 'translate(0px,-4px)'
  },
  paperReject: {
    //background: palette.warning.main,
    //borderColor: palette.warning.main,
    //color: palette.warning.contrastText,
    color: palette.text.primary,
    borderColor: palette.error.main, //'#ff4000',
    boxShadow: `0px 2px 4px 0px ${palette.error.light}`, //'0px 4px 0px 1px #ffc3ae',
    borderStyle: 'solid',
    transform: 'translate(0px,-4px) scale(1.025)'
  },
  paperLoading: {
    //background: `${palette.info.main}33`,
    background: palette.action.focus,
    color: palette.text.primary,
    borderColor: palette.primary.main
    //boxShadow: `0px 4px 0px 1px ${blueGrey[100]}`,
    //transform: 'translate(0px,-5px) scale(1.02)'
  },
  paperPreview: {
    background: palette.common.white,
    borderColor: palette.text.primary,
    padding: 0
    //boxShadow: `0px 4px 0px 1px ${blueGrey[100]}`,
    //transform: 'translate(0px,-5px) scale(1.02)',
    /*transition: 'color 0.3s ease, transform 0.3s ease',
  '&:hover': {
    borderColor: palette.common.black,
    boxShadow: `0px 4px 0px 1px ${blueGrey[600]}`
  }*/
  },
  paperMultiple: {
    position: 'absolute',
    transform: 'scale(0.95) translateX(-8px)'
  },
  paperMultiple2: {
    transform: 'scale(0.9) translateX(-16px)'
  },
  clear: {
    position: 'absolute',
    marginLeft: spacing(1 * 12 + 4 + 1),
    display: 'flex',
    flexDirection: 'column'
  },
  actionButton: { marginBottom: spacing(1) },
  badge: {
    marginBottom: spacing(1.5)
  }
}))

export default function DocumentLabel({
  interactionProps,
  fileProps,
  documentProps,
  actionHandlers
}) {
  const { reusable, signature, multiple } = documentProps
  const { hasFile, files } = fileProps
  const { isDragActive, isDragAccept, isDragReject, isLoading } = interactionProps
  const { handleAdd, handleEdit, handleRemove } = actionHandlers
  const classes = useStyles({ isDragActive, isDragAccept, isDragReject, isLoading })
  return (
    <section className={classes.section}>
      {multiple && (
        <>
          {hasFile && files.length > 2 && (
            <Paper
              className={clsx(
                classes.paper,
                classes.paperMultiple,
                hasFile && !isLoading && classes.paperPreview,
                classes.paperMultiple2
              )}
              variant="outlined"
            />
          )}
          {hasFile && files.length > 1 && (
            <Paper
              className={clsx(
                classes.paper,
                classes.paperMultiple,
                hasFile && !isLoading && classes.paperPreview
              )}
              variant="outlined"
            />
          )}
        </>
      )}
      {hasFile && !isLoading && (
        <div className={classes.clear}>
          <Fade in>
            <IconButton
              size="small"
              color="default"
              aria-label="remove"
              edge="start"
              className={classes.actionButton}
              onClick={handleRemove}
            >
              <ClearIcon />
            </IconButton>
          </Fade>
          {multiple && (
            <>
              {files.length > 1 && (
                <Fade in>
                  <IconButton
                    size="small"
                    color="default"
                    aria-label="view list"
                    edge="start"
                    className={classes.actionButton}
                    onClick={handleEdit}
                  >
                    <ListIcon />
                  </IconButton>
                </Fade>
              )}
              <Fade in>
                <IconButton
                  size="small"
                  color="primary"
                  aria-label="add"
                  edge="start"
                  className={classes.actionButton}
                  onClick={handleAdd}
                >
                  <AddIcon />
                </IconButton>
              </Fade>
            </>
          )}
        </div>
      )}
      {/* Document representation */}
      <Badge
        //badgeContent={hasFile && !isLoading && multiple && files.length > 1 ? files.length : 0}
        badgeContent={files.length}
        invisible={files.length < 2}
        className={classes.badge}
        color="secondary"
        max={9}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Paper
          className={clsx(
            classes.paper,
            !isLoading && !hasFile && !isDragActive && classes.paperEmpty,
            isDragAccept && classes.paperAccept,
            isDragReject && classes.paperReject,
            isLoading && classes.paperLoading,
            hasFile && !isLoading && classes.paperPreview
          )}
          variant="outlined"
        >
          {/* Clickable area */}
          <CardActionArea
            focusRipple
            onClick={handleAdd}
            disabled={isLoading}
            classes={{
              root: classes.cardActionArea,
              focusHighlight: classes.focusHighlight
            }}
          >
            {!isLoading && !hasFile && !isDragActive && (
              <>
                <AddIcon color="primary" />
              </>
            )}
            {isDragReject && (
              <>
                <ClearIcon fontSize="large" />
                <Typography variant="caption" className={classes.dragTypography}>
                  Formato no admitido
                </Typography>
              </>
            )}
            {isDragAccept && (
              <>
                <CheckIcon fontSize="large" />
                <Typography variant="caption" className={classes.dragTypography}>
                  Soltar para aportar
                </Typography>
              </>
            )}
            {isLoading ? (
              <CircularProgress className={classes.progre2ss} color="primary" />
            ) : (
              hasFile &&
              !isDragActive && (
                <>
                  <DocumentPreview file={files[0]} />
                </>
              )
            )}
          </CardActionArea>
        </Paper>
      </Badge>
      {/* Document Meta */}
      {hasFile && !isLoading ? (
        <DocumentFileMeta file={files[0]} files={files} />
      ) : (
        <>
          <Typography variant="subtitle2" gutterBottom color="textPrimary">
            <Skeleton animation={isLoading ? 'pulse' : false} width={200} />
          </Typography>
          <Typography
            component="p"
            variant="caption"
            className={classes.inline}
            color="textPrimary"
          >
            <Skeleton animation={isLoading ? 'pulse' : false} width={60} />
          </Typography>
        </>
      )}
    </section>
  )
}
