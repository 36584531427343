import React from 'react'

const AuthContext = React.createContext()

function AuthProvider(props) {
  const { children } = props
  const [auth, setAuth] = React.useState(false)
  console.log('authprovider render')
  return <AuthContext.Provider value={[auth, setAuth]}>{children}</AuthContext.Provider>
}

function useAuthContext() {
  const [auth, setAuth] = React.useContext(AuthContext)
  return [auth, setAuth]
}

export { useAuthContext, AuthProvider }
