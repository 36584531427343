import React from 'react'
import PropTypes from 'prop-types'

import { printFormat } from 'iban'

import BankAccount from './BankAccount'
import { RadioGroup, SingleSelect } from '../Selection'

import spanishBanks from './banks_es.json'

// Util fn that returns the spanish bank object from a valid electronically formatted IBAN, or undefined
const getSpanishBankName = iban => {
  let name
  const country = iban.substring(0, 2)
  if (country === 'ES') {
    const code = parseInt(iban.substring(4, 8), 10)
    const bank = spanishBanks.find(el => el.code === code)
    name = bank && bank.name
  }
  return name
}

const getComponent = (format, length, required) => {
  // By default
  let SingleChoice = length < 5 ? RadioGroup : SingleSelect

  // Honor preference, if set
  switch (format) {
    case 'radio':
      SingleChoice = RadioGroup
      break
    case 'combo':
      SingleChoice = SingleSelect
      break
    default:
      break
  }
  // But prevent always radio buttons on fields NOT required (bc can't be unset, it's not polite)
  SingleChoice = required ? SingleChoice : SingleSelect
  return SingleChoice
}

/**
Use a BankAccount when you want the user to input or select an "ISO 13616 IBAN Registry technical specification" compliant account number

- Can be a free input or an assisted input with radio buttons or dropdown
- If options are passed, the input is assisted:
    - With 5 or more elements, a dropdown is displayed (with autocomplete from 12 elements forward - not yet implemented)
    - With 4 or less elements, a group of radio buttons is displayed
- This behaviour can be overriden with the format prop, forcing to use either radio or dropdown
- When the field is optional it will never display a group of radio buttons
- The underlying input field change event is logged on the analytics platform.

See each component for more info.
*/

export default function Field({
  id,
  type,
  title,
  description,
  help,
  required,
  defaultValue,
  format,
  options,
  onUpdate,
  onValidation
}) {
  // Sanitize to ensure labels are human-readable and set helper values
  const sanitizedOptions = options
    ? options.map(el => ({
        key: el.key,
        label: printFormat(el.label),
        help: getSpanishBankName(el.key)
      }))
    : undefined

  // Selection of component memoized to avoid computing again when unrelated props change
  const Choice = getComponent(format, options ? options.length : 0, required)

  // Render fn
  return options ? (
    <Choice
      id={id}
      type={type}
      title={title}
      description={description}
      help={help}
      required={required}
      defaultValue={defaultValue}
      options={sanitizedOptions}
      onUpdate={onUpdate}
      onValidation={onValidation}
    />
  ) : (
    <BankAccount
      id={id}
      type={type}
      title={title}
      description={description}
      help={help}
      required={required}
      defaultValue={defaultValue}
      onUpdate={onUpdate}
      onValidation={onValidation}
    />
  )
}

Field.propTypes = {
  /**
    DBOID of the field.
  */
  id: PropTypes.string.isRequired,
  /**
    Type.
  */
  type: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Helper text.
  */
  help: PropTypes.string,
  /**
    Use this to indicate that a value must be provided.
  */
  required: PropTypes.bool,
  /**
    Initial value of the field, array for mutiple selections and string for single selection or text
  */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
    Type of rendering. Accepts values: auto, radio or combo
  */
  format: PropTypes.string,
  /**
    Array of options
  */
  options: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })),
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
    Handler to be called when a new validation needs to be shared
    @param {string} id - The id of the field.
    @param {bool} value - The validation result.
  */
  onValidation: PropTypes.func
}

Field.defaultProps = {
  description: null,
  help: null,
  required: false,
  defaultValue: undefined,
  format: 'auto',
  options: undefined,
  onUpdate: () => {},
  onValidation: () => {}
}
