import React from 'react'

import { Link as RouterLink } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Fade from '@material-ui/core/Fade'
import Zoom from '@material-ui/core/Zoom'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'

import LaptopBrokenAnimation from '../Animations/LaptopBrokenAnimation'

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    padding: theme.spacing(4),
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    height: '40vmin'
  },
  progress: {
    margin: theme.spacing(2)
  }
}))

export default function LandingPage() {
  const classes = useStyles()

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Fade in timeout={500}>
        <section>
          <Zoom in timeout={500}>
            <LaptopBrokenAnimation />
          </Zoom>

          <Typography variant="h2">Shit happens</Typography>
          <Typography paragraph>Todo el mundo se pierde de vez en cuando</Typography>
          <Button
            variant="text"
            disableElevation
            color="primary"
            component={RouterLink}
            to="/tramite/6269000027826948699999"
            className={classes.button}
          >
            Vuelve a un lugar conocido
          </Button>
        </section>
      </Fade>
    </Container>
  )
}
