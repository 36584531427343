import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  contentSection: {
    // Tablet or smaller
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 2)
      //maxWidth: theme.breakpoints.values.sm + 100 // make some room for the sidebar
    }
  }
}))

const ContentSection = React.forwardRef(function ContentSection({ children, className }, ref) {
  const classes = useStyles()
  return (
    <Container
      maxWidth="lg"
      component="main"
      className={clsx(classes.contentSection, className)}
      ref={ref}
    >
      {children}
    </Container>
  )
})

export default ContentSection

ContentSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

ContentSection.defaultProps = {
  className: null
}
