import React from 'react'
import { useParams } from 'react-router-dom'

export default function Draft() {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  const { pid, did } = useParams()

  return (
    <div>
      <h3>Trámite: {pid}</h3>
      <h3>Borrador: {did}</h3>
    </div>
  )
}
