import React from 'react'
import PropTypes from 'prop-types'

import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Fade from '@material-ui/core/Fade'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  formControl: {
    //margin: theme.spacing(1)
  },
  inputRoot: {
    background: theme.palette.background.default,
    borderRadius: 0
  }
}))

export default function AddressApartmentForm({ apartment, onChange }) {
  const classes = useStyles()
  const { stair, floor, door } = apartment || {}

  return (
    <>
      <Grid container>
        <Grid item xs={4}>
          <Fade in={true}>
            <FormControl fullWidth variant="filled" className={classes.formControl} margin="none">
              <InputLabel shrink id="demo-simple-select-filled-label-stairs">
                Escalera
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label-stairs"
                id="demo-simple-select-filled-stairs"
                name="stair"
                // defaultValue={''}
                // margin="dense"
                classes={{ root: classes.inputRoot }}
                displayEmpty
                IconComponent={ExpandMoreIcon}
                //autoFocus
                value={(stair && stair.value) || ''}
                onChange={(event, child) => onChange(event, `Esc. ${child.props.children}`)}
              >
                <MenuItem value="">Ninguna</MenuItem>
                <MenuItem value={10}>Izquierda</MenuItem>
                <MenuItem value={20}>Derecha</MenuItem>
              </Select>
            </FormControl>
          </Fade>
        </Grid>
        <Grid item xs={4}>
          <Fade in={true}>
            <FormControl fullWidth variant="filled" className={classes.formControl} margin="none">
              <InputLabel shrink id="demo-simple-select-filled-label-floor">
                Planta
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label-floor"
                id="demo-simple-select-filled-floor"
                name="floor"
                // defaultValue={''}
                // margin="dense"
                classes={{ root: classes.inputRoot }}
                displayEmpty
                IconComponent={ExpandMoreIcon}
                value={(floor && floor.value) || ''}
                onChange={(event, child) => onChange(event, `Pl. ${child.props.children}`)}
              >
                <MenuItem value="">Ninguna</MenuItem>
                <MenuItem value={'B'}>Baja</MenuItem>
                <MenuItem value={10}>Principal</MenuItem>
                <MenuItem value={20}>Primera</MenuItem>
                <MenuItem value={30}>Segunda</MenuItem>
                <MenuItem value={40}>Tercera</MenuItem>
                <MenuItem value={'AT'}>Ático</MenuItem>
                <MenuItem value={'SA'}>Sobreático</MenuItem>
              </Select>
            </FormControl>
          </Fade>
        </Grid>
        <Grid item xs={4}>
          <Fade in={true}>
            <FormControl fullWidth variant="filled" className={classes.formControl} margin="none">
              <InputLabel shrink id="demo-simple-select-filled-label-door">
                Puerta
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label-door"
                id="demo-simple-select-filled-door"
                name="door"
                // defaultValue={''}
                // margin="dense"
                classes={{ root: classes.inputRoot }}
                displayEmpty
                IconComponent={ExpandMoreIcon}
                value={(door && door.value) || ''}
                onChange={(event, child) => onChange(event, `Pt. ${child.props.children}`)}
              >
                <MenuItem value="">Ninguna</MenuItem>
                <MenuItem value={10}>1</MenuItem>
                <MenuItem value={20}>2</MenuItem>
                <MenuItem value={30}>3</MenuItem>
                <MenuItem value={30}>4</MenuItem>
              </Select>
            </FormControl>
          </Fade>
        </Grid>
      </Grid>
    </>
  )
}
