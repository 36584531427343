import React from 'react'
import PropTypes from 'prop-types'

import Number from './Number'
import { CheckboxGroup, RadioGroup, MultiSelect, SingleSelect } from '../Selection'

const getComponent = (format, length, multiple) => {
  let MultipleChoice = length < 5 ? CheckboxGroup : MultiSelect
  let SingleChoice = length < 5 ? RadioGroup : SingleSelect
  switch (format) {
    case 'radio':
      MultipleChoice = CheckboxGroup
      SingleChoice = RadioGroup
      break
    case 'combo':
      MultipleChoice = MultiSelect
      SingleChoice = SingleSelect
      break
    default:
      break
  }
  return multiple ? MultipleChoice : SingleChoice
}

/**
Use a Number when you want the user to input or select a numerical value.

- Can be a free input or an assisted input with checkboxes, radio buttons or dropdowns.
- If options are passed, the input is assisted:
    - With 5 or more elements, a dropdown is displayed (with autocomplete from 12 elements forward).
    - With 4 or less elements, a group of checkboxes or radio buttons is displayed.
- This behaviour can be overriden with the format prop, forcing to use either checkbox/radio or dropdown.
- When the field is optional it will never display a group of radio buttons.

See each component for more info.
*/

export default function Field({
  id,
  type,
  title,
  description,
  help,
  required,
  error,
  defaultValue,
  format,
  options,
  multiple,
  digits,
  decimals,
  prefix,
  suffix,
  onUpdate,
  onValidation
}) {
  // Selection of component
  const Choice = getComponent(format, options ? options.length : 0, multiple)

  // Numerical
  const numerical = options ? typeof options[0].key === 'number' : false

  return options ? (
    <Choice
      id={id}
      type={type}
      title={title}
      description={description}
      help={help}
      required={required}
      error={error}
      defaultValue={defaultValue}
      options={options}
      numerical={numerical}
      onUpdate={onUpdate}
      onValidation={onValidation}
    />
  ) : (
    <Number
      id={id}
      type={type}
      title={title}
      description={description}
      help={help}
      required={required}
      error={error}
      defaultValue={defaultValue}
      prefix={prefix}
      suffix={suffix}
      digits={digits}
      decimals={decimals}
      onUpdate={onUpdate}
      onValidation={onValidation}
    />
  )
}

Field.propTypes = {
  /**
    DBOID of the field.
  */
  id: PropTypes.string.isRequired,
  /**
    Type.
  */
  type: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Helper text.
  */
  help: PropTypes.string,
  /**
    Use this to indicate that a value must be provided.
  */
  required: PropTypes.bool,
  /**
    Use this to change to an error state.
  */
  error: PropTypes.bool,
  /**
    Initial value of the field, array for mutiple selections and number for single selection or value
  */
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  /**
    Type of rendering. Accepts values: auto, radio or combo
  */
  format: PropTypes.string,
  /**
    Array of options
  */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string
    })
  ),
  /**
    Use this to indicate multiple choices are possible
   */
  multiple: PropTypes.bool,
  /**
    The maximum number of digits allowed, including decimals.
    If digits is equal or lower than the number of decimals allowed, accepts floats between 0 and 1.
   */
  digits: PropTypes.number,
  /**
    The number of decimals
   */
  decimals: PropTypes.number,
  /**
    Add a prefix before the number
   */
  prefix: PropTypes.string,
  /**
    Add a suffix after the number
   */
  suffix: PropTypes.string,
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
  Handler to be called when a new validation needs to be shared
  @param {string} id - The id of the field.
  @param {bool} value - The validation result.
*/
  onValidation: PropTypes.func
}

Field.defaultProps = {
  description: null,
  help: null,
  required: false,
  error: false,
  defaultValue: undefined,
  format: 'auto',
  options: undefined,
  multiple: false,
  digits: null,
  decimals: undefined,
  prefix: undefined,
  suffix: undefined,
  onUpdate: () => {},
  onValidation: () => {}
}
