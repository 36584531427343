import React, { useState, useRef, useCallback, useEffect } from 'react'
import isEqual from 'lodash/isEqual'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import ObjectModalBar from './Object/ObjectModalBar'
import ObjectModalHeader from './Object/ObjectModalHeader'
import ObjectModalOptions from './Object/ObjectModalOptions'
import ObjectModalDetails from './Object/ObjectModalDetails'

// to be moved to ./Sections
import StreetNameNumber from '../../StreetNameNumber'
import BlockToponym from '../../BlockToponym'
import Door from '../../Door'

import { useLocationContext } from '../../../../components/Context/LocationContext'

const useStyles = makeStyles(theme => ({
  paper: { padding: theme.spacing(2) }
}))

export default function FormPicker({
  title,
  value,
  // location,
  anchorEl,
  open,
  onChange,
  onLocationChange,
  onClear,
  onClose,
  onDrawerOpen
}) {
  const classes = useStyles()

  const { street } = value

  // Variant
  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'))

  // Modal anchor
  const ref = useRef()

  // Location context
  const [defaultLocation, locationUtils, addressUtils] = useLocationContext()

  // Picker state
  const [settingsOpen, setSettingsOpen] = useState(false) // Controls showing the settings panel

  // Local address value and handlers
  // Composed as the original value + the current edited location when the picker opens
  // DO WE NEED IT? --> NO
  // const [address, setAddress] = useState({ ...value, location })
  const handleClearAddress = useCallback(() => {
    const empty = { ...addressUtils.emptyAddress, location: value.location }
    // setAddress(empty)
    onChange(empty)
  }, [value.location, onChange, addressUtils.emptyAddress])
  /*const handleLocationChange = newLocation => {
    const newAddress = { ...address, location: newLocation }
    setAddress(newAddress)
  }*/
  // const handleAccessChange = v => setAddress(prev => addressUtils.updateAccess(prev, v))
  const handleStreetNameChange = useCallback(
    // type => setAddress(prev => addressUtils.updateStreetName(prev, type)),
    type => onChange(prev => addressUtils.updateStreetName(prev, type)),
    [addressUtils, onChange]
  )

  const handleAddressChange = useCallback(newAddress => onChange(newAddress), [onChange])

  const handleDetailsChange = (event, label) =>
    /*setAddress(prev =>
      addressUtils.updateProperties(prev, event.target.name, event.target.value, label)
    )*/
    onChange(prev =>
      addressUtils.updateProperties(prev, event.target.name, event.target.value, label)
    )

  // SIDE-EFFECTs
  // If inbound value changes and it's cleared
  useEffect(() => {
    // if (!value?.details) handleClearAddress()
  }, [value, handleClearAddress])

  // If location changes, reset the local address
  /*useEffect(() => {
    setAddress({ ...value, location })
  }, [location, value])*/

  // If location changes
  /*useEffect(() => {
    onChange(address)
  }, [address.location, onChange])*/

  // HANDLERS

  // Before we enter the picker: decide if we open on list or details
  const handleEnter = () => {}

  // When we exit the picker: cleanup
  const handleExited = () => {}

  // When we are exiting the picker
  const handleExiting = () => {
    // if (!isEqual(address, value)) onChange(address) // send the new value to the field if changed
  }

  return (
    <>
      {/* <ObjectModal
      open={open}
      anchorEl={anchorEl}
      fullscreen={fullscreen}
      onClose={onClose}
      onEnter={handleEnter}
      onExited={handleExited}
      onExiting={handleExiting}
   >*/}
      <ObjectModalBar>
        <ObjectModalHeader title={title} handleClose={onClose} />
      </ObjectModalBar>
      <ObjectModalOptions
        location={value.location} // address.location
        visible={true}
        open={false}
        handleOpen={onDrawerOpen}
        handleClose={onClose}
        handleSettings={() => setSettingsOpen(true)}
      />
      {/*<OptionsDrawer
        location={address.location}
        fullscreen={fullscreen}
        open={settingsOpen}
        onLocationChange={onLocationChange}
        onClose={() => setSettingsOpen(false)}
        toggleCoded={toggleCoded}
      />*/}
      <ObjectModalDetails
        listboxOpen={false}
        disabled={addressUtils.isEmpty(value)}
        handleClose={() => onClose(value)}
        //key={`details-for-location-${value.location.municipality.name}`} // this forces a re-render, we'll get a fresh form
      >
        <StreetNameNumber
          onChange={handleAddressChange}
          street={street} // address.street
          access={value.access} // value.street
        />
        <BlockToponym access={value.access} onChange={handleAddressChange} />
        <Door onChange={handleAddressChange} properties={value.properties} />
      </ObjectModalDetails>
    </>
  )
}
