import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import FaceIcon from '@material-ui/icons/Face'

import Slide from '@material-ui/core/Slide'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone'

import Hidden from '@material-ui/core/Hidden'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import Divider from '@material-ui/core/Divider'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import PartiesDialog from '../Dialogs/PartiesDialog'
import SimplePopover, { ControlledPopover } from '../Dialogs/Popover'
import { ControlledDrawer } from '../Dialogs/Drawer'

import { Dialog, DialogTitle, DialogContent, DialogActions, DialogDivider } from '../Dialogs'

import { useScrollTrigger } from '../Layout/Scroll'

const useChipStyles = makeStyles(theme => ({
  chip: {
    //background: theme.palette.common.black,
    //color: theme.palette.common.white
    fontWeight: 600,
    maxWidth: theme.breakpoints.values.sm / 2
  },
  icon: {
    marginLeft: theme.spacing(2)
  },
  arrow: {
    marginLeft: theme.spacing(1)
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '40ch',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '35ch'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '25ch'
    }
  },
  typography: {
    flexShrink: 1,
    fontWeight: 600
  }
}))

const PartiesChip = ({ slideEl, full, hideIcon }) => {
  const classes = useChipStyles()
  const mobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {mobile && !full ? (
        <>
          <IconButton color="default" onClick={handleClick}>
            <PeopleAltTwoToneIcon fontSize="small" />
          </IconButton>
        </>
      ) : (
        <>
          <Chip
            icon={!hideIcon && <PeopleAltTwoToneIcon fontSize="small" />}
            clickable
            label={
              <>
                <Typography
                  variant={hideIcon ? 'subtitle2' : 'caption'}
                  className={classes.typography}
                  noWrap
                >
                  MATEU PORTILLO, MARIA FRANCISCA
                </Typography>
                {anchorEl ? (
                  <ExpandLessIcon fontSize="small" className={classes.arrow} color="primary" />
                ) : (
                  <ExpandMoreIcon fontSize="small" className={classes.arrow} color="primary" />
                )}
              </>
            }
            color="default"
            onClick={handleClick}
            classes={{ root: classes.chip, icon: classes.icon, label: classes.label }}
          />
        </>
      )}
      <Dialog
        open={Boolean(anchorEl)}
        drawerAnchor="bottom"
        anchorEl={anchorEl}
        handleClose={handleClose}
      >
        <DialogTitle>Personas relacionadas</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2">MATEU PORTILLO, MARIA FRANCISCA</Typography>
          <Typography variant="body2">85064745G</Typography>
          <Typography variant="caption">Persona interesada</Typography>
        </DialogContent>
        <DialogContent>
          <Button variant="contained" disableElevation color="default" onClick={handleClose}>
            Cambiar la persona interesada
          </Button>
        </DialogContent>
        <DialogDivider />
        <DialogContent>
          <Typography variant="subtitle2" color="textSecondary">
            FUFASA
          </Typography>
          <Typography variant="body2" color="textSecondary">
            V54471313
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Representante
          </Typography>
        </DialogContent>
        <DialogContent>
          <Typography variant="subtitle2" color="textSecondary">
            BLANCO VIDAL, MANUELA
          </Typography>
          <Typography variant="body2" color="textSecondary">
            50298519A
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Solicitante
          </Typography>
        </DialogContent>
        <DialogActions />
      </Dialog>
    </>
  )
}

export { PartiesChip }
