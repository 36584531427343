import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormHelperText from '@material-ui/core/FormHelperText'

import { Amplitude } from '@amplitude/react-amplitude'
import { isValid, formatISO, format as formatDate } from 'date-fns'
import { DatePicker, DateTimePicker } from '@material-ui/pickers'

import { makeStyles } from '@material-ui/core/styles'

import formatFormHelperText from '../Utils/formatFormHelperText'
import { sanitizeStringDate } from '../Utils/sanitizeForInputValue'

const useStyles = makeStyles(theme => ({
  label: {
    margin: theme.spacing(0, 2, 0.5, 0),
    lineHeight: 1.25
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75)
  },
  formHelperTextWithMessage: {
    marginBottom: theme.spacing(2) + 3
  },
  pickerButton: {
    color: theme.palette.primary.main
  }
}))

// Current picker defaults --> used for the error message
const maxDate = new Date('2100-01-01T00:00')
const minDate = new Date('1900-01-01T00:00')

/**
Use a Date when you want the user to input date or date and time.

- Renders to the full width of its parent element.
- Accepts and returns time in ISO format
- The underlying input field blur event is logged on the analytics platform.
- The picker accept event is logged on the analytics platform.

### Events:
- Blur: validates empty vs required, validates invalid user-filled
- Change: does no extra validations, the picker's own inbound validation takes care of it

### Validations:
- Needs a value, if required
- Current defaults from the picker: minDate (1900-01-01 00:00 UTC), maxDate (2100-01-01 00:00 UTC)
- Not yet used but supported by picker: disableFuture, disablePast, shouldDisableDate, minDateTime, maxDateTime, minTime, maxTime)

### Known issues:
- a) Picker doesn't accept empty values (throws invalidDate error) yet, but it will support for clearable fields
- b) Mask characters (like "/") can't be typed: We can't input 1/12, must type 01/12 or it will actually show 11/2 (which is parsed as 11/02)
- c) In our locales, year expects up to 4 digits. 31/12/20 is not YE 2020, it is YE 0020 but the user doesn't have a clue
  - Can be partially prevented with minDate/minDateTime, by not allowing dates before last century (default config of the picker)
  - But that shows an invalidDate to the user, briefly
  - Idea 1: on blur, find if the year part has less than 4 digits and pad with zeroes (hard: year position depends on locale)
  - Idea 2: register any error reason, but don't show it to the user until blur happens (don't interfere with typing)
  - Idea 3: switch to momentjs, they allow parsing years with or without strict number of digits and the pickers use strict with moment (must type 4 digits)
*/

export default function DateTime({
  id,
  type,
  title,
  description,
  help,
  required,
  defaultValue,
  format,
  withTime,
  onUpdate,
  onValidation
}) {
  const classes = useStyles()

  // Local state for field value and validation
  const [selectedDate, handleDateChange] = useState(sanitizeStringDate(defaultValue))
  const [validation, setValidation] = useState({
    error: undefined,
    message: undefined,
    dirty: !!sanitizeStringDate(defaultValue)
  })

  // On change, set the new value and share it
  // The picker will only pass dates or null, it is typed
  const handleChange = date => {
    handleDateChange(date)
    onUpdate(
      isValid(date)
        ? formatISO(date, { representation: withTime ? 'complete' : 'date' })
        : String(date)
    )
  }

  // On error change, update the validation state according to each possible error type
  // The picker will validate according to its props, and give us a reason and value
  // The only extra thing we must do is allow empty values on optional fields
  const handleError = (reason, value) => {
    switch (reason) {
      case 'invalidDate':
        // We only want to show errors when there's actually some kind of value, not empty ones
        // Empty values on required fields are controlled on blur
        if (value) setValidation({ error: true, message: 'Invalid date format', dirty: true })
        break
      case 'disableFuture':
        // Example, for dates in the past
        setValidation({ error: true, message: 'Future dates are not valid', dirty: !!value })
        break
      case 'disablePast':
        // Example, for dates in the past
        setValidation({ error: true, message: 'Past dates are not valid', dirty: !!value })
        break
      case 'maxDate':
        // Example, for dates past the max date
        setValidation({
          error: true,
          message: `Date should not be after ${formatDate(maxDate, 'Pp')}`,
          dirty: !!value
        })
        break
      case 'minDate':
        // Example, for dates before the min date
        setValidation({
          error: true,
          message: `Date should not be before ${formatDate(minDate, 'Pp')}`,
          dirty: !!value
        })
        break
      case 'shouldDisableDate':
        // TBD, for dates matching the shouldDisableDate logic
        break
      default:
        // There's no error
        setValidation({ error: false, message: undefined, dirty: !!value }) // !!v casts to boolean, dirty is used to flag a test with/without value
    }
  }

  // On blur, check:
  // a) If we jump away from an empty field, apply required logic
  // --> bc the picker doesn't control required values
  // b) If we jump away from a user-filled field with invalid value
  // --> bc right now the picker throws invalidDate errors on empty default values, so subsequent user-triggered empty values don't throw again
  const handleBlur = event => {
    if (!event.target.value)
      setValidation({
        error: required,
        message: required ? 'Required field' : undefined,
        dirty: false
      })
    if (event.target.value && !validation.dirty)
      setValidation({ error: true, message: 'Invalid date format', dirty: true })
  }

  // Side-effect of the validation: sync the context (skip if validation is yet undefined)
  useEffect(() => {
    if (typeof validation.error !== 'undefined') onValidation(id, !validation.error)
  }, [onValidation, id, validation.error])

  // Side-effect of having a default value can't be done (without trying to duplicate the picker's own validation),
  // the picker has inbound value validation and will validate default values.
  // At the context level we must assume a non-empty sanitized value is valid by default (this is different than other fields)

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: type,
        fieldVariant: withTime ? 'date-time' : undefined,
        fieldLabel: title
      })}
    >
      {({ instrument }) =>
        withTime || format === 'ddmmyy hhmm' ? (
          <DateTimePicker
            autoOk
            clearable={!required}
            id={`datetime-${id}`}
            minDateTime={minDate}
            maxDateTime={maxDate}
            toolbarTitle={null}
            OpenPickerButtonProps={{ classes: { root: classes.pickerButton } }}
            renderInput={({ inputProps, InputProps, ref, error, helperText, ...rest }) => (
              <FormControl
                required={required}
                error={validation.error}
                fullWidth
                variant="outlined"
                component="fieldset"
                margin="normal"
              >
                <FormLabel
                  required={false}
                  className={classes.label}
                  id={`${id}-test-label`}
                  htmlFor={`${id}-test`}
                >
                  {title}
                </FormLabel>
                <OutlinedInput
                  {...rest}
                  {...InputProps}
                  ref={ref}
                  id={`${id}-test`}
                  aria-describedby={`${id}-test-label`}
                  variant="outlined"
                  inputProps={{ ...inputProps, onBlur: handleBlur }}
                />
                <FormHelperText
                  className={clsx(classes.formHelperText, {
                    [classes.formHelperTextWithMessage]: !required || help
                  })}
                >
                  {formatFormHelperText(required, help, validation.message)}
                </FormHelperText>
              </FormControl>
            )}
            value={selectedDate}
            onChange={date => instrument('Interacted with form field', handleChange(date))}
            onError={handleError}
            onAccept={instrument('Interacted with form field')}
          />
        ) : (
          <DatePicker
            autoOk
            clearable={!required}
            id={`date-${id}`}
            minDate={minDate}
            maxDate={maxDate}
            toolbarTitle={null}
            OpenPickerButtonProps={{ classes: { root: classes.pickerButton } }}
            renderInput={({ inputProps, InputProps, ref, error, helperText, ...rest }) => (
              <FormControl
                required={required}
                error={validation.error}
                fullWidth
                variant="outlined"
                component="fieldset"
                margin="normal"
              >
                <FormLabel
                  required={false}
                  className={classes.label}
                  id={`${id}-test-label`}
                  htmlFor={`${id}-test`}
                >
                  {title}
                </FormLabel>
                <OutlinedInput
                  {...rest}
                  {...InputProps}
                  ref={ref}
                  id={`${id}-test`}
                  aria-describedby={`${id}-test-label`}
                  variant="outlined"
                  inputProps={{ ...inputProps, onBlur: handleBlur }}
                />
                <FormHelperText>
                  {formatFormHelperText(required, help, validation.message)}
                </FormHelperText>
              </FormControl>
            )}
            value={selectedDate}
            onChange={date => instrument('Interacted with form field', handleChange(date))}
            onError={handleError}
            onAccept={instrument('Interacted with form field')}
          />
        )
      }
    </Amplitude>
  )
}

DateTime.propTypes = {
  /**
    DBOID of the field.
  */
  id: PropTypes.string.isRequired,
  /**
    Type.
  */
  type: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Helper text.
  */
  help: PropTypes.string,
  /**
    Use this to indicate that a value must be provided.
  */
  required: PropTypes.bool,
  /**
    Use this to change to an error state.
  */
  error: PropTypes.bool,
  /**
    Initial value of the field.
  */
  defaultValue: PropTypes.string,
  /**
    Include time.
  */
  withTime: PropTypes.bool,
  /**
    Type of rendering. API currently returns hardcoded values "ddmmyyy" or "ddmmyyy hhmm"
  */
  format: PropTypes.string,
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
    Handler to be called when a new validation needs to be shared
    @param {string} id - The id of the field.
    @param {bool} value - The validation result.
  */
  onValidation: PropTypes.func
}

DateTime.defaultProps = {
  description: null,
  help: null,
  required: false,
  error: false,
  defaultValue: null,
  format: 'ddmmyyy',
  withTime: false,
  onUpdate: () => {},
  onValidation: () => {}
}
