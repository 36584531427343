import React, { useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'

import { Amplitude } from '@amplitude/react-amplitude'

import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormHelperText from '@material-ui/core/FormHelperText'

import { makeStyles } from '@material-ui/core/styles'

import { string } from 'yup'
import formatFormHelperText from '../Utils/formatFormHelperText'
import { sanitizeString } from '../Utils/sanitizeForInputValue'

// Text validation schema
const schema = string()
  .when('$required', (required, s) => (required ? s.required('Completa este campo') : s)) // Empty string doesn't pass
  .when('$length', (length, s) => (length ? s.max(length) : s))
  .when('$regex', (regex, s) => (regex ? s.matches(regex) : s))

const useStyles = makeStyles(theme => ({
  label: {
    margin: theme.spacing(0, 2, 0.5, 0),
    lineHeight: 1.25
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75)
  },
  formHelperTextWithMessage: {
    marginBottom: theme.spacing(2) + 3
  }
}))

/**
Use an Administrative File input field when you want the user to enter a reference to a file.

For now, this is just a copy of the Text field.

- Free text input field.
- Blur events on the underlying input field are logged on the analytics platform.

### Events:
- Blur: always runs a validation
- Change: only runs a validation if we were on error state or if we clear the field

### Validations:
- Needs a value, if required
- Value length, if set
- Value matches regex pattern, if set
*/

export default function AdministrativeFile({
  id,
  type,
  title,
  description,
  help,
  required,
  defaultValue,
  regex,
  length,
  onUpdate,
  onValidation
}) {
  const classes = useStyles()

  // Local state for field value and validation
  const [value, setValue] = useState(sanitizeString(defaultValue))
  const [validation, setValidation] = useState({
    error: undefined,
    message: undefined,
    dirty: false
  })

  // Validate using schema, set the validation
  const handleValidation = useCallback(
    debounce(v => {
      schema
        .validate(v, { context: { required, length, regex } })
        .then(() => {
          setValidation({ error: false, message: undefined, dirty: !!v }) // !!v casts to boolean, dirty is used to flag a test with/without value
        })
        .catch(e => {
          setValidation({ error: true, message: e.message, dirty: !!v })
        })
    }, 100),
    [required, length, regex]
  )

  // On change, set the new value and share it but only validate if we had an incorrect value or if we clear the field
  const handleChange = event => {
    setValue(event.target.value)
    onUpdate(id, event.target.value)
    if (validation.error || !event.target.value) handleValidation(event.target.value)
  }

  // On blur, trigger validation
  const handleBlur = event => {
    handleValidation(event.target.value)
  }

  // Side-effect of the validation: sync the context (skip if validation is yet undefined)
  useEffect(() => {
    if (typeof validation.error !== 'undefined') onValidation(id, !validation.error)
  }, [onValidation, id, validation.error])

  // Side-effect of having a default value
  // Validation runs only on strings, and must be a healthy value
  // This handles validation of saved values, context must be aware of empty initial fields
  useEffect(() => {
    if (sanitizeString(defaultValue)) handleValidation(defaultValue)
  }, [defaultValue, handleValidation])

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: type,
        fieldVariant: 'input',
        fieldLabel: title
      })}
    >
      {({ instrument }) => (
        <FormControl
          required={required}
          error={validation.error}
          fullWidth
          variant="outlined"
          component="fieldset"
          margin="normal"
        >
          <FormLabel
            required={false}
            className={classes.label}
            id={`${id}-test-label`}
            htmlFor={`${id}-test`}
          >
            {title}
          </FormLabel>
          <OutlinedInput
            id={`${id}-test`}
            placeholder={description}
            aria-describedby={`${id}-test-label`}
            value={value}
            variant="outlined"
            onBlur={instrument('Interacted with form field', handleBlur)}
            onChange={handleChange}
          />
          <FormHelperText
            className={clsx(classes.formHelperText, {
              [classes.formHelperTextWithMessage]: !required || help
            })}
          >
            {formatFormHelperText(required, help, validation.message)}
          </FormHelperText>
        </FormControl>
      )}
    </Amplitude>
  )
}

AdministrativeFile.propTypes = {
  /**
    DBOID of the field.
  */
  id: PropTypes.string.isRequired,
  /**
    Type.
  */
  type: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Helper text.
  */
  help: PropTypes.string,
  /**
    Use this to indicate that a value must be provided.
  */
  required: PropTypes.bool,
  /**
    Initial value of the field
  */
  defaultValue: PropTypes.string,
  /**
    Regular expression for input validation
   */
  regex: PropTypes.string,
  /**
    Maximum length in characters
   */
  length: PropTypes.number,
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
  Handler to be called when a new validation needs to be shared
  @param {string} id - The id of the field.
  @param {bool} value - The validation result.
*/
  onValidation: PropTypes.func
}

AdministrativeFile.defaultProps = {
  description: null,
  help: null,
  required: false,
  defaultValue: undefined,
  regex: undefined,
  length: undefined,
  onUpdate: () => {},
  onValidation: () => {}
}
