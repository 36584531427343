import React, { useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'

import { Amplitude } from '@amplitude/react-amplitude'

import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormHelperText from '@material-ui/core/FormHelperText'

import AddressPicker from './AddressPicker'

import { makeStyles } from '@material-ui/core/styles'

import { string } from 'yup'
import formatFormHelperText from '../Utils/formatFormHelperText'
import { sanitizeString } from '../Utils/sanitizeForInputValue'

import { useLocationContext } from '../../../components/Context/LocationContext'

// Text validation schema
const schema = string()
  .when('$required', (required, s) => (required ? s.required('Completa este campo') : s)) // Empty string doesn't pass
  .when('$length', (length, s) => (length ? s.max(length) : s))
  .when('$regex', (regex, s) => (regex ? s.matches(regex) : s))

const useStyles = makeStyles(theme => ({
  label: {
    margin: theme.spacing(0, 2, 0.5, 0),
    lineHeight: 1.25
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75)
  },
  formHelperTextWithMessage: {
    marginBottom: theme.spacing(2) + 3
  }
}))

/**
Use an Address object field when you want the user to search or input a postal address.

- Free text input field.
- Blur events on the underlying input field are logged on the analytics platform.

### Events:
- Blur: always runs a validation
- Change: only runs a validation if we were on error state or if we clear the field

### Validations:
- Needs a value, if required
- Value length, if set
- Value matches regex pattern, if set
*/

export default function Address({
  id,
  type,
  title,
  description,
  help,
  required,
  defaultValue,
  regex,
  length,
  onUpdate,
  onValidation
}) {
  const classes = useStyles()

  // Location context
  const [defaultLocation, locationUtils, addressUtils] = useLocationContext()

  // State for field value and validation
  const [value, setValue] = useState(addressUtils.blankAddress)
  const [validation, setValidation] = useState({
    error: undefined,
    message: undefined,
    dirty: false
  })

  // On change, set the new value and share it and always validate
  const handleChange = newValue => {
    const address = { ...newValue /*, location: value.location*/ }
    setValue(address)
    onUpdate(id, address)
    console.log(`address ${id} has been changed`)
    console.log(address)
    // handleValidation(val)
  }

  // On clear, empty the address except the location part
  const handleClear = () => {
    const empty = { ...addressUtils.blankAddress, location: defaultLocation }
    setValue(empty)
    onUpdate(id, empty)
    console.log(`address ${id} has been cleared`)
    // handleValidation(val)
  }

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: type,
        fieldVariant: 'input',
        fieldLabel: title
      })}
    >
      {({ instrument }) => (
        <>
          <AddressPicker
            id={id}
            title={title}
            help={help}
            required={required}
            value={value}
            error={false} // validation.error
            onChange={handleChange}
            onClear={handleClear}
            // onChange & onBlur
          />
        </>
      )}
    </Amplitude>
  )
}

Text.propTypes = {
  /**
    DBOID of the field.
  */
  id: PropTypes.string.isRequired,
  /**
    Type.
  */
  type: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Helper text.
  */
  help: PropTypes.string,
  /**
    Use this to indicate that a value must be provided.
  */
  required: PropTypes.bool,
  /**
    Initial value of the field
  */
  defaultValue: PropTypes.string,
  /**
    Regular expression for input validation
   */
  regex: PropTypes.string,
  /**
    Maximum length in characters
   */
  length: PropTypes.number,
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
  Handler to be called when a new validation needs to be shared
  @param {string} id - The id of the field.
  @param {bool} value - The validation result.
*/
  onValidation: PropTypes.func
}

Text.defaultProps = {
  description: null,
  help: null,
  required: false,
  defaultValue: undefined,
  regex: undefined,
  length: undefined,
  onUpdate: () => {},
  onValidation: () => {}
}
