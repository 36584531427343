import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Amplitude } from '@amplitude/react-amplitude'

import FormControl from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'

import { array, string } from 'yup'

import formatFormHelperText from '../Utils/formatFormHelperText'
import { sanitizeArrayOfStrings, sanitizeArrayOfNumbers } from '../Utils/sanitizeForInputValue'

// Array validation schema
const schema = array().when(
  '$required',
  (required, s) => (required ? s.required('Completa este campo') : s) // Empty array doesn't pass
)
const optionsSchema = string()

const useStyles = makeStyles(theme => ({
  label: {
    margin: theme.spacing(0, 2, 0.5, 0),
    lineHeight: 1.25
  },
  formGroup: {
    marginLeft: theme.spacing(1.75)
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75)
  },
  formHelperTextWithMessage: {
    marginBottom: theme.spacing(2) + 3
  },
  fclRoot: {
    alignItems: 'flex-start'
  },
  fclLabel: {
    paddingTop: theme.spacing(1)
  }
}))

/**
Use a Checkboxgroup when you want the user to be able to check boxes.

- Can work with string or number keys (using the Numerical flag). The value dispatched will be typed.
- The underlying input fields change events are logged on the analytics platform.

### Events:
- Blur: only runs a validation on initially empty values without user value change when the field is required
- Change: always runs a validation

### Validations:
- Needs a value, if required
- Values must be a valid options
*/

export default function CheckboxGroup({
  id,
  type,
  title,
  description,
  help,
  required,
  options,
  numerical,
  defaultValue,
  onUpdate,
  onValidation
}) {
  const classes = useStyles()

  // Sanitize and transform from the JSON Array to a local Set
  // defaultChecked has to be either a Set of Numbers or a Set of Strings
  const defaultChecked = numerical
    ? new Set(sanitizeArrayOfNumbers(defaultValue))
    : new Set(sanitizeArrayOfStrings(defaultValue))

  // Local state for field value and validation
  const [value, setValue] = useState(defaultChecked)
  const [validation, setValidation] = useState({
    error: undefined,
    message: undefined,
    dirty: false
  })

  // Validate using schema, set the validation
  const handleValidation = useCallback(
    v => {
      const validOps = numerical ? options.map(el => String(el.key)) : options.map(el => el.key) // Force any number into its string representation
      schema
        .of(optionsSchema.oneOf(validOps, 'Selecciona una opción')) // Extend the validation schema to test for valid options
        .validate(v, { context: { required } })
        .then(() => {
          setValidation({ error: false, message: undefined, dirty: true })
        })
        .catch(e => {
          setValidation({ error: true, message: e.message, dirty: true })
        })
    },
    [required, options, numerical]
  )

  // On change, set the new value and share it and always validate
  const handleCheck = event => {
    const { checked } = event.target
    const vals = validation.error ? new Set() : value // If we come from an error state, clear any previous value (this can only happen if a wrong value was saved)
    const v = numerical ? parseFloat(event.target.name) : event.target.name // Uncast the attribute
    if (checked) {
      vals.add(v)
    } else {
      vals.delete(v)
    }
    setValue(vals)
    onUpdate(id, Array.from(vals))
    handleValidation(Array.from(vals)) // Trigger yup
  }

  // A blur event happens when you jump from each checkbox, there's no way to track when you jump out of the group
  // We're only interested in blur events on initially empty values without user value change when the field is required
  // Everything else is catched on inital validation or on change event
  // It will still throw false positives, but...
  const handleBlur = () => {
    if (
      required &&
      value.size === 0 &&
      defaultChecked.size === 0 &&
      validation.error === undefined
    ) {
      handleValidation(Array.from(value))
    }
  }

  // Side-effect of new validation state: sync the context (skip if validation is yet undefined)
  useEffect(() => {
    if (typeof validation.error !== 'undefined') onValidation(id, !validation.error)
  }, [onValidation, id, validation.error])

  // Side-effect of having a default value
  // Validation will not run on unmatching types (uses the numerical boolean), and must be a healthy array of strings/numbers
  // This handles validation of saved values, context must be aware of empty initial fields
  useEffect(() => {
    if (!numerical && sanitizeArrayOfStrings(defaultValue).length !== 0)
      handleValidation(defaultValue)
    if (numerical && sanitizeArrayOfNumbers(defaultValue).length !== 0)
      handleValidation(defaultValue)
  }, [numerical, defaultValue, handleValidation])

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: type,
        fieldVariant: 'checkbox-group',
        fieldLabel: title
      })}
    >
      {({ instrument }) => (
        <FormControl
          required={required}
          error={validation.error}
          component="fieldset"
          className={classes.formControl}
          margin="normal"
          onBlur={handleBlur}
        >
          <FormLabel required={false} className={classes.label} component="legend">
            {title}
          </FormLabel>
          <FormGroup className={classes.formGroup}>
            {options.map(option => (
              <FormControlLabel
                key={`${id}-${option.key}`}
                control={
                  <Checkbox
                    id={`checkbox-${id}-${option.key}`}
                    name={`${option.key}`} // See state init for why this is cast to string
                    checked={value.has(option.key)}
                    value={String(option.key)}
                    onChange={instrument('Interacted with form field', handleCheck)}
                  />
                }
                labelPlacement="end"
                classes={{ root: classes.fclRoot, label: classes.fclLabel }}
                label={
                  <>
                    <Typography>{option.label}</Typography>
                    {option.help && (
                      <Typography variant="body2" color="textSecondary">
                        {option.help}
                      </Typography>
                    )}
                  </>
                }
              />
            ))}
          </FormGroup>
          <FormHelperText
            className={clsx(classes.formHelperText, {
              [classes.formHelperTextWithMessage]: !required || help
            })}
          >
            {formatFormHelperText(required, help, validation.message)}
          </FormHelperText>
        </FormControl>
      )}
    </Amplitude>
  )
}

CheckboxGroup.propTypes = {
  /**
    DBOID of the field.
  */
  id: PropTypes.string.isRequired,
  /**
    Type.
  */
  type: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Helper text.
  */
  help: PropTypes.string,
  /**
    Use this to indicate that the box must be checked.
  */
  required: PropTypes.bool,
  /**
    Array of options
  */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ).isRequired,
  /**
    Initial state of the field.
  */
  defaultValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  /**
    Uses numerical values
  */
  numerical: PropTypes.bool,
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
   Handler to be called when a new validation needs to be shared
   @param {string} id - The id of the field.
   @param {bool} value - The validation result.
 */
  onValidation: PropTypes.func
}

CheckboxGroup.defaultProps = {
  description: null,
  help: null,
  required: false,
  defaultValue: undefined,
  numerical: false,
  onUpdate: () => {},
  onValidation: () => {}
}
