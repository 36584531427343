import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Collapse from '@material-ui/core/Collapse'
import Slide from '@material-ui/core/Slide'
import Fade from '@material-ui/core/Fade'

const useStyles = makeStyles(theme => ({
  popoverPaperRoot: {
    maxHeight: theme.mixins.toolbar.minHeight * 10,
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    borderStyle: 'solid'
  },
  popoverPaperNormal: {
    width: props => props.anchorEl?.clientWidth + 4,
    minWidth: (theme.breakpoints.values.sm * 2) / 3
  },
  popoverPaperWide: {
    width: (theme.breakpoints.values.md * 3) / 4
  },
  popoverPaperFullScreen: {
    width: '100%',
    minWidth: '100%',
    height: '100%',
    maxWidth: '100%',
    maxHeight: 'none',
    borderStyle: 'none'
  }
}))

export default function ObjectModal({
  id,
  type,
  wide,
  open,
  anchorEl,
  children,
  onClose,
  onEnter,
  onExited,
  onExiting,
  fullscreen,
  loading
}) {
  // JSS classes
  const classes = useStyles({ anchorEl })
  return (
    <Popover
      id={`popover-for-${id}`}
      open={open}
      anchorEl={anchorEl}
      onClose={() => onClose()}
      onEnter={onEnter}
      onExiting={onExiting}
      onExited={onExited}
      TransitionComponent={Fade}
      //transitionDuration={100}
      PaperProps={{
        classes: {
          root: clsx(
            classes.popoverPaperRoot,
            wide ? classes.popoverPaperWide : classes.popoverPaperNormal,
            fullscreen && classes.popoverPaperFullScreen
          )
        }
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: wide ? 'left' : 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: wide ? 'left' : 'center'
      }}
      marginThreshold={fullscreen ? 0 : 16}
    >
      {children}
    </Popover>
  )
}

ObjectModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.instanceOf(Element),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onClose: PropTypes.func,
  onEnter: PropTypes.func,
  onExiting: PropTypes.func,
  onExited: PropTypes.func,
  fullscreen: PropTypes.bool
}

ObjectModal.defaultProps = {
  anchorEl: null,
  onClose: () => {},
  onEnter: () => {},
  onExiting: () => {},
  onExited: () => {},
  fullscreen: false
}
