import React from 'react'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'

import { makeStyles } from '@material-ui/core/styles'

import DocumentLabel from './Card/DocumentLabel'
import DocumentProps from './Card/DocumentProps'
import DocumentFileInput from './Card/DocumentFileInput'

const useStyles = makeStyles(({ palette, spacing }) => ({
  root: {
    //height: '100%',
    //display: 'flex',
    //flexDirection: 'column',
    width: '100%',
    overflow: 'visible',
    marginTop: spacing(0),
    marginBottom: spacing(2),
    borderStyle: 'none',
    borderBottomStyle: 'solid',
    borderColor: palette.divider,
    //borderColor: 'transparent',
    '&:hover': {
      //borderColor: palette.divider
    },
    '&:last-of-type': {
      borderColor: 'transparent'
    }
  }
}))

/**
The Document Card displays the document field.

Has 4 areas:

- Action area: a clickable button, represents a document
- Icon area: shows field properties and a clear button
- Meta area: uploaded file properties (or a skeleton placeholder)
- Field area: shows field labels and messages (NYI)

The action area has these states:

- Emtpy field: shows an add button
- Accepted file dragged: gives a positive visual/textual clue
- Unaccepted file dragged: gives a negative visual/textual clue
- Uploading file: shows a spinner
- Uploaded file: renders a preview of the first page (PDFs) or illustrations for basic filetypes (Word, Powerpoint, Excel, Video, Audio, Generic File)
- Error on upload (not yet implemented)

*/

export default function DocumentCard({
  id,
  title,
  description,
  required,
  documentProps: { reusable, signature, multiple, accepts },
  interactionProps: { isDragActive, isDragAccept, isDragReject, isLoading },
  fileProps: { hasFile, files },
  preview,
  actionAreaProps,
  actionHandlers: { handleAdd, handleEdit, handleRemove }
}) {
  const classes = useStyles()

  return (
    <Card
      className={classes.root}
      variant="outlined"
      onDrop={e => e.preventDefault()}
      id={`document-card-for-${id}`}
    >
      {/* Draggable area */}
      <CardContent className={classes.cardContent} {...actionAreaProps}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <DocumentLabel
              title={title}
              description={description}
              error={isDragReject}
              required={required}
              hasFile={hasFile}
              documentProps={{ reusable, signature, multiple, accepts }}
            />
          </Grid>
          {/*<Grid item xs={2}>
            <DocumentProps hasFile={hasFile} documentProps={{ multiple, signature, reusable }} />
  </Grid>*/}
          <Grid item xs={4}>
            <DocumentFileInput
              interactionProps={{ isDragActive, isDragAccept, isDragReject, isLoading }}
              fileProps={{ hasFile, files }}
              documentProps={{ reusable, signature, multiple }}
              actionHandlers={{ handleAdd, handleEdit, handleRemove }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
