import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Amplitude } from '@amplitude/react-amplitude'

import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { makeStyles } from '@material-ui/core/styles'

import { array, string } from 'yup'

import formatFormHelperText from '../Utils/formatFormHelperText'
import { sanitizeArrayOfStrings, sanitizeArrayOfNumbers } from '../Utils/sanitizeForInputValue'

// Array validation schema
const schema = array().when(
  '$required',
  (required, s) => (required ? s.required('Completa este campo') : s) // Empty array doesn't pass
)
const optionsSchema = string()

const UNTOUCHED = 'Seleccionar opciones...'
const INVALID = 'Revisa las opciones...'

const useStyles = makeStyles(theme => ({
  label: {
    margin: theme.spacing(0, 2, 0.5, 0),
    lineHeight: 1.25
  },
  selectPaper: {
    marginTop: -2,
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    borderStyle: 'solid'
  },
  selectMenuItem: {
    alignItems: 'flex-start',
    padding: theme.spacing(0.75, 1)
  },
  selectListItemTextLabel: {
    marginTop: 10
  }
}))

/**
Use a MultiSelect when you want the user to be able to pick multiple options from a dropdown list.

- Can work with string or number keys (using the Numerical flag). The value dispatched will be typed.
- The underlying input field change event is logged on the analytics platform.

### Events:
- Blur: only runs a validation on untouched fields
- Change: always runs a validation

### Validations:
- Needs a value, if required
- Values must be a valid options
*/

export default function MultiSelect({
  id,
  type,
  title,
  description,
  help,
  required,
  options,
  numerical,
  defaultValue,
  onUpdate,
  onValidation
}) {
  const classes = useStyles()

  // Sanitize and transform from the JSON Array to a local Set
  // defaultSelected has to be either a Set of Numbers or a Set of Strings
  const defaultSelected = numerical
    ? sanitizeArrayOfNumbers(defaultValue)
    : sanitizeArrayOfStrings(defaultValue)

  // Local state for field value and validation
  const [selected, setSelected] = useState(defaultSelected)
  const [validation, setValidation] = useState({
    error: undefined,
    message: undefined,
    dirty: false
  })

  // Validate using schema, set the validation
  const handleValidation = useCallback(
    v => {
      const validOps = numerical ? options.map(el => String(el.key)) : options.map(el => el.key) // Force any number into its string representation
      schema
        .of(optionsSchema.oneOf(validOps, 'Selecciona una opción')) // Extend the validation schema to test for valid options
        .validate(v, { context: { required } })
        .then(() => {
          setValidation({ error: false, message: undefined, dirty: true })
        })
        .catch(e => {
          setValidation({ error: true, message: e.message, dirty: true })
        })
    },
    [required, options, numerical]
  )

  // On change, set the new value and share it and always validate
  const handleChange = event => {
    const inputVals = validation.error
      ? event.target.value.filter(el => options.find(op => op.key === el)) // If we come from an error state, clear any previous value (this can only happen if a wrong value was saved)
      : event.target.value
    console.log(inputVals)
    setSelected(inputVals)
    onUpdate(id, inputVals)
    handleValidation(inputVals) // Trigger yup
  }

  // On blur, trigger validation if it's the first interaction (a saved value was an interaction)
  const handleBlur = () => {
    if (!validation.dirty) handleValidation(selected)
  }

  // Side-effect of new validation state: sync the context (skip if validation is yet undefined)
  useEffect(() => {
    if (typeof validation.error !== 'undefined') onValidation(id, !validation.error)
  }, [onValidation, id, validation.error])

  // Side-effect of having a default value
  // Validation will not run on unmatching types (uses the numerical boolean), and must be a healthy array of strings/numbers
  // This handles validation of saved values, context must be aware of empty initial fields
  useEffect(() => {
    if (!numerical && sanitizeArrayOfStrings(defaultValue).length !== 0)
      handleValidation(defaultValue)
    if (numerical && sanitizeArrayOfNumbers(defaultValue).length !== 0)
      handleValidation(defaultValue)
  }, [numerical, defaultValue, handleValidation])

  const getLabels = vs => {
    const filtered = options.filter(op => vs.includes(op.key)).map(el => el.label)
    return filtered
  }

  const renderSelectedValue = vs => {
    const labels = getLabels(vs)
    switch (vs.length) {
      case 0:
        return required ? UNTOUCHED : ''
      case 1:
        return labels.length === vs.length ? labels : INVALID
      case 2:
        return labels.length === vs.length ? labels.join(' and ') : INVALID
      default:
        return labels.length === vs.length
          ? labels
              .slice(0, 2)
              .join(', ')
              .concat(` and ${vs.length - 2} more`)
          : INVALID
    }
  }
  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: type,
        fieldVariant: 'multi-select',
        fieldLabel: title
      })}
    >
      {({ instrument }) => (
        <FormControl
          required={required}
          error={validation.error}
          fullWidth
          variant="outlined"
          component="fieldset"
          margin="normal"
        >
          <FormLabel required={false} className={classes.label} id={`label-${id}`} htmlFor={id}>
            {title}
          </FormLabel>
          <Select
            id={id}
            placeholder={description}
            onChange={instrument('Interacted with form field', handleChange)}
            onBlur={handleBlur}
            multiple
            IconComponent={ExpandMoreIcon}
            value={selected}
            MenuProps={{
              classes: { paper: classes.selectPaper },
              variant: 'menu',
              getContentAnchorEl: undefined, // prevents position change on selection
              elevation: 4,
              MenuListProps: { disablePadding: true },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            }}
            displayEmpty
            renderValue={renderSelectedValue}
          >
            {options.map(option => (
              <MenuItem key={option.key} value={option.key} className={classes.selectMenuItem}>
                <Checkbox checked={selected.indexOf(option.key) > -1} />
                <ListItemText
                  primary={option.label}
                  secondary={option.help}
                  className={classes.selectListItemTextLabel}
                />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formatFormHelperText(required, help, validation.message)}
          </FormHelperText>
        </FormControl>
      )}
    </Amplitude>
  )
}

MultiSelect.propTypes = {
  /**
    DBOID of the field.
  */
  id: PropTypes.string.isRequired,
  /**
    Type.
  */
  type: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Helper text.
  */
  help: PropTypes.string,
  /**
    Use this to indicate that the box must be checked.
  */
  required: PropTypes.bool,
  /**
    Array of options
  */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ).isRequired,
  /**
    Initial state of the field.
  */
  defaultValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  /**
    Uses numerical values
  */
  numerical: PropTypes.bool,
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
  Handler to be called when a new validation needs to be shared
  @param {string} id - The id of the field.
  @param {bool} value - The validation result.
*/
  onValidation: PropTypes.func
}

MultiSelect.defaultProps = {
  description: null,
  help: null,
  required: false,
  defaultValue: undefined,
  numerical: false,
  onUpdate: () => {},
  onValidation: () => {}
}
