import React, { useState, useEffect, useRef } from 'react'
import isEqual from 'lodash/isEqual'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import ObjectField from './Pickers/Object/ObjectField'
import ObjectModal from './Pickers/Object/ObjectModal'

import OptionsDrawer from './Pickers/Options/OptionsDrawer'

import SearchPicker from './Pickers/SearchPicker'
import FormPicker from './Pickers/FormPicker'

import { useLocationContext } from '../../../components/Context/LocationContext'

const getPickerComponent = type => (type === 'S' ? SearchPicker : FormPicker)

/**
The AddressPicker:

- Renders the field that displays the current addres held by the Address parent component
- Holds the current edited location object
- Chooses the appropriate picker component based on current location
- Renders and controls the modal for the picker
- Renders and controls the location drawer
*/

export default function AddressPicker({
  id,
  title,
  help,
  required,
  error,
  value,
  onChange,
  onClear
}) {
  // Location context
  const [defaultLocation, locationUtils, addressUtils] = useLocationContext()

  // Modal anchor
  const ref = useRef()

  // Edit-in-progress copy of the address value (kept here to not trigger value changes & validation at the address level until a new address has been fully set)
  // This also controls which picker is shown
  const [editedAddress, setEditedAddress] = useState(value)

  // Handler for location changes
  const handleLocationChange = newLocation => {
    setEditedAddress({ ...addressUtils.blankAddress, location: newLocation })
    // console.log('handleLocationChange')
  }

  // Handler for address changes
  const handleAddressChange = newAddress => {
    setEditedAddress(newAddress)
    // console.log('handleAddressChange')
  }

  /* useEffect(() => {
    console.log('location changed')
  }, [editedAddress.location]) */

  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'))

  // Options drawer state
  const [drawerOpen, setDrawerOpen] = useState(false)
  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }
  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  // Trigger closing the drawer on location change
  useEffect(() => {
    handleDrawerClose()
  }, [editedAddress.location])

  // Modal state
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    if (!editedAddress.location.municipality) setDrawerOpen(true) // force the location picker to open
    setOpen(true) // open the modal
  }

  // Handler for the close intent of the modal
  const handleClose = address => {
    setOpen(false) // close the modal
    if (address && !isEqual(address, value)) onChange(address) // fire the change handler to save the address
  }

  // Handler for the exited state of the modal (clean-up)
  const handleReset = () => {
    setEditedAddress(value) // restore the edited address to the last saved address
  }

  // Handler for the confirmation button
  const handleAccept = () => {
    handleClose(editedAddress) // fire the close handler with the edited address
  }

  // Handler for the clear button
  const handleClear = () => {
    const empty = { ...addressUtils.blankAddress, location: defaultLocation }
    setEditedAddress(empty)
    onClear()
  }

  // Selection of component
  // const Picker = getPickerComponent(coded)
  const type = locationUtils.location.municipality.getMunicipalityOptionAddressType(
    editedAddress.location.municipality
  )
  const Picker = getPickerComponent(type)

  return (
    <>
      <ObjectField
        id={id}
        title={title}
        help={help}
        required={required}
        primary={addressUtils.isEmpty(value) ? undefined : addressUtils.formatPrimaryLabel(value)}
        secondary={
          addressUtils.isEmpty(value) ? undefined : addressUtils.formatSecondaryLabel(value)
        }
        error={error}
        ref={ref}
        onClick={handleOpen} // open object picker
        onClear={handleClear} // reset the object
        // onBlur
      />
      <ObjectModal
        id={id}
        wide={type !== 'S'}
        open={open && Boolean(editedAddress.location.municipality)}
        anchorEl={ref.current}
        fullscreen={fullscreen}
        onClose={() => handleClose()}
        onEnter={() => {}}
        onExited={handleReset}
        onExiting={() => {}} // send the new value to the field if changed
        loading={!editedAddress.location.municipality}
      >
        <Picker
          title={title}
          value={editedAddress} // value
          anchorEl={ref.current}
          onClose={handleAccept} // close object picker
          onChange={handleAddressChange}
          onDrawerOpen={handleDrawerOpen}
        />
      </ObjectModal>
      <OptionsDrawer
        location={editedAddress.location}
        fullscreen={fullscreen}
        open={drawerOpen}
        onLocationChange={handleLocationChange}
        onClose={handleDrawerClose}
      />
    </>
  )
}
