import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  sidebar: {
    minWidth: '300px',
    flexGrow: 1,
    // Tablet or smaller
    [theme.breakpoints.down('md')]: {
      minWidth: '250px' // let it shrink a little
    }
  },
  sidebarLeft: {
    position: 'sticky',
    top: 64,
    marginLeft: 'auto',
    marginRight: 0,
    maxWidth: '300px'
  },
  sidebarRight: {
    position: 'sticky',
    top: 64,
    marginLeft: 0,
    marginRight: 'auto',
    maxWidth: '300px',
    // Tablet or smaller
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: theme.spacing(2),
      position: 'relative',
      top: 0
    }
  }
}))

const Sidebar = React.forwardRef(function Sidebar(
  { position, component: Component, children, className },
  ref
) {
  const classes = useStyles()
  return (
    <Hidden mdDown={position === 'left'} smDown={position === 'right'}>
      <Component className={classes.sidebar}>
        <Container
          maxWidth="xs"
          className={clsx(
            {
              [classes.sidebarRight]: position === 'right',
              [classes.sidebarLeft]: position === 'left'
            },
            className
          )}
          ref={ref}
        >
          {children}
        </Container>
      </Component>
    </Hidden>
  )
})

export default Sidebar

Sidebar.propTypes = {
  position: PropTypes.string.isRequired,
  component: PropTypes.elementType,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

Sidebar.defaultProps = {
  component: 'div',
  className: null
}
