import React from 'react'
import clsx from 'clsx'

import Chip from '@material-ui/core/Chip'

import { makeStyles } from '@material-ui/core/styles'

const NUM_EXTENSIONS = 5

const useStyles = makeStyles(({ palette, spacing, shadows, typography }) => ({
  section: { marginTop: spacing(1), display: 'flex', justifyContent: 'flex-start' },
  chip: { marginTop: spacing(1), marginRight: spacing(1), color: palette.text.secondary },
  last: { marginRight: 0 },
  more: {
    marginTop: spacing(1),
    marginRight: spacing(1),
    color: palette.text.secondary,
    border: 'none'
  }
}))

export default function DocumentFileTypes({ accepts }) {
  const classes = useStyles()

  return (
    <section className={classes.section}>
      {accepts.map(
        (extension, index) =>
          index < NUM_EXTENSIONS && (
            <Chip
              size="small"
              label={extension.toUpperCase()}
              className={clsx(classes.chip, index === NUM_EXTENSIONS - 1 && classes.last)}
              variant="default"
              key={extension}
            />
          )
      )}
      {accepts.length > NUM_EXTENSIONS && (
        <Chip
          size="small"
          label={
            accepts.length - NUM_EXTENSIONS === 1
              ? 'y 1 tipo más'
              : `y otros ${accepts.length - NUM_EXTENSIONS} tipos`
          }
          className={classes.more}
          variant="outlined"
        />
      )}
      {/*<Chip size="small" label="PDF" className={classes.chip} variant="outlined" />
      <Chip size="small" label="DOC" className={classes.chip} variant="outlined" />
      <Chip size="small" label="DOCX" className={classes.chip} variant="outlined" />
      <Chip size="small" label="PPT" className={classes.chip} variant="outlined" />
      <Chip
        size="small"
        label="PPTX"
        className={clsx(classes.chip, classes.last)}
        variant="outlined"
      />
  <Chip size="small" label="y otros 10 tipos" className={classes.more} variant="outlined" />*/}
    </section>
  )
}
