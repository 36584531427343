import React, { useReducer } from 'react'

const emtpyfn = () => {}

const ValidationDemoStateContext = React.createContext()
const ValidationDemoUpdaterContext = React.createContext(emtpyfn)

function stateReducer(state, action) {
  switch (action.type) {
    case 'store-value': {
      const { id, value } = action.payload
      return {
        ...state,
        values: { ...state.values, [id]: value }
      }
    }
    case 'store-validation': {
      const { id, value } = action.payload
      return {
        ...state,
        validations: { ...state.validations, [id]: value }
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function ValidationDemoProvider(props) {
  const { initialState, children } = props
  const [state, dispatch] = useReducer(stateReducer, initialState)
  return (
    <ValidationDemoStateContext.Provider value={state}>
      <ValidationDemoUpdaterContext.Provider value={dispatch}>
        {children}
      </ValidationDemoUpdaterContext.Provider>
    </ValidationDemoStateContext.Provider>
  )
}

function useValidationDemoState() {
  const validationState = React.useContext(ValidationDemoStateContext)
  /*if (typeof validationState === 'undefined') {
    throw new Error('useValidationDemoState must be used within a ValidationDemoProvider')
  }*/
  return validationState
}

function useValidationDemoUpdater() {
  const dispatch = React.useContext(ValidationDemoUpdaterContext)
  /*if (typeof dispatch === 'undefined') {
    throw new Error('useValidationDemoUpdater must be used within a ValidationDemoProvider')
  }*/

  const updateValue = React.useCallback(
    (id, value) => dispatch({ type: 'store-value', payload: { id, value } }),
    [dispatch]
  )

  const updateValidation = React.useCallback(
    (id, value) => dispatch({ type: 'store-validation', payload: { id, value } }),
    [dispatch]
  )

  return [dispatch, updateValue, updateValidation]
}

export { useValidationDemoState, useValidationDemoUpdater, ValidationDemoProvider }
