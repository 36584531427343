import React, { useState, useEffect, useCallback, useContext } from 'react'
import debounce from 'lodash/debounce'

import axios from 'axios'

import { useProgressState } from './ProgressContext'

const randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

const useMockupApi = () => {
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [timestamp, setTimestamp] = useState(null)

  // console.log('mockup api')
  // console.log({ isLoading, isError, data, timestamp })

  const fetchRequest = async () => {
    setIsError(false)
    setIsLoading(true)
    try {
      const result = await axios(
        `https://flash.siwalik.in/delay/${randomIntFromInterval(500, 4000)}`
      )
      setData(result.data)
    } catch (error) {
      setIsError(true)
    }
    setIsLoading(false)
    setTimestamp(new Date())
  }

  return [{ data, isLoading, isError, timestamp }, fetchRequest]
}

const AutosaveContext = React.createContext()

function AutosaveProvider(props) {
  const { children } = props

  const [{ data, isLoading, isError, timestamp }, fetchRequest] = useMockupApi()

  // Trigger simulated save
  const triggerSave = React.useCallback(
    debounce(() => {
      /*
      setIsLoading(true)
      const timer = setTimeout(() => {
        setIsLoading(false)
      }, 3000)
      return () => clearTimeout(timer)
      */
      fetchRequest()
    }, 1500),
    []
  )

  // Hook into progress context
  const progress = useProgressState()

  // Side-effect of changes in progress: trigger save
  const isFirstRun = React.useRef(true)
  React.useEffect(() => {
    if (isFirstRun.current) isFirstRun.current = false
    else triggerSave()
  }, [progress, triggerSave, isFirstRun])

  /*React.useEffect(() => {
    const timer = setTimeout(
      () => {
        setIsLoading(prev => !prev)
      },
      isLoading ? 3000 : 10000
    )
    return () => clearTimeout(timer)
  }, [isLoading])*/

  // console.log('autosaveprovider render')
  // console.log({ isLoading, timestamp })

  return (
    <AutosaveContext.Provider value={[isLoading, timestamp]}>{children}</AutosaveContext.Provider>
  )
}

function useAutosaveContext() {
  const [isLoading, timestamp] = React.useContext(AutosaveContext)

  return [isLoading, timestamp]
}

export { useAutosaveContext, AutosaveProvider }
