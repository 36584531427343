import React, { useState } from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import { makeStyles } from '@material-ui/core/styles'

import Form from '../../../lib/Fields/Address/Pickers/FormPicker'
import { useLocationContext } from '../../Context/LocationContext'

const useStyles = makeStyles(theme => ({
  page: { marginTop: theme.spacing(4) }
}))

export default function Page() {
  const classes = useStyles()

  // Location context
  const [defaultLocation, locationUtils, addressUtils] = useLocationContext()

  // Address state
  const [filledValue, setFilledValue] = useState({
    ...addressUtils.blankAddress,
    location: defaultLocation
  })
  const handleFilledValueChange = newAddress => {
    setFilledValue(newAddress)
  }

  // Location state
  const [filledLocation, setFilledLocation] = useState(filledValue.location)
  console.log(filledLocation)
  const handleFilledLocationChange = newLocation => {
    setFilledLocation(newLocation)
  }

  return (
    <Container maxWidth="md" className={classes.page}>
      <Grid container spacing={2}>
        <Form
          title={'Formulario'}
          open
          value={filledValue}
          location={filledLocation}
          anchorEl={null}
          onClose={console.log} // close object picker
          onChange={handleFilledValueChange}
          onClear={() => {}}
          onDrawerOpen={() => {}}
        />
      </Grid>
      <pre>{JSON.stringify(filledValue, null, 2)}</pre>
    </Container>
  )
}
