import React, { useMemo } from 'react'

import { useProgressUpdater } from '../../components/Context/ProgressContext'

import Component from './Document'
import { getType } from 'mime'

export default function Document({
  id,
  title,
  description,
  required,
  reusable,
  signature,
  multiple,
  defaultValue,
  maxSize,
  accepts
}) {
  // Hook up context
  const [updateValue, updateValidation] = useProgressUpdater('documents')

  // Return memoized Document
  return useMemo(() => {
    return (
      <Component
        id={id}
        title={title}
        description={description}
        required={required}
        reusable={reusable}
        signature={signature}
        multiple={multiple}
        defaultValue={defaultValue}
        maxSize={maxSize}
        accepts={accepts}
        onUpdate={updateValue}
        onValidation={updateValidation}
      />
    )
  }, [
    accepts,
    defaultValue,
    description,
    id,
    multiple,
    maxSize,
    required,
    reusable,
    signature,
    title,
    updateValidation,
    updateValue
  ])
}
