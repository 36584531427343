import * as React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(({ palette, spacing }) => ({
  section: { marginBottom: spacing(2) },
  inherit: {},
  primary: { color: palette.primary.contrastText, backgroundColor: palette.primary.main },
  secondary: { color: palette.secondary.contrastText, backgroundColor: palette.secondary.main },
  success: { color: palette.success.contrastText, backgroundColor: palette.success.main },
  error: { color: palette.error.contrastText, backgroundColor: palette.error.main },
  action: {
    color: palette.getContrastText(palette.action.disabled),
    backgroundColor: palette.action.active
  },

  disabled: {
    color: palette.getContrastText(palette.action.disabled),
    backgroundColor: palette.action.disabled
  }
}))

const DialogContentIconSection = React.forwardRef(function DialogContentText(
  { icon, color, children },
  ref
) {
  const classes = useStyles()
  return (
    <section ref={ref} className={classes.section}>
      <Grid container spacing={2}>
        <Grid item>
          <Avatar className={classes[color]}>{icon}</Avatar>
        </Grid>
        <Grid item xs>
          {children}
        </Grid>
      </Grid>
    </section>
  )
})

DialogContentIconSection.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node.isRequired,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  icon: PropTypes.node.isRequired,
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   */
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'error',
    'success',
    'action',
    'disabled'
  ])
}

DialogContentIconSection.defaultProps = {
  color: 'action'
}

export default DialogContentIconSection
