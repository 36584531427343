import React from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'

import { makeStyles, fade } from '@material-ui/core/styles'

import artwork from './peep-start.png'

const useStyles = makeStyles(theme => ({
  section: {
    padding: theme.spacing(2, 4.25),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      padding: theme.spacing(0.75, 2, 0.75, 2.75)
    }
  },
  artwork: { maxWidth: '75%', [theme.breakpoints.down('xs')]: { maxHeight: '25vh' } }
}))

export default function StartTyping() {
  const classes = useStyles()
  return (
    <section className={classes.section}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Fade in>
            <img src={artwork} alt="instructive peep" className={classes.artwork} />
          </Fade>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography variant="subtitle2" gutterBottom>
            Empieza a teclear
          </Typography>
          <Typography variant="body2" paragraph>
            Encuentra tu dirección por el nombre de tu calle y número
          </Typography>
        </Grid>
      </Grid>
    </section>
  )
}
