import React, { useState } from 'react'
import clsx from 'clsx'

import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import FilledInput from '@material-ui/core/FilledInput'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import PlusOneIcon from '@material-ui/icons/PlusOne'
import TextFormatIcon from '@material-ui/icons/TextFormat'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import MenuIcon from '@material-ui/icons/Menu'
import FormatColorTextIcon from '@material-ui/icons/FormatColorText'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import { useLocationContext } from '../../components/Context/LocationContext'
import { useEffect } from 'react'

const useStyles = makeStyles(theme => ({
  margin: {
    //margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    //width: '25ch'
  },
  inputRoot: {
    //background: theme.palette.background.default,
    //borderRadius: 0
  },
  selectIcon: {
    color: theme.palette.primary.main
  },
  button: { marginTop: 2 }
}))

export default function InputAdornments({ onChange, street, access }) {
  const classes = useStyles()

  // Location context
  const [defaultLocation, locationUtils, addressUtils] = useLocationContext()

  const [constants] = useState({
    street: { types: locationUtils.street.getStreetTypes() },
    number: { types: locationUtils.access.getNumerationTypes() },
    duplicate: { values: locationUtils.access.getDuplicates() }
  })

  // Inbound values
  const { type: streetType = constants.street.types[0], name: streetName = '' } = street
  const {
    type: numerationType = constants.number.types[0],
    value: rawNumerationValue = ''
  } = access
  const numerationValue = rawNumerationValue.start ? rawNumerationValue.start : rawNumerationValue
  const duplicate = '' // provisional

  const [inputStreetName, setStreetName] = useState(streetName)
  const [inputNumerationValue, setNumerationValue] = useState(numerationValue)

  const [menuOpen, setMenuOpen] = useState('')

  const handleMenuOpen = event => {
    const { currentTarget } = event
    setMenuOpen(currentTarget)
  }

  const handleMenuClose = () => {
    setMenuOpen('')
  }

  /*const handleMenuSelection = (name, value) => {
    const option = constants[name].types.find(el => el.key === value)
    setValues({ ...values, [name]: { ...values[name], type: option } })
    handleMenuClose()
  }*/

  const debouncedStreetNameChange = debounce(value => {
    if (value !== streetName) onChange(prev => addressUtils.updateStreetName(prev, value))
  }, 350)

  const handleStreetNameChange = event => {
    const {
      target: { value }
    } = event

    //if (value !== streetName) onChange(prev => addressUtils.updateStreetName(prev, value))
    setStreetName(value)
    debouncedStreetNameChange(value)
  }

  // Clear inputStreetName if the inbound street is empty
  useEffect(() => {
    if (!streetName) setStreetName('')
  }, [streetName])

  const handleStreetTypeChange = type => {
    if (!isEqual(streetType, type)) onChange(prev => addressUtils.updateStreetType(prev, type))
    handleMenuClose()
  }

  const debouncedNumerationValueChange = debounce(value => {
    if (value !== numerationValue)
      onChange(prev => addressUtils.updateNumerationValue(prev, numerationType.key, value))
  }, 350)

  const handleNumerationValueChange = event => {
    const {
      target: { value }
    } = event

    setNumerationValue(value)
    debouncedNumerationValueChange(value)
  }

  // Clear inputNumerationValue if the inbound access is empty
  useEffect(() => {
    if (!numerationValue) setNumerationValue('')
  }, [numerationValue])

  const handleNumerationTypeChange = type => {
    if (!isEqual(numerationType, type))
      onChange(prev => addressUtils.updateNumerationType(prev, type))
    handleMenuClose()
  }

  const handleNumertaionDuplicateChange = () => {} // provisional

  return (
    <>
      <Grid item xs={12} sm={12}>
        <FormControl
          fullWidth
          className={clsx(classes.margin, classes.textField)}
          variant="outlined"
        >
          <InputLabel htmlFor="input-street">Tipo y nombre de la vía</InputLabel>
          <OutlinedInput
            id="input-street"
            label="Tipo y nombre de la vía"
            //value={values.street.value}
            value={inputStreetName}
            //defaultValue={streetName}
            onChange={handleStreetNameChange}
            autoFocus
            startAdornment={
              <InputAdornment position="start">
                {/*<IconButton
                  aria-label="open list of street types"
                  onClick={handleMenuOpen}
                  name="street-type"
                  color="primary"
                  edge="start"
                >
                  {menuOpen === 'street-type' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>*/}
                <Button
                  startIcon={
                    menuOpen?.name === 'street-type' ? (
                      <ExpandLessIcon color="primary" />
                    ) : (
                      <ExpandMoreIcon color="primary" />
                    )
                  }
                  aria-label="open list of street types"
                  aria-controls="menu-street-type"
                  aria-haspopup="true"
                  onClick={handleMenuOpen}
                  name="street-type"
                  edge="start"
                  className={classes.button}
                >
                  {/*values.street.type.label*/}
                  {streetType.label}
                </Button>
                <Menu
                  id="menu-street-type"
                  anchorEl={menuOpen}
                  open={menuOpen?.name === 'street-type'}
                  variant="menu"
                  getContentAnchorEl={null}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  {constants.street.types.map(el => (
                    <MenuItem
                      key={`option-for-street-type-${el.key}`}
                      onClick={() => handleStreetTypeChange(el)}
                      selected={/*values.street.type.key === el.key*/ streetType.key === el.key}
                    >
                      {el.label}
                    </MenuItem>
                  ))}
                </Menu>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl
          fullWidth
          className={clsx(classes.margin, classes.textField)}
          variant="outlined"
        >
          <InputLabel htmlFor="input-number">Numeración</InputLabel>
          <OutlinedInput
            id="input-number"
            label="Numeración"
            // key={`number-field-for-type-${numerationType.key}`}
            value={inputNumerationValue}
            //defaultValue={numerationValue}
            onChange={handleNumerationValueChange}
            startAdornment={
              <InputAdornment position="start">
                <Button
                  startIcon={
                    menuOpen?.name === 'numeration-type' ? (
                      <ExpandLessIcon color="primary" />
                    ) : (
                      <ExpandMoreIcon color="primary" />
                    )
                  }
                  aria-label="open list of numeration types"
                  onClick={handleMenuOpen}
                  name="numeration-type"
                  edge="start"
                  className={classes.button}
                >
                  {numerationType.label}
                </Button>
                <Menu
                  id="menu-numeration-type"
                  anchorEl={menuOpen}
                  open={menuOpen?.name === 'numeration-type'}
                  variant="menu"
                  getContentAnchorEl={null}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  {constants.number.types.map(el => (
                    <MenuItem
                      key={`option-for-numeration-type-${el.key}`}
                      value={el.key}
                      onClick={() => handleNumerationTypeChange(el)}
                      selected={/*values.number.type.key === el.key*/ numerationType.key === el.key}
                    >
                      {el.label}
                    </MenuItem>
                  ))}
                </Menu>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth variant="outlined" className={classes.formControl} margin="none">
          <InputLabel shrink id="demo-simple-select-filled-label-duplicate">
            Duplicado (opcional)
          </InputLabel>
          <Select
            id="demo-simple-select-filled-duplicate"
            name="duplicate"
            // defaultValue={''}
            // margin="dense"
            classes={{ root: classes.inputRoot, icon: classes.selectIcon }}
            displayEmpty
            IconComponent={ExpandMoreIcon}
            disabled={numerationType.key !== 'NUM'}
            input={
              <OutlinedInput
                notched
                label="Duplicado (opcional)"
                name="duplicate"
                id="demo-simple-select-outlined-input-duplicate"
              />
            }
            value={duplicate}
            onChange={handleNumertaionDuplicateChange}
          >
            <MenuItem value="">Ninguno</MenuItem>
            {constants.duplicate.values.map(el => (
              <MenuItem key={`option-for-duplicate-${el.key}`} value={el.key}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </>
  )
}
