import React, { useEffect, useState } from 'react'

import { useRouteMatch, useParams, Redirect, Link as RouterLink } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'

import Fade from '@material-ui/core/Fade'
import Zoom from '@material-ui/core/Zoom'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'

// eslint-disable-next-line import/no-webpack-loader-syntax
import { Document as PDFDocument, Page } from 'react-pdf/dist/entry.webpack'
import './Success/pdf.css'

import PlayForWorkIcon from '@material-ui/icons/PlayForWork'
import MailTwoToneIcon from '@material-ui/icons/MailTwoTone'

import logo from '../../logo.svg'
import ChatBotAnimation from '../Animations/ChatBotAnimation'
import AstronautAnimation from '../Animations/AstronautAnimation'

import { useAuthContext } from '../Context/AuthContext'

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    padding: theme.spacing(4),
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    height: '40vmin'
  },
  progress: {
    margin: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(1, 1)
  },
  card: {
    borderWidth: 2,
    marginBottom: theme.spacing(2)
  },
  fill: {
    width: '100%',
    height: 'auto'
  }
}))

export default function SuccessPage({ title }) {
  const classes = useStyles()

  // Routing: step url parameter
  const fake = useRouteMatch('/tramite/:pid/formulario')
  const { pid } = useParams()

  // Auth context: simulate login response wait
  const [auth, setAuth] = useAuthContext()
  useEffect(() => {
    const timer = setTimeout(() => {
      setAuth(true)
    }, 3000)
    return () => clearTimeout(timer)
  }, [setAuth])

  // Schema context: simulate api call
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (auth) {
      const timer = setTimeout(() => {
        setLoading(false)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [setLoading, auth])

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Fade in timeout={500}>
        <Container maxWidth="xs">
          <Fade in>
            <div>
              <Typography variant="h5">Registro de entrada</Typography>
              <Typography paragraph>2018-E-RC-3030</Typography>
            </div>
          </Fade>
          <Card variant="outlined" className={classes.card}>
            <CardActionArea>
              <CardContent>
                <section className={classes.fill}>
                  <PDFDocument file="/justificante.pdf" renderMode="canvas">
                    <Page
                      pageNumber={1}
                      //height={150}
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                    />
                  </PDFDocument>
                </section>
              </CardContent>
            </CardActionArea>
          </Card>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<PlayForWorkIcon />}
          >
            Descargar el justificante
          </Button>
          <Button
            variant="text"
            color="secondary"
            className={classes.button}
            endIcon={<MailTwoToneIcon />}
          >
            Enviar por correo electrónico
          </Button>
        </Container>
      </Fade>
    </Container>
  )
}
