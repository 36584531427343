import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  section: {
    flexGrow: 1,
    paddingTop: theme.spacing(4),
    // Tablet or smaller
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: 72 + 36, // clear the nav rail and extra half of negative space
      paddingRight: theme.spacing(1)
    },
    // Mobile
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0, // nothing to clear
      paddingRight: 0
    }
  }
}))

const Content = React.forwardRef(function Content({ children, className }, ref) {
  const classes = useStyles()
  return (
    <Container
      maxWidth="md"
      component="article"
      disableGutters
      className={clsx(classes.section, className)}
      ref={ref}
    >
      {children}
    </Container>
  )
})

export default Content

Content.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

Content.defaultProps = {
  className: null
}
