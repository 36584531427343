import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import debounce from 'lodash/debounce'

import { Amplitude } from '@amplitude/react-amplitude'

import {
  TextField,
  Grid,
  FormLabel,
  FormHelperText,
  FormControl,
  Typography,
  Grow,
  InputAdornment
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import ContactSupportTwoToneIcon from '@material-ui/icons/ContactSupportTwoTone'
import ClearIcon from '@material-ui/icons/Clear'
import Skeleton from '@material-ui/lab/Skeleton'
import { makeStyles } from '@material-ui/core/styles'

import LegalPersonFields from './LegalPersonFields'
import NaturalPersonFields from './NaturalPersonFields'
import { FieldTooltip, FormTextField } from './FormElements'

import RenderCounter from '../Utils/RenderCounter'

import { types, isValid, printFormat, electronicFormat } from './spanishIdentity'
import formatFormHelperText from '../Utils/formatFormHelperText'
import { object, string } from 'yup'

// Validation schema
const schema = object({
  number: string()
    .when('$required', (required, s) => (required ? s.required() : s))
    .test('Is Valid ID Number', 'Please enter a valid spanish ID number', value =>
      value ? isValid(value) : true
    ),
  name: string().when('$type', (type, s) => (type === 'natural' ? s.required() : s)),
  firstSurname: string().when('$type', (type, s) => (type === 'natural' ? s.required() : s)),
  lastSurname: string(),
  entityName: string().when('$type', (type, s) => (type === 'legal' ? s.required() : s))
})

// Empty objects
const EMPTYPERSON = {
  name: '',
  firstSurname: '',
  lastSurname: '',
  entityName: ''
}
const EMPTYIDENTIFICATION = {
  number: '',
  type: undefined
}
const testperson = {
  name: '',
  firstSurname: '',
  lastSurname: '',
  entityName: ''
}
const testidentification = {
  number: '15S',
  type: 'natural'
}
const defaultValue = {
  ...testidentification,
  ...testperson
}

// Error + touched -> cross
// No error, not empty -> check (except when not required and empty, that's valid but we don't want adornment)
function AdornmentIcon({ error, touched, required, empty }) {
  return error
    ? touched && <ClearIcon color="error" />
    : touched && !(!required && empty) && <CheckIcon color="primary" />
}

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(0, 1)
  },
  horizontal: {
    width: '100%'
  },
  label: {
    margin: theme.spacing(0, 2, 0.5, 0),
    lineHeight: 1.25
  },
  lightWeight: {
    fontWeight: theme.typography.fontWeightLight
  },
  skeleton: {
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75)
  },
  formHelperTextWithMessage: {
    marginBottom: theme.spacing(2) + 3
  },
  category: {
    marginTop: 8,
    padding: '18px 8px',
    color: '#b0bec5'
  }
}))

export default function Person({
  id,
  type,
  title,
  description,
  help,
  required,
  //defaultValue,
  onUpdate,
  onValidation
}) {
  const classes = useStyles()

  // ID Value
  const [identification, setIdentification] = useState(EMPTYIDENTIFICATION)
  const [person, setPerson] = useState(EMPTYPERSON)
  const [touched, setTouched] = useState({})
  const [errors, setErrors] = useState({
    //person: undefined
    //number: undefined
  })

  // Validate using schema, set the validation and get new helper value
  const handleValidation = useCallback(
    debounce(v => {
      schema
        .validate(v, { context: { required, type: v.type }, abortEarly: false })
        .then(() => {
          setErrors({})
        })
        .catch(e => {
          const { inner } = e
          const keys = inner.map(el => el.path)
          const obj = keys.reduce((a, b) => ({ ...a, [b]: true }), {})
          setErrors({ ...obj, message: e.message, person: !!obj })
          if (!keys.includes('number')) setTouched(t => ({ ...t, number: true }))
        })
    }, 100),
    [required, identification.type]
  )

  // Handle changes of the ID Number
  const handleIdChange = event => {
    // We can do event.persist() or we store the value, we need one bc the updater func is async and the synthevent will be nulled
    const { value, name } = event.target
    // If valid, set type
    if (isValid(value)) {
      setOpen(false)
      const validId = printFormat(value)
      const type = /[KLMXYZ0-9]/.test(validId.charAt(0)) ? 'natural' : 'legal'
      const category = types[validId.charAt(0)]
      setIdentification({ number: validId, type, category })
      //if (!touched[name]) setTouched(t => ({ ...t, [name]: true }))

      switch (type) {
        case 'legal':
          if (identification.type !== 'legal')
            setPerson(prev => ({
              ...prev,
              name: '',
              firstSurname: '',
              lastSurname: '',
              entityName: ''
            }))
          setTouched(prev => (prev.number ? { number: prev.number } : {}))
          // do something
          break
        case 'natural':
          if (identification.type !== 'natural')
            setPerson(prev => ({
              ...prev,
              name: '',
              firstSurname: '',
              lastSurname: '',
              entityName: ''
            }))
          setTouched(prev => (prev.number ? { number: prev.number } : {}))
          // do something
          break
        default:
        // do something
      }

      /*setValidation(v => ({
        ...v,
        id: {
          error: false,
          message: undefined,
          touched: true
        }
      }))*/
    } else {
      setIdentification({ number: value, type: undefined, category: undefined })
      /*setValidation(v => ({
        ...v,
        id: {
          ...v.id,
          error: required ? true : !!value,
          touched: required ? true : !!value
        }
      }))*/
    }
  }

  // When fields change, validate schema
  useEffect(() => {
    //console.log(`person changed`)
    handleValidation({ ...identification, ...person })
    if (touched.number) onUpdate(id, { ...identification, ...person })
  }, [person, identification, touched.number, handleValidation, id, onUpdate])

  // When validation changes
  useEffect(() => {
    if (touched.number) onValidation(id, !errors.person)
  }, [touched.number, errors.person, id, onValidation])

  // Handler for changes of nested fields
  // Updates person object
  const handleInputChange = useCallback(event => {
    const { name, value } = event.target
    setPerson(p => (p[name] !== value ? { ...p, [name]: value } : p))
  }, [])

  // Handler for blur of any field
  // Updates touched
  const handleInputBlur = useCallback(event => {
    const { name, value } = event.target
    console.log(`blur from ${name}`)
    setTouched(t => (t[name] ? { ...t, person: true } : { ...t, [name]: true, person: true }))
  }, [])

  // Handler for focus
  const handleFocus = useCallback(event => {
    setTouched(t => ({ ...t, person: false }))
  }, [])

  // Tooltip handling
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    if (!identification.type) setOpen(true)
  }

  // Error matcher
  const displayError = useCallback(() => {
    // When there's an error (other than global person) and its field has been touched
    if (
      Object.keys(errors)
        .filter(el => el !== 'person')
        .filter(Set.prototype.has, new Set(Object.keys(touched))).length > 0
    ) {
      console.log('there are errors')
      console.log(errors)
      console.log(touched)
      console.log(
        Object.keys(errors)
          .filter(el => el !== 'person')
          .filter(Set.prototype.has, new Set(Object.keys(touched)))
      )
      return true
    }
    // When global person has been touched
    return touched.person
  }, [touched, errors])

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: type,
        fieldLabel: title
      })}
    >
      {({ instrument }) => (
        <>
          <FormControl
            fullWidth
            variant="outlined"
            component="fieldset"
            margin="normal"
            error={errors.person && displayError()}
          >
            <FormLabel className={classes.label}>{title}</FormLabel>

            <Grid container spacing={0}>
              <Grid item xs={6} md={4}>
                <FieldTooltip
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  title={
                    <>
                      <Typography paragraph variant="body2" className={classes.lightWeight}>
                        Puedes utilizar un Documento Nacional de Identidad (<b>DNI</b>), un Número
                        de Identidad de Extranjero (<b>NIE</b>) o un Número de Identificación Fiscal
                        (<b>NIF</b>).
                      </Typography>
                      <Typography paragraph variant="body2" className={classes.lightWeight}>
                        Introduce todos los números y letras de tu documento.
                      </Typography>
                    </>
                  }
                >
                  <FormTextField
                    id={`id-number-for-${id}`}
                    name={`number-for-${id}`}
                    //size="small"
                    label="Documento"
                    variant="outlined"
                    placeholder="Ej: 65004204V"
                    fullWidth
                    required={required}
                    value={identification.number}
                    error={errors.number && touched.number}
                    onChange={handleIdChange}
                    onBlur={handleInputBlur}
                    InputProps={{
                      endAdornment: (
                        <AdornmentIcon
                          error={errors.number}
                          touched={touched.number ?? false}
                          required={required}
                          empty={!identification.number}
                        />
                      )
                    }}
                  />
                </FieldTooltip>
              </Grid>
              <Grid item xs={6} md={8}>
                <Typography noWrap variant="body2" className={classes.category}>
                  {identification.category}
                </Typography>
              </Grid>

              {identification.type ? (
                <>
                  {identification.type === 'natural' && (
                    <NaturalPersonFields
                      values={person}
                      errors={touched}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      onFocus={handleFocus}
                    />
                  )}
                  {identification.type === 'legal' && (
                    <LegalPersonFields
                      values={person}
                      errors={touched}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      onFocus={handleFocus}
                    />
                  )}
                </>
              ) : (
                <Grid item xs={12}>
                  <Skeleton
                    height={56}
                    className={classes.skeleton}
                    animation={false}
                    variant="rect"
                  />
                </Grid>
              )}
            </Grid>

            <FormHelperText
              className={clsx(classes.formHelperText, {
                [classes.formHelperTextWithMessage]: !required || help
              })}
            >
              {formatFormHelperText(required, help, displayError() && errors.message)}
            </FormHelperText>
          </FormControl>

          {/*<div>
            Errors
            <pre>{JSON.stringify(errors, '', 2)}</pre>
            Touched
            <pre>{JSON.stringify(touched, '', 2)}</pre>
          </div>*/}
        </>
      )}
    </Amplitude>
  )
}
