import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  navigation: {
    marginTop: theme.spacing(8),
    // padding: theme.spacing(0, 2),
    // Tablet or smaller
    [theme.breakpoints.down('md')]: {
      marginLeft: 72 + 36, // clear the nav rail and extra half of negative space
      width: `calc(100% - 72px - 36px)`,
      padding: theme.spacing(0, 2),
      paddingRight: theme.spacing(3)
    },
    // Mobile
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0, // reset
      padding: theme.spacing(0, 2),
      width: '100%'
    }
  }
}))

const BottomNavigation = React.forwardRef(function BottomNavigation({ children, className }, ref) {
  const classes = useStyles()
  return (
    <Container
      disableGutters
      maxWidth="lg"
      className={clsx(classes.navigation, className)}
      component="nav"
      ref={ref}
    >
      {children}
    </Container>
  )
})

export default BottomNavigation

BottomNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

BottomNavigation.defaultProps = {
  className: null
}
