const biceRegex = /^d{1}[A-Z]{1}d{5}[A-Z]{1}d{2}[A-Z0-9]{4}$/

const getControlDigits = ref => {
  const weights = [13, 15, 12, 5, 4, 17, 9, 21, 3, 7, 1]
  const controlChars = 'MQWERTYUIOPASDFGHJKLBZX'

  if (ref === null || ref.length !== 18) return -1

  // Substrings involved in each control digit
  const forFirstDigit = ref.toUpperCase().substring(0, 7) + ref.substring(14, 18).toUpperCase()
  const forSecondDigit = ref.substring(7, 14).toUpperCase() + ref.substring(14, 18).toUpperCase()

  let controlDigits = ''

  // Do calculation for each digit
  const subs = [forFirstDigit, forSecondDigit]
  subs.forEach(str => {
    let sum = 0
    str.split('').forEach((char, pos) => {
      let value = char
      if (char >= 'A' && char <= 'N') {
        value = char.charCodeAt() - 64
      } else if (char === 'Ñ') {
        value = 15
      } else if (char > 'N') {
        value = char.charCodeAt() - 63
      }
      sum = (sum + value * weights[pos]) % 23
    })
    // Valor del dígito de control calculado
    controlDigits += controlChars.charAt(sum)
  })

  return controlDigits
}

const electronicFormat = unformatted => {
  // Remove spaces
  // Uppercase
  return unformatted.replace(/\s/g, '').toUpperCase()
}

// Urban: 5N 2N 2L 2N 2N 1L 4N 2L --> printable as 5N2N 2L2N2N1L 4N 2L
// Letter at position 8
const formatUrban = unformatted => {
  return unformatted.replace(/(.{7})(.{7})(.{4})(.{2})/g, '$1 $2 $3 $4')
}

// Rural: 2N 3N 1L 3N 5N 4N 2L --> printable as is
// Letter at position 6
const formatRural = unformatted => {
  return unformatted.replace(/(.{2})(.{3})(.{1})(.{3})(.{5})(.{4})(.{2})/g, '$1 $2 $3 $4 $5 $6 $7')
}

// Special: 1N1L 2N 3N 1L 2N 4C ---> printable as 1L1N2N3N 1L2N4C (no sequential or control digits)
const formatSpecial = unformatted => {
  return unformatted.replace(/(.{7})(.{7})/g, '$1 $2')
}

const printFormat = unformatted => {
  // Remove spaces, if present
  // Uppercase
  const eref = electronicFormat(unformatted)
  // Decide if urban or rural
  if (eref.length === 20)
    return /[A-Z]/.test(eref.charAt(7)) ? formatUrban(eref) : formatRural(eref) // Urban has 2 letters at pos 7-8, Rural one letter at pos 6
  if (eref.length === 14) return formatSpecial(eref)

  // Put spaces accordingly
  return unformatted
}

const isValid = ref => {
  // Not null
  if (ref === null) return false
  const eref = electronicFormat(ref)
  // 20 chars without empty spaces --> check against urban or rural control digits
  if (eref.length === 20) return eref.substring(18, 20) === getControlDigits(eref.substring(0, 18))
  // 14 chats without empty spaces --> check against special format
  if (eref.length === 14) return biceRegex.test(eref)
  return false
}

export { printFormat, electronicFormat, isValid }
