import React, { useEffect, useState } from 'react'

import { useRouteMatch, useParams, Redirect, Link as RouterLink } from 'react-router-dom'

import axios from 'axios'

import Container from '@material-ui/core/Container'
import Fade from '@material-ui/core/Fade'
import Zoom from '@material-ui/core/Zoom'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'

import logo from '../../logo.svg'
import ChatBotAnimation from '../Animations/ChatBotAnimation'
import AstronautAnimation from '../Animations/AstronautAnimation'

import { useAuthContext } from '../Context/AuthContext'
import { useSchemaUpdater, useSchemaDefinition, demoSchema } from '../Context/SchemaContext'

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    padding: theme.spacing(4),
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    height: '40vmin'
  },
  progress: {
    margin: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(0.5, 1)
  }
}))

export default function AuthPage({ title }) {
  const classes = useStyles()

  // Routing: step url parameter
  const fake = useRouteMatch('/tramite/:pid/formulario')
  const { pid } = useParams()

  // Auth context: simulate login response wait
  const [auth, setAuth] = useAuthContext()
  useEffect(() => {
    const timer = setTimeout(() => {
      setAuth(true)
    }, 3000)
    return () => clearTimeout(timer)
  }, [setAuth])

  // Schema context: simulate api call
  const [loading, setLoading] = useState(true)
  const [startingStep, setStartingStep] = useState(null)
  const schema = useSchemaDefinition()
  const updateSchema = useSchemaUpdater()
  const getSchema = pid => {
    axios.get(`https://10.49.95.50:8746/sta/api/v1/solicitud/${pid}`).then(
      response => {
        console.log(response)
        updateSchema(response.data)
        setLoading(false)
      },
      error => {
        console.log(error)
      }
    )
  }

  // Trigger getting the schema once we're auth'ed
  useEffect(() => {
    if (auth) {
      /*const timer = setTimeout(() => {
        setLoading(false)
      }, 2000)
      return () => clearTimeout(timer)*/
      if (pid) {
        getSchema(pid)
      }
    }
  }, [setLoading, auth])

  // Trigger detection of the initial step
  // Currently a demo implementation
  useEffect(() => {
    console.log('changed')
    if (!loading) {
      const { step } = schema.values().next().value
      console.log(schema)
      if (step) {
        console.log(`found step: ${step}`)
        setStartingStep(step)
      }
    }
  }, [schema, loading])

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Fade in timeout={500}>
        <section>
          <Zoom in timeout={500}>
            <AstronautAnimation />
          </Zoom>
          <LinearProgress className={classes.progress} />
          {!auth && (
            <Fade in>
              <div>
                <Typography variant="h5">Resolviendo tu identidad</Typography>
                <Typography paragraph>Empezamos</Typography>
              </div>
            </Fade>
          )}
          {auth && (loading || !startingStep) && (
            <Fade in>
              <div>
                <Typography variant="h5">Preparando el formulario</Typography>
                <Typography paragraph>Ya casi estamos</Typography>
              </div>
            </Fade>
          )}
          {auth && !loading && startingStep && (
            <Redirect
              to={{
                pathname: fake
                  ? `/tramite/${pid}/formulario/${startingStep}`
                  : `/layouts/desktop/${startingStep}`
              }}
            />
          )}
        </section>
      </Fade>
    </Container>
  )
}
