import { parseISO } from 'date-fns'

// A boolean
const sanitizeBoolean = bool => {
  if (typeof bool !== 'boolean') return false
  return bool
}

// A number
const sanitizeNumber = num => {
  if (typeof num !== 'number') return ''
  return String(num)
}

// A string
const sanitizeString = str => {
  if (typeof str !== 'string') return ''
  return str
}

// Non-empty array
const sanitizeArrayOfStrings = arr => {
  if (!(arr instanceof Array)) return []
  if (!arr.every(el => typeof el === 'string')) return []
  return arr
}

// Non-empty array
const sanitizeArrayOfNumbers = arr => {
  if (!(arr instanceof Array)) return []
  if (!arr.every(el => typeof el === 'number')) return []
  return arr
}

// Non-empty string representing time in ISO format
const sanitizeStringHour = str => {
  if (typeof str !== 'string') return ''
  if (!str) return ''
  return parseISO(`2020-07-01T${str}`)
}

// Non-empty string representing a date in ISO format
const sanitizeStringDate = str => {
  if (typeof str !== 'string') return ''
  if (!str) return ''
  return parseISO(str)
}

export {
  sanitizeBoolean,
  sanitizeNumber,
  sanitizeString,
  sanitizeArrayOfStrings,
  sanitizeArrayOfNumbers,
  sanitizeStringHour,
  sanitizeStringDate
}
