import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Avatar from '@material-ui/core/Avatar'
import MenuIcon from '@material-ui/icons/Menu'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import MoreIcon from '@material-ui/icons/MoreVert'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import PeopleOutlineTwoToneIcon from '@material-ui/icons/PeopleOutlineTwoTone'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CloudQueueIcon from '@material-ui/icons/CloudQueue'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'

import Fields from '../Content/Fields'

import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import Slide from '@material-ui/core/Slide'

import Drawer from '@material-ui/core/Drawer'
import CloseIcon from '@material-ui/icons/Close'

import SimpleDialog from '../Dialogs/Dialog'

function HideOnScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined })

  return (
    <Slide appear={false} direction="up" in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

const messages = [
  {
    id: 1,
    primary: 'Brunch this week?',
    secondary: "I'll be in the neighbourhood this week. Let's grab a bite to eat",
    person: '/static/images/avatar/5.jpg'
  },
  {
    id: 2,
    primary: 'Birthday Gift',
    secondary: `Do you have a suggestion for a good present for John on his work
      anniversary. I am really confused & would love your thoughts on it.`,
    person: '/static/images/avatar/1.jpg'
  },
  {
    id: 3,
    primary: 'Recipe to try',
    secondary: 'I am try out this new BBQ recipe, I think this might be amazing',
    person: '/static/images/avatar/2.jpg'
  },
  {
    id: 4,
    primary: 'Yes!',
    secondary: 'I have the tickets to the ReactConf for this year.',
    person: '/static/images/avatar/3.jpg'
  },
  {
    id: 5,
    primary: "Doctor's Appointment",
    secondary: 'My appointment for the doctor was rescheduled for next Saturday.',
    person: '/static/images/avatar/4.jpg'
  },
  {
    id: 6,
    primary: 'Discussion',
    secondary: `Menus that are generated by the bottom app bar (such as a bottom
      navigation drawer or overflow menu) open as bottom sheets at a higher elevation
      than the bar.`,
    person: '/static/images/avatar/5.jpg'
  },
  {
    id: 7,
    primary: 'Summer BBQ',
    secondary: `Who wants to have a cookout this weekend? I just got some furniture
      for my backyard and would love to fire up the grill.`,
    person: '/static/images/avatar/1.jpg'
  }
]

const useStyles = makeStyles(theme => ({
  text: {
    padding: theme.spacing(0, 2, 0)
  },
  context: {
    padding: theme.spacing(4, 2, 0)
  },
  paper: {
    paddingBottom: theme.spacing(16)
  },
  list: {
    marginBottom: theme.spacing(2)
  },
  subheader: {
    backgroundColor: theme.palette.background.paper
  },
  appBar: {
    top: 'auto',
    bottom: 0
  },
  grow: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  fabButton: {
    position: 'fixed',
    zIndex: theme.zIndex.appBar + 1,
    bottom: 36,
    left: '50%',
    transform: 'translateX(-50%)'
  },
  button: {
    margin: '0 auto',
    display: 'block'
  },
  textButton: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  fields: { padding: theme.spacing(2) },
  drawer: { height: '100vh' },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}))

export default function MobileNavigation() {
  const classes = useStyles()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  })

  const toggleDrawer = (anchor, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="open drawer">
          <ArrowBackIcon />
        </IconButton>
        <div className={classes.grow}>
          <Chip
            icon={<PeopleOutlineTwoToneIcon />}
            label="BLANCO VIDAL, MANUELA"
            color="textPrimary"
            onClick={handleOpen}
            onDelete={handleOpen}
            variant="outlined"
            deleteIcon={<ExpandMoreIcon />}
          />
        </div>
      </Toolbar>
      <Paper square className={classes.paper} elevation={0}>
        <Typography variant="h4" color="textSecondary" className={classes.context}>
          Título del trámite
        </Typography>
        <Typography variant="h2" gutterBottom className={classes.text}>
          Sección
        </Typography>
        <div className={classes.fields}>
          <Fields />
        </div>
        <Button variant="outlined" size="large" color="primary" className={classes.button}>
          Siguiente
        </Button>
        <Typography className={classes.textButton} variant="body2">
          Nombre corto de la sección
        </Typography>
      </Paper>
      <Fab color="secondary" aria-label="add" className={classes.fabButton} variant="extended">
        <ArrowForwardIcon className={classes.extendedIcon} />
        Presentar
      </Fab>
      <HideOnScroll>
        <AppBar position="fixed" color="default" className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer('bottom', true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6">Registro electrónico</Typography>

            <div className={classes.grow}>
              <IconButton edge="end" color="inherit" onClick={handleOpen}>
                <PeopleOutlineTwoToneIcon />
              </IconButton>
              <IconButton edge="end" color="inherit" onClick={handleOpen}>
                <CloudQueueIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Drawer anchor={'bottom'} open={state['bottom']} onClose={toggleDrawer('bottom', false)}>
        <Paper elevation={0} className={classes.drawer}>
          <Toolbar>
            <div className={classes.grow}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer('bottom', false)}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </Toolbar>

          <Typography variant="h4" gutterBottom>
            Parties
          </Typography>
          <Typography variant="h4" gutterBottom>
            Progress
          </Typography>
          <Typography variant="h4" gutterBottom>
            Draft
          </Typography>
        </Paper>
      </Drawer>
      <SimpleDialog open={open} handleClose={handleClose} />
    </>
  )
}
