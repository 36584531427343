import React from 'react'
import PropTypes from 'prop-types'

import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputBase from '@material-ui/core/InputBase'
import OutlinedInput from '@material-ui/core/OutlinedInput'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SearchIcon from '@material-ui/icons/Search'
import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone'
import PublicIcon from '@material-ui/icons/Public'
import CheckIcon from '@material-ui/icons/Check'

import Chip from '@material-ui/core/Chip'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import Fade from '@material-ui/core/Fade'
import Hidden from '@material-ui/core/Hidden'

import { makeStyles } from '@material-ui/core/styles'

import { useLocationContext } from '../../../../../components/Context/LocationContext'

const useStyles = makeStyles(theme => ({
  subheading: {
    // marginTop: theme.spacing(14),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  chip: {
    maxWidth: '100%'
  },
  chipIcon: {
    marginLeft: theme.spacing(1.5)
  },
  chipArrow: {
    marginLeft: theme.spacing(1)
  },
  chipLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  chipTypography: {
    flexShrink: 1
  },
  section: {
    padding: theme.spacing(1, 2, 2, 0),
    marginLeft: theme.spacing(6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

export default function ObjectModalOptions({
  location,
  visible,
  open,
  handleOpen,
  handleClose,
  handleSettings
}) {
  const classes = useStyles()
  // Location context
  const [defaultLocation, locationUtils, addressUtils] = useLocationContext()
  return (
    <>
      <Hidden smUp>
        <section className={classes.subheading}>
          <Chip
            icon={<PublicIcon fontSize="small" />}
            clickable
            label={
              <>
                <Typography variant="subtitle2" className={classes.chipTypography} noWrap>
                  {`${locationUtils.location.municipality.getMunicipalityOptionLabel(
                    location.municipality
                  )}, ${locationUtils.location.country.getCountryOptionLabel(location.country)}`}
                </Typography>
                {open ? (
                  <ExpandLessIcon
                    fontSize="default"
                    className={classes.chipArrow}
                    color="primary"
                  />
                ) : (
                  <ExpandMoreIcon
                    fontSize="default"
                    className={classes.chipArrow}
                    color="primary"
                  />
                )}
              </>
            }
            color="default"
            onClick={handleOpen}
            classes={{ root: classes.chip, icon: classes.chipIcon, label: classes.chipLabel }}
          />
        </section>
      </Hidden>

      <Hidden xsDown>
        <section className={classes.section}>
          <Typography variant="subtitle1" noWrap>
            {`${locationUtils.location.municipality.getMunicipalityOptionLabel(
              location.municipality
            )}, ${locationUtils.location.country.getCountryOptionLabel(location.country)}`}
          </Typography>

          {visible ? (
            <Button
              size="small"
              color="default"
              variant="text"
              startIcon={<PublicIcon />}
              onClick={handleOpen}
            >
              En otro municipio
            </Button>
          ) : (
            <Hidden xsUp>
              <Button
                size="medium"
                color="primary"
                variant="text"
                endIcon={<CheckIcon />}
                onClick={handleClose}
              >
                Seleccionar
              </Button>
            </Hidden>
          )}
        </section>
      </Hidden>
    </>
  )
}

ObjectModalOptions.propTypes = {
  visible: PropTypes.bool,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSettings: PropTypes.func
}

ObjectModalOptions.defaultProps = {
  visible: true,
  open: false,
  handleClose: () => {},
  handleSettings: () => {}
}
