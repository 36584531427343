import React from 'react'
import PropTypes from 'prop-types'

import AdministrativeFile from './AdministrativeFile'
import { CheckboxGroup, RadioGroup, MultiSelect, SingleSelect } from '../Selection'

const getComponent = (format, length, multiple, required) => {
  // By default
  let MultipleChoice = length < 5 ? CheckboxGroup : MultiSelect
  let SingleChoice = length < 5 ? RadioGroup : SingleSelect

  // Honor preference, if set
  switch (format) {
    case 'radio':
      MultipleChoice = CheckboxGroup
      SingleChoice = RadioGroup
      break
    case 'combo':
      MultipleChoice = MultiSelect
      SingleChoice = SingleSelect
      break
    default:
      break
  }

  // But prevent always radio buttons on fields NOT required (bc can't be unset, it's not polite)
  SingleChoice = required ? SingleChoice : SingleSelect
  return multiple ? MultipleChoice : SingleChoice
}

/**
Use an Administrative File input field when you want the user to enter a reference to a file.

For now, this is just a copy of the Text field.

- Can be a free input or an assisted input with checkboxes, radio buttons or dropdowns.
- If options are passed, the input is assisted:
    - With 5 or more elements, a dropdown is displayed (with autocomplete from 12 elements forward).
    - With 4 or less elements, a group of checkboxes or radio buttons is displayed.
- This behaviour can be overriden with the format prop, forcing to use either checkbox/radio or dropdown.
- When the field is optional it will never display a group of radio buttons.

See each component for more info.
*/

export default function Field({
  id,
  type,
  title,
  description,
  help,
  required,
  error,
  defaultValue,
  format,
  options,
  multiple,
  regex,
  length,
  onUpdate,
  onValidation
}) {
  // Selection of component
  const Choice = getComponent(format, options ? options.length : 0, multiple, required)

  return options ? (
    <Choice
      id={id}
      type={type}
      title={title}
      description={description}
      help={help}
      required={required}
      error={error}
      defaultValue={defaultValue}
      options={options}
      onUpdate={onUpdate}
      onValidation={onValidation}
    />
  ) : (
    <AdministrativeFile
      id={id}
      title={title}
      description={description}
      help={help}
      required={required}
      error={error}
      defaultValue={defaultValue}
      options={options}
      regex={regex}
      length={length}
      onUpdate={onUpdate}
      onValidation={onValidation}
    />
  )
}

Field.propTypes = {
  /**
    DBOID of the field.
  */
  id: PropTypes.string.isRequired,
  /**
    Type.
  */
  type: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Helper text.
  */
  help: PropTypes.string,
  /**
    Use this to indicate that a value must be provided.
  */
  required: PropTypes.bool,
  /**
    Use this to change to an error state.
  */
  error: PropTypes.bool,
  /**
    Initial value of the field, array for mutiple selections and string for single selection or text
  */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
    Type of rendering. Accepts values: auto, radio or combo
  */
  format: PropTypes.string,
  /**
    Array of options
  */
  options: PropTypes.arrayOf(PropTypes.shape({ key: PropTypes.string, label: PropTypes.string })),
  /**
    Use this to indicate multiple choices are possible
   */
  multiple: PropTypes.bool,
  /**
    Regular expression for input validation (only for free input field)
   */
  regex: PropTypes.string,
  /**
    Maximum length in characters (only for free input field)
   */
  length: PropTypes.number,
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
  Handler to be called when a new validation needs to be shared
  @param {string} id - The id of the field.
  @param {bool} value - The validation result.
*/
  onValidation: PropTypes.func
}

Field.defaultProps = {
  description: null,
  help: null,
  required: false,
  error: false,
  defaultValue: null,
  format: 'auto',
  options: undefined,
  multiple: false,
  regex: undefined,
  length: undefined,
  onUpdate: () => {},
  onValidation: () => {}
}
