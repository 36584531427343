import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import FormControl, { useFormControl } from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'

import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'

import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import IconButton from '@material-ui/core/IconButton'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import SearchIcon from '@material-ui/icons/Search'
import ClearIcon from '@material-ui/icons/Clear'
import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined'

import Grow from '@material-ui/core/Fade'

import Tooltip from '@material-ui/core/Tooltip'

import InputAdornment from '@material-ui/core/InputAdornment'

import { makeStyles } from '@material-ui/core/styles'

import formatFormHelperText from './Utils/formatFormHelperText'

function FormIconButton(props) {
  const { onFocus, onBlur } = useFormControl()
  const { children, anchorRef, tooltip, ...rest } = props

  return tooltip ? (
    <Tooltip title={tooltip}>
      <IconButton {...{ onFocus, onBlur, ref: anchorRef }} {...rest}>
        {children}
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton {...{ onFocus, onBlur, ref: anchorRef }} {...rest}>
      {children}
    </IconButton>
  )
}

const useSectionStyles = makeStyles(theme => ({
  input: {
    border: '2px solid #b0bec5',
    boxSizing: 'border-box',
    '&:hover': {
      borderColor: 'black'
    },
    '&$focused': { borderColor: theme.palette.primary.main }
  },
  focused: {} // pseudo-class
}))

const FormSection = React.forwardRef(function FormSection(props, ref) {
  const { onFocus, onBlur, focused } = useFormControl()
  const { children, className } = props
  const classes = useSectionStyles()
  return (
    <section
      {...{ onFocus, onBlur }}
      ref={ref}
      className={clsx(className, classes.input, { [classes.focused]: focused })}
    >
      {children}
    </section>
  )
})

const useStyles = makeStyles(theme => ({
  label: {
    margin: theme.spacing(0, 2, 0.5, 0),
    lineHeight: 1.25
  },
  fieldRoot: {
    display: 'flex',
    borderRadius: '4px',
    //maxWidth: '600px',
    alignItems: 'center'
    //paddingLeft: theme.spacing(1)
  },
  text: {
    width: '100%',
    padding: '14px 0',
    display: 'block',
    textAlign: 'left',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  icon: {
    color: '#b0bec5'
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75)
  },
  formHelperTextWithMessage: {
    marginBottom: theme.spacing(2) + 3
  }
}))

const AddressLabel = React.forwardRef(function AddressLabel(
  {
    streetName,
    streetNumber,
    postcode,
    city,
    interiorAccess,
    toponym,
    door,
    id,
    title,
    help,
    required,
    primary,
    secondary,
    value,
    onClick,
    onClear
  },
  ref
) {
  const classes = useStyles()
  return (
    <Grid item xs={12}>
      <FormControl
        fullWidth
        component="fieldset"
        margin="normal"
        variant="outlined"
        required={required}
        error={false}
        className={classes.formControl}
      >
        <FormLabel component="legend" className={classes.label}>
          {title}
        </FormLabel>
        <FormSection className={classes.fieldRoot} ref={ref}>
          <FormIconButton color="primary" aria-label="change city" onClick={onClick}>
            {value ? <LocationOnTwoToneIcon /> : <SearchIcon />}
          </FormIconButton>

          <ButtonBase focusRipple className={classes.text} onClick={onClick}>
            <Typography noWrap>
              {!value ? (
                <Skeleton width="75%" />
              ) : (
                //`${streetName}, ${streetNumber} ${interiorAccess} (${toponym})`
                primary
              )}
            </Typography>
            <Typography variant="caption" gutterBottom noWrap display="block">
              {!value ? <Skeleton width="35%" /> : /*`${postcode} ${city}`*/ secondary}
            </Typography>
          </ButtonBase>
          {/*<Divider orientation="vertical" flexItem />*/}
          {
            /*value && (
            <div>*/
            <Grow in={Boolean(value)} timeout={100}>
              <FormIconButton
                aria-label="clear address"
                tooltip="Quitar esta dirección"
                onClick={onClear}
              >
                {/*<LocationCityIcon />*/}
                <ClearIcon />
              </FormIconButton>
            </Grow>
            /*</div>
          )*/
          }
        </FormSection>
        <FormHelperText
          className={clsx(classes.formHelperText, {
            [classes.formHelperTextWithMessage]: !required || help
          })}
        >
          {formatFormHelperText(required, help, null)}
        </FormHelperText>
      </FormControl>
    </Grid>
  )
})

export default AddressLabel
