import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import Fade from '@material-ui/core/Fade'

import { getExtension, getType } from 'mime'

import { Default, Spreadsheet, Video, Presentation, Document, PDF } from '../Previews'

const useStyles = makeStyles(theme => ({ fullWidth: { width: '100%' } }))

const filetypes = {
  ppt: Presentation,
  pptx: Presentation,
  xls: Spreadsheet,
  xlsx: Spreadsheet,
  mp4: Video,
  avi: Video,
  doc: Document,
  docx: Document,
  pdf: PDF
}

/**
The Document Preview displays a previsualization of the file.

Supports rendering:

- First page of PDF files
- Specific illustrations for frequent filetypes (Word, Powerpoint, Excel, Video, Audio)
- A generic illustration for all other files

*/

export default function Preview({ file }) {
  const classes = useStyles()
  const type = getExtension(file.type)
  console.log(getType('.csv'))
  const Representation = filetypes[type] || Default
  return (
    <Fade in mountOnEnter unmountOnExit timeout={300} style={{ transitionDelay: '500ms' }}>
      <div>
        <Representation className={classes.fullWidth} file={file} />
      </div>
    </Fade>
  )
}
