import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Amplitude } from '@amplitude/react-amplitude'

import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormHelperText from '@material-ui/core/FormHelperText'

import { makeStyles } from '@material-ui/core/styles'

import { boolean } from 'yup'
import formatFormHelperText from '../Utils/formatFormHelperText'
import { sanitizeBoolean } from '../Utils/sanitizeForInputValue'

const schema = boolean().when('$required', (required, s) =>
  required ? s.required().oneOf([true], 'Debes marcar este campo') : s
)

const useStyles = makeStyles(theme => ({
  label: {
    margin: theme.spacing(0, 2, 0.5, 0),
    lineHeight: 1.25
  },
  formControlLabel: {
    paddingLeft: theme.spacing(1.75)
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75)
  },
  formHelperTextWithMessage: {
    marginBottom: theme.spacing(2) + 3
  },
  fclRoot: {
    alignItems: 'flex-start'
  },
  fclLabel: {
    paddingTop: theme.spacing(1)
  }
}))

/**
Use a Boolean when you want the user to check a single box.

- The underlying input field change event is logged on the analytics platform.

### Events:
- Blur: always runs a validation
- Change: always runs a validation

### Validations:
- Needs to be checked, if required
*/

export default function Boolean({
  id,
  title,
  description,
  help,
  required,
  defaultChecked,
  onUpdate,
  onValidation
}) {
  const classes = useStyles()
  const [checked, setChecked] = useState(sanitizeBoolean(defaultChecked))
  const [validation, setValidation] = useState({
    error: undefined,
    message: undefined,
    dirty: false
  })

  const handleValidation = useCallback(
    v => {
      schema
        .validate(v, { context: { required } })
        .then(() => {
          setValidation({ error: false, message: undefined, dirty: true })
        })
        .catch(e => setValidation({ error: true, message: e.message, dirty: true }))
    },
    [required]
  )

  // On change, set the new value and share it and always validate (bc we can't rely on blur event value)
  const handleChange = event => {
    setChecked(event.target.checked)
    onUpdate(id, event.target.checked)
    handleValidation(event.target.checked)
  }

  // On blur, trigger validation if it's the first interaction (a saved value was an interaction)
  const handleBlur = event => {
    if (!validation.dirty) handleValidation(event.target.checked)
  }

  // Side-effect of the validation: sync the context (skip if validation is yet undefined)
  useEffect(() => {
    if (typeof validation.error !== 'undefined') onValidation(id, !validation.error)
  }, [onValidation, id, validation.error])

  // Side-effect of having a default value
  // Validation runs only on booleans
  // This handles validation of saved values, context must be aware of empty initial fields
  useEffect(() => {
    if (typeof defaultChecked === 'boolean') handleValidation(defaultChecked)
  }, [defaultChecked, handleValidation])

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: 'checkbox',
        fieldLabel: title
      })}
    >
      {({ instrument }) => (
        <FormControl
          required={required}
          error={validation.error}
          component="fieldset"
          className={classes.formControl}
        >
          <FormLabel required={false} component="legend" className={classes.label}>
            {title}
          </FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                id={id}
                // defaultChecked={defaultChecked}
                checked={checked}
                onChange={(instrument('Interacted with form field'), handleChange)}
                onBlur={handleBlur}
              />
            }
            label={description}
            classes={{ root: classes.fclRoot, label: classes.fclLabel }}
            className={classes.formControlLabel}
          />
          <FormHelperText
            className={clsx(classes.formHelperText, {
              [classes.formHelperTextWithMessage]: !required || help
            })}
          >
            {formatFormHelperText(required, help, validation.message)}
          </FormHelperText>
        </FormControl>
      )}
    </Amplitude>
  )
}

Boolean.propTypes = {
  /**
    DBOID of the field.
  */
  id: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Helper text.
  */
  help: PropTypes.string,
  /**
    Use this to indicate that the box must be checked.
  */
  required: PropTypes.bool,
  /**
    Initial state of the field.
  */
  defaultChecked: PropTypes.bool,
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
    Handler to be called when a new validation needs to be shared
   @param {string} id - The id of the field.
   @param {bool} value - The validation result.
 */
  onValidation: PropTypes.func
}

Boolean.defaultProps = {
  description: null,
  help: null,
  required: false,
  defaultChecked: undefined,
  onUpdate: () => {},
  onValidation: () => {}
}
