import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import MuiAppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Hidden from '@material-ui/core/Hidden'
import Slide from '@material-ui/core/Slide'
import Divider from '@material-ui/core/Divider'
import Grow from '@material-ui/core/Grow'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import { useScrollTrigger, GrowOnScroll, ElevateOnScroll, SlideOnScroll } from './Scroll'

import ProgressDrawer from '../Experimental/ProgressDrawer'

import defaultWideLogo from './branding_h48px.svg'
import defaultSquareLogo from './branding_s24px.svg'

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: 'white',
    overflow: 'hidden'
  },
  fixed: {
    //borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[300],
    // Mobile
    [theme.breakpoints.down('xs')]: {
      bottom: 0,
      top: 'auto'
    }
  },
  static: {},
  classic: {},
  desktop: {},
  heroDivider: {
    height: '2px',
    background: theme.palette.common.black
  }
}))

const AppBar = React.forwardRef(function AppBar({ children, className }, ref) {
  const classes = useStyles()
  const tabletOrMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const mobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  // Used to hide its divider on scroll
  const windowScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 64
  })
  return mobile ? (
    <SlideOnScroll threshold={128} inverse>
      <MuiAppBar
        position="fixed"
        classes={{ positionFixed: classes.fixed, positionStatic: classes.static }}
        color="default"
        elevation={4}
        className={clsx(
          classes.appBar,
          { [classes.classic]: tabletOrMobile, [classes.desktop]: !tabletOrMobile },
          className
        )}
        ref={ref}
      >
        {children}
      </MuiAppBar>
    </SlideOnScroll>
  ) : (
    <ElevateOnScroll>
      <MuiAppBar
        position={tabletOrMobile ? 'fixed' : 'fixed'}
        classes={{ positionFixed: classes.fixed, positionStatic: classes.static }}
        color="transparent"
        elevation={mobile ? 4 : 0}
        className={clsx(
          classes.appBar,
          { [classes.classic]: tabletOrMobile, [classes.desktop]: !tabletOrMobile },
          className
        )}
        ref={ref}
      >
        {children}
      </MuiAppBar>
    </ElevateOnScroll>
  )
})

export default AppBar

AppBar.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

AppBar.defaultProps = {
  className: null
}

// Heading section

const useHeadingStyles = makeStyles(theme => ({
  section: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflowX: 'hidden',
    marginLeft: theme.spacing(3),
    // Tablet or smaller
    [theme.breakpoints.down('md')]: {
      //marginLeft: theme.spacing(3.5) // clear the nav rail and align with content
      marginLeft: theme.spacing(3.5)
    },
    // Mobile
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0 // reset
    }
  }
}))

function AppBarHeading({ children }) {
  const classes = useHeadingStyles()
  return <section className={classes.section}>{children}</section>
}

AppBarHeading.propTypes = {
  children: PropTypes.node.isRequired
}

// Breadcrumbs section
const useBreadcrumbsStyles = makeStyles(theme => ({
  list: {
    flexShrink: 0
  }
}))

function AppBarBreadcrumbs({ children }) {
  const classes = useBreadcrumbsStyles()
  return <ul className={classes.list}>{children}</ul>
}

function AppBarBreadcrumbElement({ children }) {
  const classes = useBreadcrumbsStyles()
  return <li className={classes.element}>{children}</li>
}

// Actions section

const useActionStyles = makeStyles(theme => ({
  section: {
    flexShrink: 0
  }
}))

function AppBarActions({ children }) {
  const classes = useActionStyles()
  return <section className={classes.section}>{children}</section>
}

AppBarActions.propTypes = {
  children: PropTypes.node.isRequired
}

// Menu button

const useMenuStyles = makeStyles(theme => ({
  menu: {}
}))

function AppBarMenu() {
  const classes = useMenuStyles()
  // Experimental progress drawer
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  return (
    <Hidden lgUp>
      <IconButton
        edge="start"
        className={classes.menu}
        color="inherit"
        aria-label="menu"
        onClick={handleOpen}
      >
        <MenuIcon />
      </IconButton>
      <ProgressDrawer open={open} handleClose={handleClose} />
    </Hidden>
  )
}

// Organization logo

const useLogoStyles = makeStyles(theme => ({
  logo: {
    height: 48,
    // Tablet or smaller
    [theme.breakpoints.down('md')]: {
      //marginLeft: theme.spacing(3.5), // clear the nav rail and align with content
      height: 36,
      margin: '0 16px'
    },
    // Mobile
    [theme.breakpoints.down('xs')]: {
      margin: 0, // reset
      marginRight: theme.spacing(0.5)
    }
  }
}))

function AppBarLogo() {
  const classes = useLogoStyles()
  const {
    branding: { logo: { wide: wideLogo, square: squareLogo } } = {
      logo: { wide: null, square: null }
    }
  } = useTheme()

  return (
    <>
      <Hidden mdDown>
        <img
          alt="logo"
          src={wideLogo ? `${process.env.PUBLIC_URL}/${wideLogo}` : defaultWideLogo}
          className={classes.logo}
        />
      </Hidden>
      <Hidden xsDown lgUp>
        <GrowOnScroll threshold={128}>
          <img
            alt="logo"
            src={squareLogo ? `${process.env.PUBLIC_URL}/${squareLogo}` : defaultSquareLogo}
            className={classes.logo}
          />
        </GrowOnScroll>
      </Hidden>
      <Hidden smUp>
        <img
          alt="logo"
          src={squareLogo ? `${process.env.PUBLIC_URL}/${squareLogo}` : defaultSquareLogo}
          className={classes.logo}
        />
      </Hidden>
    </>
  )
}

// Menu button

const useAppBrandingBarStyles = makeStyles(theme => ({
  menu: {}
}))

function AppBrandingBar({ children }) {
  const classes = useAppBrandingBarStyles()

  return (
    <Container maxWidth="lg">
      {children}
      <Divider />
    </Container>
  )
}

AppBrandingBar.propTypes = {
  children: PropTypes.node.isRequired
}

export {
  AppBarHeading,
  AppBarBreadcrumbs,
  AppBarBreadcrumbElement,
  AppBarActions,
  AppBarMenu,
  AppBarLogo,
  AppBrandingBar
}
