import React, { useState, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'

import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

import { makeStyles, fade } from '@material-ui/core/styles'

import { useLocationContext } from '../../../../../components/Context/LocationContext'

const DEBOUNCE_INTERVAL = 150

const useStyles = makeStyles(theme => ({
  selectIcon: {
    color: theme.palette.primary.main
  },
  option: {
    '&[aria-selected="true"]': {
      backgroundColor: fade(theme.palette.primary.light, 0.05),
      '& $highlight': {
        background: 'transparent'
      }
    },
    '&[data-focus="true"]': {
      backgroundColor: fade(theme.palette.primary.light, 0.2),
      '& $highlight': {
        background: 'transparent'
      }
    },
    '&:active': {
      backgroundColor: fade(theme.palette.primary.light, 0.05),
      '& $highlight': {
        background: 'transparent'
      }
    }
  },
  highlight: {
    color: theme.palette.primary.dark,
    background: fade(theme.palette.primary.light, 0.05),
    fontWeight: 500
  }
}))

export default function LocationForm({ location, onChange }) {
  const classes = useStyles()

  // Location context
  const [, locationUtils, addressUtils] = useLocationContext()

  // COUNTRY state and autocomplete options
  const [country, setCountry] = useState(location?.country)
  const [countries, isLoadingC, isErrorC] = locationUtils.location.country.findCountry('') // get all countries

  // PROVINCE state and autocomplete options
  const provinceRef = useRef()
  const [province, setProvince] = useState(location?.province)
  const [provinces, isLoadingP, isErrorP] = locationUtils.location.province.findProvince(
    '', // queryProvince
    locationUtils.location.country.getCountryOptionId(country)
  ) // get all provinces for the current country

  // Update province (debounced)
  const debouncedprovinceInputChange = useCallback(
    debounce(value => {
      setProvince(locationUtils.location.province.generateProvinceObject(value))
    }, DEBOUNCE_INTERVAL),
    []
  )

  // Update province (debounced)// Handle province free input change
  const handleProvinceInputChange = event => {
    const {
      target: { value }
    } = event
    debouncedprovinceInputChange(value)
  }

  // MUNICIPALITY state and autocomplete options
  const municipalityRef = useRef()
  const [municipality, setMunicipality] = useState(location?.municipality)
  const [
    municipalities,
    isLoadingM,
    isErrorM
  ] = locationUtils.location.municipality.findMunicipality(
    '', // queryMunicipality,
    locationUtils.location.province.getProvinceOptionId(province)
  ) // get all municipalities for the current province

  // Update municipality (debounced)
  const debouncedMunicipalityInputChange = useCallback(
    debounce(value => {
      setMunicipality(locationUtils.location.municipality.generateMunicipalityObject(value))
    }, DEBOUNCE_INTERVAL),
    []
  )

  // Handle muncipality free input change
  const handleMunicipalityInputChange = event => {
    const {
      target: { value }
    } = event
    debouncedMunicipalityInputChange(value)
  }

  // Fire a change event
  const handleClick = () => {
    const newLocation = { country, province, municipality }
    onChange(newLocation)
  }

  const renderOption = (label, inputValue) => {
    // if there's no search text, just render the plain label
    if (!inputValue) return label

    // escape special characters, build regexp with caption group, split label using regex
    const regex = new RegExp(`(${inputValue.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')})`, 'gi')
    const parts = label.split(regex)

    // if any part matches with the regexp, mark it to highlight
    return (
      <span>
        {parts.map((part, index) =>
          regex.test(part) ? (
            <mark key={`part-${String(index)}`} className={classes.highlight}>
              {part}
            </mark>
          ) : (
            part
          )
        )}
      </span>
    )
  }

  const renderCountryOption = (option, { inputValue }) => {
    const label = locationUtils.location.country.getCountryOptionLabel(option)
    return renderOption(label, inputValue)
  }

  const renderProvinceOption = (option, { inputValue }) => {
    const label = locationUtils.location.province.getProvinceOptionLabel(option)
    return renderOption(label, inputValue)
  }

  const renderMunicipalityOption = (option, { inputValue }) => {
    const label = locationUtils.location.municipality.getMunicipalityOptionLabel(option)
    return renderOption(label, inputValue)
  }

  return (
    <>
      <Grid item xs={12} sm={4}>
        <Autocomplete
          id="country-autocomplete"
          options={countries}
          autoComplete
          autoHighlight
          autoFocus
          disableClearable
          fullWidth
          includeInputInList
          loading={isLoadingC}
          popupIcon={<ExpandMoreIcon />}
          classes={{ popupIndicator: classes.selectIcon, option: classes.option }}
          value={country}
          onChange={(event, newCountry) => {
            const cleanedNewCountry = addressUtils.updateCountry(newCountry)
            if (!isEqual(cleanedNewCountry, country)) {
              setCountry(cleanedNewCountry)
              setProvince({})
              setMunicipality({})
              provinceRef.current.focus()
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              label="País"
              variant="outlined"
              autoFocus
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isLoadingC ? <CircularProgress color="secondary" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
          getOptionLabel={locationUtils.location.country.getCountryOptionLabel}
          getOptionSelected={locationUtils.location.country.getCountryOptionSelected}
          renderOption={renderCountryOption}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        {country?.provcoded ? (
          <Autocomplete
            id="province-autocomplete"
            options={provinces}
            autoComplete
            autoHighlight
            disableClearable
            fullWidth
            includeInputInList
            loading={isLoadingP}
            disabled={isEmpty(country)}
            popupIcon={<ExpandMoreIcon />}
            classes={{ popupIndicator: classes.selectIcon, option: classes.option }}
            value={province}
            onChange={(event, newProvince) => {
              const cleanedNewProvince = addressUtils.updateProvince(newProvince)
              if (!isEqual(cleanedNewProvince, province)) {
                setProvince(cleanedNewProvince)
                setMunicipality({})
                municipalityRef.current.focus()
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Provincia"
                variant="outlined"
                autoFocus={isEmpty(province)}
                inputRef={provinceRef}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoadingP ? <CircularProgress color="secondary" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
            getOptionLabel={locationUtils.location.province.getProvinceOptionLabel}
            getOptionSelected={locationUtils.location.province.getProvinceOptionSelected}
            renderOption={renderProvinceOption}
          />
        ) : (
          <TextField
            fullWidth
            label="Provincia"
            variant="outlined"
            onChange={handleProvinceInputChange}
            disabled={isEmpty(country)}
            autoFocus={isEmpty(province)}
            inputRef={provinceRef}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={4}>
        {province?.muncoded ? (
          <Autocomplete
            id="municipality-autocomplete"
            options={municipalities}
            autoComplete
            autoHighlight
            disableClearable
            fullWidth
            includeInputInList
            loading={isLoadingM}
            disabled={isEmpty(province)}
            popupIcon={<ExpandMoreIcon />}
            classes={{ popupIndicator: classes.selectIcon, option: classes.option }}
            value={municipality}
            onChange={(event, newMunicipality) => {
              const cleanedNewMunicipality = addressUtils.updateMunicipality(newMunicipality)
              if (!isEqual(cleanedNewMunicipality, municipality))
                setMunicipality(cleanedNewMunicipality)
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="Municipio"
                variant="outlined"
                autoFocus={isEmpty(municipality)}
                inputRef={municipalityRef}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isLoadingM ? <CircularProgress color="secondary" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
            getOptionLabel={locationUtils.location.municipality.getMunicipalityOptionLabel}
            getOptionSelected={locationUtils.location.municipality.getMunicipalityOptionSelected}
            renderOption={renderMunicipalityOption}
          />
        ) : (
          <TextField
            fullWidth
            label="Municipio"
            variant="outlined"
            onChange={handleMunicipalityInputChange}
            disabled={isEmpty(province)}
            autoFocus={isEmpty(municipality)}
            inputRef={municipalityRef}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        <Button onClick={handleClick} disabled={isEmpty(province) || isEmpty(municipality)}>
          Aceptar
        </Button>
      </Grid>
    </>
  )
}

LocationForm.propTypes = {
  location: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func
}

LocationForm.defaultProps = {
  onChange: () => {}
}
