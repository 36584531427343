import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import Helmet from 'react-helmet'

import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'
import Badge from '@material-ui/core/Badge'
import CloudDoneTwoToneIcon from '@material-ui/icons/CloudDoneTwoTone'
import Hidden from '@material-ui/core/Hidden'

import SvgIcon from '@material-ui/core/SvgIcon'

import Slide from '@material-ui/core/Slide'
import Fade from '@material-ui/core/Fade'
import Grow from '@material-ui/core/Grow'
import Collapse from '@material-ui/core/Collapse'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'

// Rail icons
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone'

import AdjustIcon from '@material-ui/icons/Adjust' // Current section
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked' // Section not completed
import CheckCircleIcon from '@material-ui/icons/CheckCircle' // Section completed

import CheckIcon from '@material-ui/icons/Check'
import Link from '@material-ui/core/Link'

import { makeStyles } from '@material-ui/core/styles'

// Layout blocks
import {
  AppBar,
  AppBarLogo,
  AppBarMenu,
  AppBarHeading,
  AppBarActions,
  AppBrandingBar,
  Header,
  Main,
  Content,
  TopNavigation,
  BottomNavigation,
  Footer,
  FooterLogo,
  Sidebar,
  OffsetSidebar,
  ContentSection,
  FooterSection,
  ScrollTopTop
} from './Layout'

// Form layout blocks
import { NavigationButtons, PrevNavigationButton, NavigationBar, NavigationRail } from './Form'

// Content blocks
import FieldSection from './Content/Fields'
import DocumentSection from './Content/Documents'
import SubmitSection from './Content/Submit'
import BrandingSidebar from './Sidebar/BrandingSidebar'
import OffsetSeparator from './Sidebar/OffsetSeparator'
import ProgressSidebar from './Sidebar/ProgressSidebar'
import PartiesSidebar from './Sidebar/PartiesSidebar'

// import AutosaveBar from './Autosave/AutosaveBar'

// To be moved in
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone'
import AddCommentTwoToneIcon from '@material-ui/icons/AddCommentTwoTone'

// App branding
import { ReactComponent as AppLogo } from './Layout/app.svg'

// Experimental
import { ReactComponent as Logo } from './Layout/branding_h48px.svg'
import { PartiesChip } from './Parties/Chip'
import AutosaveBar from './Autosave/AutosaveBar'
import { useSchemaDefinition } from '../Context/SchemaContext'
import ProgressSteps from './Experimental/ProgressSteps'
import { ReactComponent as TestIcon } from '../Animations/Icons/download.svg'
import DownloadAnimatedIcon from '../Animations/Icons/DownloadAnimatedIcon'
import AutosaveButton from './Autosave/AutosaveButton'

// Social icons
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import TwitterIcon from '@material-ui/icons/Twitter'
import YouTubeIcon from '@material-ui/icons/YouTube'

// Context
import { useProgressState } from '../Context/ProgressContext'
import { ScrollToTop, CollapseOnScroll, GrowOnScroll } from './Layout/Scroll'

const debugHighlighter = 'white' // 'rgba(207, 232, 252, 0.5)'

const useStyles = makeStyles(theme => ({
  appBar: {
    // borderBottom: '2px solid'
    // borderColor: theme.palette.common.black
    marginTop: theme.spacing(8),
    '& $heroDivider': {
      marginTop: theme.spacing(2)
    }
  },
  titleSection: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  saveSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  centeredContainer: {
    background: debugHighlighter,
    // paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
    minHeight: '120px'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4)
  },
  divider: {
    height: '2px'
  },
  heroDivider: {
    height: '2px',
    background: theme.palette.common.black
  },
  logo: {
    width: 200,
    height: 50,
    position: 'fixed',
    top: 16,
    left: 16,
    background: 'url("https://ep01.epimg.net/iconos/v2.x/v2.0/logos/el_pais.svg") 0 0 no-repeat',
    zIndex: 3000
  },
  save: {
    width: 200,
    height: 50,
    position: 'fixed',
    top: 16,
    right: 16,
    background:
      'url("https://ep01.epimg.net/iconos/v2.x/v2.0/logos/el_pais.svg") top right no-repeat',
    zIndex: 3000
  },
  experimental: {},
  // to be moved in
  verticalDivider: {
    width: 2,
    background: theme.palette.text.primary
  },
  chip: {
    background: theme.palette.common.black,
    color: theme.palette.common.white
  },
  icon: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1.5)
  },
  appLogo: {
    flexShrink: 0,
    width: theme.spacing(21)
  },
  brandingToolbar: {
    marginBottom: theme.spacing(0),
    //padding: theme.spacing(1, 0),
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    borderBottomColor: '#b0bec5',
    justifyContent: 'space-between'
    // alignItems: 'flex-end'
  },
  grow: { flexGrow: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }
}))

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      Registro electrónico.{' '}
      <Link color="inherit" href="https://material-ui.com/">
        Ayuntamiento de Benalmádena
      </Link>
      .
    </Typography>
  )
}

const useLinkStyles = makeStyles(theme => ({ link: { marginRight: theme.spacing(2) } }))

function Links() {
  const classes = useLinkStyles()
  return (
    <Typography variant="body2" color="textSecondary">
      <Link
        color="inherit"
        href="https://www.t-systems.com/"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        Normativa legal
      </Link>{' '}
      <Link
        color="inherit"
        href="https://www.t-systems.com/"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        Tratamiento de datos personales
      </Link>{' '}
      <Link
        color="inherit"
        href="https://www.t-systems.com/"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        Catálogo de trámites
      </Link>{' '}
      <Link
        color="inherit"
        href="https://www.t-systems.com/"
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        Sede electrónica
      </Link>
    </Typography>
  )
}

function StateInspector() {
  const state = useProgressState()
  const left = Object.values(state.progress).filter(el => el === false).length
  return (
    <>
      {left}
      <pre>{JSON.stringify(state, '', 2)}</pre>
    </>
  )
}

function AnimatedIcon(props) {
  return (
    <SvgIcon {...props}>
      <DownloadAnimatedIcon />
    </SvgIcon>
  )
}

export default function FormPage() {
  const classes = useStyles()
  const { step } = useParams()

  // Used by ProgressSidebar to hide itself
  // Used by PartiesSidebar to fade its divider
  const progressNavigationRef = useRef()

  // Used by PartiesSidebar to grow a divider below its contents
  const contentRef = useRef()

  // Used by ProgressNavigation to grow a divider below the next/prev buttons
  const footerRef = useRef()

  // Match step to definition
  const def = useSchemaDefinition()
  const current = Array.from(def).find(v => v.step === step)

  return (
    <>
      <Helmet>
        <title>{`${current.fullName} / Registro electrónico`}</title>
      </Helmet>
      <AutosaveBar />
      <ScrollToTop />
      <AppBar>
        <Hidden mdDown>
          <CollapseOnScroll disableHysteresis threshold={128} inverse>
            <Container maxWidth="lg">
              <Toolbar disableGutters className={classes.brandingToolbar}>
                <Typography variant="h6" component="h1" noWrap>
                  Registro electrónico
                </Typography>
                <AppBarLogo />
              </Toolbar>
            </Container>
          </CollapseOnScroll>
          <GrowOnScroll
            disableHysteresis
            threshold={128}
            inverse
            style={{ transitionDelay: '100ms' }}
          >
            <Divider className={classes.divider} />
          </GrowOnScroll>
        </Hidden>

        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <AppBarMenu />

            <Hidden lgUp>
              <AppBarHeading>
                <Typography variant="h6" component="h1" noWrap>
                  Registro electrónico
                </Typography>
              </AppBarHeading>
              <AppBarLogo />
            </Hidden>

            <Hidden mdDown>
              <NavigationBar />
            </Hidden>

            <AppBarActions>
              {current.type !== 'submit' && <PartiesChip />}
              <AutosaveButton />
            </AppBarActions>
          </Toolbar>
        </Container>
      </AppBar>
      <TopNavigation>
        <PrevNavigationButton edge="start" />
        <div className={classes.grow} />
        <PartiesChip full />
      </TopNavigation>
      <Main>
        <Header>
          <Typography variant="h4" color="textSecondary">
            Solicitud de actuación: queja o sugerencia
          </Typography>
          <Typography variant="h2" gutterBottom>
            {current.fullName}
          </Typography>
        </Header>

        <Content ref={contentRef}>
          {current.type === 'data' && <FieldSection />}
          {current.type === 'documents' && <DocumentSection />}
          {current.type === 'submit' && <SubmitSection />}
          {process.env.NODE_ENV === 'development' && false && <StateInspector />}
        </Content>
      </Main>
      <BottomNavigation ref={progressNavigationRef}>
        <NavigationButtons growEl={footerRef} />
      </BottomNavigation>
      <NavigationRail />
      <Footer ref={footerRef}>
        <FooterSection>
          <Toolbar disableGutters>
            <FooterLogo />
            <div className={classes.grow}>
              <IconButton>
                <FacebookIcon />
              </IconButton>
              <IconButton>
                <InstagramIcon />
              </IconButton>
              <IconButton>
                <LinkedInIcon />
              </IconButton>
              <IconButton>
                <TwitterIcon />
              </IconButton>
              <IconButton>
                <YouTubeIcon />
              </IconButton>
            </div>
          </Toolbar>
        </FooterSection>
        <FooterSection>
          <Divider />
        </FooterSection>
        <FooterSection>
          <Links />
        </FooterSection>
        {/*<FooterSection>
          <Copyright />
        </FooterSection>*/}
      </Footer>
    </>
  )
}
