import React, { useState } from 'react'
import clsx from 'clsx'

import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Button from '@material-ui/core/Button'

import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import FilledInput from '@material-ui/core/FilledInput'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import PlusOneIcon from '@material-ui/icons/PlusOne'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useLocationContext } from '../../components/Context/LocationContext'

const useStyles = makeStyles(theme => ({
  margin: {
    //margin: theme.spacing(1)
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  textField: {
    //width: '25ch'
  },
  button: { marginTop: 2 }
}))

export default function InputAdornments({ onChange, access }) {
  const classes = useStyles()

  // Location context
  const [defaultLocation, locationUtils, addressUtils] = useLocationContext()

  const [constants] = useState({
    interior: { types: locationUtils.access.getInteriorAccessTypes() }
  })

  // Inbound values
  const {
    interiorAccess: {
      type: interiorAccessType = constants.interior.types[0],
      value: interiorAccessValue = ''
    },
    toponym = ''
  } = access

  /*const [values, setValues] = useState({
    interior: { type: constants.interior.types[0], value: '' },
    toponym: { value: '' }
  })*/

  const [menuOpen, setMenuOpen] = useState('')

  const handleMenuOpen = event => {
    const { currentTarget } = event
    setMenuOpen(currentTarget)
  }

  const handleMenuClose = () => {
    setMenuOpen('')
  }

  /*const handleMenuSelection = (name, value) => {
    const option = constants[name].types.find(el => el.key === value)
    setValues({ ...values, [name]: { ...values[name], type: option } })
    handleMenuClose()
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: { ...values[name], value: event.target.value } })
  }*/

  const debouncedInteriorAccessChange = debounce(value => {
    if (value !== interiorAccessValue)
      onChange(prev => addressUtils.updateInteriorAccessValue(prev, value))
  }, 350)

  const handleInteriorAccessChange = event => {
    const {
      target: { value }
    } = event

    debouncedInteriorAccessChange(value)
  }

  const handleInteriorAccessTypeChange = type => {
    if (!isEqual(interiorAccessType, type))
      onChange(prev => addressUtils.updateInteriorAccessType(prev, type))
    handleMenuClose()
  }

  const debouncedToponymChange = debounce(value => {
    if (value !== toponym) onChange(prev => addressUtils.updateToponym(prev, value))
  }, 350)

  const handleToponymChange = event => {
    const {
      target: { value }
    } = event

    debouncedToponymChange(value)
  }

  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormControl
          fullWidth
          className={clsx(classes.margin, classes.textField)}
          variant="outlined"
        >
          <InputLabel htmlFor="input-interior-access">Acceso interior (opcional)</InputLabel>
          <OutlinedInput
            id="input-interior-access"
            label="Acceso interior (opcional)"
            //value={values.interior.value}
            key={`interior-access-field-for-type-${interiorAccessType.key}`}
            defaultValue={interiorAccessValue}
            onChange={handleInteriorAccessChange}
            disabled={interiorAccessType.key === ''}
            startAdornment={
              <InputAdornment position="start">
                <Button
                  startIcon={
                    menuOpen?.name === 'interior-access-type' ? (
                      <ExpandLessIcon color="primary" />
                    ) : (
                      <ExpandMoreIcon color="primary" />
                    )
                  }
                  aria-label="open list of interior access types"
                  onClick={handleMenuOpen}
                  name="interior-access-type"
                  edge="start"
                  className={classes.button}
                >
                  {interiorAccessType.label}
                </Button>
                <Menu
                  id="menu-interior-access-type"
                  anchorEl={menuOpen}
                  open={menuOpen?.name === 'interior-access-type'}
                  variant="menu"
                  getContentAnchorEl={null}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                >
                  {constants.interior.types.map(el => (
                    <MenuItem
                      key={`option-for-interior-access-type-${el.key}`}
                      value={el.key}
                      onClick={() => handleInteriorAccessTypeChange(el)}
                      selected={interiorAccessType.key === el.key}
                    >
                      {el.label}
                    </MenuItem>
                  ))}
                </Menu>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl
          fullWidth
          className={clsx(classes.margin, classes.textField)}
          variant="outlined"
        >
          <InputLabel shrink htmlFor="input-toponym">
            Topónimo (opcional)
          </InputLabel>
          <OutlinedInput
            id="input-toponym"
            label="Topónimo (opcional)"
            placeholder="Ej: Edificio Las Palomas"
            notched
            defaultValue={toponym}
            onChange={handleToponymChange}
          />
        </FormControl>
      </Grid>
    </>
  )
}
