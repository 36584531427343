import React, { useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import debounce from 'lodash/debounce'

import { Amplitude } from '@amplitude/react-amplitude'

import NumberFormat from 'react-number-format'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormHelperText from '@material-ui/core/FormHelperText'

import { number } from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import { sanitizeNumber } from '../Utils/sanitizeForInputValue'
import formatFormHelperText from '../Utils/formatFormHelperText'

// Number validation schema
const schema = number()
  .when('$required', (required, s) => (required ? s.required('Completa este campo') : s)) // Empty string doesn't pass
  .when('$max', (max, s) => (max ? s.lessThan(max) : s))

const useStyles = makeStyles(theme => ({
  label: {
    margin: theme.spacing(0, 2, 0.5, 0),
    lineHeight: 1.25
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75)
  },
  formHelperTextWithMessage: {
    marginBottom: theme.spacing(2) + 3
  }
}))

// digits = precision (significant numbers) & decimals = scale (numbers to the right of the decimal point)
const getMax = (digits, decimals) => {
  return decimals ? 10 ** (digits - decimals) : 10 ** digits
}

/**
Use a Number input field when you want the user to enter a numerical value.

- Free number input field.
- Only accepts positive values.
- Has thousand separators built in.
- Accepts any number of digits and decimals and has no prefix or suffix, unless overriden with those props.
- Blur events on the underlying input field are logged on the analytics platform.

### Events:
- Blur: only runs a validation if field wasn't interacted before
- Change: always runs a validation

### Validations:
- Needs a value, if required
- Value less than maximum, if digits is set (using digits as precision and decimals as scale)
*/

export default function Number({
  id,
  type,
  title,
  description,
  help,
  required,
  defaultValue,
  digits,
  decimals,
  prefix,
  suffix,
  onUpdate,
  onValidation
}) {
  const classes = useStyles()

  // Local state for field value and validation
  const [value, setValue] = useState(sanitizeNumber(defaultValue))
  const [validation, setValidation] = useState({
    error: undefined,
    message: undefined,
    dirty: false
  })

  // Validate using schema, set the validation
  const handleValidation = useCallback(
    debounce(v => {
      schema
        .validate(v, { context: { required, max: digits ? getMax(digits, decimals) : undefined } })
        .then(() => {
          setValidation({ error: false, message: undefined, dirty: true }) // !!v casts to boolean, dirty is used to flag a test with/without value
        })
        .catch(e => {
          setValidation({ error: true, message: e.message, dirty: true })
        })
    }, 100),
    [required, digits, decimals]
  )

  // On change, set the new value and share it and always validate (bc we can't rely on blur event value)
  const handleChange = values => {
    setValue(values.value)
    onUpdate(id, values.floatValue)
    handleValidation(values.floatValue)
  }

  // On blur, trigger validation if it's the first interaction (a saved value was an interaction)
  const handleBlur = () => {
    if (!validation.dirty) handleValidation(value)
  }

  // Side-effect of the validation: sync the context (skip if validation is yet undefined)
  useEffect(() => {
    if (typeof validation.error !== 'undefined') onValidation(id, !validation.error)
  }, [onValidation, id, validation.error])

  // Side-effect of having a default value
  // Validation runs only on numbers, and must be a healthy value
  // This handles validation of saved values, context must be aware of empty initial fields
  useEffect(() => {
    if (sanitizeNumber(defaultValue)) handleValidation(defaultValue)
  }, [defaultValue, handleValidation])

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: type,
        fieldVariant: 'input',
        fieldLabel: title
      })}
    >
      {({ instrument }) => (
        <FormControl
          required={required}
          error={validation.error}
          fullWidth
          variant="outlined"
          component="fieldset"
          margin="normal"
        >
          <FormLabel
            required={false}
            className={classes.label}
            id={`${id}-test-label`}
            htmlFor={`${id}-test`}
          >
            {title}
          </FormLabel>
          <NumberFormat
            customInput={OutlinedInput}
            id={id}
            placeholder={description}
            fullWidth
            value={value}
            isNumericString
            required={required}
            variant="filled"
            onBlur={instrument('Interacted with form field', handleBlur)}
            onValueChange={handleChange}
            type="text"
            decimalScale={decimals}
            thousandSeparator="."
            decimalSeparator=","
            allowedDecimalSeparators={[',', '.', ' ']}
            fixedDecimalScale
            prefix={prefix}
            suffix={suffix}
            allowNegative={false}
          />
          <FormHelperText
            className={clsx(classes.formHelperText, {
              [classes.formHelperTextWithMessage]: !required || help
            })}
          >
            {formatFormHelperText(required, help, validation.message)}
          </FormHelperText>
        </FormControl>
      )}
    </Amplitude>
  )
}

Number.propTypes = {
  /**
    DBOID of the field.
  */
  id: PropTypes.string.isRequired,
  /**
    Type.
  */
  type: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Helper text.
  */
  help: PropTypes.string,
  /**
    Use this to indicate that a value must be provided.
  */
  required: PropTypes.bool,
  /**
    Initial value of the field
  */
  defaultValue: PropTypes.number,
  /**
    The maximum number of digits allowed, including decimals.
    If digits is equal or lower than the number of decimals allowed, accepts floats between 0 and 1.
   */
  digits: PropTypes.number,
  /**
    The number of decimals
   */
  decimals: PropTypes.number,
  /**
    Add a prefix before the number
   */
  prefix: PropTypes.string,
  /**
    Add a suffix after the number
   */
  suffix: PropTypes.string,
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
     Handler to be called when a new validation needs to be shared
    @param {string} id - The id of the field.
    @param {bool} value - The validation result.
  */
  onValidation: PropTypes.func
}

Number.defaultProps = {
  description: null,
  help: null,
  required: false,
  defaultValue: undefined,
  digits: null,
  decimals: undefined,
  prefix: undefined,
  suffix: undefined,
  onUpdate: () => {},
  onValidation: () => {}
}
