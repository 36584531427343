import React, { useState } from 'react'
import PropTypes from 'prop-types'

import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

import TextField from '@material-ui/core/TextField'

import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Fade from '@material-ui/core/Fade'
import Grow from '@material-ui/core/Grow'

import { makeStyles } from '@material-ui/core/styles'

import Autocomplete from '@material-ui/lab/Autocomplete'

import { useLocationContext } from '../../components/Context/LocationContext'

const useStyles = makeStyles(theme => ({
  formControl: {
    //margin: theme.spacing(1)
  },
  inputRoot: {
    //background: theme.palette.background.default,
    //borderRadius: 0
  },
  selectIcon: {
    color: theme.palette.primary.main
  }
}))

export default function AddressApartmentForm({ properties, onChange, autoFocus }) {
  const classes = useStyles()
  const {
    stair = { key: '', label: 'Única' },
    floor = { key: '', label: 'Única' },
    door = { key: '', label: 'Única' }
  } = properties

  // Location context
  const [defaultLocation, locationUtils, addressUtils] = useLocationContext()

  const [constants] = useState({
    stairs: locationUtils.address.getStairs(),
    floors: locationUtils.address.getFloors(),
    doors: locationUtils.address.getDoors()
  })

  /* const handleChange = (event, child) => {
    const {
      props: { option }
    } = child

    const {
      target: { name }
    } = event

    if (!isEqual([name], option))
      onChange(prev => addressUtils.updateProperties(prev, name, option))
  } */

  const handleSelection = (event, value, reason, details) => {
    const {
      target: { id }
    } = event
    const name = id.substring(0, id.indexOf('-'))
    if (!isEqual([name], value)) onChange(prev => addressUtils.updateProperties(prev, name, value))
  }

  return (
    <>
      <Grid item xs={12} sm={4}>
        <Fade in={true} timeout={100}>
          {/*
          <FormControl fullWidth variant="outlined" className={classes.formControl} margin="none">
            <InputLabel shrink htmlFor="demo-simple-select-filled-stairs">
              Escalera
            </InputLabel>
            <Select
              id="demo-simple-select-filled-stairs"
              name="stair"
              // defaultValue={''}
              // margin="dense"
              classes={{ root: classes.inputRoot, icon: classes.selectIcon }}
              displayEmpty
              IconComponent={ExpandMoreIcon}
              input={
                <OutlinedInput
                  notched
                  label="Escalera"
                  name="stair"
                  id="demo-simple-select-outlined-input-stairs"
                />
              }
              autoFocus={autoFocus}
              value={stair.key}
              onChange={handleChange}
            >
              <MenuItem value="">Única</MenuItem>
              {constants.stairs.map(el => (
                <MenuItem key={`option-for-stair-${el.key}`} value={el.key} option={el}>
                  {el.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
              */}
          <Autocomplete
            id="stair-autocomplete"
            options={[{ key: '', label: 'Única' }, ...constants.stairs]}
            autoComplete
            autoHighlight
            disableClearable
            fullWidth
            includeInputInList
            //disabled={isEmpty(stair.key)}
            popupIcon={<ExpandMoreIcon />}
            classes={{ popupIndicator: classes.selectIcon, option: classes.option }}
            value={stair}
            onChange={handleSelection}
            renderInput={params => (
              <TextField
                {...params}
                label="Escalera"
                variant="outlined"
                name="stair"
                //autoFocus={isEmpty(municipality)}
                //inputRef={municipalityRef}
              />
            )}
            getOptionLabel={option => option.label}
            getOptionSelected={(option, value) => option.key === value.key}
            //getOptionLabel={locationUtils.location.municipality.getMunicipalityOptionLabel}
            //getOptionSelected={locationUtils.location.municipality.getMunicipalityOptionSelected}
            //renderOption={renderMunicipalityOption}
          />
        </Fade>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Fade in={true} timeout={200}>
          {/*<FormControl fullWidth variant="outlined" className={classes.formControl} margin="none">
            <InputLabel shrink id="demo-simple-select-filled-label-floor">
              Planta
            </InputLabel>
            <Select
              id="demo-simple-select-filled-floor"
              name="floor"
              // defaultValue={''}
              // margin="dense"
              classes={{ root: classes.inputRoot, icon: classes.selectIcon }}
              displayEmpty
              IconComponent={ExpandMoreIcon}
              input={
                <OutlinedInput
                  notched
                  label="Planta"
                  name="floor"
                  id="demo-simple-select-outlined-input-floor"
                />
              }
              value={floor.key}
              onChange={handleChange}
            >
              <MenuItem value="">Única</MenuItem>
              {constants.floors.map(el => (
                <MenuItem key={`option-for-floor-${el.key}`} value={el.key} option={el}>
                  {el.label}
                </MenuItem>
              ))}
            </Select>
              </FormControl>*/}
          <Autocomplete
            id="floor-autocomplete"
            options={[{ key: '', label: 'Única' }, ...constants.doors]}
            autoComplete
            autoHighlight
            disableClearable
            fullWidth
            includeInputInList
            //disabled={isEmpty(stair.key)}
            popupIcon={<ExpandMoreIcon />}
            classes={{ popupIndicator: classes.selectIcon, option: classes.option }}
            value={floor}
            onChange={handleSelection}
            renderInput={params => (
              <TextField
                {...params}
                label="Planta"
                variant="outlined"
                name="floor"
                //autoFocus={isEmpty(municipality)}
                //inputRef={municipalityRef}
              />
            )}
            getOptionLabel={option => option.label}
            getOptionSelected={(option, value) => option.key === value.key}
            //getOptionLabel={locationUtils.location.municipality.getMunicipalityOptionLabel}
            //getOptionSelected={locationUtils.location.municipality.getMunicipalityOptionSelected}
            //renderOption={renderMunicipalityOption}
          />
        </Fade>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Fade in={true} timeout={300}>
          {/*<FormControl fullWidth variant="outlined" className={classes.formControl} margin="none">
            <InputLabel shrink id="demo-simple-select-filled-label-door">
              Puerta
            </InputLabel>
            <Select
              id="demo-simple-select-filled-door"
              name="door"
              // defaultValue={''}
              // margin="dense"
              classes={{ root: classes.inputRoot, icon: classes.selectIcon }}
              displayEmpty
              IconComponent={ExpandMoreIcon}
              input={
                <OutlinedInput
                  notched
                  label="Puerta"
                  name="door"
                  id="demo-simple-select-outlined-input-door"
                />
              }
              value={door.key}
              onChange={handleChange}
            >
              <MenuItem value="">Única</MenuItem>
              {constants.doors.map(el => (
                <MenuItem key={`option-for-door-${el.key}`} value={el.key} option={el}>
                  {el.label}
                </MenuItem>
              ))}
            </Select>
              </FormControl>*/}
          <Autocomplete
            id="door-autocomplete"
            options={[{ key: '', label: 'Única' }, ...constants.doors]}
            autoComplete
            autoHighlight
            disableClearable
            fullWidth
            includeInputInList
            //disabled={isEmpty(stair.key)}
            popupIcon={<ExpandMoreIcon />}
            classes={{ popupIndicator: classes.selectIcon, option: classes.option }}
            value={door}
            onChange={handleSelection}
            renderInput={params => (
              <TextField
                {...params}
                label="Puerta"
                variant="outlined"
                name="door"
                //autoFocus={isEmpty(municipality)}
                //inputRef={municipalityRef}
              />
            )}
            getOptionLabel={option => option.label}
            getOptionSelected={(option, value) => option.key === value.key}
            //getOptionLabel={locationUtils.location.municipality.getMunicipalityOptionLabel}
            //getOptionSelected={locationUtils.location.municipality.getMunicipalityOptionSelected}
            //renderOption={renderMunicipalityOption}
          />
        </Fade>
      </Grid>
    </>
  )
}
