import React, { useState } from 'react'
import clsx from 'clsx'
import axios from 'axios'
import { useRouteMatch, useParams, Redirect, Link as RouterLink } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Fab from '@material-ui/core/Fab'

import Chip from '@material-ui/core/Chip'
import Transition from '@material-ui/core/Fade'
import Divider from '@material-ui/core/Divider'

import Grow from '@material-ui/core/Grow'

import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

import Avatar from '@material-ui/core/Avatar'
import Toolbar from '@material-ui/core/Toolbar'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'

import CheckIcon from '@material-ui/icons/Check'
import PersonIcon from '@material-ui/icons/Person'
import PeopleTwoToneIcon from '@material-ui/icons/PeopleTwoTone'
import SupervisorAccountTwoToneIcon from '@material-ui/icons/SupervisorAccountTwoTone'
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone'
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined'
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore'

import AddIcon from '@material-ui/icons/Add'

import { makeStyles } from '@material-ui/core/styles'

import { useSchemaDefinition } from '../../Context/SchemaContext'
import { useProgressState } from '../../Context/ProgressContext'

import PartiesSidebar from '../Sidebar/PartiesSidebar'
import { PartiesChip } from '../Parties/Chip'

import Field from '../../../lib/Fields'

import { Clock, SummaryStep } from '../Form'

const useStyles = makeStyles(theme => ({
  section: { marginBottom: theme.spacing(8) },
  parties: {},
  summaryHeader: {
    /*marginTop: theme.spacing(8)*/
  },
  notifications: { marginTop: theme.spacing(2) /*paddingLeft: theme.spacing(2)*/ },
  sticky: { position: 'sticky', top: theme.spacing(12) },
  heading: { marginTop: theme.spacing(6), marginBottom: theme.spacing(4) },
  paper: { padding: theme.spacing(4), background: theme.palette.action.hover },
  submitButton: {
    marginTop: theme.spacing(3) /*position: 'absolute', bottom: -22, right: 24*/
  },
  submitToolbar: { justifyContent: 'flex-end' },
  checkbox: { marginTop: theme.spacing(2), alignItems: 'flex-start' },
  radio: { alignItems: 'flex-start' },
  label: {
    paddingTop: 10
  },
  partyHeader: {
    fontWeight: 400
  },
  partyType: {
    marginLeft: theme.spacing(2.25) + 1,
    paddingLeft: theme.spacing(6) - 2,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    borderLeft: '2px solid #eceff1'
    //marginBottom: theme.spacing(1)
  },
  idNumber: { marginLeft: theme.spacing(1) },
  avatar: { marginRight: theme.spacing(1) },
  listItemAvatar: { minWidth: theme.spacing(4) },
  listItemAvatarFlexStart: { marginTop: theme.spacing(0.25) },
  smallAvatar: { width: theme.spacing(3), height: theme.spacing(3) },
  transparent: { backgroundColor: 'transparent' }
}))

export default function Page() {
  const classes = useStyles()

  // Hook into schema definition context to display sections
  const def = useSchemaDefinition()
  const sections = Array.from(def)

  // Hook into state progress context to get completion
  const state = useProgressState()
  const [values] = useState(state.values?.data)

  // Routing: step url parameter
  const fake = useRouteMatch('/tramite/:pid/formulario')
  const { pid } = useParams()

  // Fake post handler
  const [success, setSuccess] = useState(false)
  const handlePost = () => {
    axios
      .post('https://httpbin.org/post', {
        firstName: 'Hello',
        lastName: 'World'
      })
      .then(
        response => {
          console.log(response)
          setSuccess(true)
        },
        error => {
          console.log(error)
        }
      )
  }

  // Checkboxes
  const [checkboxes, setCheckboxes] = React.useState({
    checkedA: false,
    checkedB: false,
    checkedF: false,
    checkedG: false
  })
  const handleCheckboxChange = event => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked })
  }

  // Radios
  const [radio, setRadio] = React.useState('electronic')

  const handleRadioChange = event => {
    setRadio(event.target.value)
  }

  return (
    <>
      <Container maxWidth="lg" component="section" className={classes.section}>
        <Grid container spacing={4}>
          {/* Summary column */}
          <Grid item xs={12} md={8}>
            <Container
              maxWidth="lg"
              component="section"
              disableGutters
              className={classes.summarySection}
            >
              <Typography variant="h3" gutterBottom className={classes.summaryHeader}>
                Resumen
              </Typography>
            </Container>
            <Container
              maxWidth="lg"
              component="section"
              disableGutters
              className={clsx(classes.section, classes.parties)}
            >
              {/*<Typography variant="h4" gutterBottom>
                Personas relacionadas
              </Typography>
              <Typography paragraph>
                Así que, no debes congojarte por las desgracias que a mí me suceden, pues a ti no te
                cabe parte dellas.
  </Typography>*/}

              <SummaryStep title="Persona interesada" icon={<PersonIcon />}>
                {/*<PartiesChip hideIcon />*/}
                <Chip
                  variant="default"
                  color="default"
                  label={
                    <Typography variant="subtitle2" noWrap>
                      MATEU PORTILLO, MARIA FRANCISCA
                    </Typography>
                  }
                />
                <Typography variant="overline" color="textSecondary" className={classes.idNumber}>
                  85064745G
                </Typography>

                <FormControl component="fieldset" fullWidth className={classes.notifications}>
                  <Typography paragraph>
                    Las notificaciones relacionadas con esta solicitud serán gestionadas de la forma
                    siguiente:
                  </Typography>
                  <RadioGroup
                    aria-label="nofitificacions"
                    name="notifications"
                    value={radio}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      value="electronic"
                      control={<Radio />}
                      label={
                        <>
                          <Typography variant="subtitle2" gutterBottom>
                            Notificación electrónica
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            La notificación se remitirá al interesado, llegando un aviso al correo
                            electrónico que se haya indicado. Para recoger la notificación
                            electrónica será necesario acceder con un certificado de persona física
                            del interesado.
                          </Typography>

                          <Field
                            type="text"
                            required={radio === 'electronic'}
                            disabled={radio === 'paper'}
                            defaultValue="mateu.portillo@usuaria.es"
                            title="Correo electrónico"
                            help="Para la recepción del aviso de puesta a disposición de notificaciones"
                            regex="^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                          />
                        </>
                      }
                      className={classes.radio}
                      classes={{ label: classes.label }}
                    />
                    <FormControlLabel
                      value="paper"
                      control={<Radio />}
                      label={
                        <>
                          <Typography variant="subtitle2" gutterBottom>
                            Notificación en papel
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            La notificación se remitirá al interesado, por correo ordinario a la
                            dirección indicada.
                          </Typography>

                          <Field
                            type="address"
                            required={radio === 'paper'}
                            disabled={radio === 'electronic'}
                            title="Dirección postal"
                            help="Para la recepción de notificaciones por correo ordinario"
                          />
                        </>
                      }
                      className={classes.radio}
                      classes={{ label: classes.label }}
                    />
                  </RadioGroup>
                </FormControl>
              </SummaryStep>

              <SummaryStep title="Representante" icon={<SupervisorAccountTwoToneIcon />}>
                <Chip
                  variant="default"
                  color="default"
                  label={
                    <Typography variant="subtitle2" noWrap>
                      FUFASA
                    </Typography>
                  }
                />
                <Typography variant="overline" color="textSecondary" className={classes.idNumber}>
                  V54471313
                </Typography>

                <List>
                  <ListItem dense alignItems="flex-start">
                    <ListItemAvatar
                      classes={{
                        root: classes.listItemAvatar,
                        alignItemsFlexStart: classes.listItemAvatarFlexStart
                      }}
                    >
                      <Avatar className={clsx(classes.smallAvatar, classes.transparent)}>
                        <CheckIcon fontSize="small" color="default" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Autorización para presentar solicitudes en nombre de la persona interesada acreditada por inscripción en el Registro Electrónico de Apoderamientos" />
                  </ListItem>
                </List>
              </SummaryStep>

              <SummaryStep title="Solicitante" icon={<PeopleTwoToneIcon />}>
                <Chip
                  variant="default"
                  color="default"
                  label={
                    <Typography variant="subtitle2" noWrap>
                      BLANCO VIDAL, MANUELA
                    </Typography>
                  }
                />
                <Typography variant="overline" color="textSecondary" className={classes.idNumber}>
                  50298519A
                </Typography>

                <List>
                  <ListItem dense alignItems="flex-start">
                    <ListItemAvatar
                      classes={{
                        root: classes.listItemAvatar,
                        alignItemsFlexStart: classes.listItemAvatarFlexStart
                      }}
                    >
                      <Avatar className={clsx(classes.smallAvatar, classes.transparent)}>
                        <CheckIcon fontSize="small" color="default" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Identidad autenticada mediante certificado digital" />
                  </ListItem>
                  <ListItem dense alignItems="flex-start">
                    <ListItemAvatar
                      classes={{
                        root: classes.listItemAvatar,
                        alignItemsFlexStart: classes.listItemAvatarFlexStart
                      }}
                    >
                      <Avatar className={clsx(classes.smallAvatar, classes.transparent)}>
                        <CheckIcon fontSize="small" color="default" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Autorización para actuar en nombre del representante acreditada por certificado digital de respresentación de persona jurídica" />
                  </ListItem>
                </List>
              </SummaryStep>
            </Container>

            <Container maxWidth="lg" component="section" disableGutters className={classes.section}>
              <Typography variant="h4" gutterBottom>
                Contenido de la solicitud
              </Typography>
              {/*<Typography paragraph>
                Todas estas borrascas que nos suceden son señales de que presto ha de serenar el
                tiempo y han de sucedernos bien las cosas; porque no es posible que el mal ni el
                bien sean durables, y de aquí se sigue que, habiendo durado mucho el mal, el bien
                está ya cerca.
              </Typography>*/}
              {sections.map(
                section =>
                  section.type !== 'submit' && (
                    <SummaryStep
                      key={section.type}
                      title={section.fullName}
                      icon={<DescriptionOutlinedIcon />}
                    >
                      {/*<Typography paragraph>Todos los campos requeridos completados</Typography>
                      <Typography gutterBottom variant="body2">
                        3 campos opcionales completados
                      </Typography>
                      <Typography gutterBottom variant="body2">
                        Si lo deseas, hay 2 campos opcionales más
                      </Typography>*/}

                      <ListItem dense alignItems="flex-start">
                        <ListItemAvatar
                          classes={{
                            root: classes.listItemAvatar,
                            alignItemsFlexStart: classes.listItemAvatarFlexStart
                          }}
                        >
                          <Avatar className={clsx(classes.smallAvatar, classes.transparent)}>
                            <CheckIcon fontSize="small" color="primary" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="Todos los campos requeridos completados" />
                      </ListItem>
                      <ListItem dense alignItems="flex-start">
                        <ListItemAvatar
                          classes={{
                            root: classes.listItemAvatar,
                            alignItemsFlexStart: classes.listItemAvatarFlexStart
                          }}
                        >
                          <Avatar className={clsx(classes.smallAvatar, classes.transparent)}>
                            <CheckIcon fontSize="small" color="textSecondary" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary="3 campos opcionales completados" />
                      </ListItem>
                      <ListItem dense alignItems="flex-start">
                        <ListItemAvatar
                          classes={{
                            root: classes.listItemAvatar,
                            alignItemsFlexStart: classes.listItemAvatarFlexStart
                          }}
                        >
                          <Avatar className={clsx(classes.smallAvatar, classes.transparent)}>
                            <AddIcon fontSize="small" />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText secondary="Revisa si quieres completar otros campos opcionales" />
                      </ListItem>
                    </SummaryStep>
                  )
              )}
            </Container>

            <Container component="section" disableGutters className={classes.section}>
              <Typography variant="h4" gutterBottom>
                Privacidad y base legal
              </Typography>
              {/*<Typography paragraph>
                Todas estas borrascas que nos suceden son señales de que presto ha de serenar el
                tiempo y han de sucedernos bien las cosas; porque no es posible que el mal ni el
                bien sean durables, y de aquí se sigue que, habiendo durado mucho el mal, el bien
                está ya cerca.
              </Typography>*/}

              <SummaryStep
                title="Tratamiento de datos personales"
                icon={<VerifiedUserTwoToneIcon />}
              >
                <Typography paragraph>
                  El ejercicio de las competencias o funciones propias, así como la prestación de
                  servicios públicos por parte del Ayuntamiento de Badalona, conlleva necesariamente
                  el tratamiento de datos personales de las personas relacionadas, ya sea de forma
                  manual o automatizada, total o parcialmente.
                </Typography>
                <Button startIcon={<InfoOutlinedIcon />}>
                  Más información sobre el tratamiento de datos
                </Button>

                {/*<ListItem dense alignItems="flex-start">
                  <ListItemAvatar
                    classes={{
                      root: classes.listItemAvatar,
                      alignItemsFlexStart: classes.listItemAvatarFlexStart
                    }}
                  >
                    <Avatar className={classes.smallAvatar}>
                      <CheckIcon fontSize="small" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <b>Responsable:</b> Ayuntamiento de Las Torres de Cotillas.
                      </>
                    }
                  />
                </ListItem>
                <ListItem dense alignItems="flex-start">
                  <ListItemAvatar
                    classes={{
                      root: classes.listItemAvatar,
                      alignItemsFlexStart: classes.listItemAvatarFlexStart
                    }}
                  >
                    <Avatar className={classes.smallAvatar}>
                      <CheckIcon fontSize="small" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <b>Finalidad:</b> Tramitar procedimientos y actuaciones administrativas.
                      </>
                    }
                  />
                </ListItem>
                <ListItem dense alignItems="flex-start">
                  <ListItemAvatar
                    classes={{
                      root: classes.listItemAvatar,
                      alignItemsFlexStart: classes.listItemAvatarFlexStart
                    }}
                  >
                    <Avatar className={classes.smallAvatar}>
                      <CheckIcon fontSize="small" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <b>Legitimación:</b> Cumplimiento de una misión realizada en interés público
                        o en el ejercicio de poderes públicos otorgados a esta Entidad.
                      </>
                    }
                  />
                </ListItem>
                <ListItem dense alignItems="flex-start">
                  <ListItemAvatar
                    classes={{
                      root: classes.listItemAvatar,
                      alignItemsFlexStart: classes.listItemAvatarFlexStart
                    }}
                  >
                    <Avatar className={classes.smallAvatar}>
                      <CheckIcon fontSize="small" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <b>Destinatarios:</b> Se cederán datos, en su caso, a otras Administraciones
                        Públicas y a los Encargados del Tratamiento de los Datos. No hay previsión
                        de transferencias a terceros países.
                      </>
                    }
                  />
                </ListItem>
                <ListItem dense alignItems="flex-start">
                  <ListItemAvatar
                    classes={{
                      root: classes.listItemAvatar,
                      alignItemsFlexStart: classes.listItemAvatarFlexStart
                    }}
                  >
                    <Avatar className={classes.smallAvatar}>
                      <CheckIcon fontSize="small" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <b>Derechos:</b> Acceder, rectificar y suprimir los datos, así como otros
                        derechos, tal y como se explica en la información adicional.
                      </>
                    }
                  />
                </ListItem>
                <ListItem dense alignItems="flex-start">
                  <ListItemAvatar
                    classes={{
                      root: classes.listItemAvatar,
                      alignItemsFlexStart: classes.listItemAvatarFlexStart
                    }}
                  >
                    <Avatar className={classes.smallAvatar}>
                      <CheckIcon fontSize="small" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <b>Información adicional:</b> Puede consultar la información adicional y
                        detallada sobre Protección de Datos en la siguiente dirección.
                      </>
                    }
                  />
                  </ListItem>*/}
              </SummaryStep>
              <SummaryStep title="Normativa legal" icon={<LockTwoToneIcon />}>
                <Typography paragraph>
                  Todas estas borrascas que nos suceden son señales de que presto ha de serenar el
                  tiempo y han de sucedernos bien las cosas; porque no es posible que el mal ni el
                  bien sean durables, y de aquí se sigue que, habiendo durado mucho el mal, el bien
                  está ya cerca.
                </Typography>
                <Typography variant="body2" paragraph>
                  Así que, no debes congojarte por las desgracias que a mí me suceden, pues a ti no
                  te cabe parte dellas.
                </Typography>
              </SummaryStep>
            </Container>
          </Grid>
          {/* Submit surface */}
          <Grid item xs={12} md={4}>
            <Paper elevation={1} className={clsx(classes.paper, classes.sticky)}>
              <Clock />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkboxes.checkedA}
                    onChange={handleCheckboxChange}
                    name="checkedA"
                  />
                }
                label="He sido informado del tratamiento de datos"
                className={classes.checkbox}
                classes={{ label: classes.label }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkboxes.checkedB}
                    onChange={handleCheckboxChange}
                    name="checkedB"
                  />
                }
                label="Declaro que el contenido de esta solicitud es cierto y confirmo mi voluntad de firmarla electrónicamente en nombre de la persona interesada"
                className={classes.checkbox}
                classes={{ label: classes.label }}
              />
              <Toolbar disableGutters className={classes.submitToolbar}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  onClick={handlePost}
                  className={classes.submitButton}
                  disabled={!(checkboxes.checkedA && checkboxes.checkedB)}
                >
                  Presentar
                </Button>
              </Toolbar>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      {success && (
        <Redirect
          push
          to={{
            pathname: fake ? `/tramite/${pid}/justificante` : `/layouts/desktop/datos`
          }}
        />
      )}
    </>
  )
}
