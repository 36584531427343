import React from 'react'

import { useRouteMatch, useParams, Link as RouterLink } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Fade from '@material-ui/core/Fade'
import Zoom from '@material-ui/core/Zoom'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

import logo from '../../logo.svg'

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    padding: theme.spacing(4),
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing(0.5, 1)
  }
}))

export default function PublicPage({ title }) {
  const classes = useStyles()

  // Routing: step url parameter
  const fake = useRouteMatch('/tramite/:pid')
  const { pid } = useParams()

  return (
    <Container fixed className={classes.container}>
      <Fade in timeout={500}>
        <section>
          <Typography variant="h2" color="textSecondary">
            {title}
          </Typography>
          <Typography variant="h3" gutterBottom>
            Presentación por los residentes en España de las declaraciones sobre las transacciones
            económicas y los saldos de activos y pasivos financieros con el exterior
          </Typography>

          <Button
            variant="outlined"
            color="default"
            component={RouterLink}
            to={fake ? `/tramite/${pid}/formulario` : `/layouts/desktop/`}
            className={classes.button}
          >
            Usar mi DNI electrónico
          </Button>

          <Button
            variant="outlined"
            color="default"
            component={RouterLink}
            to={fake ? `/tramite/${pid}/formulario` : `/layouts/desktop`}
            className={classes.button}
          >
            Usar mi certificado digital
          </Button>

          <Button
            variant="outlined"
            color="default"
            component={RouterLink}
            to={fake ? `/tramite/${pid}/formulario` : `/layouts/desktop`}
            className={classes.button}
          >
            Usar una clave concertada
          </Button>
        </section>
      </Fade>
    </Container>
  )
}
