import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'

import Divider from '@material-ui/core/Divider'

import Grow from '@material-ui/core/Grow'
import Fade from '@material-ui/core/Fade'

import useScrollTrigger from '../Experimental/useScrollTrigger'

// Dialogs
import PartiesDialog from '../Dialogs/AutosaveDialog'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
    // marginTop: '25vh',
    // Tablet or smaller
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  },
  inline: {
    display: 'inline'
  },
  changePartiesButton: {
    marginBottom: theme.spacing(2)
  },
  item: {
    // borderLeft: '2px solid',
    borderColor: theme.palette.divider,
    '&:hover': {
      borderColor: theme.palette.primary.main
    },
    '&$active': {
      borderColor: theme.palette.common.black
    }
  },
  active: {},
  heroDivider: {
    height: '2px',
    background: theme.palette.common.black
  }
}))

export default function PartiesSidebar({ growEl, fadeEl }) {
  const classes = useStyles()

  // Triggers when the component gets pinned to the top
  // growEl is contentRef
  const growTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: growEl.current ? growEl.current.offsetTop - (72 + 16) : null
  })

  // Triggers when the bottom progress navigation appears at the bottom
  // fadeEl is progressNavigationRef
  const fadeTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: fadeEl.current ? fadeEl.current.offsetTop - window.innerHeight + 180 : null
  })

  // Dialog
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <List className={classes.root}>
        <ListItem
          disableGutters
          alignItems="flex-start"
          className={clsx(classes.item, classes.active)}
        >
          <ListItemText
            primary={
              <Typography color="secondary" variant="h6">
                Interesado
              </Typography>
            }
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  70321409J
                </Typography>
                {' — BLANCO VIDAL, MANUELA'}
              </>
            }
          />
        </ListItem>

        <Button
          variant="outlined"
          size="small"
          color="secondary"
          className={classes.changePartiesButton}
          onClick={handleClickOpen}
        >
          Modificar
        </Button>

        <ListItem disableGutters alignItems="flex-start" className={classes.item}>
          <ListItemText
            primary={
              <Typography variant="h6" color="textSecondary">
                Representante
              </Typography>
            }
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  A71867980
                </Typography>
                {' — T-SYSTEMS ITC IBERIA, SAU'}
              </>
            }
          />
        </ListItem>

        <ListItem disableGutters alignItems="flex-start" className={classes.item}>
          <ListItemText
            primary={
              <Typography variant="h6">
                Solicitante <PermIdentityIcon fontSize="small" />
              </Typography>
            }
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  86187978D
                </Typography>
                {' — MARIA CONSUELO CASTILLA BARRIOS'}
              </>
            }
          />
        </ListItem>
      </List>

      <Grow in={growTrigger} timeout={350} style={{ transformOrigin: 'top left' }}>
        <div>
          <Fade in={!fadeTrigger}>
            <Divider className={classes.heroDivider} />
          </Fade>
        </div>
      </Grow>

      <PartiesDialog open={open} handleClose={handleClose} />
    </>
  )
}

PartiesSidebar.propTypes = {
  growEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.oneOfType([
        PropTypes.instanceOf(HTMLInputElement),
        PropTypes.instanceOf(Element)
      ])
    })
  ]),
  fadeEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.oneOfType([
        PropTypes.instanceOf(HTMLInputElement),
        PropTypes.instanceOf(Element)
      ])
    })
  ])
}

PartiesSidebar.defaultProps = {
  growEl: { current: null },
  fadeEl: { current: null }
}
