import React, { useState } from 'react'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Transition from '@material-ui/core/Fade'
import Divider from '@material-ui/core/Divider'

import { makeStyles } from '@material-ui/core/styles'

import { useSchemaDefinition } from '../../Context/SchemaContext'
import { useProgressState } from '../../Context/ProgressContext'

import Field from '../../../lib/Fields'

const useStyles = makeStyles(theme => ({
  leftalignedContainer: {
    paddingBottom: theme.spacing(4),
    marginLeft: 0
  },
  heading: { marginTop: theme.spacing(6), marginBottom: theme.spacing(4) }
}))

export default function Page() {
  const classes = useStyles()

  // Hook into schema definition context to display fields
  const def = useSchemaDefinition()
  const groupsOfFields = Array.from(def).find(v => v.step === 'datos')?.fields

  // Hook into state progress context to get saved values
  // Save to component state to avoid feeding new default values to the fields on input change
  // We only want to do that when the section renders again (will happen on navigation)
  const state = useProgressState()
  const [values] = useState(state.values?.data)

  return (
    <>
      {groupsOfFields?.map(group => (
        <Container
          maxWidth="sm"
          className={classes.leftalignedContainer}
          component="section"
          key={group.id}
        >
          {group.title && (
            <Typography variant="h4" gutterBottom className={classes.heading}>
              {group.title}
            </Typography>
          )}
          <Grid container spacing={0} key={`container-for-${group.id}`}>
            {group.fields.map((field, i) => (
              <Transition
                in
                ttimeout={300}
                style={{ transitionDelay: Math.min(50 * (i + 1), 500) }}
                key={`transition-for ${field.id}`}
              >
                <Grid item xs={12}>
                  <Field
                    type={field.type}
                    key={`field-for-${field.id}`}
                    id={field.id}
                    title={field.title}
                    description={field.description}
                    help={field.help}
                    //defaultValue={state.values?.data?.[field.id]}
                    defaultValue={values?.[field.id]}
                    required={field.required}
                    options={field.items?.length > 0 ? field.items : undefined}
                    multiple={field.multiple}
                    length={field.length}
                    digits={field.digits}
                    decimals={field.decimals}
                    regex={field.regex}
                  />
                </Grid>
              </Transition>
            ))}
          </Grid>
        </Container>
      ))}
    </>
  )
}
