import React, { useReducer } from 'react'
import debounce from 'lodash/debounce'
import { useSchemaDefinition } from './SchemaContext'
import { useEffect } from 'react'

const emtpyfn = () => {}
const initialProgress = { data: {}, documents: {}, submit: {} }
const initialValues = {}
const initialState = { values: initialValues, progress: initialProgress }

const ProgressStateContext = React.createContext()
const ProgressUpdaterContext = React.createContext(emtpyfn)

const buildProgressSchema = definition => {
  const builder = initialProgress

  // Section 1: data
  const groupsOfFields = Array.from(definition).find(v => v.type === 'data')?.fields
  const allFields = groupsOfFields?.reduce((accum, group) => accum.concat(group.fields), [])
  const initialFieldValidations = Object.fromEntries(
    allFields?.map(el => [el.id, el.required ? !el.required : true]) || []
  )
  builder.data = initialFieldValidations

  // Section 2: documents
  const groupsOfDocuments = Array.from(definition).find(v => v.type === 'documents')?.documents
  const allDocuments = groupsOfDocuments?.reduce(
    (accum, group) => accum.concat(group.documents),
    []
  )
  const initialDocumentValidations = Object.fromEntries(
    allDocuments?.map(el => [el.id, el.required ? !el.required : true]) || []
  )
  builder.documents = initialDocumentValidations

  // Add other sections as needed

  // Build the progress object, retaining only the sections needed
  const progressSchema = Array.from(definition).reduce((obj, el) => {
    return {
      ...obj,
      [el.type]: Object.prototype.hasOwnProperty.call(builder, el.type) ? builder[el.type] : {}
    }
  }, {})

  return progressSchema
}

function stateReducer(state, action) {
  switch (action.type) {
    case 'init': {
      const { progress } = action.payload
      return { values: initialValues, progress }
    }
    case 'clear': {
      return initialState
    }
    case 'store-value': {
      const { section, id, value } = action.payload
      return {
        ...state,
        values: { ...state.values, [section]: { ...state.values[section], [id]: value } }
      }
    }
    case 'store-validation': {
      const { section, id, value } = action.payload
      // Only mutate state if we're asking to store a different validation
      // On navigation, all fields are rendered fresh. Current progress values are fed to them.
      // They will validate again these values and try to feed them to state
      return state.progress?.[section]?.[id] !== value
        ? {
            ...state,
            progress: { ...state.progress, [section]: { ...state.progress[section], [id]: value } }
          }
        : state
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function ProgressProvider(props) {
  const { children } = props

  // Get the schema
  const def = useSchemaDefinition()

  // const progressSchema = buildProgressSchema(def)

  const [state, dispatch] = useReducer(stateReducer, initialState)

  useEffect(() => {
    console.log('progressprovider saw def change')
    const progress = buildProgressSchema(def)
    console.log(progress)
    dispatch({ type: 'init', payload: { progress } })
  }, [def])

  console.log('progressprovider render')

  return (
    <ProgressStateContext.Provider value={state}>
      <ProgressUpdaterContext.Provider value={dispatch}>{children}</ProgressUpdaterContext.Provider>
    </ProgressStateContext.Provider>
  )
}

function useProgressState() {
  const progressState = React.useContext(ProgressStateContext)
  /*if (typeof progressState === 'undefined') {
    throw new Error('useProgressState must be used within a ProgressProvider')
  }*/
  return progressState
}

function useProgressUpdater(section) {
  const dispatch = React.useContext(ProgressUpdaterContext)

  /*if (typeof dispatch === 'undefined') {
    throw new Error('useProgressUpdater must be used within a ProgressProvider')
  }*/

  const updateValue = React.useCallback(
    debounce(
      (id, value) => dispatch({ type: 'store-value', payload: { section, id, value } }),
      150
    ),
    [dispatch, section]
  )

  const updateValidation = React.useCallback(
    (id, value) => dispatch({ type: 'store-validation', payload: { section, id, value } }),
    [dispatch, section]
  )

  return [/*dispatch,*/ updateValue, updateValidation]
}

export { useProgressState, useProgressUpdater, ProgressProvider }
