import React from 'react'

import Chip from '@material-ui/core/Chip'

import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import VpnKeyIcon from '@material-ui/icons/VpnKey'

import PlusOneIcon from '@material-ui/icons/PlusOne'

import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette, spacing }) => ({
  list: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  chip: { marginTop: spacing(2), marginRight: spacing(1), color: palette.text.secondary }
}))

export default function DocumentLabel({ documentProps }) {
  const classes = useStyles()
  return (
    <section className={classes.list}>
      {documentProps.signature && (
        <Chip
          size="small"
          icon={<VpnKeyIcon />}
          label={<Typography variant="subtitle2">Requiere firma electrónica</Typography>}
          className={classes.chip}
        />
      )}
      {documentProps.multiple && (
        <Chip
          size="small"
          icon={<PlusOneIcon />}
          label={<Typography variant="subtitle2">Admite múltiples ficheros</Typography>}
          className={classes.chip}
        />
      )}
      {documentProps.reusable && (
        <Chip
          size="small"
          icon={<RotateLeftIcon />}
          label={<Typography variant="subtitle2">Admite reutilización</Typography>}
          className={classes.chip}
        />
      )}
    </section>
  )
}
