import React from 'react'

import { useRouteMatch, useParams, Link as RouterLink } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Fade from '@material-ui/core/Fade'
import Zoom from '@material-ui/core/Zoom'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    padding: theme.spacing(4),
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing(0.5, 1)
  }
}))

export default function PublicPage({ title }) {
  const classes = useStyles()

  // Routing: step url parameter
  const fake = useRouteMatch('/tramite/:pid')
  const { pid } = useParams()

  return (
    <Container fixed className={classes.container}>
      <Fade in timeout={500}>
        <section>
          <Typography variant="h2" color="textSecondary">
            Post page
          </Typography>
          <Typography variant="h3" gutterBottom>
            See what happens
          </Typography>

          <form action="/post">
            <input type="text" name="demo" />
            <button type="submit">Enviar</button>
          </form>
        </section>
      </Fade>
    </Container>
  )
}
