import React, { useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import LinearProgress from '@material-ui/core/LinearProgress'
import Fade from '@material-ui/core/Fade'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'

import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'

import Field from '../../../lib/Fields'

import { useDocuments, useFields } from '../../Api'

import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing, palette }) => ({
  saving: {
    position: 'fixed',
    width: '100%',
    height: '2px'
  },
  container: {
    //marginTop: theme.spacing(4),
    //marginBottom: theme.spacing(6),
    padding: spacing(2),
    textAlign: 'left'
  },
  scrollable: {
    overflowX: 'scroll',
    display: 'block',
    scrollbarWidth: 'none', // Firefox
    '&::-webkit-scrollbar': {
      display: 'none' // Safari + Chrome
    },
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    padding: spacing(0, 1),
    background: `linear-gradient(to right, ${palette.background.paper} 10%, transparent 15%),
      linear-gradient(to left, ${palette.background.paper} 10%, transparent 15%),
  linear-gradient(to right, ${palette.divider}, transparent 5%),
  linear-gradient(to left, ${palette.divider}, transparent 5%)`,
    /* Opera doesn't support this in the shorthand */
    backgroundAttachment: 'local, local, scroll, scroll',
    '&::after': {
      content: '""',
      position: 'relative',
      top: -10,
      display: 'block',
      pointerEvents: 'none',
      backgroundImage: 'linear-gradient(to right, rgba(255,255,255,0), red 85%)',
      width: '100%',
      height: '100%'
    }
  },
  noWrapOl: {
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: spacing(1)
  },
  chip: {
    margin: spacing(1)
  },
  pageHeading: {
    marginBottom: spacing(4)
  },
  sectionHeading: {
    marginTop: spacing(6)
  }
}))

export default function Page() {
  const classes = useStyles()
  const { pid } = useParams()

  const [fields, isLoadingF, isErrorF] = useFields(pid)

  const ref = useRef()
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: ref.current ? ref.current.offsetTop : null
  })

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(
      () => {
        console.log('This will toggle')
        /*chipRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center'
        })*/
        setIsLoading(prev => !prev)
      },
      isLoading ? 3000 : 10000
    )
    return () => clearTimeout(timer)
  }, [isLoading])

  const chipRef = useRef()
  const handleClick = () => console.log('chip clicked')

  return (
    <>
      <Fade in={isLoading}>
        <LinearProgress className={classes.saving} />
      </Fade>

      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Registro electrónico
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="xl" className={classes.container}>
        <Typography variant="h3" className={classes.title} gutterBottom>
          Solicitud de actuación (queja o consulta)
        </Typography>
        <Typography variant="body1">
          Trámite para solicitar una actuación (queja o consulta). No es necesario disponer de un
          certíficado o firma electrónica reconocida. Este trámite se puede también iniciar en
          persona en cualquiera de las tres oficinas de atención directa.
        </Typography>
      </Container>
      <Container maxWidth={false} disableGutters className={classes.scrollable}>
        <Chip
          label="Datos de la solicitud"
          variant="default"
          color="secondary"
          className={classes.chip}
          onClick={handleClick}
        />
        <Chip
          label="Documentos requeridos"
          variant="outlined"
          className={classes.chip}
          ref={chipRef}
          onClick={handleClick}
        />
        <Chip
          label="Una tercera sección"
          variant="outlined"
          className={classes.chip}
          onClick={handleClick}
        />
      </Container>
      <Divider />
      <Container maxWidth="xl" className={classes.container}>
        <Typography variant="h4" className={classes.pageHeading} gutterBottom>
          Datos de la solicitud
        </Typography>

        {fields.map(group => (
          <>
            <Typography variant="h5" className={classes.sectionHeading} gutterBottom>
              {group.title}
            </Typography>

            <Grid container spacing={2} key={`container-for-${group.id}`}>
              {group.fields.map(field => (
                <Grid item xs={12}>
                  <Field
                    type={field.type}
                    key={`field-for-${field.id}`}
                    id={field.id}
                    title={field.title}
                    description={field.description}
                    help={field.help}
                    required={field.required}
                    options={field.items}
                    digits={field.digits}
                    decimals={field.decimals}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ))}
      </Container>
    </>
  )
}
