import React from 'react'
import clsx from 'clsx'

import Drawer from '@material-ui/core/Drawer'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import LocationForm from './LocationForm'

const useStyles = makeStyles(theme => ({
  drawer: {
    padding: theme.spacing(2)
  },
  drawerGrid: {
    marginTop: theme.spacing(1)
  }
}))

export default function OptionsDrawer({ location, fullscreen, open, onLocationChange, onClose }) {
  const classes = useStyles()
  return (
    <Drawer
      anchor={fullscreen ? 'bottom' : 'top'}
      open={open}
      onClose={onClose}
      classes={{ paper: clsx('mui-fixed', classes.drawer) }}
    >
      <Container disableGutters maxWidth="md">
        <Typography variant="subtitle1" gutterBottom>
          Seleccionar un municipio
        </Typography>
        <Grid container spacing={2} className={classes.drawerGrid}>
          <LocationForm location={location} onChange={onLocationChange} />
        </Grid>
      </Container>
    </Drawer>
  )
}
