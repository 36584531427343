import React from 'react'

import Container from '@material-ui/core/Container'
import Fade from '@material-ui/core/Fade'
import Zoom from '@material-ui/core/Zoom'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'

import logo from '../../logo.svg'

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    padding: theme.spacing(4),
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    height: '40vmin'
  },
  progress: {
    margin: theme.spacing(2)
  }
}))

export default function LandingPage({ title }) {
  const classes = useStyles()

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Fade in timeout={500}>
        <section>
          <Zoom in timeout={500}>
            <img src={logo} className={classes.img} alt="logo" />
          </Zoom>
          <LinearProgress className={classes.progress} />
          <Typography variant="h6">{title}</Typography>
          <Typography>Preparando el registro electrónico</Typography>
        </section>
      </Fade>
    </Container>
  )
}
