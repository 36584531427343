import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Amplitude } from '@amplitude/react-amplitude'
import { isValid, printFormat, electronicFormat } from 'iban'

import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormHelperText from '@material-ui/core/FormHelperText'

import Tooltip from '@material-ui/core/Tooltip'
import CheckIcon from '@material-ui/icons/Check'

import { makeStyles } from '@material-ui/core/styles'

import { string } from 'yup'

import spanishBanks from './banks_es.json'
import formatFormHelperText from '../Utils/formatFormHelperText'
import { sanitizeString } from '../Utils/sanitizeForInputValue'

import RenderCounter from '../Utils/RenderCounter'

// Validation schema
const schema = string()
  .when('$required', (required, s) => (required ? s.required() : s))
  .test('Is Valid IBAN', 'Please enter an IBAN bank account number', value =>
    value ? isValid(value) : true
  )

// Util fn that returns the spanish bank object from a valid electronically formatted IBAN, or undefined
const getSpanishBankName = iban => {
  let name
  const country = iban.substring(0, 2)
  if (country === 'ES') {
    const code = parseInt(iban.substring(4, 8), 10)
    const bank = spanishBanks.find(el => el.code === code)
    name = bank && bank.name
  }
  return name
}

const useStyles = makeStyles(theme => ({
  label: {
    margin: theme.spacing(0, 2, 0.5, 0),
    lineHeight: 1.25
  },
  helperText: {
    color: theme.palette.text.primary
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75)
  },
  formHelperTextWithMessage: {
    marginBottom: theme.spacing(2) + 3
  },
  tooltip: {
    backgroundColor: theme.palette.primary.light,
    ...theme.typography.caption
  },
  tooltipPlacementBottom: {
    margin: theme.spacing(0.25, 0),
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(0.25, 0)
    }
  }
}))

/**
Use a BankAccount when you want the user to input or select an "ISO 13616 IBAN Registry technical specification" compliant account number

- Can be a free input or an assisted input with radio buttons or dropdown
- If options are passed, the input is assisted:
    - With 5 or more elements, a dropdown is displayed (with autocomplete from 12 elements forward - not yet implemented)
    - With 4 or less elements, a group of radio buttons is displayed
- This behaviour can be overriden with the format prop, forcing to use either radio or dropdown
- When the field is optional it will never display a group of radio buttons
- The underlying input field change event is logged on the analytics platform.

### Events:
- Blur: always runs a validation
- Change: only runs a validation if we were on error state or if we clear the field

### Validations:
- Value must be set, if required
- Value must be a valid IBAN, if not empty
*/

export default function BankAccount({
  id,
  type,
  title,
  description,
  help,
  required,
  defaultValue,
  onUpdate,
  onValidation
}) {
  const classes = useStyles()

  // Local state for field value and validation
  const [value, setValue] = useState(printFormat(sanitizeString(defaultValue)))
  const [helperValue, setHelperValue] = useState(null)
  const [validation, setValidation] = useState({
    error: undefined,
    message: undefined,
    dirty: false
  })

  // Validate using schema, set the validation and get new helper value
  const handleValidation = useCallback(
    v => {
      schema
        .validate(v, { context: { required } })
        .then(() => {
          setValidation({ error: false, message: undefined, dirty: !!v }) // !!v casts to boolean, dirty is used to flag a test with/without value
          setValue(printFormat(v))
          const bank = getSpanishBankName(electronicFormat(v))
          setHelperValue(bank)
        })
        .catch(e => {
          setValidation({ error: true, message: e.message, dirty: !!v })
        })
    },
    [required]
  )

  // Side-effect of the validation: sync the context (skip if validation is yet undefined)
  useEffect(() => {
    if (typeof validation.error !== 'undefined') onValidation(id, !validation.error)
  }, [onValidation, id, validation.error])

  // On change, set the new value and share it but only validate if we had an incorrect value or if we clear the field
  const handleChange = event => {
    setValue(event.target.value)
    onUpdate(id, electronicFormat(event.target.value))
    if (validation.error || !event.target.value) handleValidation(event.target.value)
  }

  // On blur, trigger validation
  const handleBlur = event => {
    handleValidation(event.target.value)
  }

  // Side-effect of having a default value: validate it (skip if undefined, sanitize to empty string if null)
  // This expects the context to set its initial validation state
  useEffect(() => {
    if (sanitizeString(defaultValue)) handleValidation(defaultValue)
  }, [defaultValue, handleValidation])

  // Render fn
  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: type,
        fieldVariant: 'input',
        fieldLabel: title
      })}
    >
      {({ instrument }) => (
        <FormControl
          required={required}
          error={validation.error}
          fullWidth
          variant="outlined"
          component="fieldset"
          margin="normal"
        >
          <FormLabel
            required={false}
            className={classes.label}
            id={`${id}-test-label`}
            htmlFor={`${id}`}
          >
            {title}
          </FormLabel>
          <Tooltip
            title={(value && validation.dirty && !validation.error && helperValue) || ''}
            placement="bottom-end"
            classes={{
              tooltip: classes.tooltip,
              tooltipPlacementBottom: classes.tooltipPlacementBottom
            }}
          >
            <OutlinedInput
              id={`${id}`}
              name={`${id}`}
              placeholder="ESXX XXXX XXXX XXXX XXXX XXXX"
              aria-describedby={`${id}-test-label`}
              value={value}
              variant="outlined"
              onBlur={instrument('Interacted with form field', handleBlur)}
              onChange={handleChange}
              endAdornment={
                value && validation.dirty && !validation.error && <CheckIcon color="primary" />
              }
            />
          </Tooltip>

          <FormHelperText
            className={clsx(classes.formHelperText, {
              [classes.formHelperTextWithMessage]: !required || help
            })}
          >
            {formatFormHelperText(required, help, validation.message)}
          </FormHelperText>

          {/*<RenderCounter />*/}
        </FormControl>
      )}
    </Amplitude>
  )
}

BankAccount.propTypes = {
  /**
    DBOID of the field.
  */
  id: PropTypes.string.isRequired,
  /**
    Type.
  */
  type: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Helper text.
  */
  help: PropTypes.string,
  /**
    Use this to indicate that a value must be provided.
  */
  required: PropTypes.bool,
  /**
    Initial value of the field, array for mutiple selections and string for single selection or text
  */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /**
    Type of rendering. Accepts values: auto, radio or combo
  */
  format: PropTypes.string,

  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
    Handler to be called when a new validation needs to be shared
    @param {string} id - The id of the field.
    @param {bool} value - The validation result.
  */
  onValidation: PropTypes.func
}

BankAccount.defaultProps = {
  description: null,
  help: null,
  required: false,
  defaultValue: undefined,
  format: 'auto',
  onUpdate: () => {},
  onValidation: () => {}
}
