import React from 'react'

import Container from '@material-ui/core/Container'

import Fab from '@material-ui/core/Fab'
import NavigationIcon from '@material-ui/icons/Navigation'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

import { ValidationDemoProvider, useValidationDemoState } from '../../Context/ValidationDemoContext'
import Field from '../../../lib/Fields'

const definition = {
  fields: [
    { id: 'iban1', type: 'bank-account', title: 'First bank account' },
    { id: 'iban2', type: 'bank-account', title: 'Second bank account', required: true },
    {
      id: 'iban3',
      type: 'bank-account',
      title: 'Third bank account',
      defaultValue: 'ES55 0182 1422 9535 4562 6518a'
    },
    {
      id: 'iban4',
      type: 'bank-account',
      title: 'Fourth bank account',
      required: true,
      defaultValue: 'ES8421006190606121734440'
    },
    {
      id: 'iban5',
      type: 'bank-account',
      title: 'Fifth bank account',
      required: true,
      defaultValue: undefined
    }
  ]
}

const initialValidation = {
  values: {},
  validations: Object.fromEntries(
    definition.fields.map(el => [el.id, el.required ? !el.required : true])
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'left',
    marginTop: theme.spacing(4),
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  saving: {
    position: 'fixed',
    width: '100%',
    height: '2px',
    zIndex: 99
  }
}))

function RenderCounter() {
  const ref = React.useRef()
  React.useEffect(() => {
    ref.current.textContent = Number(ref.current.textContent || '0') + 1
  })
  return (
    <p>
      This has rendered <span ref={ref} /> times.
    </p>
  )
}

function Display() {
  const state = useValidationDemoState()
  const left = Object.values(state.validations).filter(el => el === false).length

  return (
    <>
      <pre>{JSON.stringify(state, '', 2)}</pre>
      {left}
      <RenderCounter />
    </>
  )
}

function SubmitButton() {
  const state = useValidationDemoState()
  const valid = Object.values(state.validations).every(i => i)
  return (
    <Fab variant="extended" disabled={!valid}>
      <NavigationIcon />
      Send
    </Fab>
  )
}

export default function Validation() {
  const classes = useStyles()
  return (
    <Container maxWidth="sm" className={classes.container}>
      <Typography variant="h2" gutterBottom>
        Validation demo
      </Typography>
      <ValidationDemoProvider initialState={initialValidation}>
        {definition.fields.map(field => (
          <Field
            type={field.type}
            key={`field-for-${field.id}`}
            id={field.id}
            title={field.title}
            description={field.description}
            help={field.help}
            defaultValue={field.defaultValue}
            required={field.required}
            options={field.items}
            digits={field.digits}
            decimals={field.decimals}
          />
        ))}
        <Display />
        <SubmitButton />
      </ValidationDemoProvider>
    </Container>
  )
}
