import React from 'react'
import clsx from 'clsx'

import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'

import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import VpnKeyIcon from '@material-ui/icons/VpnKey'

import { makeStyles } from '@material-ui/core/styles'

import formatFormHelperText from '../../Fields/Utils/formatFormHelperText'

import DocumentProps from './DocumentProps'
import DocumentFileTypes from './DocumentFileTypes'

const useStyles = makeStyles(({ palette, spacing, shadows, typography }) => ({
  section: { display: 'flex', color: palette.field.label },
  icon: { marginRight: spacing(2) },
  required: { color: palette.primary.main },
  formHelperText: { marginTop: spacing(2), fontSize: typography.body2.fontSize },
  chip: { marginTop: spacing(2), marginRight: spacing(1) },
  more: { border: 'none' }
}))

export default function DocumentLabel({
  title,
  description,
  required,
  error,
  hasFile,
  documentProps
}) {
  const classes = useStyles()
  const { accepts } = documentProps

  const FilledIcon = required ? CheckCircleIcon : CheckCircleIcon
  return (
    <section className={classes.section}>
      {hasFile ? (
        <FilledIcon className={clsx(classes.icon, required && classes.required)} fontSize="small" />
      ) : (
        <RadioButtonUncheckedIcon className={clsx(classes.icon)} fontSize="small" />
      )}
      {/* Field description */}
      <section>
        <FormLabel error={error}>{title}</FormLabel>
        <FormHelperText
          className={clsx(classes.formHelperText, {
            [classes.formHelperTextWithMessage]: !required || description
          })}
        >
          {formatFormHelperText(required, description, null)}
        </FormHelperText>
        <DocumentProps documentProps={documentProps} />
        <DocumentFileTypes accepts={accepts} />
      </section>
    </section>
  )
}
