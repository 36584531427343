import React, { useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import LinearProgress from '@material-ui/core/LinearProgress'
import Fade from '@material-ui/core/Fade'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'

import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'

import Link from '@material-ui/core/Link'

import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined'
import IconButton from '@material-ui/core/IconButton'

import Field from '../../../lib/Fields'

import InterestedPartyChip from './InterestedPartyChip'

import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ spacing, palette }) => ({
  saving: {
    position: 'fixed',
    width: '100%',
    height: '2px',
    zIndex: 99
  },
  container: {
    //marginTop: theme.spacing(4),
    //marginBottom: theme.spacing(6),
    padding: spacing(2, 2, 0, 2),
    textAlign: 'left'
  },
  scrollable: {
    overflowX: 'scroll',
    display: 'block',
    scrollbarWidth: 'none', // Firefox
    '&::-webkit-scrollbar': {
      display: 'none' // Safari + Chrome
    },
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    padding: spacing(0, 1),
    background: `linear-gradient(to right, ${palette.background.paper} 10%, transparent 15%),
      linear-gradient(to left, ${palette.background.paper} 10%, transparent 15%),
  linear-gradient(to right, ${palette.divider}, transparent 5%),
  linear-gradient(to left, ${palette.divider}, transparent 5%)`,
    /* Opera doesn't support this in the shorthand */
    backgroundAttachment: 'local, local, scroll, scroll',
    '&::after': {
      content: '""',
      position: 'relative',
      top: -10,
      display: 'block',
      pointerEvents: 'none',
      backgroundImage: 'linear-gradient(to right, rgba(255,255,255,0), red 85%)',
      width: '100%',
      height: '100%'
    }
  },
  noWrapOl: {
    flexWrap: 'nowrap',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: spacing(1)
  },
  chip: {
    margin: spacing(1)
  },
  pageHeading: {
    marginTop: spacing(4)
  },
  sectionHeading: {
    marginTop: spacing(6)
  },
  footer: {
    //padding: spacing(2, 0),
    marginTop: 'auto',
    backgroundColor: palette.type === 'dark' ? palette.grey[800] : palette.grey[200]
  },
  footerContainer: {
    paddingTop: spacing(4),
    paddingBottom: spacing(4)
  },
  test: {
    top: 0
  }
}))

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      Copyright ©
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

function Links() {
  return (
    <Typography variant="body2" color="textSecondary">
      Follow{' '}
      <Link color="inherit" href="https://material-ui.com/">
        some
      </Link>{' '}
      <Link color="inherit" href="https://material-ui.com/">
        useful
      </Link>{' '}
      <Link color="inherit" href="https://material-ui.com/">
        links
      </Link>
      {'.'}
    </Typography>
  )
}

export default function Page() {
  const classes = useStyles()
  const { pid } = useParams()

  const ref = useRef()
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: ref.current ? ref.current.offsetTop : null
  })

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(
      () => {
        console.log('This will toggle')
        /*chipRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center'
        })*/
        setIsLoading(prev => !prev)
      },
      isLoading ? 3000 : 10000
    )
    return () => clearTimeout(timer)
  }, [isLoading])

  const chipRef = useRef()
  const handleClick = () => console.log('chip clicked')

  return (
    <>
      <Fade in={isLoading}>
        <LinearProgress color="secondary" className={classes.saving} />
      </Fade>

      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Registro electrónico
          </Typography>
        </Toolbar>
      </AppBar>

      <Fade in={trigger}>
        <AppBar position="fixed" color="secondary" elevation={4} className={classes.test}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
            >
              <PermIdentityOutlinedIcon />
            </IconButton>
            <Typography className={classes.title} variant="subtitle1" noWrap>
              BLANCO VIDAL, MANUELA
            </Typography>
          </Toolbar>
        </AppBar>
      </Fade>

      <Container maxWidth="xl" className={classes.container}>
        <Typography variant="h3" className={classes.title} gutterBottom>
          Solicitud de actuación (queja o consulta)
        </Typography>
        <Typography variant="body1">
          Trámite para solicitar una actuación (queja o consulta). No es necesario disponer de un
          certíficado o firma electrónica reconocida. Este trámite se puede también iniciar en
          persona en cualquiera de las tres oficinas de atención directa.
        </Typography>
        <InterestedPartyChip />
      </Container>
      <Container maxWidth={false} disableGutters className={classes.scrollable} ref={ref}>
        <Chip
          label="Datos de la solicitud"
          variant="default"
          color="secondary"
          className={classes.chip}
          onClick={handleClick}
        />
        <Chip
          label="Documentos requeridos"
          variant="outlined"
          className={classes.chip}
          ref={chipRef}
          onClick={handleClick}
        />
        <Chip
          label="Una tercera sección"
          variant="outlined"
          className={classes.chip}
          onClick={handleClick}
        />
      </Container>

      <Divider />

      <Container maxWidth="xl" className={classes.container}>
        <Typography variant="h4" className={classes.pageHeading} gutterBottom>
          Datos de la solicitud
        </Typography>

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Field
              type="text"
              required
              options={[
                { key: '1', label: 'Correo postal' },
                { key: '2', label: 'Correo electrónico' },
                { key: '3', label: 'Oficina virtual' }
              ]}
              title="Modo de contacto para la recepción de escritos"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              type="text"
              required
              options={[
                { key: '1', label: 'Correo postal' },
                { key: '2', label: 'Correo electrónico' },
                { key: '3', label: 'Oficina virtual' }
              ]}
              title="Modo de contacto"
              help="Lo usaremos para la recepción de escritos"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              type="text"
              required
              options={[
                { key: '1', label: 'Euskera' },
                { key: '2', label: 'Castellano' },
                { key: '3', label: 'Bilingüe' }
              ]}
              title="Idioma de contacto"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              type="text"
              required
              options={[
                { key: '1', label: 'Hombre' },
                { key: '2', label: 'Mujer' },
                { key: '3', label: 'Colectivo' },
                { key: '4', label: 'Sin identificar' }
              ]}
              title="Sexo"
            />
          </Grid>
          <Grid item xs={12}>
            <Field type="text" title="Franjas horarias para contacto telefónico" />
          </Grid>
          <Grid item xs={12}>
            <Field
              type="text"
              options={[
                { key: '1', label: 'Sí' },
                { key: '2', label: 'No' }
              ]}
              title="¿Ha presentado una consulta o queja al Ararteko con anterioridad?"
              required
            />
          </Grid>
        </Grid>

        <Typography variant="h5" className={classes.sectionHeading} gutterBottom>
          Datos de la queja
        </Typography>

        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Field type="textarea" id="q1" required title="¿Cuál es el motivo de su queja?" />
          </Grid>
          <Grid item xs={12}>
            <Field type="text" title="¿A qué administración o a quién se ha quejado?" />
          </Grid>
          <Grid item xs={12}>
            <Field
              type="textarea"
              title="¿Qué respuesta ha recibido por parte de la Administración?"
              help="Ayuda"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              type="text"
              options={[
                { key: '1', label: 'Sí' },
                { key: '2', label: 'No' }
              ]}
              title="¿Ha emprendido acciones judiciales?"
            />
          </Grid>
          <Grid item xs={12}>
            <Field type="bank-account" id="ba" title="CC" />
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth={false} className={classes.footer} component="footer">
        <Container
          maxWidth="xl"
          className={classes.footerContainer}
          component="section"
          disableGutters
        >
          <Typography variant="body2" gutterBottom>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Feugiat vivamus at augue eget arcu.
          </Typography>
        </Container>
        <Divider />
        <Container
          maxWidth="xl"
          className={classes.footerContainer}
          component="section"
          disableGutters
        >
          <Copyright />
          <Links />
        </Container>
      </Container>
    </>
  )
}
