import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useTooltipStyles = makeStyles(theme => ({
  arrow: {
    color: theme.palette.primary.main
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightLight
  }
}))

export default function DocumentTooltip(props) {
  const classes = useTooltipStyles()
  return <Tooltip arrow placement="right" classes={classes} {...props} />
}
