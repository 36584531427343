import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Fade from '@material-ui/core/Fade'
import LinearProgress from '@material-ui/core/LinearProgress'

import { useAutosaveContext } from '../../Context/AutosaveContext'

const useStyles = makeStyles(theme => ({
  saving: {
    position: 'fixed',
    width: '100%',
    height: '2px',
    zIndex: 1300, //AppBar sits at 1201 (drawer+1)
    // Tablet or smaller
    [theme.breakpoints.down('md')]: {
      //top: 64
    },
    // Mobile
    [theme.breakpoints.down('xs')]: {
      //top: 56
    }
  }
}))

export default function AutosaveBar() {
  const classes = useStyles()
  /*const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const timer = setTimeout(
      () => {
        setIsLoading(prev => !prev)
      },
      isLoading ? 3000 : 10000
    )
    return () => clearTimeout(timer)
  }, [isLoading])*/

  const [isLoading, timestamp] = useAutosaveContext()

  return (
    <Fade in={isLoading}>
      <LinearProgress color="secondary" className={classes.saving} />
    </Fade>
  )
}
