import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import FaceIcon from '@material-ui/icons/Face'

import Slide from '@material-ui/core/Slide'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone'

import Hidden from '@material-ui/core/Hidden'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import PartiesDialog from '../Dialogs/PartiesDialog'
import SimplePopover, { ControlledPopover } from '../Dialogs/Popover'
import useScrollTrigger from './useScrollTrigger'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}))

export default function Chips() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleDelete = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Chip
        icon={<PeopleAltTwoToneIcon />}
        label="BLANCO VIDAL, MANUELA"
        color="textPrimary"
        onClick={handleClick}
        onDelete={handleDelete}
        variant="outlined"
        deleteIcon={<ExpandMoreIcon />}
      />
      <SimplePopover anchorEl={anchorEl} handleClose={handleClose} />
    </>
  )
}

const useChipStyles = makeStyles(theme => ({
  chip: {
    //background: theme.palette.common.black,
    //color: theme.palette.common.white
    fontWeight: 600,
    maxWidth: theme.breakpoints.values.sm / 2
  },
  icon: {
    marginLeft: theme.spacing(2)
  },
  arrow: {
    marginLeft: theme.spacing(1)
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '40ch',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '35ch'
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '25ch'
    }
  },
  typography: {
    flexShrink: 1,
    fontWeight: 600
  }
}))

const HeaderPartyChip = ({ slideEl, full }) => {
  const classes = useChipStyles()
  const mobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {mobile && !full ? (
        <>
          <IconButton color="default" onClick={handleClick}>
            <PeopleAltTwoToneIcon fontSize="small" />
          </IconButton>
          <PartiesDialog open={Boolean(anchorEl)} handleClose={handleClose} />
        </>
      ) : (
        <>
          <Chip
            icon={<PeopleAltTwoToneIcon fontSize="small" />}
            clickable
            label={
              <>
                <Typography variant="caption" className={classes.typography} noWrap>
                  BLANCO VIDAL, MANUELA
                </Typography>
                {anchorEl ? (
                  <ExpandLessIcon fontSize="small" className={classes.arrow} color="primary" />
                ) : (
                  <ExpandMoreIcon fontSize="small" className={classes.arrow} color="primary" />
                )}
              </>
            }
            color="default"
            onClick={handleClick}
            classes={{ root: classes.chip, icon: classes.icon, label: classes.label }}
          />
          <ControlledPopover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            handleClose={handleClose}
          />
        </>
      )}
    </>
  )
}

export { HeaderPartyChip }
