import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Grid, Fade, makeStyles } from '@material-ui/core'
import { FormTextField } from './FormElements'

const useStyles = makeStyles(theme => ({}))

export default function NaturalPersonFields({ values, errors, onChange, onBlur, onFocus }) {
  const classes = useStyles()
  return (
    <>
      <Grid item xs={12} sm={4} md={4}>
        <Fade in>
          <FormTextField
            required
            id="name"
            error={(errors.name || errors.person) && !values.name}
            value={values.name}
            name="name"
            //size="small"
            label="Nombre"
            fullWidth
            variant="outlined"
            first
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        </Fade>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Fade in>
          <FormTextField
            required
            id="firstSurname"
            name="firstSurname"
            error={(errors.firstSurname || errors.person) && !values.firstSurname}
            value={values.firstSurname}
            //size="small"
            label="Primer apellido"
            fullWidth
            variant="outlined"
            middle
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
          />
        </Fade>
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <Fade in>
          <FormTextField
            id="lastSurname"
            name="lastSurname"
            //error={errors.lastSurname}
            value={values.lastSurname}
            //size="small"
            label="Segundo apellido"
            placeholder="(opcional)"
            fullWidth
            variant="outlined"
            last
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
          />
        </Fade>
      </Grid>
    </>
  )
}
