import React from 'react'
import PropTypes from 'prop-types'

import Fade from '@material-ui/core/Fade'
import MenuList from '@material-ui/core/MenuList'

import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import Skeleton from '@material-ui/lab/Skeleton'

import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone'

import { makeStyles, fade } from '@material-ui/core/styles'

import { EmptySearch, StartTyping, ErrorMessage } from './State'

import { useLocationContext } from '../../../../../components/Context/LocationContext'

const useStyles = makeStyles(theme => ({
  menuList: {
    // borderTopStyle: 'solid',
    // borderTopColor: theme.palette.text.primary
  },
  menuItem: {
    // border: '2px solid transparent',
    padding: theme.spacing(0.75, 4.25),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.75, 2, 0.75, 2.75)
    },
    // borderRadius: '4px',
    '&[aria-selected="true"]': {
      // backgroundColor: fade(theme.palette.primary.main, 0.2),
      // borderColor: 'grey'
      '& $listItemIcon': {
        color: theme.palette.primary.main
      },
      '& $listItemTextPrimary': {
        fontWeight: 500,
        color: theme.palette.primary.main
      }
    },
    '&[data-focus="true"]': {
      backgroundColor: fade(theme.palette.primary.light, 0.1),
      // borderColor: theme.palette.common.black
      '& $listItemIcon': {
        color: theme.palette.primary.light
      }
    },
    '&:active': {
      // backgroundColor: fade(theme.palette.primary.main, 0.2),
      // borderColor: 'grey'
      '& $listItemIcon': {
        color: theme.palette.primary.main
      },
      '& $listItemTextPrimary': {
        fontWeight: 500,
        color: theme.palette.primary.main
      }
    },
    '&[aria-disabled="true"]': {
      opacity: theme.palette.action.disabledOpacity
      // pointerEvents: 'none'
    }
  },
  listItemIcon: {
    minWidth: 'unset',
    padding: '9px'
  },
  listItemText: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1.25)
    }
  },
  listItemTextPrimary: {} // pseudo-class
}))

function ListboxElement({ icon, primary, secondary, OptionProps, delay, disabled }) {
  const classes = useStyles()
  return (
    <Fade
      in
      // timeout={100 * (index + 1)}
      // timeout={Math.min(100 * (index + 1), 500)}
      // timeout={400}
      // style={{ transformOrigin: 'center left', transitionDelay: 30 * (index + 1) }}
      style={{ transitionDelay: delay }}
    >
      <MenuItem
        {...OptionProps}
        className={classes.menuItem}
        disabled={disabled} /* divider={fullscreen} */
      >
        <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        <ListItemText
          classes={{
            root: classes.listItemText,
            primary: classes.listItemTextPrimary
          }}
          disableTypography={!disabled}
          primary={primary}
          secondary={secondary}
        />
      </MenuItem>
    </Fade>
  )
}

ListboxElement.propTypes = {
  icon: PropTypes.node.isRequired,
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  OptionProps: PropTypes.shape({}),
  delay: PropTypes.number,
  disabled: PropTypes.bool
}

ListboxElement.defaultProps = {
  OptionProps: {},
  delay: 0,
  disabled: false
}

export default function SearchListbox({
  visible,
  isSearch,
  isLoading,
  isError,
  getListboxProps,
  options,
  getOptionProps
}) {
  const classes = useStyles()
  // Location context
  const [, locationUtils] = useLocationContext()
  return (
    visible && (
      <div>
        {!isLoading && isError && <ErrorMessage />}
        {!isLoading && !isError && isSearch && options.length === 0 && <EmptySearch />}
        {!isLoading && !isError && !isSearch && options.length === 0 && <StartTyping />}
        <Fade in={visible} mountOnEnter unmountOnExit>
          <MenuList {...getListboxProps()} className={classes.menuList} disablePadding>
            {isLoading &&
              [0, 1, 2, 3, 4].map((sk, index) => (
                <ListboxElement
                  key={`skeleton-${sk}`}
                  style={{ opacity: 0.8 - 0.2 * index }}
                  primary={<Skeleton width={240} />}
                  secondary={<Skeleton width={120} />}
                  icon={<LocationOnTwoToneIcon />}
                  disabled
                />
              ))}
            {!isLoading &&
              options.map((option, index) => (
                <ListboxElement
                  key={`option-${index}`}
                  disableTypography
                  primary={
                    <Typography variant="body1" noWrap>
                      {/*option.nomComplet*/}
                      {locationUtils.access.getAccessOptionLabel(option)}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" noWrap>
                      {/*`080${option.districtePostal}, ${option.barri.nom} (${option.districte.descripcio})`*/}
                      {locationUtils.access.getAccessOptionSecondaryLabel(option)}
                    </Typography>
                  }
                  icon={<LocationOnTwoToneIcon />}
                  OptionProps={{ ...getOptionProps({ option, index }) }}
                  delay={Math.min(30 * (index + 1), 300)}
                />
              ))}
          </MenuList>
        </Fade>
      </div>
    )
  )
}

SearchListbox.propTypes = {
  visible: PropTypes.bool,
  isLoading: PropTypes.bool,
  getListboxProps: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getOptionProps: PropTypes.func.isRequired
}

SearchListbox.defaultProps = {
  visible: true,
  isLoading: false
}
