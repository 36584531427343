import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { useParams, useRouteMatch, Link } from 'react-router-dom'

import Container from '@material-ui/core/Container'

import AdjustIcon from '@material-ui/icons/Adjust' // Current section
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked' // Section not completed
import CheckCircleIcon from '@material-ui/icons/CheckCircle' // Section completed
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined' // Section completed but on a future step

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Grow from '@material-ui/core/Grow'
import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/core/styles'

import { Tooltip, TooltipHeader, TooltipText } from '../../Tooltip'

import { useSchemaDefinition } from '../../../Context/SchemaContext'
import { useProgressState } from '../../../Context/ProgressContext'

const useStyles = makeStyles(theme => ({
  // NavigationBar
  root: {},
  ol: {
    margin: 0,
    display: 'flex',
    padding: 0,
    flexWrap: 'wrap',
    listStyle: 'none',
    alignItems: 'center'
  },
  // NavigationButton
  anchor: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.color
  },
  anchorTypography: {
    fontWeight: 600
  },
  icon: { marginLeft: theme.spacing(0.5) },
  completed: { color: theme.palette.text.primary },
  active: { color: theme.palette.primary.main },
  // NavigationSeparator
  separator: {
    margin: theme.spacing(0, 0.5, 0, 0.5),
    display: 'flex',
    userSelect: 'none',
    minWidth: theme.spacing(4)
  },
  divider: {
    height: '2px',
    width: '100%',
    transition: theme.transitions.background,
    background: theme.palette.text.secondary
  },
  activeDivider: {
    background: theme.palette.text.primary
  },
  completedDivider: {
    background: theme.palette.text.primary
  },
  disabledDivider: {
    background: theme.palette.action.disabled
  }
}))

function NavigationButton({ completed, active, disabled, title, path, delay, description, past }) {
  const classes = useStyles()
  return (
    <li>
      <Grow in timeout={delay}>
        <Tooltip
          placement="bottom-start"
          title={description && <TooltipText>{description}</TooltipText>}
        >
          <Button
            component={Link}
            to={path}
            disabled={disabled}
            size="small"
            className={clsx(classes.anchor, {
              [classes.active]: active,
              [classes.completed]: completed
            })}
          >
            <Typography variant="body2" className={classes.anchorTypography}>
              {title}
            </Typography>
            {!completed && !active && (
              <RadioButtonUncheckedIcon fontSize="small" className={classes.icon} />
            )}
            {!completed && active && <AdjustIcon fontSize="small" className={classes.icon} />}
            {completed && past && (
              <Fade in>
                <CheckCircleIcon fontSize="small" className={classes.icon} />
              </Fade>
            )}
            {completed && !past && (
              <Fade in>
                <CheckCircleOutlinedIcon fontSize="small" className={classes.icon} />
              </Fade>
            )}
          </Button>
        </Tooltip>
      </Grow>
    </li>
  )
}

NavigationButton.propTypes = {
  completed: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  delay: PropTypes.number
}

NavigationButton.defaultProps = {
  completed: false,
  active: false,
  disabled: false,
  delay: 0
}

function NavigationSeparator({ disabled, active, completed, delay }) {
  const classes = useStyles()
  return (
    <li className={classes.separator} aria-hidden>
      <Grow in timeout={delay}>
        <Divider
          className={clsx({
            [classes.divider]: true,
            [classes.disabledDivider]: disabled,
            [classes.activeDivider]: active,
            [classes.completedDivider]: completed
          })}
        />
      </Grow>
    </li>
  )
}

NavigationSeparator.propTypes = {
  completed: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  delay: PropTypes.number
}

NavigationSeparator.defaultProps = {
  completed: false,
  active: false,
  disabled: false,
  delay: 0
}

function NavigationElement({
  completed,
  active,
  disabled,
  title,
  path,
  first,
  delay,
  description,
  past
}) {
  return (
    <>
      {!first && (
        <NavigationSeparator
          active={active}
          disabled={disabled}
          completed={completed}
          delay={delay}
        />
      )}
      <NavigationButton
        title={title}
        description={description}
        path={path}
        active={active}
        disabled={disabled}
        completed={completed}
        first={first}
        past={past}
        delay={delay}
      />
    </>
  )
}

NavigationElement.propTypes = {
  completed: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  first: PropTypes.bool,
  delay: PropTypes.number
}

NavigationElement.defaultProps = {
  completed: false,
  active: false,
  disabled: false,
  first: false,
  delay: 0
}

const NavigationBar = React.forwardRef(function AppBar({ className }, ref) {
  const classes = useStyles()

  // Routing: step url parameter
  const fake = useRouteMatch('/tramite/:pid/formulario/:step')
  const { pid, step } = useParams()

  // Context: request definition
  const def = useSchemaDefinition()
  const currentIndex = Array.from(def).findIndex(el => el.step === step)

  // Context: request progress
  const state = useProgressState()
  const pendingIndex = Array.from(def).findIndex(
    el => el.isComplete(state.progress[el.type]) === false
  )

  return (
    <Container
      fixed
      component="nav"
      className={clsx(classes.root, className)}
      disableGutters
      ref={ref}
    >
      <ol className={classes.ol}>
        {Array.from(def).map((el, i, a) => (
          <NavigationElement
            key={`nav-element-${el.type}`}
            delay={100 * i}
            title={el.shortName}
            description={el.getProgressMessage(state.progress[el.type])}
            path={fake ? `/tramite/${pid}/formulario/${el.step}` : `/layouts/desktop/${el.step}`}
            active={i === currentIndex}
            past={i < currentIndex}
            first={i === 0}
            disabled={pendingIndex >= 0 ? i > pendingIndex : false}
            completed={
              el.isComplete(state.progress[el.type]) && (i < pendingIndex || pendingIndex === -1)
            }
          />
        ))}
        {/*<NavigationElement
          title="Presentación"
          path={`/tramite/${pid}/presentacion`}
          disabled={pendingIndex >= 0}
          delay={(Array.from(def).length + 1) * 100}
          description={''}
        />*/}
      </ol>
    </Container>
  )
})

export default NavigationBar

NavigationBar.propTypes = {
  className: PropTypes.string
}

NavigationBar.defaultProps = {
  className: null
}
