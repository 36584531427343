import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import Container from '@material-ui/core/Container'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const useStyles = makeStyles(({ palette, spacing }) => ({
  dialog: {
    // border: '2px solid black'
  }
}))

/**
Use a DocumentDialog when you want to show document details.

- Renders to the full width of its parent element.
- Starts with 2 lines and grows up to 10 lines of text before showing a scrollbar.
- The underlying input field blur event is logged on the analytics platform.
*/

export default function FileList({
  open,
  onClose,
  id,
  title,
  children,
  description,
  required,
  reusable,
  signature,
  multiple,
  isDragActive,
  isDragAccept,
  isDragReject,
  isLoading,
  hasFile,
  preview
}) {
  const classes = useStyles()
  const handleOk = () => onClose()
  const handleCancel = () => onClose()

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      //maxWidth="lg"
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.dialog }}
      aria-labelledby={`${id}-dialog-title`}
      open={open}
    >
      <DialogTitle id={`${id}-dialog-title`} disableTypography>
        <Typography variant="subtitle1" gutterBottom>
          Ficheros anexados
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container>{children}</Grid>
      </DialogContent>

      <DialogActions>
        {/*<Button autoFocus onClick={handleCancel} variant="outlined" color="primary">
          Cancel
  </Button>*/}
        <Button variant="text" disableElevation onClick={handleOk} color="primary">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
