import React from 'react'
import PropTypes from 'prop-types'

import AppBar from '@material-ui/core/AppBar'
import Typography from '@material-ui/core/Typography'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import InputBase from '@material-ui/core/InputBase'
import OutlinedInput from '@material-ui/core/OutlinedInput'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SearchIcon from '@material-ui/icons/Search'
import LocationOnTwoToneIcon from '@material-ui/icons/LocationOnTwoTone'
import PublicIcon from '@material-ui/icons/Public'
import CheckIcon from '@material-ui/icons/Check'

import Chip from '@material-ui/core/Chip'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import Fade from '@material-ui/core/Fade'
import Hidden from '@material-ui/core/Hidden'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  appBar: {
    padding: theme.spacing(2, 2, 1),
    background: theme.palette.common.white
  },
  subheading: {
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  chipIcon: {
    marginLeft: theme.spacing(1.5)
  },
  chipArrow: {
    marginLeft: theme.spacing(1)
  },
  chipLabel: {
    display: 'flex',
    alignItems: 'center'
  },
  chipTypography: {
    flexShrink: 1
  },
  section: {
    padding: theme.spacing(1, 2, 2, 0),
    marginLeft: theme.spacing(6),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  inputRoot: {
    flex: 1,
    padding: theme.spacing(1, 2, 0, 1.5),
    marginTop: theme.spacing(0.5)
  },
  inputBase: {
    // Same as OutlinedInput ('18.5px 14px') less the border of the Paper on the top and left borders: '15.5px 14px 18.5px 12px'
    // Adjusted to align icon position with the Field '15.5px 14px 18.5px 4px'
    // Adjusted to position bottom border
    padding: '15.5px 14px 10.5px 4px',
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    borderBottomColor: theme.palette.field.border,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 4
    }
  },
  InputAdornment: { marginTop: theme.spacing(0.25) }
}))

export default function SearchInput({
  title,
  isLoading,
  listboxOpen,
  handleClose,
  getInputProps,
  getRootProps
}) {
  const classes = useStyles()
  return (
    <>
      <Hidden smUp>
        <Typography variant="subtitle1" gutterBottom noWrap>
          {title}
        </Typography>
        <OutlinedInput
          fullWidth
          autoFocus
          placeholder="Buscar una dirección"
          startAdornment={
            <InputAdornment position="start">
              <IconButton edge="start" color="inherit" onClick={handleClose}>
                <ArrowBackIcon />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              {isLoading ? (
                <Fade in={isLoading} mountOnEnter unmountOnExit>
                  <CircularProgress className={classes.inputProgress} size={24} />
                </Fade>
              ) : (
                <Fade in={!isLoading} mountOnEnter unmountOnExit>
                  {listboxOpen ? <SearchIcon /> : <LocationOnTwoToneIcon />}
                </Fade>
              )}
            </InputAdornment>
          }
          inputProps={{ ...getInputProps() }}
          {...getRootProps()}
        />
      </Hidden>

      <Hidden xsDown>
        <InputBase
          classes={{ root: classes.inputRoot, input: classes.inputBase }}
          fullWidth
          autoFocus
          placeholder="Buscar una dirección"
          startAdornment={
            <InputAdornment position="start" className={classes.InputAdornment}>
              {isLoading ? (
                <Fade in={isLoading} mountOnEnter unmountOnExit>
                  <CircularProgress className={classes.inputProgress} size={24} />
                </Fade>
              ) : (
                <Fade in={!isLoading} mountOnEnter unmountOnExit>
                  {listboxOpen ? <SearchIcon /> : <LocationOnTwoToneIcon />}
                </Fade>
              )}
            </InputAdornment>
          }
          inputProps={{ ...getInputProps() }}
          {...getRootProps()}
        />
      </Hidden>
    </>
  )
}

SearchInput.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  listboxOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  getInputProps: PropTypes.func,
  getRootProps: PropTypes.func
}

SearchInput.defaultProps = {
  title: 'Field title',
  isLoading: false,
  listboxOpen: true,
  handleClose: () => {},
  getInputProps: () => {},
  getRootProps: () => {}
}
