import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

// eslint-disable-next-line import/no-webpack-loader-syntax
import { Document as PDFDocument, Page } from 'react-pdf/dist/entry.webpack'
import './pdf.css'

const useStyles = makeStyles(({ spacing }) => ({
  fill: {
    width: '100%',
    height: '100%'
  }
}))

export default function PDFPreview({ file }) {
  const classes = useStyles()
  return (
    <section className={classes.fill}>
      <PDFDocument file={file} renderMode="canvas">
        <Page pageNumber={1} height={150} renderAnnotationLayer={false} renderTextLayer={false} />
      </PDFDocument>
    </section>
  )
}

PDFPreview.propTypes = {
  file: PropTypes.shape({}).isRequired
}
