import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Amplitude } from '@amplitude/react-amplitude'

import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ListItemText from '@material-ui/core/ListItemText'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { makeStyles } from '@material-ui/core/styles'

import { string } from 'yup'
import formatFormHelperText from '../Utils/formatFormHelperText'
import { sanitizeNumber, sanitizeString } from '../Utils/sanitizeForInputValue'

const UNSELECTED = 'Seleccionar una opción...'
const NONE = 'Sin selección'

// Text validation schema
const schema = string().when(
  '$required',
  (required, s) => (required ? s.required('Completa este campo') : s) // Empty string doesn't pass
)

const useStyles = makeStyles(theme => ({
  label: {
    margin: theme.spacing(0, 2, 0.5, 0),
    lineHeight: 1.25
  },
  menuItem: {
    paddingTop: theme.spacing(1.875),
    paddingBottom: theme.spacing(1.875)
  },
  listItemText: {
    margin: 0
  },
  selectPaper: {
    marginTop: -1,
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    borderStyle: 'solid'
  },
  selectIcon: {
    color: theme.palette.primary.main
  }
}))

/**
Use a SingleSelect when you want the user to be able to pick an option from a dropdown list.

- Can work with string or number keys (using the Numerical flag). The value dispatched will be typed.
- The underlying input field change event is logged on the analytics platform.

### Events:
- Blur: only runs a validation on untouched fields
- Change: always runs a validation

### Validations:
- Needs a value, if required
- Value must be a valid option
*/

export default function SingleSelect({
  id,
  type,
  title,
  description,
  help,
  required,
  options,
  numerical,
  defaultValue,
  onUpdate,
  onValidation
}) {
  const classes = useStyles()

  // Local state for field validation
  // The DOM input field always uses string representation of numbers in attributes
  const [value, setValue] = useState(
    numerical ? sanitizeNumber(defaultValue) : sanitizeString(defaultValue)
  )
  const [validation, setValidation] = useState({
    error: undefined,
    message: undefined,
    dirty: false
  })

  // Validate using schema, set the validation
  const handleValidation = useCallback(
    v => {
      const validOps = numerical ? options.map(el => String(el.key)) : options.map(el => el.key) // Force any number into its string representation
      if (!required) validOps.push('')
      schema
        .oneOf(validOps, 'Selecciona una opción') // Extend the validation schema to test for valid options
        .validate(v, { context: { required } })
        .then(() => {
          setValidation({ error: false, message: undefined, dirty: true })
        })
        .catch(e => {
          setValidation({ error: true, message: e.message, dirty: true })
        })
    },
    [required, options, numerical]
  )

  // On change, set the new value and share it and always validate
  const handleChange = event => {
    const { value: val } = event.target
    setValue(val)
    onUpdate(id, numerical ? parseFloat(val) : val)
    handleValidation(val)
  }

  // On blur, trigger validation if it's the first interaction (a saved value was an interaction)
  const handleBlur = () => {
    if (!validation.dirty) handleValidation(value)
  }

  // Side-effect of new validation state: sync the context (skip if validation is yet undefined)
  useEffect(() => {
    if (typeof validation.error !== 'undefined') onValidation(id, !validation.error)
  }, [onValidation, id, validation.error])

  // Side-effect of having a default value
  // Validation will not run on unmatching types (uses the numerical boolean), and must be a healthy string/number
  // This handles validation of saved values, context must be aware of empty initial fields
  useEffect(() => {
    if (!numerical && sanitizeString(defaultValue)) handleValidation(defaultValue)
    if (numerical && sanitizeNumber(defaultValue)) handleValidation(defaultValue)
  }, [numerical, defaultValue, handleValidation])

  // Renders a label or falls back
  const renderSelectedValue = v => {
    const op = options.find(el => `${el.key}` === `${v}`) // See state init for why this is cast to string
    switch (v) {
      case '':
        return required ? UNSELECTED : ''
      default:
        return op ? op.label : UNSELECTED
    }
  }

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: type,
        fieldVariant: 'single-select',
        fieldLabel: title
      })}
    >
      {({ instrument }) => (
        <FormControl
          required={required}
          error={validation.error}
          fullWidth
          variant="outlined"
          component="fieldset"
          margin="normal"
        >
          <FormLabel required={false} className={classes.label} id={`label-${id}`} htmlFor={id}>
            {title}
          </FormLabel>
          <Select
            id={id}
            placeholder={description}
            value={value}
            MenuProps={{
              classes: { paper: classes.selectPaper },
              elevation: 4,
              MenuListProps: { disablePadding: true },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            }}
            IconComponent={ExpandMoreIcon}
            onChange={instrument('Interacted with form field', handleChange)}
            onBlur={handleBlur}
            displayEmpty
            renderValue={renderSelectedValue}
            classes={{ icon: classes.selectIcon }}
          >
            {!required && (
              <MenuItem key={`select-${id}-option-none`} value="" className={classes.menuItem}>
                <ListItemText className={classes.listItemText}>{NONE}</ListItemText>
              </MenuItem>
            )}
            {options.map(option => (
              <MenuItem
                key={`select-${id}-option-${option.key}`}
                value={`${option.key}`} // See state init for why this is cast to string
                className={classes.menuItem}
              >
                <ListItemText className={classes.listItemText} secondary={option.help || null}>
                  {option.label}
                </ListItemText>
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {formatFormHelperText(required, help, validation.message)}
          </FormHelperText>
        </FormControl>
      )}
    </Amplitude>
  )
}

SingleSelect.propTypes = {
  /**
    DBOID of the field.
  */
  id: PropTypes.string.isRequired,
  /**
    Type.
  */
  type: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Helper text.
  */
  help: PropTypes.string,
  /**
    Use this to indicate that the box must be checked.
  */
  required: PropTypes.bool,
  /**
    Array of options
  */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ).isRequired,
  /**
    Initial state of the field.
  */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
    Uses numerical values
  */
  numerical: PropTypes.bool,
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
   Handler to be called when a new validation needs to be shared
   @param {string} id - The id of the field.
   @param {bool} value - The validation result.
 */
  onValidation: PropTypes.func
}

SingleSelect.defaultProps = {
  description: null,
  help: null,
  required: false,
  defaultValue: undefined,
  numerical: false,
  onUpdate: () => {},
  onValidation: () => {}
}
