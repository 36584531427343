import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import InboxIcon from '@material-ui/icons/MoveToInbox'
import MailIcon from '@material-ui/icons/Mail'

import ProgressSidebar from '../Sidebar/ProgressSidebar'

const useStyles = makeStyles({
  list: {
    width: '75vw'
  }
})

export default function TemporaryDrawer({ open, handleClose }) {
  const classes = useStyles()

  return (
    <Drawer anchor="left" open={open} onClose={handleClose} classes={{ paper: classes.list }}>
      <ProgressSidebar />
    </Drawer>
  )
}
