import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import Typography from '@material-ui/core/Typography'

import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone'
import EventTwoToneIcon from '@material-ui/icons/EventTwoTone'

import { makeStyles } from '@material-ui/core/styles'

import DocumentTooltip from '../DocumentTooltip'

function fileSizeIEC(a, b, c, d, e) {
  return (
    ((b = Math), (c = b.log), (d = 1024), (e = (c(a) / c(d)) | 0), a / b.pow(d, e)).toFixed(2) +
    ' ' +
    (e ? 'KMGTPEZY'[--e] + 'iB' : 'Bytes')
  )
}

function fileSizeSI(a, b, c, d, e) {
  return (
    ((b = Math), (c = b.log), (d = 1e3), (e = (c(a) / c(d)) | 0), a / b.pow(d, e)).toFixed(2) +
    ' ' +
    (e ? 'kMGTPEZY'[--e] + 'B' : 'Bytes')
  )
}

const useStyles = makeStyles(({ spacing }) => ({
  section: {
    width: '100%',
    padding: spacing(0, 2),
    textAlign: 'center'
  },
  listIcon: {
    minWidth: 'auto',
    color: '#b0bec5',
    marginRight: spacing(0.5)
  }
}))

export default function DocumentFileMeta({ files }) {
  const classes = useStyles()

  const primaryLabel = files.length > 1 ? 'Múltiples ficheros' : files[0].name
  const secondaryLabel =
    files.length > 1
      ? fileSizeIEC(files.reduce((sum, element) => sum + element.size, 0))
      : fileSizeIEC(files[0].size)

  return (
    <section className={classes.section}>
      <Typography variant="subtitle2" gutterBottom color="textPrimary" noWrap>
        {primaryLabel}
      </Typography>
      <Typography component="p" variant="caption" className={classes.inline} color="textPrimary">
        {secondaryLabel}
      </Typography>
    </section>
  )
}
