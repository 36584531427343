import React, { useContext } from 'react'

import Helmet from 'react-helmet'

import amplitude from 'amplitude-js'
import { AmplitudeProvider, Amplitude } from '@amplitude/react-amplitude'

import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import DateFnsUtils from '@material-ui/pickers/adapter/date-fns'

import { ConfigContext } from './components/Config'
import { makeLocalTheme } from './components/Theme'

import { AuthProvider } from './components/Context/AuthContext'
import { LocaleProvider } from './components/Context/LocaleContext'
import { SchemaProvider, demoSchema } from './components/Context/SchemaContext'
import { LocationProvider } from './components/Context/LocationContext'
import { ProgressProvider } from './components/Context/ProgressContext'
import { AutosaveProvider } from './components/Context/AutosaveContext'

import {
  LandingPage,
  PublicPage,
  AuthPage,
  FormPage,
  SuccessPage,
  ErrorPage,
  HomePage,
  FieldsAndDocuments,
  Draft,
  Typography,
  DesktopLayout,
  TabletLayout,
  MobileLayout,
  MobileRemoteLayout,
  MobileNavigation,
  Validation,
  LocationSelector,
  AddressEdit,
  Submit,
  Post
} from './components/Pages'

const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_KEY
const amplitudeInstance = amplitude.getInstance()

const initialValidation = {
  values: {},
  validations: {}
}

function Analytics({ children }) {
  return amplitude ? (
    <AmplitudeProvider amplitudeInstance={amplitudeInstance} apiKey={amplitudeApiKey}>
      {children}
    </AmplitudeProvider>
  ) : (
    children
  )
}

function App() {
  const config = useContext(ConfigContext)
  const theme = makeLocalTheme(config)
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Registro electrónico</title>
      </Helmet>
      <Analytics>
        <Amplitude
          eventProperties={{
            scope: ['app'],
            name: 'Registro electrónico'
          }}
        >
          {/* Makes the theme available */}
          <MuiThemeProvider theme={theme}>
            {/* Makes the date utils available to pickers and the i18n utils */}
            <LocaleProvider>
              {/* Makes the auth data available, conditionally renders or redirects on error */}
              <AuthProvider>
                {/* Makes the procedure definition available, conditionally renders on error */}
                <SchemaProvider initialState={demoSchema}>
                  {/* Makes the values and progress available */}
                  <ProgressProvider initialState={initialValidation}>
                    {/* Makes the autosave utils available */}
                    <AutosaveProvider dateAdapter={DateFnsUtils}>
                      <Router>
                        {/* Makes the territory utils available, fetchs territory constants, conditionally renders on error */}
                        <LocationProvider>
                          <CssBaseline />
                          <Switch>
                            <Route exact path="/">
                              <HomePage />
                            </Route>
                            <Route path="/tramite/:pid/borrador/:did">
                              <Draft />
                            </Route>
                            {/* steps */}
                            <Route path="/tramite/:pid/formulario/:step">
                              <FormPage />
                            </Route>
                            {/* respuesta del proveedor de identidad, carga de las secciones necesarias */}
                            <Route path="/tramite/:pid/formulario">
                              <AuthPage title="Resolviendo identidad y formulario" />
                            </Route>
                            {/* presentación */}
                            <Route path="/tramite/:pid/presentacion">
                              <LandingPage title="Confirmar presentación" />
                            </Route>
                            {/* justificante */}
                            <Route path="/tramite/:pid/justificante">
                              <SuccessPage title="Confirmar presentación" />
                            </Route>
                            {/* info pública, previo a autenticar y comenzar a tramitar */}
                            <Route path="/tramite/:pid">
                              <PublicPage title="Registro electrónico" />
                            </Route>
                            <Route path="/api">
                              <FieldsAndDocuments />
                            </Route>
                            <Route path="/typography">
                              <Typography />
                            </Route>
                            <Route path="/layouts/desktop/:step">
                              <FormPage />
                            </Route>
                            <Route path="/layouts/desktop">
                              <DesktopLayout />
                            </Route>
                            <Route path="/layouts/tablet/:pid">
                              <TabletLayout />
                            </Route>
                            <Route path="/layouts/tablet">
                              <TabletLayout />
                            </Route>
                            <Route path="/layouts/mobile/:pid">
                              <MobileRemoteLayout />
                            </Route>
                            <Route path="/layouts/mobile">
                              <MobileLayout />
                            </Route>
                            <Route path="/nav/mobile">
                              <MobileNavigation />
                            </Route>
                            <Route path="/validation">
                              <Validation />
                            </Route>
                            <Route path="/location">
                              <LocationSelector />
                            </Route>
                            <Route path="/address">
                              <AddressEdit />
                            </Route>
                            <Route path="/submit">
                              <Submit />
                            </Route>
                            <Route path="/post">
                              <Post />
                            </Route>
                            <Route path="*">
                              <ErrorPage />
                            </Route>
                          </Switch>
                        </LocationProvider>
                      </Router>
                    </AutosaveProvider>
                  </ProgressProvider>
                </SchemaProvider>
              </AuthProvider>
            </LocaleProvider>
          </MuiThemeProvider>
        </Amplitude>
      </Analytics>
    </>
  )
}

export default App
