import React from 'react'
import PropTypes from 'prop-types'

import Lottie from 'react-lottie'
import animationData from './LaptopBrokenNeutral.json'

export default function UncontrolledLottie({ style }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  return <Lottie options={defaultOptions} height={400} width={400} style={style} />
}

UncontrolledLottie.propTypes = {
  style: PropTypes.shape({})
}

UncontrolledLottie.defaultProps = {
  style: {}
}
