import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import blueGrey from '@material-ui/core/colors/blueGrey'

const useStyles = makeStyles(theme => ({
  footerSection: {
    padding: theme.spacing(1, 2),
    // paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
    // backgroundColor: blueGrey[50],
    // Tablet or smaller
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(4, 2),
      marginLeft: 0
    }
  }
}))

const FooterSection = React.forwardRef(function FooterSection({ children, className }, ref) {
  const classes = useStyles()
  return (
    <Container
      maxWidth="lg"
      component="section"
      className={clsx(classes.footerSection, className)}
      ref={ref}
    >
      {children}
    </Container>
  )
})

export default FooterSection

FooterSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

FooterSection.defaultProps = {
  className: null
}
