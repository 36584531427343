import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
//import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Zoom from '@material-ui/core/Zoom'
import Grow from '@material-ui/core/Grow'
import Fade from '@material-ui/core/Fade'

import Drawer from '@material-ui/core/Drawer'
import Badge from '@material-ui/core/Badge'

import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined'
import CloudDoneTwoToneIcon from '@material-ui/icons/CloudDoneTwoTone'

// Rail icons
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone'

import InterestedPartyChip, { HeaderPartyChip } from './InterestedPartyChip'

import Fab from '@material-ui/core/Fab'
import CheckIcon from '@material-ui/icons/Check'
import Link from '@material-ui/core/Link'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ImageIcon from '@material-ui/icons/Image'
import WorkIcon from '@material-ui/icons/Work'
import BeachAccessIcon from '@material-ui/icons/BeachAccess'

//import useScrollTrigger from '@material-ui/core/useScrollTrigger'
import useScrollTrigger from './useScrollTrigger'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles } from '@material-ui/core/styles'

import { useDocuments, useFields } from '../../Api'

// Layout blocks
import {
  AppBar,
  AppBarMenu,
  AppBarHeading,
  AppBarActions,
  Header,
  Content,
  Footer,
  Sidebar,
  ContentSection,
  ContentSubSection,
  FooterSection
} from '../Layout'

// Form layout blocks
import { NavigationButtons, PrevNavigationButton, NavigationBar, NavigationRail } from '../Form'

// Content blocks
import ContentFields from '../Content/Fields'
import ProgressSidebar from '../Sidebar/ProgressSidebar'
import PartiesSidebar from '../Sidebar/PartiesSidebar'
import ProgressSteps from './ProgressSteps'
import AutosaveBar from '../Autosave/AutosaveBar'

import Document from '../../../lib/Document'
import Field from '../../../lib/Fields'

const definition = {
  fields: [
    { id: 'iban1', type: 'bank-account', title: 'First bank account' },
    { id: 'iban2', type: 'bank-account', title: 'Second bank account', required: true },
    {
      id: 'iban3',
      type: 'bank-account',
      title: 'Third bank account',
      defaultValue: 'ES55 0182 1422 9535 4562 6518a'
    },
    {
      id: 'iban4',
      type: 'bank-account',
      title: 'Fourth bank account',
      required: true,
      defaultValue: 'ES8421006190606121734440'
    },
    {
      id: 'iban5',
      type: 'bank-account',
      title: 'Fifth bank account',
      required: true,
      defaultValue: undefined
    }
  ]
}

const debugHighlighter = 'white' //'rgba(207, 232, 252, 0.5)'

const useStyles = makeStyles(theme => ({
  section: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    marginTop: 64,
    marginLeft: 84 // tablet
  },
  appBar: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.grey[300],
    zIndex: theme.zIndex.drawer + 1
  },
  mainDiv: {
    width: 'calc(100% - 600px)',
    minHeight: 2000
  },
  sidebar: {
    minWidth: 225, // <-tablet '300px',
    flexGrow: 1,
    marginTop: '25vh'
  },
  sidebarLeft: {
    position: 'sticky',
    top: 64,
    marginLeft: 'auto',
    marginRight: 0,
    maxWidth: 225, // <-tablet '300px',
    paddingLeft: 0 // tablet
  },
  sidebarRight: {
    position: 'sticky',
    top: 64,
    marginLeft: 0,
    marginRight: 'auto',
    maxWidth: '300px'
  },
  titleSection: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(2) //tablet
  },
  saveSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  demo: {
    background: debugHighlighter
  },
  container: {
    background: debugHighlighter,
    padding: theme.spacing(6)
  },
  headerContainer: {
    background: debugHighlighter,
    padding: theme.spacing(6),
    paddingBottom: 0
  },
  bodyContainer: {
    background: debugHighlighter,
    padding: theme.spacing(6)
    //paddingTop: 0
  },
  absoluteContainer: {
    background: 'transparent',
    padding: theme.spacing(6),
    position: 'sticky',
    top: '35vh'
  },
  centeredContainer: {
    background: debugHighlighter,
    //paddingTop: theme.spacing(4),
    //paddingBottom: theme.spacing(4),
    minHeight: '120px'
  },
  fixedContainer: {
    position: 'sticky',
    top: '72px'
  },
  leftalignedContainer: {
    background: debugHighlighter,
    //paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginLeft: 0,
    minHeight: '120px'
  },
  tabs: {
    marginTop: theme.spacing(6),
    //position: 'sticky',
    //top: 0,
    background: theme.palette.common.white
    //zIndex: 10
  },
  elevatedTabs: {
    background: theme.palette.common.white
  },
  pageHeading: {
    marginTop: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2)
  },
  groupHeading: {},
  groupDivider: {
    margin: theme.spacing(6)
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4)
  },
  mainGridItem: {
    maxWidth: '960px'
  },
  heroDivider: {
    height: '2px',
    background: theme.palette.common.black
  },
  footer: {
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[100]
  },
  footerContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  },
  //Tablet extras
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    width: theme.spacing(4) + 1
  },
  drawerPaper: {
    padding: theme.spacing(10, 1.5, 0, 1.5)
  },
  drawerButton: {
    marginBottom: theme.spacing(3)
  }
}))

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      Copyright ©
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

function Links() {
  return (
    <Typography variant="body2" color="textSecondary">
      Follow{' '}
      <Link color="inherit" href="https://material-ui.com/">
        some
      </Link>{' '}
      <Link color="inherit" href="https://material-ui.com/">
        useful
      </Link>{' '}
      <Link color="inherit" href="https://material-ui.com/">
        links
      </Link>
      {'.'}
    </Typography>
  )
}

function ShowBreakPoint() {
  const sm = useMediaQuery(theme => theme.breakpoints.up('sm'))
  const md = useMediaQuery(theme => theme.breakpoints.up('md'))
  const lg = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const xl = useMediaQuery(theme => theme.breakpoints.up('xl'))
  if (xl) return 'xl'
  if (lg) return 'lg'
  if (md) return 'md'
  if (sm) return 'sm'
  return 'xs'
}

export default function Page() {
  const classes = useStyles()
  const { pid } = useParams()

  // Used by the AppBar (TO BE MOVED IN) to hide its divider on scroll
  const windowScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 64
  })

  // Used by ProgressSidebar (TO BE MOVED IN) to hide itself
  // Used by PartiesSidebar (ref and trigger moved) to hide its divider
  const progressNavigationRef = useRef()
  const progressNavigationVisible = useScrollTrigger({
    disableHysteresis: true,
    threshold: progressNavigationRef.current
      ? progressNavigationRef.current.offsetTop - window.innerHeight
      : null
  })

  // Used by ProgressNavigation (TO BE MOVED IN) to show a divider below the next/prev buttons
  const footerRef = useRef()
  const footerVisible = useScrollTrigger({
    disableHysteresis: true,
    threshold: footerRef.current ? footerRef.current.offsetTop - window.innerHeight + 180 : null
  })

  // Progress drawer control
  const [progressDrawerOpen, setProgressDrawerOpen] = React.useState(false)
  const handleDrawerOpen = () => {
    setProgressDrawerOpen(true)
  }
  const handleDrawerClose = () => {
    setProgressDrawerOpen(false)
  }

  return (
    <>
      <AutosaveBar />

      <AppBar>
        <Toolbar>
          <AppBarMenu />
          <AppBarHeading>
            <Typography variant="h6" noWrap>
              Registro electrónico
            </Typography>
          </AppBarHeading>
          <AppBarActions>
            <IconButton aria-label="save">
              <CloudDoneTwoToneIcon />
            </IconButton>
            <HeaderPartyChip />
          </AppBarActions>
        </Toolbar>
        <Grow
          in={!windowScrolled}
          timeout={350}
          style={{ transformOrigin: 'top left', transitionDelay: '100ms' }}
        >
          <Divider className={classes.heroDivider} />
        </Grow>
      </AppBar>

      <Header>
        <Typography variant="h2" gutterBottom>
          Datos de la solicitud
        </Typography>
        <Typography variant="h4" color="textSecondary" paragraph>
          Presentación por los residentes en España de las declaraciones sobre las transacciones
          económicas y los saldos de activos y pasivos financieros con el exterior (formulario ETE)
        </Typography>
      </Header>

      <Content>
        <ContentSection>
          <Typography variant="body1" color="textSecondary" paragraph>
            Por favor, completa los campos neceasarios.
          </Typography>

          <ContentFields />

          <ContentSubSection ref={progressNavigationRef}>
            <NavigationButtons />
            <Grow in={footerVisible} timeout={350} style={{ transformOrigin: 'top left' }}>
              <Divider className={classes.heroDivider} />
            </Grow>
          </ContentSubSection>
        </ContentSection>

        <Sidebar position="right" component="nav">
          <Fade in timeout={350}>
            <Container className={classes.centeredContainer}>
              <PartiesSidebar ref={progressNavigationRef} />
            </Container>
          </Fade>
        </Sidebar>
      </Content>

      <Zoom in={true}>
        <section className={clsx('mui-fixed', classes.fab)}>
          <Fab variant="extended" color="primary" aria-label="add">
            <CheckIcon className={classes.extendedIcon} />
            Presentar
          </Fab>
        </section>
      </Zoom>

      <NavigationRail />

      <Footer ref={footerRef}>
        <FooterSection>
          <ProgressSteps />
        </FooterSection>
        <FooterSection>
          <Typography variant="h6">Registro electrónico</Typography>
          <Typography variant="caption" paragraph>
            Ayuntamiento de Benalmádena
          </Typography>
          <Copyright />
          <Links />
        </FooterSection>
      </Footer>
    </>
  )
}
