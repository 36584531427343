import React from 'react'

import esLocale from 'date-fns/locale/es'
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns'
import { LocalizationProvider } from '@material-ui/pickers'

function LocaleProvider(props) {
  const { children } = props
  console.log('localeprovider render')
  return (
    <LocalizationProvider dateAdapter={DateFnsUtils} locale={esLocale}>
      {children}
    </LocalizationProvider>
  )
}

function useLocaleContext() {}

export { useLocaleContext, LocaleProvider }
