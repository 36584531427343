import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import {
  Grid,
  TextField,
  OutlinedInput,
  FormLabel,
  FormHelperText,
  FormControl,
  Paper,
  useFormControl,
  Chip,
  Tooltip,
  Typography,
  Divider,
  Grow,
  Collapse
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import RenderCounter from '../Utils/RenderCounter'

import Skeleton from '@material-ui/lab/Skeleton'

import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const usePaperStyles = makeStyles(theme => ({
  paper: {
    //padding: theme.spacing(1, 1.5),
    //paddingBottom: 2,
    //display: 'flex',
    borderWidth: 2,
    borderColor: 'rgba(90, 100, 135, 0.5)',
    boxSizing: 'border-box',
    '&:hover': {
      borderColor: theme.palette.common.black
    }
  },
  focused: {
    borderWidth: 2,
    borderBottomWidth: 2,
    borderColor: theme.palette.primary.light,
    //padding: `calc(${theme.spacing(1)}px - 0px) calc(${theme.spacing(1.5)}px - 0px)`,
    //paddingBottom: 1,
    '&:hover': {
      borderColor: theme.palette.primary.light
    }
  }
}))

function FormPaper(props) {
  const { focused, variant } = useFormControl()
  const { children, ...rest } = props
  const classes = usePaperStyles()
  return (
    <Paper
      {...{ focused, variant }}
      {...rest}
      className={clsx({ [classes.paper]: true, [classes.focused]: focused })}
    >
      {children}
    </Paper>
  )
}
FormPaper.muiName = Paper.muiName

const useTextFieldStyles = makeStyles(theme => ({
  textField: {
    marginTop: theme.spacing(1)
  },
  middleToLastGroupedTextField: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginTop: -1
    }
  },
  root: {
    '&$focus $firstOutline': {
      [theme.breakpoints.up('sm')]: {
        borderRightWidth: 0
      },
      [theme.breakpoints.down('xs')]: {
        borderBottomWidth: 0
      }
    },
    '&$focus $middleOutline': {
      [theme.breakpoints.up('sm')]: {
        borderLeftWidth: 0,
        borderRightWidth: 0
      },
      [theme.breakpoints.down('xs')]: {
        borderTopWidth: 0,

        borderBottomWidth: 0
      }
    },
    '&$focus $lastOutline': {
      [theme.breakpoints.up('sm')]: {
        borderLeftWidth: 0
      },
      [theme.breakpoints.down('xs')]: {
        borderTopWidth: 0
      }
    }
  },
  firstOutline: {
    [theme.breakpoints.up('sm')]: {
      borderRightColor: 'transparent',
      borderRightWidth: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderBottomColor: 'transparent',
      borderBottomWidth: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0
    }
  },
  lastOutline: {
    [theme.breakpoints.up('sm')]: {
      borderLeftColor: 'transparent',
      borderLeftWidth: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderTopColor: 'transparent',
      borderTopWidth: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    }
  },
  middleOutline: {
    [theme.breakpoints.up('sm')]: {
      borderLeftColor: 'transparent',
      borderLeftWidth: 0,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderRightColor: 'transparent',
      borderRightWidth: 0,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    },
    [theme.breakpoints.down('xs')]: {
      borderTopColor: 'transparent',
      borderTopWidth: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      borderBottomColor: 'transparent',
      borderBottomWidth: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0
    }
  },
  focus: {} // pseudo-class to be used as a selector
}))

const FormTextField = React.forwardRef(function FormTextField(props, ref) {
  const { onFocus, onBlur } = useFormControl()
  const { children, first, last, middle, InputProps, error, ...rest } = props
  const classes = useTextFieldStyles()
  return (
    <TextField
      ref={ref}
      error={error}
      className={clsx(classes.textField, {
        [classes.middleToLastGroupedTextField]: middle || last
      })}
      InputProps={{
        endAdornment: error && <ClearIcon color="error" />,
        ...InputProps,
        inputProps: { ...{ onFocus, onBlur } },
        classes: {
          root: classes.root,
          focused: classes.focus,
          notchedOutline: clsx({
            [classes.firstOutline]: first,
            [classes.middleOutline]: middle,
            [classes.lastOutline]: last
          })
        }
      }}
      InputLabelProps={{ shrink: true }}
      {...rest}
    >
      {children}
    </TextField>
  )
})
FormTextField.muiName = TextField.muiName

const useTooltipStyles = makeStyles(theme => ({
  arrow: {
    color: theme.palette.primary.main
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightLight
  }
}))

function FieldTooltip({ children, ...rest }) {
  const classes = useTooltipStyles()
  const mobile = useMediaQuery(theme => theme.breakpoints.down('xs'))

  return (
    <Tooltip
      arrow
      disableHoverListener
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      placement={mobile ? 'bottom-start' : 'right-start'}
      classes={classes}
      {...rest}
    >
      <div>{children}</div>
    </Tooltip>
  )
}
FieldTooltip.muiName = Tooltip.muiName

export { FormPaper, FormTextField, FieldTooltip }
