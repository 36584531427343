import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles(theme => ({
  typography: {
    //padding: theme.spacing(2)
  },
  paper: {
    //padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    minWidth: theme.breakpoints.values.sm / 2,
    maxWidth: (2 * theme.breakpoints.values.sm) / 3,
    borderRadius: theme.spacing(2)
  }
}))

export default function SimplePopover({ anchorEl, handleClose }) {
  const classes = useStyles()
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      PaperProps={{ classes: { root: classes.paper } }}
    >
      <Typography className={classes.typography} variant="h4">
        The content of the Popover.
      </Typography>
      <Button onClick={handleClose}>Close</Button>
    </Popover>
  )
}

function ControlledPopover({ anchorEl, open, handleClose, children }) {
  const classes = useStyles()
  const id = open ? 'simple-popover' : undefined

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      PaperProps={{ classes: { root: classes.paper } }}
    >
      {children}
    </Popover>
  )
}

export { ControlledPopover }
