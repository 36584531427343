import React, { useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Amplitude } from '@amplitude/react-amplitude'

import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import FormHelperText from '@material-ui/core/FormHelperText'

import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { string } from 'yup'
import formatFormHelperText from '../Utils/formatFormHelperText'
import { sanitizeString } from '../Utils/sanitizeForInputValue'

// Text validation schema
const schema = string().when('$required', (required, s) =>
  required ? s.required('Completa este campo') : s
) // Empty string doesn't pass

const useStyles = makeStyles(theme => ({
  label: {
    margin: theme.spacing(0, 2, 0.5, 0),
    lineHeight: 1.25
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75)
  },
  formHelperTextWithMessage: {
    marginBottom: theme.spacing(2) + 3
  }
}))

/**
Use a Textarea when you want the user to input a long body of text.

- Free textarea input field.
- Starts with 3 lines (6 on mobile) and grows up to 10 lines of text before showing a scrollbar.
- Blur events on the underlying input field are logged on the analytics platform.

### Events:
- Blur: always runs a validation
- Change: only runs a validation if we were on error state or if we clear the field

### Validations:
- Needs a value, if required
*/

export default function Textarea({
  id,
  title,
  description,
  help,
  required,
  defaultValue,
  onUpdate,
  onValidation
}) {
  const classes = useStyles()
  const mobile = useMediaQuery(theme => theme.breakpoints.down('md'))

  // Local state for field value and validation
  const [value, setValue] = useState(sanitizeString(defaultValue))
  const [validation, setValidation] = useState({
    error: undefined,
    message: undefined,
    dirty: false
  })

  // Validate using schema, set the validation
  const handleValidation = useCallback(
    v => {
      schema
        .validate(v, { context: { required } })
        .then(() => {
          setValidation({ error: false, message: undefined, dirty: !!v }) // !!v casts to boolean, dirty is used to flag a test with/without value
        })
        .catch(e => {
          setValidation({ error: true, message: e.message, dirty: !!v })
        })
    },
    [required]
  )

  // On change, set the new value and share it but only validate if we had an incorrect value or if we clear the field
  const handleChange = event => {
    setValue(event.target.value)
    onUpdate(id, event.target.value)
    if (validation.error || !event.target.value) handleValidation(event.target.value)
  }

  // On blur, trigger validation
  const handleBlur = event => {
    handleValidation(event.target.value)
  }

  // Side-effect of the validation: sync the context (skip if validation is yet undefined)
  useEffect(() => {
    if (typeof validation.error !== 'undefined') onValidation(id, !validation.error)
  }, [onValidation, id, validation.error])

  // Side-effect of having a default value
  // Validation runs only on strings, and must be a healthy value
  // This handles validation of saved values, context must be aware of empty initial fields
  useEffect(() => {
    if (sanitizeString(defaultValue)) handleValidation(defaultValue)
  }, [defaultValue, handleValidation])

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: 'textarea',
        fieldLabel: title
      })}
    >
      {({ instrument }) => (
        <FormControl
          required={required}
          error={validation.error}
          fullWidth
          variant="outlined"
          component="fieldset"
          margin="normal"
        >
          <FormLabel required={false} className={classes.label} id={`label-${id}`} htmlFor={id}>
            {title}
          </FormLabel>
          <OutlinedInput
            id={`${id}-test`}
            placeholder={description}
            aria-describedby={`${id}-test-label`}
            multiline
            value={value}
            required={required}
            rows={mobile ? 6 : 3}
            rowsMax="10"
            variant="outlined"
            onBlur={instrument('Interacted with form field', handleBlur)}
            onChange={handleChange}
          />
          <FormHelperText
            className={clsx(classes.formHelperText, {
              [classes.formHelperTextWithMessage]: !required || help
            })}
          >
            {formatFormHelperText(required, help, validation.message)}
          </FormHelperText>
        </FormControl>
      )}
    </Amplitude>
  )
}

Textarea.propTypes = {
  /**
    DBOID of the field.
  */
  id: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Helper text.
  */
  help: PropTypes.string,
  /**
    Use this to indicate that a value must be provided.
  */
  required: PropTypes.bool,
  /**
    Initial value of the field.
  */
  defaultValue: PropTypes.string,
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
 Handler to be called when a new validation needs to be shared
 @param {string} id - The id of the field.
 @param {bool} value - The validation result.
*/
  onValidation: PropTypes.func
}

Textarea.defaultProps = {
  description: null,
  help: null,
  required: false,
  defaultValue: undefined,
  onUpdate: () => {},
  onValidation: () => {}
}
