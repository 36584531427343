import React from 'react'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(6),
    padding: theme.spacing(1),
    textAlign: 'left'
  },
  toolbar: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderBottom: `2px solid ${theme.palette.common.black}`
  }
}))

export default function Page() {
  const classes = useStyles()
  return (
    <div>
      <AppBar position="static" color="transparent" elevation={0}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" className={classes.title}>
            Registro electrónico
          </Typography>
          <Typography variant="caption" className={classes.title}>
            Ayuntamiento de Benalmádena
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" className={classes.container}>
        <Typography variant="h1" gutterBottom>
          h1. Heading
        </Typography>
        <Typography variant="h2" gutterBottom>
          h2. Heading
        </Typography>
        <Typography variant="h3" gutterBottom>
          h3. Heading
        </Typography>
        <Typography variant="h4" gutterBottom>
          h4. Heading
        </Typography>
        <Typography variant="h5" gutterBottom>
          h5. Heading
        </Typography>
        <Typography variant="h6" gutterBottom>
          h6. Heading
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis
          tenetur
        </Typography>
        <Typography variant="body1" paragraph gutterBottom>
          body1. Apparently we had reached a great height in the atmosphere, for the sky was a dead
          black, and the stars had ceased to twinkle. By the same illusion which lifts the horizon
          of the sea to the level of the spectator on a hillside, the sable cloud beneath was dished
          out, and the car seemed to float in the middle of an immense dark sphere, whose upper half
          was strewn with silver. Looking down into the dark gulf below, I could see a ruddy light
          streaming through a rift in the clouds.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis
          tenetur
        </Typography>
        <Typography variant="body2" paragraph gutterBottom>
          body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur
          unde suscipit, quam beatae rerum inventore consectetur, neque doloribus, cupiditate
          numquam dignissimos laborum fugiat deleniti? Eum quasi quidem quibusdam.
        </Typography>
        <Typography variant="button" display="block" gutterBottom>
          button text
        </Typography>
        <Typography variant="caption" display="block" gutterBottom>
          caption text
        </Typography>
        <Typography variant="overline" display="block" gutterBottom>
          overline text
        </Typography>
      </Container>

      <Container maxWidth="sm" className={classes.container}>
        <Typography variant="h1" gutterBottom>
          Making the web more beautiful, fast, and open through great typography
        </Typography>
        <Typography variant="h4" gutterBottom>
          We believe the best way to bring personality and performance to websites and products is
          through great design and technology. Our goal is to make that process simple, by offering
          an intuitive and robust collection of open source designer web fonts. By using our
          extensive catalog, you can share and integrate typography into any design project
          seamlessly—no matter where you are in the world.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Discover Great Typography
        </Typography>
        <Typography variant="body2" gutterBottom>
          Our font catalog places typography front and center, inviting users to explore, sort, and
          test fonts for use in more than 135 languages. We showcase individual type designers and
          foundries, giving you valuable information about the people and their processes, as well
          as analytics on usage and demographics. Our series of thematic collections helps you
          discover new fonts that have been vetted and organized by our team of designers,
          engineers, and collaborators, and our default sort organizes fonts based on popularity,
          trends, and your geographic location. You can also create your own highly customized
          collections by filtering families, weights, and scripts, plus test color themes, and
          review sample copy. Collections can be shared, making it easy to collaborate on projects
          and ensure typography is optimized and streamlined throughout the design and engineering
          process.
        </Typography>
      </Container>

      <Container maxWidth="sm" className={classes.container}>
        <Typography variant="h6" gutterBottom>
          DESTACADO
        </Typography>
        <Typography variant="h4" gutterBottom>
          La versión preliminar para Android 11 ya está disponible
        </Typography>
        <Typography variant="body1" gutterBottom>
          Ya está disponible la Vista previa para desarrolladores de Android 11, que incluye
          protecciones de privacidad más eficientes, nuevas formas de atraer a los usuarios, mayor
          compatibilidad con dispositivos plegables, extensiones de Vulkan y mucho más. Comienza a
          preparar tus apps y envíanos tus comentarios para que el lanzamiento sea aún más sólido.
        </Typography>
      </Container>

      <Container maxWidth="sm" className={classes.container}>
        <Typography variant="subtitle1" gutterBottom>
          DESTACADO
        </Typography>
        <Typography variant="h4" gutterBottom>
          Podcast de apps, juegos y estadísticas
        </Typography>
        <Typography variant="body1" gutterBottom>
          La nueva serie de podcasts reúne las últimas estadísticas, historias y debates de expertos
          de la industria. Se incluyen temas como la participación responsable; el asesoramiento de
          expertos sobre fusiones, adquisiciones y capital de riesgo; y la privacidad y la
          accesibilidad.
        </Typography>
      </Container>
    </div>
  )
}
