import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

export default function SimpleDialog({ open, handleClose }) {
  const theme = useTheme()

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{'Diálogo'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Una olla de algo más vaca que carnero, salpicón las más noches, duelos y quebrantos los
          sábados, lantejas los viernes, algún palomino de añadidura los domingos, consumían las
          tres partes de su hacienda.
        </DialogContentText>
        <DialogContentText>
          El resto della concluían sayo de velarte, calzas de velludo para las fiestas, con sus
          pantuflos de lo mesmo, y los días de entresemana se honraba con su vellorí de lo más fino.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Mal
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Bien
        </Button>
      </DialogActions>
    </Dialog>
  )
}
