import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { useParams, useRouteMatch, Link } from 'react-router-dom'

import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import Divider from '@material-ui/core/Divider'
import BottomNavigation from '@material-ui/core/BottomNavigation'
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction'
import { makeStyles } from '@material-ui/core/styles'

import Grow from '@material-ui/core/Grow'
import Fade from '@material-ui/core/Fade'

import AdjustIcon from '@material-ui/icons/Adjust' // Current section
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked' // Section not completed
import CheckCircleIcon from '@material-ui/icons/CheckCircle' // Section completed
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined' // Section completed but on a future step

import { useSchemaDefinition } from '../../../Context/SchemaContext'
import { useProgressState } from '../../../Context/ProgressContext'

const useActionStyles = makeStyles(theme => ({
  navigationAction: {
    // height: 86,
    // minHeight: 86,
    width: 72,
    minWidth: 72,
    flex: 0,
    color: theme.palette.text.secondary,
    marginBottom: 4,
    marginTop: 4
  },
  label: {
    fontSize: theme.typography.pxToRem(10),
    '&$selected': {
      fontSize: theme.typography.pxToRem(10),
      fontWeight: 600
    }
  },
  selected: {}, // pseudo-class
  active: { color: theme.palette.primary.main },
  completed: { color: theme.palette.text.primary },
  disabled: { color: theme.palette.action.disabled }
}))

function NavigationRailAction({
  label,
  value,
  icon,
  path,
  active,
  completed,
  disabled,
  showLabel,
  className,
  delay,
  ...rest
}) {
  const classes = useActionStyles()

  return (
    <Grow in timeout={delay}>
      <BottomNavigationAction
        label={label}
        value={value}
        icon={icon}
        disabled={disabled}
        showLabel={showLabel}
        className={clsx(
          classes.navigationAction,
          {
            [classes.disabled]: disabled,
            [classes.active]: active,
            [classes.completed]: completed
          },
          className
        )}
        classes={{ label: classes.label, selected: classes.selected }}
        {...rest}
        component={Link}
        to={path}
      />
    </Grow>
  )
}

NavigationRailAction.propTypes = {
  completed: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  className: PropTypes.string,
  delay: PropTypes.number,
  showLabel: PropTypes.bool // injected by BottomNavigation
}

NavigationRailAction.defaultProps = {
  completed: false,
  active: false,
  disabled: false,
  className: null,
  delay: 0,
  showLabel: undefined
}

const useSeparatorStyles = makeStyles(theme => ({
  divider: {
    background: theme.palette.text.secondary
  },
  vertical: { height: theme.spacing(4), width: 2, margin: '0 auto' },
  active: {
    background: theme.palette.text.primary
  },
  completed: {
    background: theme.palette.text.primary
  },
  disabled: {
    background: theme.palette.action.disabled
  }
}))

function NavigationRailSeparator({ disabled, active, completed, className, delay }) {
  const classes = useSeparatorStyles()
  return (
    <Grow in timeout={delay}>
      <Divider
        orientation="vertical"
        className={clsx(
          classes.divider,
          {
            [classes.disabled]: disabled,
            [classes.selected]: active,
            [classes.completed]: completed
          },
          className
        )}
        classes={{ vertical: classes.vertical }}
      />
    </Grow>
  )
}

NavigationRailSeparator.propTypes = {
  completed: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  delay: PropTypes.number
}

NavigationRailSeparator.defaultProps = {
  completed: false,
  active: false,
  disabled: false,
  className: null,
  delay: 0
}

const useElementStyles = makeStyles(theme => ({}))

function NavigationRailElement({
  completed,
  past,
  active,
  disabled,
  title,
  path,
  first,
  value,
  selected,
  showLabel,
  delay
}) {
  const classes = useElementStyles()
  return (
    <>
      {!first && (
        <NavigationRailSeparator
          active={active}
          disabled={disabled}
          completed={completed}
          delay={delay}
        />
      )}
      <NavigationRailAction
        label={title}
        value={value}
        path={path}
        icon={
          <>
            {!completed && !active && (
              <Fade in>
                <RadioButtonUncheckedIcon fontSize="small" className={classes.icon} />
              </Fade>
            )}
            {!completed && active && (
              <Fade in>
                <AdjustIcon fontSize="small" className={classes.icon} />
              </Fade>
            )}
            {completed && past && (
              <Fade in>
                <CheckCircleIcon fontSize="small" className={classes.icon} />
              </Fade>
            )}
            {completed && !past && (
              <Fade in>
                <CheckCircleOutlinedIcon fontSize="small" className={classes.icon} />
              </Fade>
            )}
          </>
        }
        disabled={disabled}
        selected={selected}
        completed={completed}
        showLabel={showLabel}
        delay={delay}
      />
    </>
  )
}

NavigationRailElement.propTypes = {
  completed: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  first: PropTypes.bool,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool, // injected by BottomNavigation
  showLabel: PropTypes.bool, // injected by BottomNavigation
  delay: PropTypes.number
}

NavigationRailElement.defaultProps = {
  completed: false,
  active: false,
  disabled: false,
  first: false,
  selected: false,
  showLabel: undefined,
  delay: 0
}

const useStyles = makeStyles(theme => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    width: theme.spacing(4) + 1
  },
  drawerPaper: {
    padding: theme.spacing(8, 0, 0, 0),
    border: 'none'
  },
  navigation: {
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start',
    paddingTop: 16
  }
}))

const NavigationRail = React.forwardRef(function NavigationRail({ className }, ref) {
  const classes = useStyles()

  // Routing: step url parameter
  const fake = useRouteMatch('/tramite/:pid/formulario/:step')
  const { pid, step } = useParams()

  // Context: request definition
  const def = useSchemaDefinition()
  const currentIndex = Array.from(def).findIndex(el => el.step === step)
  const currentElement = Array.from(def)[currentIndex]

  // Context: request progress
  const state = useProgressState()
  const pendingIndex = Array.from(def).findIndex(
    el => el.isComplete(state.progress[el.type]) === false
  )

  return (
    <Hidden xsDown lgUp>
      <Drawer
        variant="permanent"
        anchor="left"
        className={clsx(classes.drawer, className)}
        classes={{
          paper: classes.drawerPaper
        }}
        ref={ref}
      >
        <BottomNavigation
          showLabels
          className={classes.navigation}
          value={currentElement.type}
          component="nav"
        >
          {Array.from(def).map((el, i, a) => (
            <NavigationRailElement
              key={`nav-element-${el.type}`}
              delay={100 * i}
              title={el.shortName}
              path={fake ? `/tramite/${pid}/formulario/${el.step}` : `/layouts/desktop/${el.step}`}
              value={el.type}
              active={i === currentIndex}
              past={i < currentIndex}
              first={i === 0}
              disabled={pendingIndex >= 0 ? i > pendingIndex : false}
              completed={
                el.isComplete(state.progress[el.type]) && (i < pendingIndex || pendingIndex === -1)
              }
            />
          ))}
          {/*<NavigationRailElement
            title="Presentación"
            value="submit"
            path={`/tramite/${pid}/presentacion`}
            disabled={pendingIndex >= 0}
            delay={(Array.from(def).length + 1) * 100}
          />*/}
        </BottomNavigation>
      </Drawer>
    </Hidden>
  )
})

export default NavigationRail

NavigationRail.propTypes = {
  className: PropTypes.string
}

NavigationRail.defaultProps = {
  className: null
}
