import React from 'react'
import clsx from 'clsx'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import ListItemText from '@material-ui/core/ListItemText'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import Hidden from '@material-ui/core/Hidden'

import NoEncryptionTwoToneIcon from '@material-ui/icons/NoEncryptionTwoTone'
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone'
import LiveHelpTwoToneIcon from '@material-ui/icons/LiveHelpTwoTone'
import EditAttributesTwoToneIcon from '@material-ui/icons/EditAttributesTwoTone'
import FeedbackTwoToneIcon from '@material-ui/icons/FeedbackTwoTone'

import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone'

import { makeStyles } from '@material-ui/core/styles'

const debugHighlighter = 'white' //'rgba(207, 232, 252, 0.5)'

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1, 2, 4),
    // Tablet or smaller
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1, 0, 4)
    }
  },
  heading: {
    marginBottom: theme.spacing(2)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}))

export default function ProgressNavigation() {
  const classes = useStyles()

  return (
    <Hidden xsDown>
      <section className={classes.root}>
        <Typography variant="h5" color="textPrimary" className={classes.heading} gutterBottom>
          Progreso
        </Typography>
        {/*<Typography variant="body1" color="textSecondary" paragraph>
          Presentación por los residentes en España de las declaraciones sobre las transacciones
          económicas y los saldos de activos y pasivos financieros con el exterior (formulario ETE)
  </Typography>*/}
        <Typography variant="body1" color="textSecondary" paragraph>
          Solicitud de actuación: queja o sugerencia
        </Typography>
        <Grid container spacing={3} justify="space-between" className={classes.container}>
          <Grid item xs>
            <FeedbackTwoToneIcon color="secondary" />
            <ListItemText
              primary={
                <Typography variant="h6" color="textPrimary" className={classes.itemHeading}>
                  Datos de la solicitud
                </Typography>
              }
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    Pendiente
                  </Typography>
                  {' — Tienes 8 campos por completar'}
                </>
              }
            />
          </Grid>
          <Grid item xs>
            <DescriptionTwoToneIcon />
            <ListItemText
              primary={
                <Typography variant="h6" color="textPrimary" className={classes.itemHeading}>
                  Documentos requeridos
                </Typography>
              }
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    Pendiente
                  </Typography>
                  {' — Tienes 3 documentos por aportar'}
                </>
              }
            />
          </Grid>
          <Grid item xs>
            <CheckCircleTwoToneIcon htmlColor="green" />
            <ListItemText
              primary={
                <Typography variant="h6" color="textPrimary" className={classes.itemHeading}>
                  Otra sección
                </Typography>
              }
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    Completada
                  </Typography>
                </>
              }
            />
          </Grid>
          <Grid item xs>
            <NoEncryptionTwoToneIcon color="error" />
            <ListItemText
              primary={
                <Typography variant="h6" color="textPrimary" className={classes.itemHeading}>
                  Pago de tasa
                </Typography>
              }
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    No disponible
                  </Typography>
                  {' — Por favor, completa las otra secciones antes de proceder al pago'}
                </>
              }
            />
          </Grid>
          <Grid item xs>
            <FeedbackTwoToneIcon />
            <ListItemText
              primary={
                <Typography variant="h6" color="textPrimary" className={classes.itemHeading}>
                  Quinta sección
                </Typography>
              }
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    Pendiente
                  </Typography>
                  {' — Tienes algo por hacer'}
                </>
              }
            />
          </Grid>
        </Grid>
      </section>
    </Hidden>
  )
}
