import React, { useEffect, useState } from 'react'

import axios from 'axios'

import delay from 'lodash/delay'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { constant } from 'lodash'

const { CancelToken } = axios

// CONSTANT VALUES management

export default function useConstants() {
  const [results, setResults] = useState({})
  const [isLoading, setIsLoading] = useState(true) // to prevent rendering initially with loading false, bc loading is used to conditionally render
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const source = CancelToken.source()
    const fetchRequest = async () => {
      setIsError(false)
      setIsLoading(true)
      setResults({})
      try {
        const result = await axios(`https://10.49.95.50:8746/sta/api/v1/bdtConstants`, {
          cancelToken: source.token
        })
        delay(() => {
          setResults(result.data)
          setIsLoading(false)
        }, 0) // just to test the loading state
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('constants request was cancelled by cleanup')
        } else {
          setIsError(true)
          setResults({})
          setIsLoading(false)
        }
      }
    }

    fetchRequest()
    return () => {
      source.cancel('constants fetch canceled')
    }
  }, [])

  return [results, isLoading, isError]
}

function getDefaultLocation(constants) {
  return constants.defaultValues
}

function getStreetTypes(constants) {
  return constants.acronyms
}

function getDefaultStreetType(constants) {
  return constants.acronyms?.find(el => el.key === 'CALLE') // provisional
}

function getNumerationTypes(constants) {
  return constants.nums
}

function getDefaultNumerationType(constants) {
  return constants.nums?.find(el => el.key === 'NUM')
}

function getDuplicates(constants) {
  return [
    { key: 'A', label: 'A' },
    { key: 'B', label: 'B' }
  ]
}
function getInteriorAccessTypes(constants) {
  const interiorAcessTypes = [{ key: '', label: 'Ninguno' }, ...constants.blocks]
  return interiorAcessTypes
}
function getFloors(constants) {
  return constants.floors
}
function getStairs(constants) {
  return constants.stairs
}
function getDoors(constants) {
  return constants.doors
}

// COUNTRY

function useCountryLookup(searchTerm) {
  const [results, setResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const source = CancelToken.source()
    const fetchRequest = async req => {
      setIsError(false)
      setIsLoading(true)
      setResults([])
      try {
        const result = await axios(`https://10.49.95.50:8746/sta/api/v1/bdtUtils?country=${req}`, {
          cancelToken: source.token
        })
        delay(() => {
          setResults(result.data)
          setIsLoading(false)
        }, 0) // just to test the loading state
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('country request was cancelled by cleanup')
        } else {
          setIsError(true)
          setResults([])
          setIsLoading(false)
        }
      }
    }

    // if (searchTerm) fetchRequest(searchTerm)
    fetchRequest(searchTerm)
    return () => {
      source.cancel('country fetch canceled')
    }
  }, [searchTerm])

  return [results, isLoading, isError]
}

const getCountryOptionLabel = option => option.name || ''
const getCountryOptionSelected = (option, value) => option.dboid === value.dboid
const getCountryOptionId = option => option?.dboid
const generateCountryObject = name =>
  name
    ? {
        dboid: undefined,
        name: name.toUpperCase(),
        provcoded: false,
        regcoded: false
      }
    : {}

// PROVINCE

function useProvinceLookup(searchTerm, countryId) {
  const [results, setResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const source = CancelToken.source()
    const fetchRequest = async req => {
      setIsError(false)
      setIsLoading(true)
      setResults([])
      try {
        const result = await axios(
          `https://10.49.95.50:8746/sta/api/v1/bdtUtils?country=${countryId}&province=${req}`,
          {
            cancelToken: source.token
          }
        )
        delay(() => {
          setResults(result.data)
          setIsLoading(false)
        }, 0) // just to test the loading state
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('province request was cancelled by cleanup')
        } else {
          setIsError(true)
          setResults([])
          setIsLoading(false)
        }
      }
    }

    if (countryId) fetchRequest(searchTerm)

    return () => {
      source.cancel('province fetch canceled')
    }
  }, [searchTerm, countryId])

  return [results, isLoading, isError]
}

const getProvinceOptionLabel = option => option.name || ''
const getProvinceOptionSelected = (option, value) => option.dboid === value.dboid
const getProvinceOptionId = option => option?.dboid
const generateProvinceObject = name =>
  name
    ? {
        dboid: undefined,
        name: name.toUpperCase(),
        muncoded: false
      }
    : {}

// MUNICIPALITY

function useMunicipalityLookup(searchTerm, provinceId) {
  const [results, setResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const source = CancelToken.source()
    const fetchRequest = async req => {
      setIsError(false)
      setIsLoading(true)
      setResults([])
      try {
        const result = await axios(
          `https://10.49.95.50:8746/sta/api/v1/bdtUtils?province=${provinceId}&municipality=${req}`,
          {
            cancelToken: source.token
          }
        )
        delay(() => {
          setResults(result.data)
          setIsLoading(false)
        }, 0) // just to test the loading state
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log('municipality request was cancelled by cleanup')
        } else {
          setIsError(true)
          setResults([])
          setIsLoading(false)
        }
      }
    }

    if (provinceId) fetchRequest(searchTerm)

    return () => {
      source.cancel('municipality fetch canceled')
    }
  }, [searchTerm, provinceId])

  return [results, isLoading, isError]
}

const getMunicipalityOptionLabel = option => option.name || ''
const getMunicipalityOptionSelected = (option, value) => option.dboid === value.dboid
const getMunicipalityOptionId = option => option?.dboid
const generateMunicipalityObject = name =>
  name
    ? {
        dboid: undefined,
        name: name.toUpperCase(),
        stdcoded: false,
        stcodfa: 'U'
      }
    : {}
//const getMunicipalityOptionAddressType = option => (option?.stcoded ? option.stcodfa : 'U')
const getMunicipalityOptionAddressType = option => {
  if (option?.name === 'CAMBRILS') return 'S'
  return option?.stcoded ? option.stcodfa : 'U'
}
/*
S: All addresses have streets coded
N: Addresses may have non-coded streets. By default new addresses have non-coded streets 
X: Addresses may have non-coded streets. By default new addresses have coded streets
U: All addresses have non-coded streets. --> this is not in the data model
*/

// STREET

// ACCESS

function useAccessLookup(searchTerm) {
  const [accesses, setAccesses] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const fetchRequest = async req => {
      setIsError(false)
      setIsLoading(true)
      try {
        const result = await axios(
          `https://10.49.95.50:8746/sta/api/v1/accessSearch/101700200000645500001?access=${req}`
        )
        delay(() => {
          // if (result.data.length > 0) setAccesses(result.data)
          setAccesses(result.data)
        }, 0) // just to test the loading state
      } catch (error) {
        setAccesses([])
        setIsError(true)
      }
      delay(() => setIsLoading(false), 300) // 250
    }

    if (searchTerm) fetchRequest(searchTerm)
  }, [searchTerm])

  return [accesses, isLoading, isError]
}

function formatAccessOptionNumeration(access) {
  const { num1, num2, dupli1, dupli2 } = access
  return num2 > num1
    ? `${num1}${isEmpty(dupli1.trim()) ? '' : ` ${dupli1}`} - ${num2}${
        isEmpty(dupli2.trim()) ? '' : ` ${dupli2}`
      }`
    : `${num1}`
}

function formatAccessOptionType(access) {
  const { indkm, km } = access
  switch (indkm) {
    case 'K': // Kilometro
      return `KM ${parseFloat(km).toFixed(2)}`
    case 'M': // Manzana
      return `MANZANA ${km}`
    case 'P': // Parcela rústica
      return `PARCELA RÚSTICA ${km}`
    default:
      // Números
      return formatAccessOptionNumeration(access)
  }
}

function formatAccessOptionDetails(access) {
  const { indblock, fblock, toponymy } = access
  switch (indblock) {
    case 'B': // Bloque
      return `Bloque ${fblock}`
    case 'P': // Polígono
      return `Polígono ${fblock}`
    case 'V': // Barraca
      return `Barraca ${fblock}`
    case 'O': // Portal
      return `Portal ${fblock}`
    case 'X': // Bloque-Portal
      return `Bloque-Portal ${fblock}`
    case 'L': // Lote (México)
      return `Lote ${fblock}`
    default:
      return ''
  }
}

const getAccessOptionLabel = option =>
  option ? `${option.street.acronym} ${option.street.stname} ${formatAccessOptionType(option)}` : ''

const getAccessOptionInputLabel = option =>
  option ? `${option.street.stname} ${formatAccessOptionType(option)}` : ''

const getAccessOptionSecondaryLabel = option =>
  option ? `${formatAccessOptionDetails(option)}` : ''

const getAccessOptionSelected = (option, value) => option.dboid === value.dboid

const getAccessInputLabel = (street, access) =>
  `${street.name} ${formatAccessOptionType(access.entity)}`

// ADDRESS management

const emptyAddress = {
  street: null,
  access: null,
  properties: null
}

function updateCountry(country) {
  return country
}

function updateProvince(province) {
  const filtered = omit(province, 'country')
  return filtered
}

function updateMunicipality(municipality) {
  const filtered = omit(municipality, 'province', 'region')
  return filtered
}

function updateLocation(newLocation) {
  return newLocation
}

function updateAccess(prevAddress, newAccess, constants) {
  return {
    ...prevAddress,
    street: {
      id: newAccess.street.dboid,
      type: getStreetTypes(constants).find(el => el.key === newAccess.street.acronym),
      name: newAccess.street.stname,
      entity: omit(newAccess, 'municipality')
    },
    access: {
      id: newAccess.dboid,
      type:
        newAccess.indkm !== ' '
          ? getNumerationTypes(constants).find(el => el.key === newAccess.indkm)
          : getNumerationTypes(constants).find(el => el.key === 'NUM'),
      value:
        newAccess.indkm !== ' '
          ? newAccess.km
          : {
              start: newAccess.num1,
              end: newAccess.num2,
              startDuplicate: newAccess.dupli1,
              endDuplicate: newAccess.dupli2
            },
      interiorAccess:
        newAccess.indblock !== ' '
          ? {
              type: getInteriorAccessTypes(constants).find(el => el.key === newAccess.indblock),
              value: newAccess.fblock
            }
          : { type: undefined, value: undefined },

      toponym: newAccess.toponimy,
      entity: omit(newAccess, 'street')
    },
    properties: {}
  }
}

function updateStreetType(prevAddress, type) {
  return {
    ...prevAddress,
    street: { ...prevAddress.street, type }
  }
}

function updateStreetName(prevAddress, name) {
  return {
    ...prevAddress,
    street: { ...prevAddress.street, name }
  }
}

function updateNumerationType(prevAddress, type) {
  return {
    ...prevAddress,
    access: { ...prevAddress.access, value: undefined, type }
  }
}

function updateNumerationValue(prevAddress, type, value) {
  return {
    ...prevAddress,
    access: {
      ...prevAddress.access,
      value:
        type === 'NUM'
          ? { start: value, end: '0', startDuplicate: undefined, endDuplicate: undefined }
          : value
    } // provisional implementation
  }
}

function updateDuplicate(prevAddress, value) {
  return {
    ...prevAddress,
    access: { ...prevAddress.access, duplicate: value } // provisional implementation (and pending normalization)
  }
}

function updateInteriorAccessType(prevAddress, type) {
  const {
    access: {
      interiorAccess: { value = undefined }
    }
  } = prevAddress
  return {
    ...prevAddress,
    access: {
      ...prevAddress.access,
      interiorAccess:
        type.key === ''
          ? {}
          : {
              ...prevAddress.access.interiorAccess,
              value,
              type
            }
    }
  }
}

function updateInteriorAccessValue(prevAddress, value) {
  return {
    ...prevAddress,
    access: {
      ...prevAddress.access,
      interiorAccess: { ...prevAddress.access.interiorAccess, value }
    }
  }
}

function updateToponym(prevAddress, toponym) {
  return {
    ...prevAddress,
    access: { ...prevAddress.access, toponym }
  }
}

function updateProperties(prevAddress, propertyName, property) {
  return {
    ...prevAddress,
    properties: {
      ...prevAddress.properties,
      [propertyName]: property
    }
  }
}

function formatAccessNumeration(value) {
  const { start = '', end = '', startDuplicate = '', endDuplicate = '' } = value
  return end > start
    ? `${start}${isEmpty(startDuplicate.trim()) ? '' : ` ${startDuplicate}`} - ${end}${
        isEmpty(endDuplicate.trim()) ? '' : ` ${endDuplicate}`
      }`
    : `${start}${isEmpty(startDuplicate.trim()) ? '' : ` ${startDuplicate}`}`
}

function formatAccessType(access) {
  const {
    type: { key },
    value
  } = access
  switch (key) {
    case 'K': // Kilometro
      return `KM ${parseFloat(value).toFixed(2)}`
    case 'M': // Manzana
      return `MANZANA ${value}`
    case 'P': // Parcela rústica
      return `PARCELA RÚSTICA ${value}`
    default:
      // Números
      return formatAccessNumeration(value)
  }
}

function formatAccessDetails(access) {
  const {
    toponym = '',
    interiorAccess: { type = '', value = '' }
  } = access
  switch (type.key) {
    case 'B': // Bloque
      return `Bloque ${value}`
    case 'P': // Polígono
      return `Polígono ${value}`
    case 'V': // Barraca
      return `Barraca ${value}`
    case 'O': // Portal
      return `Portal ${value}`
    case 'X': // Bloque-Portal
      return `Bloque-Portal ${value}`
    case 'L': // Lote (México)
      return `Lote ${value}`
    default:
      return ''
  }
}

function getPrimaryLabel(address) {
  return (
    <>{`${address.street.type.label} ${address.street.name} ${formatAccessType(address.access)}`}</>
  )
}

function getSecondaryLabel(address) {
  return (
    <>
      {`${formatAccessDetails(address.access)} `}
      {address.properties.stair && `${address.properties.stair.label} `}
      {address.properties.floor && `${address.properties.floor.label} `}
      {address.properties.door && `${address.properties.door.label} `}
      {`${getMunicipalityOptionLabel(address.location.municipality)}`}
      {', '}
      {`${getCountryOptionLabel(address.location.country)}`}
    </>
  )
}

function formatPrimaryLabel(address) {
  return address.street ? getPrimaryLabel(address) : ''
}

function formatSecondaryLabel(address) {
  return address.street ? getSecondaryLabel(address) : ''
}

export {
  emptyAddress,
  getDefaultLocation,
  getStreetTypes,
  getDefaultStreetType,
  getNumerationTypes,
  getDefaultNumerationType,
  getDuplicates,
  getInteriorAccessTypes,
  getFloors,
  getStairs,
  getDoors,
  useCountryLookup,
  getCountryOptionLabel,
  getCountryOptionSelected,
  getCountryOptionId,
  generateCountryObject,
  useProvinceLookup,
  getProvinceOptionLabel,
  getProvinceOptionSelected,
  getProvinceOptionId,
  generateProvinceObject,
  useMunicipalityLookup,
  getMunicipalityOptionLabel,
  getMunicipalityOptionSelected,
  getMunicipalityOptionId,
  getMunicipalityOptionAddressType,
  generateMunicipalityObject,
  useAccessLookup,
  getAccessOptionInputLabel,
  getAccessOptionLabel,
  getAccessOptionSecondaryLabel,
  getAccessOptionSelected,
  getAccessInputLabel,
  updateCountry,
  updateProvince,
  updateMunicipality,
  updateLocation,
  updateAccess,
  updateStreetType,
  updateStreetName,
  updateNumerationType,
  updateNumerationValue,
  updateDuplicate,
  updateInteriorAccessType,
  updateInteriorAccessValue,
  updateToponym,
  updateProperties,
  formatPrimaryLabel,
  formatSecondaryLabel
}
