import React from 'react'

import { useRouteMatch, useParams, Link as RouterLink } from 'react-router-dom'

import Container from '@material-ui/core/Container'
import Fade from '@material-ui/core/Fade'
import Zoom from '@material-ui/core/Zoom'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'

import logo from '../../logo.svg'

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: 'center',
    padding: theme.spacing(4),
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    margin: theme.spacing(0.5, 1)
  },
  code: {
    wordBreak: 'break-word'
  }
}))

const helloWorld = `ReactDOM.render(\r\n  <h1>Hello, world!<\/h1>,\r\n  document.getElementById(\'root\')\r\n);`

export default function PublicPage({ title }) {
  const classes = useStyles()

  // Routing: step url parameter
  const fake = useRouteMatch('/tramite/:pid')
  const { pid } = useParams()

  return (
    <Fade in timeout={500}>
      <Container fixed className={classes.container}>
        <section>
          <Typography variant="h1">Hello, World!</Typography>
          <Typography variant="h4" color="textSecondary" paragraph>
            <code className={classes.code}>{helloWorld}</code>
          </Typography>
          <Typography variant="h4" color="primary">
            ;-)
          </Typography>
        </section>
      </Container>
    </Fade>
  )
}
