import React, { useEffect, useState, useCallback, useRef } from 'react'
import clsx from 'clsx'

import FormControl, { useFormControl } from '@material-ui/core/FormControl'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import EditLocationOutlinedIcon from '@material-ui/icons/EditLocationOutlined'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ButtonBase from '@material-ui/core/ButtonBase'
import Popover from '@material-ui/core/Popover'
import InputBase from '@material-ui/core/InputBase'
import FilledInput from '@material-ui/core/FilledInput'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Select from '@material-ui/core/Select'

import Tooltip from '@material-ui/core/Tooltip'

import Skeleton from '@material-ui/lab/Skeleton'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import Radio from '@material-ui/core/Radio'
import { Checkbox as Checkbox2 } from '@material-ui/core'

import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'

import Fade from '@material-ui/core/Fade'
import Slide from '@material-ui/core/Slide'
import Grow from '@material-ui/core/Grow'

import { makeStyles } from '@material-ui/core/styles'
import parse from 'autosuggest-highlight/parse'
import debounce from 'lodash/debounce'
import delay from 'lodash/delay'

import useAutocomplete from '@material-ui/lab/useAutocomplete'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'

import axios from 'axios'

import AddressLabel from './AddressLabel'
import AddressApartmentForm from './AddressApartmentForm'

function FormButton(props) {
  const { onFocus, onBlur } = useFormControl()
  const { children, anchorRef, tooltip, ...rest } = props

  return tooltip ? (
    <Tooltip title={tooltip}>
      <Button {...{ onFocus, onBlur, ref: anchorRef }} {...rest}>
        {children}
      </Button>
    </Tooltip>
  ) : (
    <Button {...{ onFocus, onBlur, ref: anchorRef }} {...rest}>
      {children}
    </Button>
  )
}

function FormPopover(props) {
  const { onFocus, onBlur } = useFormControl()
  const { children, ...rest } = props
  return (
    <Popover {...{ onFocus, onBlur }} {...rest}>
      {children}
    </Popover>
  )
}

function FormTextField(props) {
  const { onFocus, onBlur } = useFormControl()
  const { anchorEl, value, handleOpen, ...rest } = props
  return (
    <TextField
      ref={anchorEl}
      value={value ? `${value.carrer.nom18} ${value.numeracioPostal}` : ''}
      onClick={handleOpen}
      onKeyDown={ev => {
        if ((ev.ctrlKey && ev.keyCode === 86) || (ev.metaKey && ev.keyCode === 86)) {
          // something was pasted
          handleOpen()
        }
        if (ev.keyCode === 9) {
          //tab was pressed
          return
        }
        if (ev.ctrlKey || ev.metaKey || ev.altKey) {
          // combination of ctrl+key, alt+key, meta+key was pressed
          return
        }
        handleOpen()
      }}
      {...rest}
      {...{ onFocus, onBlur }}
    />
  )
}

const useStyles = makeStyles(theme => ({
  formControl: {
    //paddingLeft: theme.spacing(1.5),
    //paddingRight: theme.spacing(1.5)
  },
  label: {
    margin: theme.spacing(0, 2, 0.5, 0),
    lineHeight: 1.25
  },
  popoverButton: {
    // Mimics the default input display property used by browsers for an input.
    ...theme.typography.body1,
    color: theme.palette.text.primary,
    lineHeight: '1.1876em', // Reset (19px), match the native input line-height
    boxSizing: 'border-box', // Prevent padding issue with fullWidth.
    position: 'relative',
    display: 'inline-flex',
    alignItems: 'center',

    letterSpacing: 'normal',

    // Same as OutlinedInput less the top/bottom px of the borders
    padding: '17.5px 14px',

    // Extras to modify the button styles to match
    textTransform: 'none',
    maxWidth: '100%'
  },
  popoverButtonMain: {
    flexGrow: 1
  },
  popoverButtonDoubleLine: {
    paddingTop: 0,
    paddingBottom: 0
  },
  justifyAlignStart: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left'
  },
  directionColumn: {
    flexDirection: 'column'
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    width: '100%',
    letterSpacing: 'normal',
    lineHeight: '1.1876em' // Reset (19px), match the native input line-height
  },
  popoverCity: {
    padding: '0 14px 8px'
  },
  fakeInputRoot: {
    width: '25ch',
    position: 'relative',
    backgroundColor: 'rgba(0, 0, 0, 0.09)',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shorter,
      easing: theme.transitions.easing.easeOut
    }),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.13)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'rgba(0, 0, 0, 0.09)'
      }
    },
    '&$fakeInputFocused': {
      backgroundColor: 'rgba(0, 0, 0, 0.13)'
    },
    '&$disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)'
    },
    '&:after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      left: 0,
      bottom: 0,
      // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
      content: '""',
      position: 'absolute',
      right: 0,
      transform: 'scaleX(0)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shorter,
        easing: theme.transitions.easing.easeOut
      }),
      pointerEvents: 'none' // Transparent to the hover style.
    },
    '&$fakeInputFocused:after': {
      transform: 'scaleX(1)'
    },
    '&$error:after': {
      borderBottomColor: theme.palette.error.main,
      transform: 'scaleX(1)' // error is always underlined in red
    },
    '&:before': {
      borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
      left: 0,
      bottom: 0,
      // Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242
      content: '"\\00a0"',
      position: 'absolute',
      right: 0,
      transition: theme.transitions.create('border-bottom-color', {
        duration: theme.transitions.duration.shorter
      }),
      pointerEvents: 'none' // Transparent to the hover style.
    },
    '&:hover:before': {
      borderBottom: `1px solid ${theme.palette.text.primary}`
    },
    '&$disabled:before': {
      borderBottomStyle: 'dotted'
    }
  },
  fakeInput: {
    padding: '27px 12px 10px',
    '&:-webkit-autofill': {
      WebkitBoxShadow: theme.palette.type === 'dark' ? '0 0 0 100px #266798 inset' : null,
      WebkitTextFillColor: theme.palette.type === 'dark' ? '#fff' : null,
      borderTopLeftRadius: 'inherit',
      borderTopRightRadius: 'inherit'
    }
  },
  fakeInputFocused: {},
  popoverPaperRoot: {
    padding: theme.spacing(0),
    minWidth: theme.breakpoints.values.sm,
    maxWidth: theme.breakpoints.values.sm,
    borderColor: theme.palette.primary.main,
    borderWidth: 2,
    borderStyle: 'solid'
  },
  inputDiv: {
    display: 'flex',
    flexDirection: 'column'
  },
  inputRoot: {
    flex: 1
  },
  inputBase: {
    // Same as OutlinedInput less the border of the Paper on the top and left borders
    padding: '15.5px 14px 18.5px 12px' // '18.5px 14px'
  },
  inputProgress: {
    margin: theme.spacing(2)
  },
  nestedListItem: {
    paddingLeft: theme.spacing(4)
  },
  menuItem: {
    '&[aria-selected="true"]': {
      backgroundColor: theme.palette.action.selected
    },
    '&[data-focus="true"]': {
      backgroundColor: theme.palette.action.hover
    },
    '&:active': {
      backgroundColor: theme.palette.action.selected
    },
    '&[aria-disabled="true"]': {
      opacity: theme.palette.action.disabledOpacity,
      pointerEvents: 'none'
    }
  },
  popoverMoreActions: {
    display: 'flex',
    padding: theme.spacing(1, 2),
    justifyContent: 'flex-end'
  },
  actionButton: {
    marginLeft: theme.spacing(1)
  }
}))

export default function AddressHookAutocompleteButton() {
  const classes = useStyles()
  const [inputValue, setInputValue] = React.useState('')
  const [options, setOptions] = React.useState([])

  // THE SELECTED ADDRESS DATA
  const BLANK_ADDRESS = {
    street: null,
    access: null,
    apartment: null,
    label: null,
    postal: null
  }
  const [address, setAddress] = useState(BLANK_ADDRESS)
  const handleClearAddress = () => setAddress(BLANK_ADDRESS)
  const handleAccessEdit = value =>
    setAddress(prev => ({
      ...prev,
      street: { value: value.carrer.codi, label: value.carrer.nomComplet },
      access: { value: value.id, label: value.numeracioPostal },
      apartment: {},
      label: `${value.carrer.nomComplet} ${value.numeracioPostal}`,
      postal: `(080${value.districtePostal}) Barcelona`
    }))
  const handleApartmentEdit = (event, label) =>
    setAddress(prev => ({
      ...prev,
      apartment: {
        ...prev.apartment,
        [event.target.name]: event.target.value ? { value: event.target.value, label } : undefined
      }
    }))

  const [listOpen, setListOpen] = useState(false) // Controls showing the list of results
  const [formOpen, setFormOpen] = useState(false) // Controls showing the additional form controls
  const [actionsOpen, setActionsOpen] = useState(false) // Controls showing the additional actions

  const handleChange = event => {
    setInputValue(event.target.value)
  }

  const debouncedHandleChange = useCallback(
    debounce((e, v, r) => {
      if (r === 'input') {
        setInputValue(v)
        setListOpen(true)
        setActionsOpen(true)
      }
    }, 350),
    []
  )

  function useBarcelonaLookup(searchTerm) {
    const [streets, setStreets] = useState([])
    const [accesses, setAccesses] = useState([])
    const [results, setResults] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
      const fetchRequest = async req => {
        setIsError(false)
        setIsLoading(true)
        try {
          const result = await axios(`https://w33.bcn.cat/GeoBcn/serveis/territori?&max=5&q=${req}`)
          if (result.data.resultats.adreces) setAccesses(result.data.resultats.adreces)
          if (result.data.resultats.vies) setStreets(result.data.resultats.vies)
          if (result.data.resultats.vies && result.data.resultats.adreces)
            setResults(
              result.data.resultats.vies.map(el => ({
                ...el,
                adreces: result.data.resultats.adreces.filter(ad => ad.carrer.codi === el.codi)
              }))
            )
        } catch (error) {
          setIsError(true)
        }
        delay(() => setIsLoading(false), 250)
      }

      if (searchTerm) fetchRequest(searchTerm)
    }, [searchTerm])

    return [streets, accesses, results, isLoading, isError]
  }

  function useAccessLookup(searchTerm) {
    const [streets, setStreets] = useState([])
    const [accesses, setAccesses] = useState([])
    const [results, setResults] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    useEffect(() => {
      const fetchRequest = async req => {
        setIsError(false)
        setIsLoading(true)
        try {
          const result = await axios(
            `http://10.49.95.50:8380/sta/api/v1/accessSearch/101700200000645500001?access=${req}`
          )
          console.log(result.data)
          if (result.data.length > 0) {
            setAccesses(
              result.data.map(el => ({
                id: el.dboid,
                nomComplet: `${el.street.acronym} ${el.street.stname} ${el.num1} ${el.num2}`,
                inputValue: `${el.street.acronym} ${el.street.stname} ${el.num1} ${el.num2}`,
                numeracioPostal: `${el.num1}`,
                districtePostal: '02',
                barri: { nom: 'el Gòtic' },
                districte: { descripcio: 'Ciutat Vella' },
                carrer: {
                  codi: el.street.dboid,
                  nomComplet: `${el.street.acronym} ${el.street.stname}`
                }
              }))
            )
          }
        } catch (error) {
          setIsError(true)
        }
        delay(() => setIsLoading(false), 250)
      }

      if (searchTerm) fetchRequest(searchTerm)
    }, [searchTerm])

    return [streets, accesses, results, isLoading, isError]
  }

  const [streets, accesses, results, isLoading, isError] = useBarcelonaLookup(inputValue)

  const [open, setOpen] = useState(false)
  const handleOpen = event => setOpen(true)
  const handleClose = event => setOpen(false)
  const anchorEl = useRef()

  //const filter = createFilterOptions()
  const {
    value,
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    getClearProps,
    groupedOptions
  } = useAutocomplete({
    id: 'hook-demo',
    style: { width: 300 },
    getOptionLabel: option => option.nomComplet,
    getOptionSelected: (option, value) => option.id === value.id, // id per adreça, codi per carrer
    filterOptions: x => x,
    /*filterOptions: (options, params) => {
      const filtered = filter(options, params)

      if (params.inputValue !== '') {
        filtered.push({
          inputValue: params.inputValue,
          nomComplet: `Add "${params.inputValue}"`,
          districtePostal: '00',
          barri: { nom: 'prova' },
          districte: { descripcio: 'prova' }
        })
      }

      return filtered
    },*/
    options: accesses,
    autoComplete: true,
    includeInputInList: true,
    //blurOnSelect: true,
    disableCloseOnSelect: true,
    disablePortal: true,
    open: listOpen,
    onClose: (event, reason) => {
      console.log(`wants to close bc ${reason}`)
      // blur, toggleInput, select-option, escape
      const allowedExits = ['escape', 'select-option']
      if (allowedExits.includes(reason)) {
        setOpen(false)
      }
    },
    onChange: (event, value, reason) => {
      console.log(`changed bc ${reason}`)
      // reason One of "create-option", "select-option", "remove-option", "blur" or "clear".
      if (reason === 'select-option') {
        setListOpen(false)
        setFormOpen(true)
        handleAccessEdit(value)
      }
      if (reason === 'clear') {
        handleClearAddress()
      }
    },
    onInputChange: debouncedHandleChange
  })

  return (
    <FormControl
      fullWidth
      component="fieldset"
      margin="normal"
      variant="outlined"
      required={true}
      error={false}
      className={classes.formControl}
    >
      <FormLabel component="legend" className={classes.label}>
        Dirección del establecimiento
      </FormLabel>

      <ButtonGroup aria-label="outlined primary button group">
        <FormButton
          fullWidth={false}
          disableElevation
          variant="outlined"
          onClick={handleOpen}
          className={`${classes.popoverButton} ${classes.popoverButtonMain}`}
          classes={{ label: `${classes.justifyAlignStart} ${classes.directionColumn}` }}
          anchorRef={anchorEl}
          //endIcon={<SearchIcon />}
        >
          {address.label ? (
            <>
              <Typography variant="body1" noWrap display="block" className={classes.ellipsis}>
                {address.apartment.stair || address.apartment.floor || address.apartment.door ? (
                  <>{`${address.label} ${address.postal}`}</>
                ) : (
                  <>{`${address.label}`}</>
                )}
              </Typography>
              <Typography variant="body2" noWrap display="block" className={classes.ellipsis}>
                {address.apartment.stair || address.apartment.floor || address.apartment.door ? (
                  <>
                    {address.apartment.stair && ` ${address.apartment.stair.label}`}
                    {address.apartment.floor && ` ${address.apartment.floor.label}`}
                    {address.apartment.door && ` ${address.apartment.door.label}`}
                  </>
                ) : (
                  <>{address.postal}</>
                )}
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="body1" noWrap display="block" className={classes.ellipsis}>
                Buscar en Colinas del Campo de Martín Moro Toledano
              </Typography>
              <Typography variant="body2" noWrap display="block" className={classes.ellipsis}>
                León, España
              </Typography>
            </>
          )}
        </FormButton>
        {value ? (
          <FormButton
            aria-label="clear address"
            //startIcon={<DeleteIcon />}
            className={classes.popoverButton}
            tooltip="Quitar esta dirección"
            {...getClearProps()}
            tabIndex={0}
          >
            <ClearIcon fontSize="small" />
          </FormButton>
        ) : (
          <FormButton
            aria-label="change city"
            //startIcon={<DeleteIcon />}
            className={classes.popoverButton}
            tooltip="Seleccionar otro municipio"
          >
            <LocationCityIcon fontSize="small" />
          </FormButton>
        )}
      </ButtonGroup>
      <FormHelperText>This could help you</FormHelperText>

      <pre>{JSON.stringify(address, null, 2)}</pre>
      {/*
      <br />

      <FormButton
        fullWidth={false}
        disableElevation
        variant="outlined"
        onClick={handleOpen}
        className={classes.popoverButton}
        //anchorRef={anchorEl}
      >
        {value
          ? `${value.carrer.nom18} ${value.numeracioPostal}`
          : `Buscar una dirección en Barcelona`}
      </FormButton>

      <br />

      <ButtonGroup aria-label="outlined primary button group">
        <FormButton
          fullWidth={false}
          disableElevation
          variant="outlined"
          onClick={handleOpen}
          className={`${classes.popoverButton} ${classes.popoverButtonMain}`}
          classes={{ label: classes.justifyAlignStart }}
          //anchorRef={anchorEl}
        >
          {value
            ? `${value.carrer.nom18} ${value.numeracioPostal}`
            : `Buscar una dirección en Colinas del Campo de Martín Moro Toledano`}
        </FormButton>
        <FormButton
          aria-label="delete"
          endIcon={<EditLocationOutlinedIcon />}
          className={classes.popoverButton}
        >
          Otro municipio
        </FormButton>
      </ButtonGroup>
      <FormHelperText>Help</FormHelperText>

      <br />

      <ButtonGroup aria-label="outlined primary button group">
        <FormButton
          fullWidth={false}
          disableElevation
          variant="outlined"
          onClick={handleOpen}
          className={`${classes.popoverButton} ${classes.popoverButtonMain}`}
          classes={{ label: `${classes.justifyAlignStart} ${classes.directionColumn}` }}
          //anchorRef={anchorEl}
        >
          <Typography variant="body1" display="block">
            Calle y número
          </Typography>
        </FormButton>
        <FormButton
          aria-label="delete"
          //startIcon={<DeleteIcon />}
          className={classes.popoverButton}
          tooltip="Quitar esta dirección"
        >
          <ClearIcon />
        </FormButton>
      </ButtonGroup>
      <FormHelperText>Help</FormHelperText>

      <br />

      <ButtonGroup aria-label="outlined primary button group">
        <FormButton
          fullWidth={false}
          disableElevation
          variant="outlined"
          onClick={handleOpen}
          className={`${classes.popoverButton} ${classes.popoverButtonMain}`}
          classes={{ label: `${classes.justifyAlignStart} ${classes.directionColumn}` }}
          //anchorRef={anchorEl}
        >
          <Typography variant="body1" display="block">
            Buscar una dirección
          </Typography>
          <Typography variant="body2" noWrap display="block" className={classes.ellipsis}>
            en Barcelona
          </Typography>
        </FormButton>
        <FormButton
          aria-label="delete"
          //startIcon={<DeleteIcon />}
          className={classes.popoverButton}
        >
          <EditLocationOutlinedIcon />
        </FormButton>
        <FormButton
          aria-label="delete"
          //startIcon={<DeleteIcon />}
          className={classes.popoverButton}
        >
          <ClearIcon />
        </FormButton>
      </ButtonGroup>
      <FormHelperText>Help</FormHelperText>

      <AddressLabel
        streetName={value ? `${value.carrer.nomComplet}` : `Carrer de Cucurulla`}
        streetNumber={value ? `${value.numeracioPostal}` : `5`}
        postcode={value ? `080${value.districtePostal}` : '08002'}
        city={value ? `${value.barri.nom} (${value.districte.descripcio})` : 'Barcelona'}
        interiorAccess="Bloque 2"
        toponym="Edificio Las Palomas"
        door="Escalera A, Planta 4, Puerta 1"
        value={value}
      />
          */}
      <FormPopover
        open={open}
        onEntering={() => {
          value ? setFormOpen(true) : setListOpen(true)
          //delay(() => setActionsOpen(true), 3000)
        }}
        onExited={() => {
          setListOpen(false)
          setActionsOpen(false)
        }}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        PaperProps={{
          classes: { root: classes.popoverPaperRoot },
          tabIndex: -1
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <div className={classes.inputDiv}>
          <InputBase
            classes={{ root: classes.inputRoot, input: classes.inputBase }}
            placeholder="Buscar en Colinas del Campo de Martín Moro Toledano"
            autoFocus
            endAdornment={
              <InputAdornment position="end">
                {isLoading ? (
                  <Fade
                    in={isLoading}
                    mountOnEnter
                    unmountOnExit
                    style={{
                      transitionDelay: '000ms'
                    }}
                  >
                    <CircularProgress className={classes.inputProgress} size={24} />
                  </Fade>
                ) : null}
                {!isLoading ? (
                  <Fade in={!isLoading} mountOnEnter unmountOnExit>
                    <SearchIcon className={classes.inputProgress} color="disabled" />
                  </Fade>
                ) : null}
              </InputAdornment>
            }
            inputProps={{ ...getInputProps() }}
            {...getRootProps()}
          />
          <Divider />
          <FormHelperText>This could help you</FormHelperText>
        </div>
        <Divider />
        <Fade in={listOpen && groupedOptions.length > 0}>
          <MenuList {...getListboxProps()} className={classes.menuList} disablePadding>
            {groupedOptions.map((option, index) => (
              <MenuItem {...getOptionProps({ option, index })} className={classes.menuItem}>
                <ListItemText
                  primary={option.nomComplet}
                  secondary={`080${option.districtePostal}, ${option.barri.nom} (${option.districte.descripcio})`}
                />
              </MenuItem>
            ))}
            <Divider />
          </MenuList>
        </Fade>

        {!listOpen && (
          <>
            <div className={classes.popoverFormRow}>
              <AddressApartmentForm apartment={address.apartment} onChange={handleApartmentEdit} />
            </div>
            <Divider />
            <div className={classes.popoverMoreActions}>
              <Button
                variant="contained"
                disableElevation
                color="primary"
                size="medium"
                className={classes.actionButton}
                onClick={handleClose}
              >
                Cerrar
              </Button>
            </div>
            <Divider />
          </>
        )}

        <Fade
          in={actionsOpen && listOpen}
          style={{
            display: actionsOpen && listOpen ? 'flex' : 'none',
            transitionDelay: actionsOpen ? '2000ms' : '0ms'
          }}
        >
          <div className={classes.popoverMoreActions}>
            <Button size="small">No encuentro mi dirección</Button>
          </div>
        </Fade>
      </FormPopover>
    </FormControl>
  )
}
