import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Toolbar from '@material-ui/core/Toolbar'

import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({}))

const TopNavigation = React.forwardRef(function BottomNavigation({ children, className }, ref) {
  const classes = useStyles()
  return (
    <Hidden smUp>
      <Toolbar className={clsx(classes.navigation, className)} component="aside" ref={ref}>
        {children}
      </Toolbar>
    </Hidden>
  )
})

export default TopNavigation

TopNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

TopNavigation.defaultProps = {
  className: null
}
