import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import axios from 'axios'

import { makeStyles } from '@material-ui/core/styles'

import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'

import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import CheckIcon from '@material-ui/icons/Check'
import SearchIcon from '@material-ui/icons/Search'
import LinearProgress from '@material-ui/core/LinearProgress'

import Field from '../../../lib/Fields'
import Document from '../../../lib/Document'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2),
    background: theme.palette.common.white
  },
  grid: {
    marginTop: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2)
  },
  field: {
    minWidth: '35ch'
  },
  message: {
    height: theme.spacing(3),
    marginBottom: theme.spacing(4)
  },
  progress: {
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0
  },
  tab: {
    padding: theme.spacing(2),
    minHeight: '30vh'
  },
  tabPanel: { border: '2px solid lightgrey', minHeight: '30vh' },
  tabList: { marginTop: theme.spacing(4) }
}))

const useRestAPI = () => {
  const [data, setData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const fetchRequest = async req => {
    setIsError(false)
    setIsLoading(true)
    try {
      const result = await axios(`https://10.49.95.50:8746/sta/api/v1/solicitud/${req}`)
      setData(result.data)
    } catch (error) {
      setIsError(true)
      setData({})
    }
    setIsLoading(false)
  }

  return [{ data, isLoading, isError }, fetchRequest]
}

export default function FieldsAndDocuments() {
  const classes = useStyles()
  const [request, setRequest] = useState('6269000027969883999999')
  const [{ data, isLoading, isError }, fetchRequest] = useRestAPI()

  const [groupOfFields, setGroupOfFields] = useState([])
  const [groupsOfDocuments, setGroupsOfDocuments] = useState([])

  useEffect(() => {
    setGroupOfFields(data.variables ? data.variables : [])
    setGroupsOfDocuments(data.documentos ? data.documentos : [])
  }, [data])

  const handleChange = event => {
    setRequest(event.target.value)
  }
  const handleClick = () => {
    if (request) {
      fetchRequest(request)
    }
  }

  // Main tabs
  const [value, setValue] = useState(0)
  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Typography variant="h2" gutterBottom>
        API para campos y documentos
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Solicitud maestra de pruebas</Typography>
          <Typography gutterBottom>6269000027969883999999</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Otros ejemplos con campos</Typography>
          <Typography gutterBottom>6269000002186952505040</Typography>
          <Typography gutterBottom>6269000012139296099999</Typography>
          <Typography gutterBottom>6269000020659257099999</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="subtitle1">Otros ejemplos con documentos</Typography>
          <Typography gutterBottom>6269000027826948699999</Typography>
          <Typography gutterBottom>6269000021196020299999</Typography>
          <Typography gutterBottom>6269000021271637599999</Typography>
        </Grid>
      </Grid>

      <TextField
        margin="normal"
        className={classes.field}
        variant="outlined"
        value={request}
        error={isError}
        helperText={isError && 'Prueba con otra solicitud'}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleClick}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {isLoading && <LinearProgress className={classes.progress} />}
      <TabContext value={value}>
        <TabList
          onChange={handleTabChange}
          aria-label="simple tabs example"
          className={classes.tabList}
        >
          <Tab label={<Typography variant="subtitle1">Datos</Typography>} />
          <Tab label={<Typography variant="subtitle1">Documentos</Typography>} />
          <Tab label={<Typography variant="subtitle1">JSON</Typography>} />
        </TabList>

        <TabPanel value={0} className={classes.tabPanel}>
          {groupOfFields.map(group => (
            <>
              <Typography variant="h4" gutterBottom>
                {group.title}
              </Typography>
              <Grid container spacing={2} key={`container-for-${group.id}`}>
                {group.fields.map(field => (
                  <>
                    <Grid item xs={6}>
                      <Field
                        type={field.type}
                        key={`field-for-${field.id}`}
                        id={field.id}
                        title={field.title}
                        description={field.description}
                        help={field.help}
                        required={field.required}
                        options={field.items?.length > 0 ? field.items : undefined}
                        format={field.format}
                        length={field.length}
                        digits={field.digits}
                        decimals={field.decimals}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Paper elevation={0} className={classes.paper}>
                        <pre>{JSON.stringify(field, undefined, 4)}</pre>
                      </Paper>
                    </Grid>
                  </>
                ))}
              </Grid>
            </>
          ))}
        </TabPanel>
        <TabPanel value={1} className={classes.tabPanel}>
          {groupsOfDocuments.map(group => (
            <>
              <Typography variant="h4" gutterBottom>
                {group.title}
              </Typography>
              <Grid container spacing={2} key={`container-for-${group.id}`}>
                {group.documents.map(doc => (
                  <>
                    <Grid item xs={6}>
                      <Document
                        key={`document-for-${doc.id}`}
                        id={doc.id}
                        title={doc.title}
                        description={doc.description}
                        required={doc.required}
                        signature={doc.signature}
                        reusable={doc.reusable}
                        multiple={doc.multiple}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Paper elevation={0} className={classes.paper}>
                        <pre>{JSON.stringify(doc, undefined, 4)}</pre>
                      </Paper>
                    </Grid>
                  </>
                ))}
              </Grid>
            </>
          ))}
        </TabPanel>
        <TabPanel value={2} className={classes.tabPanel}>
          <pre>{JSON.stringify(data, undefined, 4)}</pre>
        </TabPanel>
      </TabContext>
    </Container>
  )
}
