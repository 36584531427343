import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({}))

const ContentSubSection = React.forwardRef(function ContentSubSection(
  { children, className },
  ref
) {
  const classes = useStyles()
  return (
    <Container
      disableGutters
      maxWidth="lg"
      className={clsx(className)}
      component="section"
      ref={ref}
    >
      {children}
    </Container>
  )
})

export default ContentSubSection

ContentSubSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

ContentSubSection.defaultProps = {
  className: null
}
