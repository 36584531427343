import React from 'react'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  typography: {
    //padding: theme.spacing(2)
  }
}))

export default function AutosaveModal({ handleClose }) {
  const classes = useStyles()
  return (
    <>
      <Typography className={classes.typography} variant="h6" gutterBottom>
        Borrador de la solicitud
      </Typography>
      <Typography className={classes.typography} paragraph>
        Una olla de algo más vaca que carnero, salpicón las más noches, duelos y quebrantos los
        sábados, lantejas los viernes, algún palomino de añadidura los domingos, consumían las tres
        partes de su hacienda.
      </Typography>
      <Typography className={classes.typography} paragraph>
        El resto della concluían sayo de velarte, calzas de velludo para las fiestas, con sus
        pantuflos de lo mesmo, y los días de entresemana se honraba con su vellorí de lo más fino.
      </Typography>
      <Button onClick={handleClose}>Cerrar</Button>
    </>
  )
}
