import React from 'react'
import PropTypes from 'prop-types'

import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'

function defaultTrigger(store, options) {
  const { disableHysteresis = false, threshold = 100, target } = options
  const previous = store.current

  if (target) {
    // Get vertical scroll
    store.current = target.pageYOffset !== undefined ? target.pageYOffset : target.scrollTop
  }

  if (!disableHysteresis && previous !== undefined) {
    if (store.current < previous) {
      return false
    }
  }

  return store.current > threshold
}

const defaultTarget = typeof window !== 'undefined' ? window : null

function useScrollTrigger(options = {}) {
  const { getTrigger = defaultTrigger, target = defaultTarget, ...other } = options
  const store = React.useRef()
  const [trigger, setTrigger] = React.useState(() => getTrigger(store, other))

  React.useLayoutEffect(() => {
    const handleScroll = () => {
      setTrigger(getTrigger(store, { target, ...other }))
    }

    handleScroll() // Re-evaluate trigger when dependencies change
    target.addEventListener('scroll', handleScroll)
    return () => {
      target.removeEventListener('scroll', handleScroll)
    }
    // See Option 3. https://github.com/facebook/react/issues/14476#issuecomment-471199055
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, getTrigger, JSON.stringify(other)])

  return trigger
}

function ElevateOnScroll(props) {
  const { children, inverse, threshold, disableHysteresis, target, elevation } = props
  const trigger = useScrollTrigger({
    disableHysteresis,
    threshold,
    target
  })

  return inverse
    ? React.cloneElement(children, {
        elevation: trigger ? 0 : elevation
      })
    : React.cloneElement(children, {
        elevation: trigger ? elevation : 0
      })
}

ElevateOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  inverse: PropTypes.bool,
  threshold: PropTypes.number,
  disableHysteresis: PropTypes.bool,
  target: PropTypes.element,
  elevation: PropTypes.number
}

ElevateOnScroll.defaultProps = {
  inverse: false,
  threshold: 0,
  disableHysteresis: true,
  target: undefined,
  elevation: 4
}

const useStyles = makeStyles(theme => ({
  appBar: {
    // background: theme.palette.common.white,
    background: 'linear-gradient(180deg, rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%)',
    paddingBottom: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2, 2, 1)
    }
  }
}))

export default function ObjectModalBar({ children }) {
  const classes = useStyles()
  return (
    <AppBar color="default" position="sticky" elevation={0} className={classes.appBar}>
      {children}
    </AppBar>
  )
}
