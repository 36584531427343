import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Amplitude } from '@amplitude/react-amplitude'

import FormControl from '@material-ui/core/FormControl'
import Radio from '@material-ui/core/Radio'
import FormGroup from '@material-ui/core/RadioGroup'

import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'

import { string } from 'yup'
import formatFormHelperText from '../Utils/formatFormHelperText'
import { sanitizeNumber, sanitizeString } from '../Utils/sanitizeForInputValue'

// Text validation schema
const schema = string().when(
  '$required',
  (required, s) => (required ? s.required('Completa este campo') : s) // Empty string doesn't pass
)

const useStyles = makeStyles(theme => ({
  label: {
    margin: theme.spacing(0, 2, 0.5, 0),
    lineHeight: 1.25
  },
  formGroup: {
    marginLeft: theme.spacing(1.75)
  },
  formHelperText: {
    marginLeft: theme.spacing(1.75)
  },
  formHelperTextWithMessage: {
    marginBottom: theme.spacing(2) + 3
  },
  fclRoot: {
    alignItems: 'flex-start'
  },
  fclLabel: {
    paddingTop: 10
  }
}))

/**
Use a RadioGroup when you want the user to be able to pick one option.

- Can work with string or number keys (using the Numerical flag). The value dispatched will be typed.
- Warning: radio groups should never be optional but this component doesn't enforce it (bc it's not intended to be used directly).
- The underlying radio group field change event is logged on the analytics platform.

### Events:
- Blur: only runs a validation if value changed
- Change: always runs a validation

### Validations:
- Needs a value, if required
- Value must be a valid option
*/

export default function RadioGroup({
  id,
  type,
  title,
  description,
  help,
  required,
  options,
  numerical,
  defaultValue,
  onUpdate,
  onValidation
}) {
  const classes = useStyles()

  // Local state for field value and validation
  // The DOM input field always uses string representation of numbers in attributes
  const [value, setValue] = useState(
    numerical ? sanitizeNumber(defaultValue) : sanitizeString(defaultValue)
  )
  const [validation, setValidation] = useState({
    error: undefined,
    message: undefined,
    dirty: false
  })

  // Validate using schema, set the validation
  const handleValidation = useCallback(
    v => {
      const validOps = numerical ? options.map(el => String(el.key)) : options.map(el => el.key) // Force any number into its string representation
      if (!required) validOps.push('')
      schema
        .oneOf(validOps, 'Selecciona una opción') // Extend the validation schema to test for valid options
        .validate(v, { context: { required } })
        .then(() => {
          setValidation({ error: false, message: undefined, dirty: !!v })
        })
        .catch(e => {
          setValidation({ error: true, message: e.message, dirty: true })
        })
    },
    [required, options, numerical]
  )

  // On change, set the new value and share it and always validate
  const handleChange = event => {
    const { value: val } = event.target
    setValue(val)
    onUpdate(id, numerical ? parseFloat(val) : val)
    handleValidation(val)
  }

  // On blur, trigger validation if there's a new value
  const handleBlur = event => {
    if (event.target.value !== value) handleValidation(value)
  }

  // Side-effect of new validation state: sync the context (skip if validation is yet undefined)
  useEffect(() => {
    if (typeof validation.error !== 'undefined') onValidation(id, !validation.error)
  }, [onValidation, id, validation.error])

  // Side-effect of having a default value
  // Validation will not run on unmatching types (uses the numerical boolean), and must be a healthy string/number
  // This handles validation of saved values, context must be aware of empty initial fields
  useEffect(() => {
    if (!numerical && sanitizeString(defaultValue)) handleValidation(defaultValue)
    if (numerical && sanitizeNumber(defaultValue)) handleValidation(defaultValue)
  }, [numerical, defaultValue, handleValidation])

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'field'],
        fieldType: type,
        fieldVariant: 'radio-group',
        fieldLabel: title
      })}
    >
      {({ instrument }) => (
        <FormControl
          required={required}
          error={validation.error}
          component="fieldset"
          className={classes.formControl}
          margin="normal"
        >
          <FormLabel required={false} className={classes.label} component="legend">
            {title}
          </FormLabel>
          <FormGroup
            name={id}
            className={classes.formGroup}
            value={value}
            onChange={instrument('Interacted with form field', handleChange)}
            onBlur={handleBlur}
          >
            {options.map(option => (
              <FormControlLabel
                key={`${id}-${option.key}`}
                value={String(option.key)} // If the option keys were Numbers, cast them
                control={<Radio id={`radio-${id}-${option.key}`} />}
                labelPlacement="end"
                classes={{ root: classes.fclRoot, label: classes.fclLabel }}
                label={
                  <>
                    <Typography>{option.label}</Typography>
                    {option.help && (
                      <Typography variant="body2" color="textSecondary">
                        {option.help}
                      </Typography>
                    )}
                  </>
                }
              />
            ))}
          </FormGroup>
          <FormHelperText
            className={clsx(classes.formHelperText, {
              [classes.formHelperTextWithMessage]: !required || help
            })}
          >
            {formatFormHelperText(required, help, validation.message)}
          </FormHelperText>
        </FormControl>
      )}
    </Amplitude>
  )
}

RadioGroup.propTypes = {
  /**
    DBOID of the field.
  */
  id: PropTypes.string.isRequired,
  /**
    Type.
  */
  type: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Helper text.
  */
  help: PropTypes.string,
  /**
    Use this to indicate that the box must be checked.
  */
  required: PropTypes.bool,
  /**
    Array of options
  */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  ).isRequired,
  /**
    Initial state of the field.
  */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
    Uses numerical values
  */
  numerical: PropTypes.bool,
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
  Handler to be called when a new validation needs to be shared
  @param {string} id - The id of the field.
  @param {bool} value - The validation result.
*/
  onValidation: PropTypes.func
}

RadioGroup.defaultProps = {
  description: null,
  help: null,
  required: false,
  defaultValue: undefined,
  numerical: false,
  onUpdate: () => {},
  onValidation: () => {}
}
