import React, { useMemo } from 'react'

import { useProgressUpdater } from '../../components/Context/ProgressContext'

import Text from './Text'
import Number from './Number'
import Textarea from './Textarea'
import Boolean from './Boolean'
import Date from './Date'
import Hour from './Hour'
import AddressHookAutocompleteButton from './AddressHookAutocompleteButton'
import AddressSimpleLabel from './AddressSimpleLabel'
import CodedAddressDemo from './CodedAddressDemo'
import Address from './Address/index'
import BankAccount from './BankAccount'
import Plot from './Plot'
import Person from './Person'
import AdministrativeFile from './AdministrativeFile'

const getComponent = type => {
  switch (type) {
    case 'text':
      return Text
    case 'number':
      return Number
    case 'textarea':
      return Textarea
    case 'boolean':
      return Boolean
    case 'date':
      return Date
    case 'hour':
      return Hour
    case 'address':
      return Address
    case 'bank-account':
      return BankAccount
    case 'plot':
      return Plot
    case 'person':
      return Person
    case 'administrative-file':
      return AdministrativeFile
    default:
      return Text
  }
}

export default function Field({
  id,
  title,
  type,
  description,
  help,
  required,
  defaultValue,
  format,
  length,
  options,
  multiple,
  decimals,
  regex,
  digits
}) {
  // Selection of component memoized to avoid computing again when unrelated props change
  const Component = useMemo(() => getComponent(type), [type])

  // Hook up context
  const [updateValue, updateValidation] = useProgressUpdater('data')

  return useMemo(() => {
    return (
      <Component
        id={id}
        type={type}
        title={title}
        description={description}
        help={help}
        defaultValue={defaultValue}
        required={required}
        format={format}
        options={options}
        multiple={multiple}
        length={length}
        digits={digits}
        decimals={decimals}
        regex={regex}
        onUpdate={updateValue}
        onValidation={updateValidation}
      />
    )
  }, [
    decimals,
    defaultValue,
    description,
    digits,
    format,
    help,
    id,
    length,
    multiple,
    options,
    regex,
    required,
    title,
    type,
    updateValidation,
    updateValue
  ])
}
