import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Grid, Fade, makeStyles } from '@material-ui/core'
import { FormTextField } from './FormElements'

const useStyles = makeStyles(theme => ({}))

export default function LegalPersonFields({ values, errors, onChange, onBlur, onFocus }) {
  const classes = useStyles()
  return (
    <Grid item xs={12}>
      <Fade in>
        <FormTextField
          required
          id="entityName"
          name="entityName"
          error={(errors.entityName || errors.person) && !values.entityName}
          value={values.entityName}
          //size="small"
          label="Razón social"
          fullWidth
          variant="outlined"
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </Fade>
    </Grid>
  )
}
