import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import FormLabel from '@material-ui/core/FormLabel'

import Avatar from '@material-ui/core/Avatar'

import OfflinePinTwoToneIcon from '@material-ui/icons/OfflinePinTwoTone'
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import NotInterestedIcon from '@material-ui/icons/NotInterested'

import DialogContentIconSection from '../Dialog/DialogContentIconSection'

import { TextField } from '@material-ui/core'
import { DateRangePicker, DateRangeDelimiter } from '@material-ui/pickers'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import { Date as DateField } from '../Fields/Date'
import { FormHelperText } from '@material-ui/core'

const useStyles = makeStyles(({ palette, spacing }) => ({
  dialog: {
    border: '2px solid black'
  },
  paper: {
    width: spacing(18),
    height: spacing(27),
    padding: spacing(4, 1),
    marginTop: spacing(2),
    textAlign: 'center',
    overflow: 'hidden',
    transition: 'background 0.3s ease, color 0.3s ease'
  }
}))

/**
Use a DocumentDialog when you want to show document details.

- Renders to the full width of its parent element.
- Starts with 2 lines and grows up to 10 lines of text before showing a scrollbar.
- The underlying input field blur event is logged on the analytics platform.
*/

export default function DocumentDialog({
  open,
  onClose,
  id,
  title,
  description,
  required,
  reusable,
  signature,
  multiple,
  isDragActive,
  isDragAccept,
  isDragReject,
  isLoading,
  hasFile,
  preview
}) {
  const classes = useStyles()
  const handleOk = () => onClose()
  const handleCancel = () => onClose()
  const [selectedDate, handleDateChange] = React.useState([new Date(), new Date()])
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      fullWidth
      classes={{ paper: classes.dialog }}
      aria-labelledby={`${id}-dialog-title`}
      open={open}
    >
      <DialogTitle id={`${id}-dialog-title`} disableTypography>
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <DialogContentIconSection icon={<RotateLeftIcon />} color="success">
              <Typography variant="h6" gutterBottom>
                Puede ser reutilizado
              </Typography>
              <Typography variant="body1" paragraph>
                En futuros trámites no deberás volver a aportar este documento.
              </Typography>
            </DialogContentIconSection>
            <DialogContentIconSection icon={<LockTwoToneIcon />} color="secondary">
              <Typography variant="h6" gutterBottom>
                Sellado electrónicamente
              </Typography>
              <Typography variant="body2" paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Vitae justo eget magna fermentum
                iaculis eu non. Eget dolor morbi non arcu risus quis varius.
              </Typography>
            </DialogContentIconSection>
          </Grid>
          <Grid item xs={6}>
            <DialogContentIconSection icon={<NotInterestedIcon />} color="error">
              <Typography variant="h6" gutterBottom>
                No se podrá reutilizar
              </Typography>
              <Typography variant="body1" paragraph>
                Te has opuesto a la reutilización del documento por parte de la administración
                pública. En futuros trámites deberás volver a aportarlo.
              </Typography>
              <Typography variant="overline">Motivo:</Typography>
              <Typography variant="body2" paragraph>
                Te has opuesto a la reutilización del documento por parte de la administración
                pública. En futuros trámites deberás volver a aportarlo.
              </Typography>
              <Button variant="outlined">Quiero permitir su reutilización</Button>
            </DialogContentIconSection>
            <DialogContentIconSection icon={<LockTwoToneIcon />} color="secondary">
              <Typography variant="h6" gutterBottom>
                Firmado electrónicamente
              </Typography>
              <Typography variant="body2" paragraph>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Vitae justo eget magna fermentum
                iaculis eu non. Eget dolor morbi non arcu risus quis varius.
              </Typography>
            </DialogContentIconSection>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={handleCancel} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button variant="contained" disableElevation onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
