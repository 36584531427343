import { merge } from 'lodash'
import { createMuiTheme, responsiveFontSizes, fade } from '@material-ui/core/styles'
import blueGrey from '@material-ui/core/colors/blueGrey'
import shadows from './shadows'
import transitions from './transitions'

// Color manipulation
function hexToRGBA(h, a) {
  let r = 0
  let g = 0
  let b = 0

  // 3 digits
  if (h.length == 4) {
    r = `0x${h[1]}${h[1]}`
    g = `0x${h[2]}${h[2]}`
    b = `0x${h[3]}${h[3]}`

    // 6 digits
  } else if (h.length == 7) {
    r = `0x${h[1]}${h[2]}`
    g = `0x${h[3]}${h[4]}`
    b = `0x${h[5]}${h[6]}`
  }

  return `rgba(${parseInt(r, 16)},${parseInt(g, 16)},${parseInt(b, 16)},${a})`
}

// Font families
const headingFontFamily =
  '"Metropolis", "Zilla Slab", "News Cycle", "Roboto", "Helvetica", "Arial", sans-serif'
const copyFontFamily = '"Inter", "Roboto", "Helvetica", "Arial", sans-serif'

// Palette
const primaryColor = '#0f63f2'
const secondaryColor = '#f29f0f'
const actionColor = blueGrey[900]
const disabledColor = blueGrey[400]
const fieldBorder = blueGrey[200]
const fieldLabel = blueGrey[600]

// Base values copied from material-ui/src/styles/createTypography.js for consistency
// We can use them to tweak the defaults
const fontSize = 14
const htmlFontSize = 16
const coef = fontSize / 14
const pxToRem = size => `${(size / htmlFontSize) * coef}rem`
const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 600
const fontWeightBold = 700

// The default theme properties
const defaultThemeConfig = {
  breakpoints: {
    values: {
      xs: 0, // mobile screens
      sm: 600, // up to 600 large mobile, 600+ small tablet
      md: 1024, // up to 960 large tablet, 960+ small laptop screen
      lg: 1280, // up to 1280 regular laptop screen, 1280+ large laptop screen
      xl: 1920 // up to 1920 regular desktop screen, 1920+ high resolution screen
    }
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: primaryColor //'#0f63f2'
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.secondary.main,
      main: secondaryColor //'#f29f0f'
      // dark: will be calculated from palette.secondary.main,
      // contrastText: will be calculated from palette.secondary.main,
    },
    text: { primary: blueGrey[900], secondary: blueGrey[600] },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    action: {
      active: fade(actionColor, 0.54),
      selected: fade(actionColor, 0.08),
      hover: fade(actionColor, 0.04),
      disabled: fade(disabledColor, 0.26),
      disabledBackground: fade(disabledColor, 0.12),
      focus: fade(actionColor, 0.12)
    },
    divider: fade(actionColor, 0.12),
    background: {
      default: '#fff'
    },
    field: {
      border: fieldBorder,
      label: fieldLabel
    }
  },
  typography: {
    fontFamily: copyFontFamily,
    h1: {
      fontFamily: headingFontFamily,
      //lineHeight: 1.0,
      fontWeight: fontWeightLight
    },
    h2: {
      fontFamily: headingFontFamily,
      //lineHeight: 1.0,
      fontWeight: fontWeightBold
    },
    h3: {
      fontFamily: headingFontFamily,
      //lineHeight: 1.167,
      fontWeight: fontWeightRegular
    },
    h4: {
      fontFamily: headingFontFamily,
      //lineHeight: 1.167,
      fontWeight: fontWeightLight
    },
    h5: {
      fontFamily: headingFontFamily,
      //lineHeight: 1.25,
      fontWeight: fontWeightMedium
      //fontWeight: fontWeightRegular
    },
    h6: {
      fontFamily: headingFontFamily,
      //lineHeight: 1.25,
      fontWeight: fontWeightRegular
      //fontWeight: fontWeightRegular
    },
    subtitle1: {
      fontWeight: fontWeightMedium
      //lineHeight: 1.67
    },
    subtitle2: {
      fontWeight: fontWeightMedium
    }
  },
  shadows,
  transitions,
  overrides: {
    MuiButton: {
      root: { borderRadius: 24, fontWeight: fontWeightMedium },
      outlined: { borderWidth: 2, borderColor: blueGrey[200] },
      outlinedPrimary: { borderWidth: 2, '&:hover': { borderWidth: 2 } },
      outlinedSecondary: { borderWidth: 2, '&:hover': { borderWidth: 2 } }
    },
    MuiIconButton: {
      root: { color: blueGrey[900] }
    },
    MuiChip: {
      root: { backgroundColor: blueGrey[50], color: blueGrey[900] },
      icon: { color: 'inherit' },
      outlined: { borderWidth: 2, borderColor: blueGrey[200] },
      outlinedPrimary: { borderWidth: 2 },
      outlinedSecondary: { borderWidth: 2 }
    },
    MuiFormLabel: {
      root: { color: fieldLabel, lineHeight: 1.3 }
    },
    MuiFormHelperText: {
      root: { color: blueGrey[400], lineHeight: 1.4 }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderWidth: 2,
        borderColor: fieldBorder
      },
      root: {
        '&$disabled': {
          backgroundColor: fade(disabledColor, 0.03)
        }
      }
    },
    MuiSelect: {
      outlined: {
        paddingRight: 36
      },
      iconOutlined: {
        right: 12
      }
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: blueGrey[50],
        color: blueGrey[900]
      }
    },
    MuiSkeleton: {
      root: {
        backgroundColor: blueGrey[50]
      }
    }
  }
}

// Filter only the properties we allow to be modified, deeply merge with defaults and create the theme
const makeLocalTheme = config => {
  const { theme: { branding } = { branding: {} } } = config
  const { theme: { palette } = { palette: {} } } = config

  const allowedPalette = ['primary', 'secondary', 'error', 'warning', 'info', 'success']

  const filteredPalette = Object.keys(palette)
    .filter(key => allowedPalette.includes(key))
    .reduce((obj, key) => {
      return {
        ...obj,
        [key]: palette[key]
      }
    }, {})

  const localThemeConfig = merge(defaultThemeConfig, { palette: filteredPalette }, { branding })

  return responsiveFontSizes(createMuiTheme(localThemeConfig))
}

// Theme without local config
const defaultTheme = createMuiTheme(defaultThemeConfig)

export default responsiveFontSizes(defaultTheme)
export { makeLocalTheme }
