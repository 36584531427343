import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { useParams, useRouteMatch, Link as RouterLink } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import Divider from '@material-ui/core/Divider'
import Grow from '@material-ui/core/Grow'

// import Fab from '@material-ui/core/Fab'

import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'

import { useSchemaDefinition } from '../../../Context/SchemaContext'
import { useProgressState } from '../../../Context/ProgressContext'

const useStyles = makeStyles(theme => ({
  navRoot: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  nav: {
    padding: theme.spacing(2, 0)
  },
  prev: {
    '& $typography': {
      marginLeft: theme.spacing(6),
      // Small tablets
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0 // reset
      }
    }
  },
  next: {
    textAlign: 'right',
    '& $typography': {
      marginRight: theme.spacing(6),
      // Small tablets
      [theme.breakpoints.down('sm')]: {
        marginRight: 0 // reset
      }
    },
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      marginBottom: theme.mixins.toolbar.minHeight
    }
  },
  typography: {},
  button: {
    marginBottom: theme.spacing(1)
  },
  heroDivider: {
    height: '2px',
    background: theme.palette.text.secondary
  },
  fab: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 2,
    top: 40,
    right: 64
  }
}))

// Pendiente:
// Añadir el FAB para avanzar a tramitar

function PrevNavigationButton({ edge }) {
  const classes = useStyles()

  // Routing: step url parameter
  const fake = useRouteMatch('/tramite/:pid/formulario/:step')
  const { pid, step } = useParams()

  // Context: request definition
  const def = useSchemaDefinition()
  const i = Array.from(def).findIndex(v => v.step === step)
  const first = Array.from(def)[0]
  const current = Array.from(def)[i]
  const prev = Array.from(def)[Math.max(0, i - 1)]
  const next = Array.from(def)[Math.min(def.size - 1, i + 1)]
  const last = Array.from(def).pop()

  return (
    step !== first.step && (
      <IconButton
        component={RouterLink}
        edge={edge}
        to={fake ? `/tramite/${pid}/formulario/${prev.step}` : `/layouts/desktop/${prev.step}`}
      >
        <ArrowBackIcon />
      </IconButton>
    )
  )
}

export { PrevNavigationButton }

export default function Buttons({ growEl }) {
  const classes = useStyles()
  const footerVisible = useScrollTrigger({
    disableHysteresis: true,
    threshold: growEl.current ? growEl.current.offsetTop - window.innerHeight + 180 : null
  })

  // Routing: step url parameter
  const fake = useRouteMatch('/tramite/:pid/formulario/:step')
  const { pid, step } = useParams()

  // Context: request definition
  const def = useSchemaDefinition()
  const i = Array.from(def).findIndex(v => v.step === step)
  const first = Array.from(def)[0]
  const current = Array.from(def)[i]
  const prev = Array.from(def)[Math.max(0, i - 1)]
  const next = Array.from(def)[Math.min(def.size - 1, i + 1)]
  const last = Array.from(def).pop()

  // Context: request progress
  const state = useProgressState()
  const pendingIndex = Array.from(def).findIndex(
    el => el.isComplete(state.progress[el.type]) === false
  )

  return (
    <>
      <Grid container className={classes.navRoot} spacing={4}>
        <Hidden xsDown>
          <Grid item sm={6} className={clsx(classes.nav, classes.prev)}>
            {step !== first.step && (
              <>
                <Button
                  variant="outlined"
                  size="large"
                  className={classes.button}
                  startIcon={<ArrowBackIcon />}
                  component={RouterLink}
                  to={
                    fake
                      ? `/tramite/${pid}/formulario/${prev.step}`
                      : `/layouts/desktop/${prev.step}`
                  }
                >
                  Volver
                </Button>

                <Typography variant="h5" className={classes.typography}>
                  {prev.fullName}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  className={classes.typography}
                  paragraph
                >
                  <Typography component="span" variant="body2" color="textPrimary">
                    {prev.isComplete(state.progress[prev.type]) ? 'Completado' : 'Pendiente'}
                  </Typography>
                  {' — '}
                  {prev.getProgressMessage(state.progress[prev.type])}
                </Typography>
              </>
            )}
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={6} className={clsx(classes.nav, classes.next)}>
          {/*<Grow in={!footerVisible} timeout={350} style={{ transformOrigin: 'center center' }}>
            <Fab color="secondary" aria-label="add" className={classes.fab} variant="extended">
              <ArrowForwardIcon className={classes.extendedIcon} />
              Presentar la solicitud
            </Fab>
            </Grow>*/}
          {step !== last.step && (
            <>
              <Button
                variant="contained"
                size="large"
                disableElevation
                color={next.step === last.step ? 'primary' : 'primary'}
                className={classes.button}
                endIcon={<ArrowForwardIcon />}
                component={RouterLink}
                to={
                  fake ? `/tramite/${pid}/formulario/${next.step}` : `/layouts/desktop/${next.step}`
                }
                disabled={!current.isComplete(state.progress[current.type])}
              >
                Continuar
              </Button>

              <Typography variant="h5" className={classes.typography}>
                {next.fullName}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.typography}
                paragraph
              >
                {next.step !== last.step && false && (
                  <>
                    <Typography component="span" variant="body2" color="textPrimary">
                      {current.isComplete(state.progress[current.type])
                        ? 'Sección completa'
                        : 'No disponible'}
                    </Typography>
                    {' — '}
                  </>
                )}
                {current.getProgressMessage(state.progress[current.type])}
              </Typography>
            </>
          )}
          {/*step !== last.step && (
            <>
              <Button
                variant="contained"
                size="large"
                disableElevation
                color={'primary'}
                className={classes.button}
                component={RouterLink}
                to={
                  fake ? `/tramite/${pid}/formulario/${next.step}` : `/layouts/desktop/${next.step}`
                }
                disabled={!current.isComplete(state.progress[current.type])}
              >
                Continuar <ArrowForwardIcon />
              </Button>

              <Typography variant="h5" className={classes.typography}>
                {next.fullName}
              </Typography>
              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.typography}
                paragraph
              >
                <Typography component="span" variant="body2" color="textPrimary">
                  {next.isComplete(state.progress[next.type]) ? 'Completado' : 'Pendiente'}
                </Typography>
                {' — '}
                {next.getProgressMessage(state.progress[next.type])}
              </Typography>
            </>
              )*/}

          {/*step === last.step && (
            <>
              <Button
                variant="contained"
                size="large"
                disableElevation
                color="secondary"
                className={classes.button}
                component={RouterLink}
                to={fake ? `/tramite/${pid}/presentacion` : `/layouts/desktop/`}
                disabled={pendingIndex >= 0}
              >
                Continuar <ArrowForwardIcon />
              </Button>

              <Typography variant="h5" className={classes.typography}>
                Presentar la solicitud
              </Typography>
            </>
          )*/}
        </Grid>
      </Grid>
      {/*<Grow in={footerVisible} timeout={350} style={{ transformOrigin: 'top left' }}>
        <Divider className={classes.heroDivider} />
          </Grow>*/}
    </>
  )
}

Buttons.propTypes = {
  growEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.oneOfType([
        PropTypes.instanceOf(HTMLInputElement),
        PropTypes.instanceOf(Element)
      ])
    })
  ])
}

Buttons.defaultProps = {
  growEl: { current: null }
}
