import React from 'react'
import clsx from 'clsx'

import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import { makeStyles } from '@material-ui/core/styles'

import DocumentFileMeta from '../Sections/DocumentFileMeta'
import DocumentPreview from '../Sections/DocumentPreview'

const useStyles = makeStyles(({ palette, spacing, shadows, typography }) => ({
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: spacing(6)
  },
  paper: {
    position: 'relative',
    width: spacing(1 * 12),
    height: spacing(1 * 18),
    borderWidth: 2,
    borderColor: palette.field.border,
    textAlign: 'center',
    overflow: 'hidden',
    transition: 'box-shadow 0.3s ease, color 0.3s ease, transform 0.3s ease',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: spacing(2) // we have no badge here
  },

  paperPreview: {
    background: palette.common.white,
    borderColor: palette.text.primary,
    padding: 0
  }
}))

export default function FileListItem({ file }) {
  const classes = useStyles()
  return (
    <Grid item xs={12} sm={4} md={3} lg={3} className={classes.section}>
      <Paper className={clsx(classes.paper, classes.paperPreview)} variant="outlined">
        <DocumentPreview file={file} />
      </Paper>
      {/* Document Meta */}
      <DocumentFileMeta files={[file]} />
    </Grid>
  )
}
