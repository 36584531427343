import React, { useState, useCallback, useEffect } from 'react'
import debounce from 'lodash/debounce'

import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useAutocomplete from '@material-ui/lab/useAutocomplete'

import ObjectModalBar from './Object/ObjectModalBar'
import ObjectModalOptions from './Object/ObjectModalOptions'
import ObjectModalDetails from './Object/ObjectModalDetails'

import SearchInput from './Search/SearchInput'
import SearchListbox from './Search/SearchListbox'

// to be moved to ./Sections
import Door from '../../Door'

import { useLocationContext } from '../../../../components/Context/LocationContext'

export default function SearchPicker({
  title,
  open,
  value,
  // location,
  anchorEl,
  onClose,
  onChange,
  onClear,
  onDrawerOpen
}) {
  // Variant
  const theme = useTheme()
  const fullscreen = useMediaQuery(theme.breakpoints.down('xs'))

  // Location context
  const [defaultLocation, locationUtils, addressUtils] = useLocationContext()

  // Controlled autocomplete values
  // The input value (what's typed)
  // The selected value (the object)
  const [inputValue, setInputValue] = useState(
    addressUtils.isEmpty(value)
      ? ''
      : locationUtils.access.getAccessInputLabel(value.street, value.access)
  )
  const [selected, setSelected] = useState(
    addressUtils.isEmpty(value) ? '' : { ...value.access.entity, street: value.street.entity }
  )

  // Controlled query
  const [query, setQuery] = useState('')
  const [accesses, isLoading, isError] = locationUtils.access.findAccess(query)

  // Picker state
  const [listboxOpen, setListboxOpen] = useState(false) // Controls showing the list of results
  const [settingsOpen, setSettingsOpen] = useState(false) // Controls showing the settings panel

  // Change handlers
  const handleClearAddress = useCallback(() => {
    const empty = { ...addressUtils.emptyAddress, location: value.location }
    //setAddress(empty)
    setInputValue('')
    setSelected('')
    onChange(empty)
  }, [value.location, onChange, addressUtils.emptyAddress])
  const handleAccessChange = v => onChange(prev => addressUtils.updateAccess(prev, v))
  const handleAddressChange = useCallback(newAddress => onChange(newAddress), [onChange])

  console.log('search picker here with location:')
  console.log(value.location)

  // SIDE-EFFECTs
  // If inbound value changes and it's cleared
  /*useEffect(() => {
    if (!value?.properties) handleClearAddress()
  }, [value, handleClearAddress])

  // If location changes, reset the local address
  useEffect(() => {
    setAddress({ ...value, location })
  }, [location, value])*/

  // On render
  useEffect(() => {
    setListboxOpen(!selected)
  }, [selected])

  // Debounced query updater
  const debouncedQuery = useCallback(
    debounce(q => {
      setQuery(q)
      setListboxOpen(true)
    }, 350),
    []
  )

  // Input value change
  const handleInputValueChange = (e, v, r) => {
    if (r === 'input') {
      setInputValue(v)
      debouncedQuery(v)
    }
  }

  // Selection change
  const handleSelectionChange = (event, val, reason) => {
    setSelected(val)
    setInputValue(locationUtils.access.getAccessOptionLabel(val))
    console.log(val)
    console.log(`changed bc ${reason}`)
    // reasons: "create-option", "select-option", "remove-option", "blur" or "clear".
    if (reason === 'select-option') {
      setListboxOpen(false)
      handleAccessChange(val)
    }
    if (reason === 'clear') {
      handleClearAddress()
    }
  }

  // Autocomplete close intent
  const handleCloseIntent = (event, reason) => {
    console.log(`wants to close bc ${reason}`)
    // reasons: blur, toggleInput, select-option, escape
    const allowedExits = ['escape']
    if (allowedExits.includes(reason)) {
      onClose()
      //setAnchorEl(null)
    }
  }

  // Autocomplete configuration
  const {
    getRootProps,
    // getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    // getClearProps,
    groupedOptions
  } = useAutocomplete({
    id: 'autocomplete-coded-address-searcher',
    value: selected,
    inputValue,
    onClose: handleCloseIntent,
    onChange: handleSelectionChange,
    onInputChange: handleInputValueChange,
    getOptionLabel: locationUtils.access.getAccessOptionInputLabel,
    getOptionSelected: locationUtils.access.getAccessOptionSelected,
    filterOptions: x => x,
    options: accesses,
    autoComplete: true,
    includeInputInList: true,
    disableCloseOnSelect: true,
    disablePortal: true,
    open: listboxOpen,
    blurOnSelect: true
  })

  return (
    <>
      <ObjectModalBar>
        <SearchInput
          title={title}
          fullscreen={fullscreen}
          isLoading={isLoading}
          listboxOpen={listboxOpen}
          getInputProps={getInputProps}
          getRootProps={getRootProps}
          handleClose={onClose}
        />
      </ObjectModalBar>
      <ObjectModalOptions
        location={value.location}
        visible={listboxOpen}
        open={settingsOpen}
        handleOpen={onDrawerOpen}
        handleClose={onClose}
        handleSettings={() => setSettingsOpen(true)}
      />
      <SearchListbox
        visible={listboxOpen}
        isSearch={Boolean(query)}
        isLoading={isLoading}
        isError={isError}
        getListboxProps={getListboxProps}
        options={groupedOptions}
        getOptionProps={getOptionProps}
      />
      <ObjectModalDetails listboxOpen={listboxOpen} handleClose={() => onClose(value)}>
        <Door onChange={handleAddressChange} properties={value.properties} autoFocus />
      </ObjectModalDetails>
    </>
  )
}
