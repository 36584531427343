import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Grid from '@material-ui/core/Grid'
import Fab from '@material-ui/core/Fab'
import Button from '@material-ui/core/Button'

import CheckIcon from '@material-ui/icons/Check'

import Hidden from '@material-ui/core/Hidden'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  fabSection: {
    width: '100%',
    position: 'fixed',
    bottom: theme.mixins.toolbar.minHeight,
    display: 'flex',
    justifyContent: 'center'
  },
  fab: {},
  button: { marginLeft: 'auto' },
  door: {
    paddingLeft: theme.spacing(5.5),
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      // marginTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  alignRight: {
    textAlign: 'right'
  }
}))

export default function ObjectModalDetails({ listboxOpen, handleClose, disabled, children }) {
  const classes = useStyles()
  return (
    !listboxOpen && (
      <>
        <Grid container spacing={2} className={classes.door}>
          {children}
        </Grid>
        <Hidden xsDown>
          <section className={clsx(classes.door, classes.alignRight)}>
            <Button
              size="large"
              color="primary"
              variant="text"
              endIcon={<CheckIcon />}
              onClick={handleClose}
              className={classes.button}
              disabled={disabled}
            >
              Seleccionar
            </Button>
          </section>
        </Hidden>
        <Hidden smUp>
          <section className={classes.fabSection}>
            <Fab
              variant="extended"
              className={classes.fab}
              color="primary"
              onClick={handleClose}
              disabled={disabled}
            >
              <CheckIcon /> Seleccionar
            </Fab>
          </section>
        </Hidden>
      </>
    )
  )
}

ObjectModalDetails.propTypes = {
  listboxOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

ObjectModalDetails.defaultProps = {
  listboxOpen: true,
  disabled: false,
  handleClose: () => {}
}
