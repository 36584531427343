import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { some, filter, isEmpty, isEqual, xorWith } from 'lodash'

import { Amplitude } from '@amplitude/react-amplitude'
import { useDropzone } from 'react-dropzone'
import { getType } from 'mime'

import { makeStyles } from '@material-ui/core/styles'

import DocumentCard from './NewDocumentCard'
import FileList from './List/FileList'
import FileListItem from './List/FileListItem'

import DocumentDialog from './DocumentDialog'
import DocumentPreview from './Sections/DocumentPreview'

import { useProgressUpdater } from '../../components/Context/ProgressContext'
import { useEffect } from 'react'

// Preferred document extensions
const scores = {
  pdf: 100,
  docx: 91,
  doc: 90
}

// Compare arrays of objects
const isArrayEqual = (x, y) => isEmpty(xorWith(x, y, isEqual))

const useStyles = makeStyles(({ spacing }) => ({}))

/**
Use a Document when you want the user to input a file.

- Files can be selected through the browser/os user interface
- Files can also be dragged to the field
- Selection can be cleared
- Accepted file types follow HTML the regular accept attribute of file input components (a string of comma-delimited unique file type specifiers: extensions, mime-types, etc.)
- Beware: on drag operations extensions are not available (File Web API does not make path and name available), best to use a redundant accept string with extensions + mimes

Current implementation:

- Is just a simple display component: does nothing with the file, does no validations, can't render pre-uploaded files
- Does not handle multiple documents
- Knows and displays nothing about signature or reusability

*/

export default function Document({
  id,
  title,
  description,
  required,
  reusable,
  signature,
  multiple,
  maxSize,
  accepts,
  defaultValue,
  onUpdate,
  onValidation
}) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  // Accept input attribute
  const [accept] = useState(
    accepts.length > 0
      ? accepts
          .sort((a, b) => {
            const scoreA = scores[a] || 0
            const scoreB = scores[b] || 0
            return scoreB - scoreA
          })
          .map(extension => {
            const type = getType(extension)
            return type ? `.${extension},${type}` : `.${extension}`
          })
          .join(',')
      : undefined
  )

  // Single file management
  const [file, setFile] = useState(defaultValue)

  // Multiple file management
  const [files, setFiles] = useState(defaultValue)

  // Loading state
  // const [loading, setLoading] = useState(false)

  // File dropzone management
  const [droppedFiles, setDroppedFiles] = useState([])

  const onDropAccepted = useCallback(
    acceptedFiles => {
      // Find duplicates (files already attached)
      const duplicates = acceptedFiles.filter(acceptedFile => some(files, acceptedFile))

      // Give feedback
      console.log('duplicates found')
      console.log(duplicates)

      // Filter out duplicates
      const filtered = filter(acceptedFiles, el => !some(files, el))

      setDroppedFiles(filtered)
    },
    [files]
  )

  const onDropRejected = useCallback((fileRejections, event) => {
    console.log(fileRejections)
    console.log(event)
    // Check rejection motives:
    // If mime-type is not valid
    // If size exceeds max
    // Give feedback
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open
  } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    multiple,
    maxSize,
    disabled: (!multiple && files.length) ?? false,
    noKeyboard: true,
    noClick: true
  })

  // Style classes with drop props
  const classes = useStyles({ isDragActive, isDragAccept, isDragReject })

  // Remove handler
  const handleRemove = () => {
    setFiles([])
    onUpdate(id, [])
    onValidation(id, false)
  }

  // Side-effect of successful file drop
  useEffect(() => {
    // We don't care about multiple files yet
    // We don't send them to the server yet (future hint: test posting to httpbin.org)
    /*droppedFiles.forEach(f => {
      setFile(f)
      onUpdate(id, f)
      onValidation(id, true)
    })*/

    // We care about multiple files
    // We don't send them to the server yet (future hint: test posting to httpbin.org)
    if (droppedFiles.length) {
      const newFiles = multiple ? [...droppedFiles, ...files] : droppedFiles
      console.log('updating files')
      console.log(newFiles)
      setFiles(newFiles)
      setDroppedFiles([])
      onUpdate(id, newFiles)
      onValidation(id, true)
    }
    //onUpdate(id, newFiles)
    //onValidation(id, true)
    //console.log(newFiles)
  }, [droppedFiles, files, onUpdate, onValidation, id, multiple])

  // Side-effect of updating files
  /*useEffect(() => {
    if (!isArrayEqual(files, defaultValue)) {
      console.log('current values')
      console.log(files)
      console.log(defaultValue)
      onUpdate(id, files)
      onValidation(id, true)
      console.log('sending new files to state')
    }
  }, [files, defaultValue, id, onUpdate, onValidation])*/

  return (
    <Amplitude
      eventProperties={inheritedProps => ({
        ...inheritedProps,
        scope: [...inheritedProps.scope, 'document']
      })}
    >
      {({ instrument }) => (
        <>
          <input
            accept={accept}
            className={classes.input}
            id={`input-for-file-${id}`}
            multiple
            type="file"
            hidden
            {...getInputProps()}
          />
          <DocumentCard
            id={id}
            title={title}
            description={description}
            required={required}
            documentProps={{ reusable, signature, multiple, accepts }}
            interactionProps={{ isDragActive, isDragAccept, isDragReject, isLoading: false }}
            fileProps={{ hasFile: Boolean(files.length), files }}
            actionAreaProps={{ ...getRootProps() }}
            actionHandlers={{
              handleAdd: instrument('add button', open),
              handleEdit: instrument('edit button', () => setIsDialogOpen(true)),
              handleRemove: instrument('remove button', handleRemove)
            }}
          />
          <FileList
            id={id}
            title={title}
            description={description}
            open={isDialogOpen}
            onClose={() => setIsDialogOpen(false)}
          >
            {files.map(element => (
              <FileListItem file={element} key={element.name} />
            ))}
          </FileList>
        </>
      )}
    </Amplitude>
  )
}

Document.propTypes = {
  /**
    DBOID of the required document.
  */
  id: PropTypes.string.isRequired,
  /**
    Label.
  */
  title: PropTypes.string.isRequired,
  /**
    Additional label.
  */
  description: PropTypes.string,
  /**
    Use this to indicate that a file must be provided.
  */
  required: PropTypes.bool,
  /**
    If it can be reused on future requests.
  */
  reusable: PropTypes.bool,
  /**
    If must be signed with a digital certificate.
  */
  signature: PropTypes.bool,
  /**
    If it accepts uploading multiple files.
  */
  multiple: PropTypes.bool,
  /**
    Max filesize admitted in bytes.
  */
  maxSize: PropTypes.number,
  /**
    Array of extensions used to build a comma-separated list of unique file type specifiers (see HTML input file accept attribute).
  */
  accepts: PropTypes.arrayOf(PropTypes.string),
  /**
    Saved value, array of File objects.
  */
  defaultValue: PropTypes.arrayOf(PropTypes.shape({})),
  /**
    Handler to be called when a new value needs to be shared
    @param {string} id - The id of the field.
    @param {any} value - The new value.
  */
  onUpdate: PropTypes.func,
  /**
   Handler to be called when a new validation needs to be shared
  @param {string} id - The id of the field.
  @param {bool} value - The validation result.
  */
  onValidation: PropTypes.func
}

Document.defaultProps = {
  description: null,
  required: false,
  reusable: false,
  signature: false,
  multiple: false,
  maxSize: 30000000,
  accepts: ['xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'doc', 'docx', 'csv'], // demo
  defaultValue: [],
  onUpdate: () => {},
  onValidation: () => {}
}
