import React from 'react'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'

import Chip from '@material-ui/core/Chip'
import Transition from '@material-ui/core/Fade'
import Divider from '@material-ui/core/Divider'

import Grow from '@material-ui/core/Grow'

import Checkbox from '@material-ui/core/Checkbox'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'

import Avatar from '@material-ui/core/Avatar'
import Toolbar from '@material-ui/core/Toolbar'

import CheckIcon from '@material-ui/icons/Check'
import PeopleTwoToneIcon from '@material-ui/icons/PeopleTwoTone'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  header: {
    //fontWeight: 400
  },
  block: {
    marginLeft: theme.spacing(2.25) + 1,
    paddingLeft: theme.spacing(3.5),
    //paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    borderLeft: '2px solid #eceff1'
    //marginBottom: theme.spacing(1)
  },
  avatar: { marginRight: theme.spacing(1) }
}))

export default function SummaryStep({ title, icon, children }) {
  const classes = useStyles()
  return (
    <>
      <Toolbar disableGutters>
        <Avatar className={classes.avatar}>{icon}</Avatar>
        <Typography variant="h6" className={classes.header}>
          {title}
        </Typography>
      </Toolbar>
      <section className={classes.block}>{children}</section>
    </>
  )
}

SummaryStep.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}
