import React, { useState } from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import { makeStyles } from '@material-ui/core/styles'

import Selector from '../../../lib/Fields/Address/Pickers/Options/LocationForm'
import { useLocationContext } from '../../Context/LocationContext'

const useStyles = makeStyles(theme => ({
  page: { marginTop: theme.spacing(4) }
}))

export default function Page() {
  const classes = useStyles()

  // Location context
  const [defaultLocation, locationUtils, addressUtils] = useLocationContext()

  // Filled address state
  const [filledValue, setFilledValue] = useState({
    ...addressUtils.emptyAddress,
    location: defaultLocation
  })

  // Filled location state
  const [filledLocation, setFilledLocation] = useState(filledValue.location)
  console.log(filledLocation)
  const handleFilledLocationChange = newLocation => {
    setFilledLocation(newLocation)
  }

  // Empty address state
  const [emptyValue, setEmptyValue] = useState({
    ...addressUtils.emptyAddress,
    location: {}
  })

  // Empty location state
  const [emptyLocation, setEmptyLocation] = useState(emptyValue.location)
  console.log(emptyLocation)
  const handleEmptyLocationChange = newLocation => {
    setEmptyLocation(newLocation)
  }

  // Partial address state
  const [partialValue, setPartialValue] = useState({
    ...addressUtils.emptyAddress,
    location: { ...defaultLocation, province: undefined, municipality: undefined }
  })

  // Partial location state
  const [partialLocation, setPartialLocation] = useState(partialValue.location)
  console.log(partialLocation)
  const handlePartialLocationChange = newLocation => {
    setPartialLocation(newLocation)
  }

  return (
    <Container maxWidth="md" className={classes.page}>
      <Grid container spacing={2}>
        <Selector location={filledLocation} onChange={handleFilledLocationChange} />
      </Grid>
      <pre>{JSON.stringify(filledLocation, null, 2)}</pre>
      <Grid container spacing={2}>
        <Selector location={emptyLocation} onChange={handleEmptyLocationChange} />
      </Grid>
      <pre>{JSON.stringify(emptyLocation, null, 2)}</pre>
      <Grid container spacing={2}>
        <Selector location={partialLocation} onChange={handlePartialLocationChange} />
      </Grid>
      <pre>{JSON.stringify(partialLocation, null, 2)}</pre>
    </Container>
  )
}
