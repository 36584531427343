import React from 'react'
import clsx from 'clsx'

import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { makeStyles, fade } from '@material-ui/core/styles'

import { ControlledPopover } from './Popover'
import { ControlledDrawer } from './Drawer'

export default function Dropdown({ open, drawerAnchor, anchorEl, handleClose, children }) {
  const mobile = useMediaQuery(theme => theme.breakpoints.down('xs'))
  return mobile ? (
    <ControlledDrawer open={open} anchor={drawerAnchor} handleClose={handleClose}>
      {children}
    </ControlledDrawer>
  ) : (
    <ControlledPopover open={open} anchorEl={anchorEl} handleClose={handleClose}>
      {children}
    </ControlledPopover>
  )
}

const useStyles = makeStyles(theme => ({
  padding: { padding: theme.spacing(0, 2) },
  header: { marginTop: theme.spacing(2) },
  iconText: {
    background: fade(theme.palette.secondary.light, 0.25),
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(2)
  },
  icon: { minWidth: 'unset', padding: theme.spacing(1), color: 'inherit' },
  centered: { textAlign: 'center' },
  highlight: { background: fade(theme.palette.primary.light, 0.25) }
}))

function DropdownHeader({ children }) {
  const classes = useStyles()
  return (
    <Typography variant="h6" gutterBottom className={clsx(classes.header, classes.padding)}>
      {children}
    </Typography>
  )
}

function DropdownText({ small, bold, centered, highlight, children }) {
  const classes = useStyles()
  return (
    <Typography
      paragraph
      variant={small ? 'body2' : bold ? 'subtitle1' : 'body1'}
      className={clsx(
        classes.padding,
        centered && classes.centered,
        highlight && classes.highlight
      )}
    >
      {children}
    </Typography>
  )
}

function DropdownIconText({ children, icon }) {
  const classes = useStyles()
  return (
    <ListItem className={classes.iconText}>
      <ListItemText disableTypography>
        <Typography variant="subtitle1">{children}</Typography>
      </ListItemText>
      <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
    </ListItem>
  )
}

function DropdownActions({ children }) {
  return <Toolbar>{children}</Toolbar>
}

export { DropdownHeader, DropdownText, DropdownIconText, DropdownActions }
