import { useState, useEffect } from 'react'

import axios from 'axios'

export default function useFields(pid) {
  const [fields, setFields] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    const fetchRequest = async req => {
      setIsError(false)
      setIsLoading(true)
      try {
        const result = await axios(`http://10.49.95.50:8380/sta/api/v1/solicitud/${req}`)
        if (result.data.variables) setFields(result.data.variables)
      } catch (error) {
        setIsError(true)
      }
      setIsLoading(false)
    }

    if (pid) fetchRequest(pid)
  }, [pid])

  return [fields, isLoading, isError]
}
