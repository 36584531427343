import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Hidden from '@material-ui/core/Hidden'
import Slide from '@material-ui/core/Slide'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import Grow from '@material-ui/core/Grow'

import useScrollTrigger from './useScrollTrigger'

function GrowOnScroll(props) {
  const { children, inverse, threshold, disableHysteresis, target, appear, style, timeout } = props
  const trigger = useScrollTrigger({
    disableHysteresis,
    threshold,
    target
  })

  return (
    <Grow appear={appear} in={inverse ? !trigger : trigger} timeout={timeout} style={style}>
      {children}
    </Grow>
  )
}

GrowOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  inverse: PropTypes.bool,
  threshold: PropTypes.number,
  disableHysteresis: PropTypes.bool,
  target: PropTypes.element,
  appear: PropTypes.bool,
  style: PropTypes.shape({}),
  timeout: PropTypes.shape({})
}

GrowOnScroll.defaultProps = {
  inverse: false,
  threshold: 0,
  disableHysteresis: false,
  target: undefined,
  appear: false,
  style: undefined,
  timeout: undefined
}

function SlideOnScroll(props) {
  const {
    children,
    inverse,
    threshold,
    disableHysteresis,
    target,
    appear,
    direction,
    style,
    timeout
  } = props
  const trigger = useScrollTrigger({
    disableHysteresis,
    threshold,
    target
  })

  return (
    <Slide
      direction={direction}
      appear={appear}
      in={inverse ? !trigger : trigger}
      timeout={timeout}
      style={style}
    >
      {children}
    </Slide>
  )
}

SlideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  inverse: PropTypes.bool,
  threshold: PropTypes.number,
  disableHysteresis: PropTypes.bool,
  target: PropTypes.element,
  appear: PropTypes.bool,
  direction: PropTypes.string,
  style: PropTypes.shape({}),
  timeout: PropTypes.shape({})
}

SlideOnScroll.defaultProps = {
  inverse: false,
  threshold: 0,
  disableHysteresis: false,
  target: undefined,
  appear: false,
  direction: 'up',
  style: undefined,
  timeout: undefined
}

function CollapseOnScroll(props) {
  const {
    children,
    inverse,
    threshold,
    disableHysteresis,
    target,
    appear,
    direction,
    style,
    timeout
  } = props
  const trigger = useScrollTrigger({
    disableHysteresis,
    threshold,
    target
  })

  return (
    <Collapse
      direction={direction}
      appear={appear}
      in={inverse ? !trigger : trigger}
      timeout={timeout}
      style={style}
    >
      {children}
    </Collapse>
  )
}

CollapseOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  inverse: PropTypes.bool,
  threshold: PropTypes.number,
  disableHysteresis: PropTypes.bool,
  target: PropTypes.element,
  appear: PropTypes.bool,
  direction: PropTypes.string,
  style: PropTypes.shape({}),
  timeout: PropTypes.shape({})
}

CollapseOnScroll.defaultProps = {
  inverse: false,
  threshold: 0,
  disableHysteresis: false,
  target: undefined,
  appear: false,
  direction: 'down',
  style: undefined,
  timeout: undefined
}

function ElevateOnScroll(props) {
  const { children, inverse, threshold, disableHysteresis, target, elevation } = props
  const trigger = useScrollTrigger({
    disableHysteresis,
    threshold,
    target
  })

  return inverse
    ? React.cloneElement(children, {
        elevation: trigger ? 0 : elevation
      })
    : React.cloneElement(children, {
        elevation: trigger ? elevation : 0
      })
}

ElevateOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  inverse: PropTypes.bool,
  threshold: PropTypes.number,
  disableHysteresis: PropTypes.bool,
  target: PropTypes.element,
  elevation: PropTypes.number
}

ElevateOnScroll.defaultProps = {
  inverse: false,
  threshold: 0,
  disableHysteresis: true,
  target: undefined,
  elevation: 4
}

export { SlideOnScroll, GrowOnScroll, CollapseOnScroll, ElevateOnScroll }
