import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import { ConfigContextProvider } from './components/Config'
import * as serviceWorker from './serviceWorker'

import './fonts/metropolis.css'

const configUrl = `${process.env.PUBLIC_URL}/config.json`
fetch(configUrl)
  .then(response => {
    return response.json()
  })
  .then(data => {
    // Fetched a JSON
    ReactDOM.render(
      <ConfigContextProvider config={data}>
        <App />
      </ConfigContextProvider>,
      document.getElementById('root')
    )
  })
  .catch(err => {
    // Did not fetch a JSON
    ReactDOM.render(
      <ConfigContextProvider config={{}}>
        <App />
      </ConfigContextProvider>,
      document.getElementById('root')
    )
  })

// ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
