import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Container from '@material-ui/core/Container'

import Hidden from '@material-ui/core/Hidden'
import { makeStyles, useTheme, fade } from '@material-ui/core/styles'
import blueGrey from '@material-ui/core/colors/blueGrey'

import defaultWideLogo from './branding_h48px.svg'

const useStyles = makeStyles(theme => ({
  footer: {
    marginTop: 'auto',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    // marginBottom: theme.spacing(6),
    backgroundColor: fade(blueGrey[50], 0.5),
    // Tablet or smaller
    [theme.breakpoints.down('md')]: {
      paddingLeft: 72, // clear the nav rail
      paddingRight: 0
    },
    // Mobile
    [theme.breakpoints.down('xs')]: {
      padding: 0 // no gutters
    }
  }
}))

const Footer = React.forwardRef(function Footer({ children, className }, ref) {
  const classes = useStyles()
  return (
    <Hidden mdDown>
      <Container
        maxWidth={false}
        component="footer"
        className={clsx(classes.footer, className)}
        ref={ref}
      >
        {children}
      </Container>
    </Hidden>
  )
})

export default Footer

Footer.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

Footer.defaultProps = {
  className: null
}

const useLogoStyles = makeStyles(theme => ({
  logo: {
    height: 64
  }
}))

function FooterLogo() {
  const classes = useLogoStyles()
  const {
    branding: { logo: { wide: wideLogo } } = {
      logo: { wide: null, square: null }
    }
  } = useTheme()

  return (
    <img
      alt="logo"
      src={wideLogo ? `${process.env.PUBLIC_URL}/${wideLogo}` : defaultWideLogo}
      className={classes.logo}
    />
  )
}

export { FooterLogo }
