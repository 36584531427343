import React from 'react'
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme, makeStyles } from '@material-ui/core/styles'

import { ReactComponent as Artwork } from '../../Artwork/undraw_going_up_ttm5.svg'

const useStyles = makeStyles(theme => ({ fab: { margin: '0 auto' } }))

export default function AutosaveDialog({ open, handleClose }) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const classes = useStyles()

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">Borrador guardado</DialogTitle>
      <DialogContent>
        <Artwork />
      </DialogContent>
      <DialogContent>
        <DialogContentText>
          Una olla de algo más vaca que carnero, salpicón las más noches, duelos y quebrantos los
          sábados, lantejas los viernes, algún palomino de añadidura los domingos, consumían las
          tres partes de su hacienda.
        </DialogContentText>
        <DialogContentText>
          El resto della concluían sayo de velarte, calzas de velludo para las fiestas, con sus
          pantuflos de lo mesmo, y los días de entresemana se honraba con su vellorí de lo más fino.
        </DialogContentText>
      </DialogContent>
      <DialogContent>
        <Fab
          variant="extended"
          color="primary"
          aria-label="Envíame un enlace para continuar más tarde"
          className={classes.fab}
        >
          Envíame un enlace para continuar más tarde
        </Fab>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Mal
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Prefiero seguir con el trámite
        </Button>
      </DialogActions>
    </Dialog>
  )
}
