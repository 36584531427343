import React, { useEffect, useState } from 'react'

import Typography from '@material-ui/core/Typography'
import { useUtils } from '@material-ui/pickers'

export default function Clock() {
  // Current time
  const utils = useUtils()
  const [now, setNow] = useState(utils.date())
  const formattedDate = `${utils.formatByString(now, 'cccc')}, ${utils.formatByString(now, 'PPP')}`
  const formattedTime = utils.formatByString(now, 'pp')
  // Refresh logic
  useEffect(() => {
    const timer = setTimeout(() => {
      setNow(utils.date())
    }, 1000)
    return () => clearTimeout(timer)
  }, [now, setNow, utils])
  return (
    <>
      <Typography variant="overline" gutterBottom>
        {formattedDate}
      </Typography>
      <Typography variant="h3" gutterBottom>
        {formattedTime}
      </Typography>
    </>
  )
}
