import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
  typography: {
    //padding: theme.spacing(2)
  },
  paper: {
    //padding: theme.spacing(2)
  }
}))

function ControlledDrawer({ anchor, open, handleClose, children }) {
  const classes = useStyles()
  const id = open ? 'drawer' : undefined

  return (
    <Drawer
      id={id}
      open={open}
      anchor={anchor}
      onClose={handleClose}
      PaperProps={{ classes: { root: classes.paper } }}
    >
      {children}
    </Drawer>
  )
}

export { ControlledDrawer }
