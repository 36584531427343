import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Container from '@material-ui/core/Container'
import Badge from '@material-ui/core/Badge'
import CloudDoneTwoToneIcon from '@material-ui/icons/CloudDoneTwoTone'
import Hidden from '@material-ui/core/Hidden'

import Slide from '@material-ui/core/Slide'
import Grow from '@material-ui/core/Grow'
import Collapse from '@material-ui/core/Collapse'
import useScrollTrigger from '@material-ui/core/useScrollTrigger'

// Rail icons
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone'

import AdjustIcon from '@material-ui/icons/Adjust' // Current section
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked' // Section not completed
import CheckCircleIcon from '@material-ui/icons/CheckCircle' // Section completed

import CheckIcon from '@material-ui/icons/Check'
import Link from '@material-ui/core/Link'

import { makeStyles } from '@material-ui/core/styles'

// Layout blocks
import {
  AppBar,
  AppBarLogo,
  AppBarMenu,
  AppBarHeading,
  AppBarActions,
  AppBrandingBar,
  Header,
  Content,
  BottomNavigation,
  Footer,
  FooterLogo,
  Sidebar,
  OffsetSidebar,
  ContentSection,
  FooterSection
} from '../Layout'

// Form layout blocks
import { NavigationButtons, PrevNavigationButton, NavigationBar, NavigationRail } from '../Form'

// Content blocks
import ContentFields from '../Content/Fields'
import ContentDocuments from '../Content/Documents'
import BrandingSidebar from '../Sidebar/BrandingSidebar'
import OffsetSeparator from '../Sidebar/OffsetSeparator'
import ProgressSidebar from '../Sidebar/ProgressSidebar'
import PartiesSidebar from '../Sidebar/PartiesSidebar'
import ProgressSteps from './ProgressSteps'
// import AutosaveBar from './Autosave/AutosaveBar'

import { ScrollToTop } from '../Layout/Scroll'

// To be moved in
import Divider from '@material-ui/core/Divider'
import Chip from '@material-ui/core/Chip'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone'
import AddCommentTwoToneIcon from '@material-ui/icons/AddCommentTwoTone'

// App branding
import { ReactComponent as AppLogo } from '../Layout/app.svg'

// Experimental
import { ReactComponent as Logo } from '../Layout/branding_h48px.svg'
import { HeaderPartyChip } from './InterestedPartyChip'
import AutosaveBar from '../Autosave/AutosaveBar'
import { useSchemaDefinition } from '../../Context/SchemaContext'

// Context
import { useProgressState } from '../../Context/ProgressContext'

const debugHighlighter = 'white' // 'rgba(207, 232, 252, 0.5)'

function CollapseOnScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 128,
    target: window ? window() : undefined
  })

  return (
    <Collapse
      appear={false}
      direction="down"
      in={!trigger}

      //unmountOnExit
      //mountOnEnter
      //timeout={{ enter: 100, exit: 100 }}
      //style={{ transitionDelay: '100ms' }}
    >
      {children}
    </Collapse>
  )
}

CollapseOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

function GrowOnScroll(props) {
  const { children, window } = props
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 128,
    target: window ? window() : undefined
  })

  return (
    <Grow
      appear={false}
      in={!trigger}
      //unmountOnExit
      //mountOnEnter
      //timeout={{ enter: 200, exit: 200 }}
      //style={{ transformOrigin: '50% 50% 0' }}
      style={{ transitionDelay: '100ms' }}
    >
      {children}
    </Grow>
  )
}

GrowOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
}

const useStyles = makeStyles(theme => ({
  appBar: {
    // borderBottom: '2px solid'
    // borderColor: theme.palette.common.black
    marginTop: theme.spacing(8),
    '& $heroDivider': {
      marginTop: theme.spacing(2)
    }
  },
  titleSection: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  saveSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  centeredContainer: {
    background: debugHighlighter,
    // paddingTop: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
    minHeight: '120px'
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4)
  },
  divider: {
    height: '2px'
  },
  heroDivider: {
    height: '2px',
    background: theme.palette.common.black
  },
  logo: {
    width: 200,
    height: 50,
    position: 'fixed',
    top: 16,
    left: 16,
    background: 'url("https://ep01.epimg.net/iconos/v2.x/v2.0/logos/el_pais.svg") 0 0 no-repeat',
    zIndex: 3000
  },
  save: {
    width: 200,
    height: 50,
    position: 'fixed',
    top: 16,
    right: 16,
    background:
      'url("https://ep01.epimg.net/iconos/v2.x/v2.0/logos/el_pais.svg") top right no-repeat',
    zIndex: 3000
  },
  experimental: {},
  // to be moved in
  verticalDivider: {
    width: 2,
    background: theme.palette.text.primary
  },
  button: { color: theme.palette.common.black, marginLeft: theme.spacing(1) },
  chip: {
    background: theme.palette.common.black,
    color: theme.palette.common.white
  },
  icon: {
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1.5)
  },
  appLogo: {
    flexShrink: 0,
    width: theme.spacing(21)
  },
  brandingToolbar: {
    marginBottom: theme.spacing(0),
    //padding: theme.spacing(1, 0),
    borderBottomStyle: 'solid',
    borderBottomWidth: 0,
    borderBottomColor: '#b0bec5',
    justifyContent: 'space-between'
    // alignItems: 'flex-end'
  }
}))

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      Copyright ©
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

function Links() {
  return (
    <Typography variant="body2" color="textSecondary">
      Follow{' '}
      <Link color="inherit" href="https://material-ui.com/">
        some
      </Link>{' '}
      <Link color="inherit" href="https://material-ui.com/">
        useful
      </Link>{' '}
      <Link color="inherit" href="https://material-ui.com/">
        links
      </Link>
      .
    </Typography>
  )
}

function StateInspector() {
  const state = useProgressState()
  const left = Object.values(state.progress).filter(el => el === false).length
  return (
    <>
      {left}
      <pre>{JSON.stringify(state, '', 2)}</pre>
    </>
  )
}

export default function Page() {
  const classes = useStyles()
  const { step } = useParams()

  // Used by ProgressSidebar to hide itself
  // Used by PartiesSidebar to fade its divider
  const progressNavigationRef = useRef()

  // Used by PartiesSidebar to grow a divider below its contents
  const contentRef = useRef()

  // Used by ProgressNavigation to grow a divider below the next/prev buttons
  const footerRef = useRef()

  // Match step to definition
  const def = useSchemaDefinition()
  const current = Array.from(def).find(v => v.step === step)

  return (
    <>
      {/*<AutosaveBar />*/}

      {/*<AppBar className={classes.experimental}>
        <Toolbar>
          <AppBarMenu />
          <AppBarLogo />
          <AppBarHeading>
            <Typography variant="h6" noWrap>
              Registro electrónico
            </Typography>
          </AppBarHeading>
          <AppBarActions>
            <IconButton aria-label="save">
              <CloudDoneTwoToneIcon />
            </IconButton>
            <HeaderPartyChip slideEl={contentRef} />
          </AppBarActions>
        </Toolbar>
  </AppBar>*/}

      <ScrollToTop />

      <AppBar>
        <Hidden mdDown>
          <CollapseOnScroll>
            <Container maxWidth="lg">
              <Toolbar disableGutters className={classes.brandingToolbar}>
                <Typography variant="h5" component="h1" noWrap>
                  Registro electrónico
                </Typography>
                <AppBarLogo />
              </Toolbar>
            </Container>
          </CollapseOnScroll>
          <GrowOnScroll>
            <Divider className={classes.divider} />
          </GrowOnScroll>
        </Hidden>

        <Container maxWidth="lg">
          <Toolbar disableGutters>
            <AppBarMenu />

            <Hidden lgUp>
              <AppBarHeading>
                <Typography variant="h6" noWrap>
                  Registro electrónico
                </Typography>
              </AppBarHeading>
              <AppBarLogo />
            </Hidden>

            <Hidden mdDown>
              <NavigationBar />
            </Hidden>

            <AppBarActions>
              <Chip
                icon={<PeopleAltTwoToneIcon fontSize="small" />}
                label={'BLANCO VIDAL, MANUELA'}
                color="primary"
                onClick={() => {}}
                onDelete={() => {}}
                deleteIcon={<ExpandMoreIcon fontSize="small" />}
                classes={{ root: classes.chip, icon: classes.icon }}
              />
              <IconButton className={classes.button}>
                <AddCommentTwoToneIcon />
              </IconButton>
            </AppBarActions>
          </Toolbar>
        </Container>
      </AppBar>

      <Hidden smUp>
        <Toolbar component="nav">
          <PrevNavigationButton edge="start" />
        </Toolbar>
      </Hidden>

      <main>
        <Header>
          <Typography variant="h4" color="textSecondary">
            Solicitud de actuación: queja o sugerencia
          </Typography>
          <Typography variant="h2" gutterBottom>
            {current.fullName}
          </Typography>
          {/*<Typography variant="h4" color="textSecondary" paragraph>
          Presentación por los residentes en España de las declaraciones sobre las transacciones
          económicas y los saldos de activos y pasivos financieros con el exterior (formulario ETE)
  </Typography>*/}
        </Header>

        <Content ref={contentRef}>
          {/*<Sidebar position="left" component="aside">
          <ProgressSidebar fadeEl={progressNavigationRef} />
</Sidebar>*/}

          {/*<OffsetSidebar position="left" component="aside" className={classes.demo}>
          <BrandingSidebar growEl={contentRef} fadeEl={progressNavigationRef} />
        </OffsetSidebar>

<OffsetSeparator growEl={contentRef} fadeEl={progressNavigationRef} />*/}

          {step === 'datos' && <ContentFields />}
          {step === 'documentos' && <ContentDocuments />}
          {/*<StateInspector />*/}

          {/* <ContentSubSection ref={progressNavigationRef}>
            <ProgressNavigation />
            <Grow in={footerVisible} timeout={350} style={{ transformOrigin: 'top left' }}>
              <Divider className={classes.heroDivider} />
            </Grow>
</ContentSubSection> */}

          {/*<Sidebar position="right" component="nav">
          <PartiesSidebar growEl={contentRef} fadeEl={progressNavigationRef} />
</Sidebar>*/}
        </Content>
      </main>

      <BottomNavigation ref={progressNavigationRef}>
        <NavigationButtons growEl={footerRef} />
      </BottomNavigation>

      <NavigationRail />

      <Hidden mdDown>
        <Footer ref={footerRef}>
          {/*<FooterSection>
            <ProgressSteps />
          </FooterSection>*/}

          <FooterSection>
            <FooterLogo />

            <Typography variant="h6">Registro electrónico</Typography>
            <Typography variant="caption" paragraph>
              Ayuntamiento de Benalmádena
            </Typography>
            <Copyright />
            <Links />
          </FooterSection>
        </Footer>
      </Hidden>
    </>
  )
}
