import React from 'react'
import PropTypes from 'prop-types'
import { useParams, Link } from 'react-router-dom'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Fade from '@material-ui/core/Fade'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import DoneIcon from '@material-ui/icons/Done'
import useScrollTrigger from '../Experimental/useScrollTrigger'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper
    // marginTop: '25vh'
  },
  inline: {
    display: 'inline'
  },
  item: {
    borderLeft: '2px solid',
    borderColor: theme.palette.divider,
    '&:hover': {
      borderColor: theme.palette.common.black,
      '& $itemHeading': {
        color: theme.palette.common.black
      }
    },
    '&$active': {
      borderColor: theme.palette.primary.main
    },
    '&$completed': {
      // borderColor: theme.palette.success.main,
      '& $itemHeading': {
        // color: theme.palette.success.main
      },
      '& $itemIcon': {
        color: theme.palette.success.main
      }
    }
  },
  active: {
    '& $itemHeading': {
      color: theme.palette.common.black
    }
  },
  completed: {},
  itemHeading: {},
  itemIcon: {}
}))

export default function ProgressSidebar({ fadeEl }) {
  const classes = useStyles()
  const { step } = useParams()

  // Triggers when the bottom progress navigation appears at the bottom
  // fadeEl is progressNavigationRef
  const fadeTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: fadeEl.current ? fadeEl.current.offsetTop - window.innerHeight + 200 : null
  })

  return (
    <Fade in={!fadeTrigger}>
      <List className={classes.root}>
        <ListItem
          alignItems="flex-start"
          className={clsx(classes.item, { [classes.active]: step === 'datos' })}
        >
          <Link to="/layouts/desktop/datos" style={{ textDecoration: 'none' }}>
            <ListItemText
              primary={
                <Typography variant="h6" color="textSecondary" className={classes.itemHeading}>
                  Datos de la solicitud
                </Typography>
              }
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    Pendiente
                  </Typography>
                  {' — Tienes 8 campos por completar'}
                </>
              }
            />
          </Link>
        </ListItem>

        <ListItem
          alignItems="flex-start"
          className={clsx(classes.item, { [classes.active]: step === 'documentos' })}
        >
          <Link to="/layouts/desktop/documentos" style={{ textDecoration: 'none' }}>
            <ListItemText
              primary={
                <Typography variant="h6" color="textSecondary" className={classes.itemHeading}>
                  Documentos requeridos
                </Typography>
              }
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    Pendiente
                  </Typography>
                  {' — Tienes 3 documentos por aportar'}
                </>
              }
            />
          </Link>
        </ListItem>

        <ListItem
          alignItems="flex-start"
          className={clsx(classes.item, classes.completed, { [classes.active]: step === 'otra' })}
        >
          <Link to="/layouts/desktop/otra" style={{ textDecoration: 'none' }}>
            <ListItemText
              primary={
                <Typography variant="h6" color="textSecondary" className={classes.itemHeading}>
                  Otra sección <DoneIcon className={classes.itemIcon} />
                </Typography>
              }
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    Completada
                  </Typography>
                </>
              }
            />
          </Link>
        </ListItem>

        <ListItem
          alignItems="flex-start"
          className={clsx(classes.item, classes.disabled, { [classes.active]: step === 'pago' })}
        >
          <Link to="/layouts/desktop/pago" style={{ textDecoration: 'none' }}>
            <ListItemText
              primary={
                <Typography variant="h6" color="textSecondary" className={classes.itemHeading}>
                  Pago de tasa
                </Typography>
              }
              secondary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    No disponible
                  </Typography>
                  {' — Por favor, completa las otra secciones antes de proceder al pago'}
                </>
              }
            />
          </Link>
        </ListItem>
      </List>
    </Fade>
  )
}

ProgressSidebar.propTypes = {
  fadeEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.oneOfType([
        PropTypes.instanceOf(HTMLInputElement),
        PropTypes.instanceOf(Element)
      ])
    })
  ])
}

ProgressSidebar.defaultProps = {
  fadeEl: { current: null }
}
