import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Button from '@material-ui/core/Button'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'

import { ReactComponent as AppLogo } from '../Layout/app.svg'

import Divider from '@material-ui/core/Divider'

import Grow from '@material-ui/core/Grow'
import Fade from '@material-ui/core/Fade'

import useScrollTrigger from '../Experimental/useScrollTrigger'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
    // marginTop: '25vh',
    // Tablet or smaller
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  },
  inline: {
    display: 'inline'
  },
  changePartiesButton: {
    marginBottom: theme.spacing(2)
  },
  item: {
    // borderLeft: '2px solid',
    borderColor: theme.palette.divider,
    '&:hover': {
      borderColor: theme.palette.primary.main
    },
    '&$active': {
      borderColor: theme.palette.common.black
    }
  },
  active: {},
  heroDivider: {
    height: '2px',
    background: theme.palette.common.black
  },
  verticalDivider: {
    width: 2,
    height: '100%',
    background: theme.palette.text.primary
  }
}))

export default function BrandingSidebar({ growEl, fadeEl }) {
  const classes = useStyles()

  // Triggers when the component gets pinned to the top
  // growEl is contentRef
  const growTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: growEl.current ? growEl.current.offsetTop - (72 + 16) : null
  })

  // Triggers when the bottom progress navigation appears at the bottom
  // fadeEl is progressNavigationRef
  const fadeTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: fadeEl.current ? fadeEl.current.offsetTop - window.innerHeight + 360 : null
  })

  return (
    <>
      <Fade in={!fadeTrigger}>
        <div>
          <Grow in={growTrigger} timeout={350} style={{ transformOrigin: 'bottom center' }}>
            <AppLogo />
          </Grow>
        </div>
      </Fade>
    </>
  )
}

BrandingSidebar.propTypes = {
  growEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.oneOfType([
        PropTypes.instanceOf(HTMLInputElement),
        PropTypes.instanceOf(Element)
      ])
    })
  ]),
  fadeEl: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.oneOfType([
        PropTypes.instanceOf(HTMLInputElement),
        PropTypes.instanceOf(Element)
      ])
    })
  ])
}

BrandingSidebar.defaultProps = {
  growEl: { current: null },
  fadeEl: { current: null }
}
